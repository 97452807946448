import { SxProps } from '@mui/material'
import { theme } from 'components/atoms/styles/theme'

const heading: SxProps = { margin: '10px 0', fontWeight: '400' }
const listItemBtn: SxProps = { paddingTop: '15px' }
const programAuthor: SxProps = { fontSize: '11px' }
const listItem: SxProps = { padding: '15px 0' }
const fullWidth: SxProps = { width: '100%' }
const marginTop10px: SxProps = { marginTop: '20px' }
const imgListItem: SxProps = {
  cursor: 'pointer',
  transition: 'background,padding 0.2s ease-in-out',
  background: '#f5f5f5',
  padding: '5px',
  ':hover': {
    background: '#e0e0e0',
    padding: '10px',
    transition: 'background,padding 0.2s ease-in-out'
  }
}
const imgListItemBar: SxProps = {
  '.MuiImageListItemBar-title,.MuiImageListItemBar-subtitle': {
    color: theme.palette.neutral_dark.dark
  }
}

export default {
  heading,
  listItemBtn,
  programAuthor,
  listItem,
  fullWidth,
  marginTop10px,
  imgListItem,
  imgListItemBar
}
