import { useState, useEffect } from 'react'

type OSType = 'iOS' | 'Android' | 'iPad' | 'Windows' | 'Unknown'
type BrowserType =
  | 'Chrome'
  | 'Safari'
  | 'Firefox'
  | 'Edge'
  | 'Opera'
  | 'Unknown'

interface DeviceInfo {
  os: OSType
  browser: BrowserType
}

const useAgentDetection = (): DeviceInfo => {
  const [os, setOS] = useState<OSType>('Unknown')
  const [browser, setBrowser] = useState<BrowserType>('Unknown')

  useEffect(() => {
    const handleAgentDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase()

      // os detection
      if (/iphone|ipod/.test(userAgent)) {
        setOS('iOS')
      } else if (/ipad/.test(userAgent)) {
        setOS('iPad')
      } else if (/android/.test(userAgent)) {
        setOS('Android')
      } else if (/win/.test(userAgent)) {
        setOS('Windows')
      } else {
        setOS('Unknown')
      }

      // browser detection
      if (
        userAgent.includes('chrome') &&
        !userAgent.includes('edge') &&
        !userAgent.includes('opr')
      ) {
        setBrowser('Chrome')
      } else if (
        userAgent.includes('safari') &&
        !userAgent.includes('chrome')
      ) {
        setBrowser('Safari')
      } else if (userAgent.includes('firefox')) {
        setBrowser('Firefox')
      } else if (userAgent.includes('opr') || userAgent.includes('opera')) {
        setBrowser('Opera')
      } else if (userAgent.includes('edge')) {
        setBrowser('Edge')
      } else {
        setBrowser('Unknown')
      }
    }

    handleAgentDetection()
  }, [])

  return { os, browser }
}

export default useAgentDetection
