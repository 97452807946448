import { createSlice } from '@reduxjs/toolkit'
import { TreeNodeInterface } from 'types'
import { v4 as uuidv4 } from 'uuid'
import log from 'loglevel'

interface BotTreeControllerStateInterface {
  botTree?: TreeNodeInterface | undefined
  // botTree?: any | undefined
  selectedNode?: any | undefined
  statesList?: any | undefined
  optionsList?: any | undefined
}

// Define the initial state using that type
const initialState: BotTreeControllerStateInterface = {
  botTree: {
    id: 'uuid-root',
    name: 'Start State',
    desc: undefined,
    nodeType: 'state_new',
    objectID: uuidv4(),
    stateType: undefined,
    audioURLs: [''],
    refID: undefined,
    children: undefined,
    isComplete: false,
    distToEndStates: undefined
  },
  selectedNode: undefined,
  statesList: undefined,
  optionsList: undefined
}

export const botTreeController = createSlice({
  name: 'bot-tree-controller',
  initialState,
  reducers: {
    resetBotTreeDSAction: (state) => {
      log.info('**** botTreeController::resetBotTreeDSAction ****')
      log.info('state.botTree: ', state.botTree as TreeNodeInterface)

      return {
        ...initialState
      }
    },
    setBotTreeDSAction: (state, action) => {
      log.info('**** botTreeController::setBotTreeDSAction ****')
      log.info('**** action.payload: ', action.payload)

      return {
        ...state,
        botTree: action.payload.botTree,
        statesList: action.payload.statesList,
        optionsList: action.payload.optionsList
      }
    },
    setSelectedNodeAction: (state, action) => {
      log.info('**** botTreeController::setSelectedNodeAction ****')
      log.info(state)
      log.info(action)
      log.info(action.payload)
      return {
        ...state,
        selectedNode: action.payload.selectedNode
      }
    },
    clearSelectedNodeAction: (state) => {
      log.info('**** botTreeController::clearSelectedNodeAction ****')
      return {
        ...state,
        selectedNode: undefined
      }
    }
  }
})

export const {
  resetBotTreeDSAction,
  setBotTreeDSAction,
  setSelectedNodeAction,
  clearSelectedNodeAction
} = botTreeController.actions
export default botTreeController.reducer
