import Bowser from 'bowser'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { setIsInternalDev } from 'datastore/slices/app-controller'
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { useGetUser } from 'hooks/use-authentication'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useAnalytics } from 'reactfire'

export function getDeviceInfo() {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const browserName = browser.getBrowserName()
  const browserVersion = browser.getBrowserVersion()
  const osName = browser.getOSName()
  const osVersion = browser.getOSVersion()

  return {
    browserName,
    browserVersion,
    osName,
    osVersion
  }
}

function usePageViewTrackingFA() {
  const analytics = useAnalytics()
  const user = useGetUser()
  const deviceInfo = getDeviceInfo()

  const isInternalDev = useStoreSelector(
    ({ appController }) => appController.isInternalDev
  )

  setUserId(analytics, user.uid, { global: true })
  setUserProperties(
    analytics,
    {
      browser_name: deviceInfo.browserName,
      browser_version: deviceInfo.browserVersion,
      os_name: deviceInfo.osName,
      os_version: deviceInfo.osVersion,
      user_id_dimension: user.uid,
      is_internal: isInternalDev,
      deployment_version: process.env.REACT_APP_DEPLOYMENT_VERSION
    },
    { global: true }
  )

  const trackFA = useCallback((pathname: string) => {
    const destPath = pathname.endsWith('/')
      ? pathname.substring(0, pathname.length - 1)
      : pathname

    logEvent(analytics, 'page_view', {
      page_location: location.href,
      page_path: destPath
    })
  }, [])

  return trackFA
}

export function usePageViewTracking() {
  const dispatch = useStoreDispatch()
  const { pathname, search } = useLocation()

  const destPath =
    pathname.length > 1 && pathname.endsWith('/')
      ? pathname.substring(0, pathname.length - 1)
      : pathname

  const trackFA = usePageViewTrackingFA()

  useEffect(() => {
    if (search.includes('internal')) {
      dispatch(setIsInternalDev(true))
    }
  }, [])

  useEffect(() => {
    trackFA(destPath)
  }, [pathname])
}
