import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { updateTempConfigOptionsAction } from 'datastore/slices/v2-program-controller'

const ASREnforceTargetLangDropdown: React.FC = () => {
  const [tempSelectedVersion, setTempSelectedVersion] = useState('')

  const asrEnforceTargetLang = useStoreSelector(
    (storeselector) => storeselector.v2ProgramController.asrEnforceTargetLang
  )

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  const dispatch = useStoreDispatch()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTempSelectedVersion(event.target.value)
    dispatch(
      updateTempConfigOptionsAction({
        tempAsrEnforceTargetLang: event.target.value
      })
    )
  }

  useEffect(() => {
    setTempSelectedVersion(asrEnforceTargetLang)
  }, [isMobileMenuOpen])

  return (
    <Box sx={{ marginBottom: 1.2 }}>
      <Typography
        variant="caption"
        component="legend"
        sx={{ color: 'black', marginBottom: -1.5, fontSize: '1.2rem' }}>
        ASR Enforce Target Language
      </Typography>
      <Select
        value={tempSelectedVersion}
        onChange={handleChange}
        sx={{
          width: '100%',
          height: '30px',
          fontSize: '0.7rem'
        }}>
        {['true', 'false'].map((val) => (
          <MenuItem key={val} value={val} sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default ASREnforceTargetLangDropdown
