import { SxProps } from '@mui/material'
import { theme } from 'components/atoms/styles/theme'

const stackContainer: SxProps = {
  width: '100%',
  borderRadius: '4px',
  bgcolor: theme.palette.neutral_light.light
}

const titleBox: SxProps = {
  p: 2,
  borderRadius: '4px 4px 0px 0px',
  bgcolor: theme.palette.primary.light
}

const textInputContainer: SxProps = {
  p: 3
}

const textInput: SxProps = {
  display: 'flex',
  flexDirection: 'column'
}

const buttonsContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}

export default {
  stackContainer,
  titleBox,
  textInputContainer,
  textInput,
  buttonsContainer
}
