import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { theme } from 'components/atoms/styles/theme'
import TextInputComponent from 'components/atoms/inputs/text-input'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'

import { registerUserService } from 'datastore/slices/auth-controller'
import { useStoreDispatch } from 'datastore/config/hooks'
import log from 'loglevel'
import { Alert } from 'alert'

const formTitle = 'Tell us more about you'

const RegisterFormComponent = () => {
  const dispatch = useStoreDispatch()
  const [fullName, setFullName] = React.useState<string>('')
  const [company, setCompany] = React.useState<string>('')
  const [awarenessChannel, setAwarenessChannel] = React.useState<string>('')

  const onFullNameChange = (e: any) => {
    setFullName(e.target.value)
  }

  const onCompanyChange = (e: any) => {
    setCompany(e.target.value)
  }

  const onAwarenessChannelChange = (e: any) => {
    setAwarenessChannel(e.target.value)
  }

  const handleSubmit = () => {
    if (!fullName) {
      Alert.show('&&&& Fullname info is required &&&&')
    } else if (!company) {
      Alert.show('&&&& Company info is required &&&&')
    } else if (!awarenessChannel) {
      Alert.show('&&&& Let us know how you heard about us is required &&&&')
    } else {
      log.info(' DISPATCHING REGISTER ')
      dispatch(
        registerUserService({
          fullName,
          company,
          awarenessChannel
        })
      )
    }
  }

  return (
    <Stack
      sx={{
        width: '100%',
        borderRadius: '4px',
        bgcolor: theme.palette.neutral_light.light
      }}>
      <Box
        sx={{
          p: 2,
          borderRadius: '4px 4px 0px 0px',
          bgcolor: theme.palette.primary.light
        }}>
        <Typography
          id="form-title"
          variant="h4"
          color={theme.palette.neutral_dark.dark}>
          {formTitle}
        </Typography>
      </Box>
      <Stack
        spacing={2}
        sx={{
          p: 3
        }}>
        <Stack
          spacing={1}
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <TextInputComponent
            width="100%"
            onValueChange={onFullNameChange}
            label="Full Name"
          />
          <TextInputComponent
            width="100%"
            onValueChange={onCompanyChange}
            label="Company"
          />
          <TextInputComponent
            width="100%"
            onValueChange={onAwarenessChannelChange}
            label="How did you hear about us?"
          />
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
          <DefaultButtonComponent
            label="Continue"
            onButtonClick={handleSubmit}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default RegisterFormComponent
