import axios from 'axios'

import { URI_CREATE_BLOCK, URI_BLOCK } from 'datastore/apis/constants'
import { BlockItemInterface } from 'types'
import log from 'loglevel'

async function createBlock(
  data: { name: string; type: string; programID: string },
  adminID: string
) {
  return axios.post(`${URI_CREATE_BLOCK}`, data, {
    params: {
      adminID
    }
  })
}

async function fetchBlock(blockID: string) {
  return axios.get<BlockItemInterface>(`${URI_BLOCK}/${blockID}`)
}

async function updateBlockName(blockID: string, data: { name: string }) {
  return axios.put<BlockItemInterface>(`${URI_BLOCK}/${blockID}/name`, data)
}
async function updateBlockData(blockID: string, data: any) {
  log.info('blockAPIs::updateBlockData::blockID: ', blockID)
  log.info('blockAPIs::updateBlockData::data: ', data)
  log.info('blockAPIs::updateBlockData::data: ', typeof data)

  return axios.put(`${URI_BLOCK}/${blockID}/data`, data)
}

async function deleteBlock(blockID: string) {
  return axios.delete(`${URI_BLOCK}/${blockID}`)
}

async function interactBlock(
  blockID: string,
  data: {
    userID: string
    status: string
    userUtteranceFile: any
    userAction: string
  }
) {
  log.info('blockAPIs::interactBlock::blockID: ', blockID)
  log.info('blockAPIs::interactBlock::data.userID: ', data.userID)
  log.info('blockAPIs::interactBlock::data.status: ', data.status)
  log.info(
    'blockAPIs::interactBlock::data.userUtteranceFile: ',
    data.userUtteranceFile
  )
  log.info('blockAPIs::interactBlock::data.userAction: ', data.userAction)

  const formData = new FormData()
  formData.append('userID', data.userID)
  formData.append('status', data.status)
  formData.append('userUtterance', data.userUtteranceFile)
  formData.append('userAction', data.userAction)
  return axios.post(`${URI_BLOCK}/${blockID}/interact`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

async function publishBlock(blockID: string) {
  log.info('blockAPIs::publishBlock::blockID: ', blockID)

  return axios.post(`${URI_BLOCK}/${blockID}/publish`)
}

export default {
  createBlock,
  fetchBlock,
  updateBlockName,
  updateBlockData,
  deleteBlock,
  interactBlock,
  publishBlock
}
