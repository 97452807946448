import { createSlice } from '@reduxjs/toolkit'

import authAPI from 'datastore/apis/auth-api'
import adminAPI from 'datastore/apis/admin-api'
import {
  PAGE_ADMIN_PROGRAM_SELECT,
  TITLE_AUTH_ERROR_NOTIFICATION,
  MESSAGE_AUTH_ERROR_NOTIFICATION
} from 'datastore/utils/constants'
import {
  changePageService,
  showPopupService
} from 'datastore/slices/app-controller'
import { GoogleLoginResponse } from 'react-google-login'
import { PopupTypeEnum, UserAccountProfileInterface } from 'types'
import log from 'loglevel'

interface AuthRequirement {
  mustUserHaveLoggedIn?: boolean
  reason?: string
}

interface AuthControllerStateInterface {
  loggedIn: boolean
  acctProfile: UserAccountProfileInterface | null
  googleUserObject: GoogleLoginResponse['profileObj'] | null
  authRequirement: AuthRequirement
}

// Define the initial state using that type
const initialState: AuthControllerStateInterface = {
  loggedIn: false,
  acctProfile: null,
  googleUserObject: null,
  authRequirement: {
    mustUserHaveLoggedIn: false
  }
}

const authController = createSlice({
  name: 'auth-controller',
  initialState,
  reducers: {
    requestSignIn(state, action: { payload: AuthRequirement }) {
      return {
        ...state,
        authRequirement: action.payload
      }
    },
    setAuthInfoAction(state, action) {
      return {
        ...state,
        acctProfile: action.payload.user,
        googleUserObject: action.payload.googleUserObject,
        loggedIn: true
      }
    },
    resetAuthInfoAction() {
      return { ...initialState }
    }
  }
})

// ============
// Actions === functions to change the state object of a particular controller/slice
// ============
export const { setAuthInfoAction, resetAuthInfoAction, requestSignIn } =
  authController.actions
export default authController.reducer

// ============
// Service === Action Functions
// ============
export function loginWithGoogleHackService() {
  return async function (dispatch: any) {
    try {
      log.info(
        `***** Logging in with pre-defined token for easy debugging *****`
      )
      // to ensure hack works, update token with valid value copied from inspecting browser after google-login
      const valResponse = await authAPI.signInWithGoogle({
        token:
          'eyJhbGciOiJSUzI1NiIsImtpZCI6ImI0OWM1MDYyZDg5MGY1Y2U0NDllODkwYzg4ZThkZDk4YzRmZWUwYWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMzc2MTEwNDM3MTczLWRxdWswNGt0MzhlY2ZuYWh0dW5zNGI1cGpucjFvazZoLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMzc2MTEwNDM3MTczLWRxdWswNGt0MzhlY2ZuYWh0dW5zNGI1cGpucjFvazZoLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE4MjY2NDc0NjkzMTI3ODIwMjkyIiwiZW1haWwiOiJjb252YWl0ZXN0MDAyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiZEdBQnBZOXVDWjRlX1RKcjdpb2pvUSIsIm5hbWUiOiJjb252YWkgdGVzdDAwMiIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BRWRGVHA0b2V0VWJDaVRoZUxZbU1OemhjNFgwZC01dnlJSnZBanhraUVEZz1zOTYtYyIsImdpdmVuX25hbWUiOiJjb252YWkiLCJmYW1pbHlfbmFtZSI6InRlc3QwMDIiLCJsb2NhbGUiOiJlbiIsImlhdCI6MTY3NTkzNjE4OCwiZXhwIjoxNjc1OTM5Nzg4LCJqdGkiOiJlMDBiYmExYzczMjM3M2ZmMzVjNzU0YzRkMTM0MTcwZmEzNzgzODU0In0.XXUrHisZ1-a9HI_nd0D93V_TAK8VO2rI6EdiPaBs4SyIwfGcqe5KbuBslqkaoDv8L_099eLaVB6JqLD5uDGRBeJ5pVEHbZK0bfsgz6f936UnFGqnOwW4YfctMnM0_6l95Yv-JOY304_KUoqdoxyTZVSqzWId0oe9-L9CMmxkf0-7rKvmX__7NrIQP71BJLeGWZsfkL0Q-8nN2bZ37AAyPQqVkfhBz2paEgDjs2qKSxKhw6qVAfd_sHmak2Inrl75ZZNoglRV6c5cu1c0V9C0EXYP4EURe5JaMrfZ_tyTmtJ3x1FNS6kLmO3haSe0bCIQg966z2NZSva4JjV3oPqpKg'
      })
      if (valResponse.status === 200) {
        log.info(`***** valResponse.data: ${valResponse.data} *****`)
        const { jwToken } = valResponse.data
        //  log.warn(token);
        authAPI.setToken(jwToken)
        const userProfile: UserAccountProfileInterface = {
          email: valResponse.data.email,
          id: valResponse.data.id
        }
        //  log.info(res.data);
        dispatch(
          setAuthInfoAction({
            user: userProfile,
            googleUserObject: null
          })
        )
      }
      return null
    } catch (error) {
      log.error(error)
      return error
    }
  }
}

export function loginWithGoogleService(response: GoogleLoginResponse) {
  return async function (dispatch: any) {
    try {
      log.info(`***** ${response.tokenId} *****`)
      const valResponse = await authAPI.signInWithGoogle({
        token: response.tokenId
      })
      if (valResponse.status === 200) {
        const { jwToken } = valResponse.data
        //  log.warn(token);
        authAPI.setToken(jwToken)
        const userProfile: UserAccountProfileInterface = {
          email: valResponse.data.email,
          id: valResponse.data.id
        }
        //  log.info(res.data);
        dispatch(
          setAuthInfoAction({
            user: userProfile,
            googleUserObject: response.profileObj
          })
        )
      }
      return null
    } catch (error) {
      log.error(error)
      return error
    }
  }
}

export function registerUserService(userCredentials: any) {
  return async function (dispatch: any) {
    try {
      log.info(' ========== ')
      log.info(userCredentials.fullName)
      log.info(userCredentials.company)
      log.info(userCredentials.awarenessChannel)
      log.info(' ========== ')
      dispatch(changePageService(PAGE_ADMIN_PROGRAM_SELECT))
      return null
    } catch (error) {
      log.error(error)
      return error
    }
  }
}

export function logoutService() {
  return async function (dispatch: any) {
    try {
      log.info('!!! Logging Out !!!')

      dispatch(resetAuthInfoAction())
      return null
    } catch (error) {
      log.error(error)
      return error
    }
  }
}

export function loginWithEmailService(requestBody: any) {
  return async function (dispatch: any) {
    try {
      log.info(`***** ${JSON.stringify(requestBody)} *****`)

      // call endpoint to get authlist
      const authListResponse = await authAPI.fetchAuthList()
      if (authListResponse.status === 200) {
        const foundObj = authListResponse.data.find(
          (obj: any) => obj.email === requestBody.email
        )

        // if email is not in authlist, display pop-up notifying user
        if (foundObj === null || foundObj === undefined) {
          dispatch(
            showPopupService({
              type: PopupTypeEnum.notification,
              data: {
                title: TITLE_AUTH_ERROR_NOTIFICATION,
                message: MESSAGE_AUTH_ERROR_NOTIFICATION
              }
            })
          )
        } else {
          // call endpoint to fetch admin info using email
          let userResponse = await adminAPI.fetchUser({
            email: requestBody.email
          })

          // if admin object does not exist, create one
          if (!userResponse.data) {
            userResponse = await adminAPI.createUser({
              email: requestBody.email
            })
          }

          log.info('1111 &&&&&&&&&&&&&&&&')
          log.info('---- user auth info ----')
          log.info(userResponse.data)
          log.info('1111 ################')

          // login and cache user authentication information
          const userProfile: UserAccountProfileInterface = {
            email: userResponse.data.email,
            id: userResponse.data.id
          }
          dispatch(
            setAuthInfoAction({
              user: userProfile,
              googleUserObject: null
            })
          )
        }
      }
      return null
    } catch (error) {
      log.error(error)
      return error
    }
  }
}
