export interface LessonBlockInterface {
  blockID: string
  blockName: string
  desc: string
}

export interface ProgramItemInterface {
  id: string
  name: string
  desc: string
  ownerID: string
  imageUrl: string
  status: string
  publisher: string
  lessonBlocks: LessonBlockInterface[]
  reviewBlocks: any
  updatedAt: string
  createdAt: string
  deletedAt: string
  isDeleted: boolean
}

export interface V2ProgramItemInterface {
  id: string
  name: string
  desc: string
  ownerID: string
  imageUrl: string
  status: string
  publisher: string
  botID: string
  updatedAt: string
  createdAt: string
  deletedAt: string
  isDeleted: boolean
}

export interface BlockItemInterface {
  id: string
  name: string
  type: string
  desc: string
  programID: string
  draftBotID: string
  liveBotID: string
  draftBotJSON: JSON
  liveBotJSON: JSON
  prerequisites: string[]
  updatedAt: string
  createdAt: string
  deletedAt: string
  isDeleted: boolean
}

export interface MessageInterface {
  dataType: 'text' | 'audio'
  sender: 'user' | 'bot' | 'logger'
  data: string
}

export interface ConvoActionsInterface {
  name: 'speak' | 'listen'
  content: string
  contentType: 'text' | 'audioHash' | 'audioBase64'
  userIsCorrect?: boolean
}

export interface ModelInterface {
  data: string[]
}

export interface UserAccountProfileInterface {
  email: string
  id?: string
}

export interface SignInResponseInterface extends UserAccountProfileInterface {
  jwToken: string
}

export interface ChartNodeInterface {
  id: string
  name: string
  title: string
}

export interface TreeNodeInterface {
  id: string | undefined
  name: string | undefined
  desc: string | undefined
  nodeType: string
  objectID: string | undefined
  stateType: string | undefined
  audioURLs: string[] | undefined
  refID: string | undefined
  children: TreeNodeInterface[] | undefined
  isComplete: boolean
  distToEndStates: { [key: string]: number } | undefined
}

export interface ApiResponseInterface {
  success: boolean
  reason?: string
  data?: any
}

export interface ProgramListAPIParamsInterface {
  status: 'active' | 'inactive'
  owner: boolean
}

export interface DropDownInterface {
  ddID: string
  ddLabel: string
}

export interface ListItemInterface {
  liID: string
  liLabel: string
}

export interface ConvoUILogInterface {
  type: string
  speaker: string
  value: string
}

export enum PopupTypeEnum {
  none = 'none',
  feedback = 'feedback',
  notification = 'notification',
  deleteBlock = 'delete_block',
  createBlock = 'create_block',
  createProgram = 'create_program'
}

export interface PopupInterface {
  type: PopupTypeEnum
  data?: any
}

export interface ProgramSettingsInterface {
  name?: string
  desc?: string
  imageUrl?: string
  publisher?: string
}

export interface BlockSettingsInterface {
  name?: string
  desc?: string
}

export type SessionStatuses =
  | 'initializing'
  | 'ready_to_record'
  | 'playing'
  | 'pausing'
  | 'recording'
  | 'processing'
