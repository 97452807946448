import {
  getAuth,
  GoogleAuthProvider,
  ProviderId as FirebaseProviderId,
  signInAnonymously,
  signInWithPopup,
  linkWithCredential
} from 'firebase/auth'
import log from 'loglevel'
import { useCallback, createContext, useContext, useEffect } from 'react'
import { useFirebaseApp } from 'reactfire'

type ProviderId =
  | 'ANONYMOUS'
  | (typeof FirebaseProviderId)[keyof typeof FirebaseProviderId]

export interface AppUserInterface {
  uid: string
  providerId: string
  displayName: string | null
  isAnonymous: boolean | null
  email: string | null
  photoURL: string | null
  phoneNumber: string | null
  firstSessionAt?: string
}

interface AuthContextInterface {
  user: AppUserInterface
}

export const AuthContext = createContext<AuthContextInterface>({
  user: {} as AppUserInterface
})

/**
 * Gets the current user.
 */
export function useSigninUser() {
  const firebaseApp = useFirebaseApp()
  const auth = getAuth(firebaseApp)

  const signIn = useCallback(
    async (param?: { signInMethod: ProviderId }) => {
      const { signInMethod } = param || { signInMethod: 'ANONYMOUS' }
      try {
        if (signInMethod === FirebaseProviderId.GOOGLE) {
          const provider = new GoogleAuthProvider()
          // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
          await signInWithPopup(auth, provider)
        } else {
          await signInAnonymously(auth)
        }
      } catch (error) {
        const errorCode = error.code
        const errorMessage = error.message
        log.error({ errorMessage, errorCode })
      }
    },
    [auth]
  )

  return { signIn, auth }
}

/**
 * Gets the current user.
 */
export function useGetUser(): AppUserInterface {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContextProvider')
  }
  return context.user
}
