import axios from 'axios'

import {
  URI_CREATE_PROGRAM,
  URI_LIST_PROGRAMS,
  URI_PROGRAM
} from 'datastore/apis/constants'
import { ProgramItemInterface, ProgramListAPIParamsInterface } from 'types'
import { AppEnvironmentEnum } from 'datastore/utils/constants'

async function createProgram(bodyParams: any, queryParams: any) {
  return axios.post<ProgramItemInterface>(`${URI_CREATE_PROGRAM}`, bodyParams, {
    params: queryParams
  })
}

async function fetchProgramList(queryParams: ProgramListAPIParamsInterface) {
  return axios.get<ProgramItemInterface[]>(`${URI_LIST_PROGRAMS}`, {
    params: queryParams
  })
}

async function fetchProgram(programID: string, queryParams: any) {
  return axios.get<ProgramItemInterface>(`${URI_PROGRAM}/${programID}`, {
    params: queryParams
  })
}

async function updateProgramName(
  programID: string,
  bodyParams: { name: string },
  queryParams: { adminID: string }
) {
  return axios.put<ProgramItemInterface>(
    `${URI_PROGRAM}/${programID}/name`,
    bodyParams,
    {
      params: queryParams
    }
  )
}

async function updateProgramData(
  programID: string,
  bodyParams: any,
  queryParams: any
) {
  return axios.put(`${URI_PROGRAM}/${programID}/data`, bodyParams, {
    params: queryParams
  })
}

async function deleteProgram(programID: string, queryParams: any) {
  return axios.delete(`${URI_PROGRAM}/${programID}`, { params: queryParams })
}

export default {
  createProgram,
  fetchProgramList,
  fetchProgram,
  updateProgramName,
  updateProgramData,
  deleteProgram
}

// ToDo: restructure calls to endpoints to enable usage such as 'programAPIs.fetchProgramList'
/*
const listPrograms = async (
  params: ProgramListAPIParamsInterface
): Promise<ProgramItemInterface[]> => {
  try {
    const response = await axios.get<ProgramItemInterface[]>(
      `${URI_LIST_PROGRAMS}`,
      {
        params
      }
    )
    return response.data
  } catch (e) {
    Console.log(e)
    return []
  }
}

export default { listPrograms }
*/
