import { createTheme } from '@mui/material/styles'
// https://mui.com/material-ui/customization/color/
// https://www.quackit.com/css/css_color_codes.cfm
import { red, yellow, green, grey } from '@mui/material/colors'

export const theme = createTheme({
  palette: {
    background: {
      default: '#fcfcfc'
    },
    primary: {
      light: '#E6E6FA' /* lavender */,
      main: '#8A2BE2' /* blueviolet */,
      dark: '#4B0082' /* indigo */
    },
    error: {
      light: red[100],
      main: red[500],
      dark: red[700]
    },
    warning: {
      light: yellow[100],
      main: yellow[500],
      dark: yellow[900]
    },
    success: {
      light: green[100],
      main: green[500],
      dark: green[900]
    },
    neutral_light: {
      light: '#fcfcfc',
      main: grey[100],
      dark: grey[300]
    },
    neutral_dark: {
      light: grey[400],
      main: grey[600],
      dark: grey[900]
    }
  },
  typography: {
    fontFamily: 'Poppins, Dongle',
    caption: {
      fontFamily: 'Dongle',
      fontSize: 33.18
    },
    h1: {
      fontSize: 24
    },
    h2: {
      fontSize: 19.2
    },
    h3: {
      fontSize: 16
    },
    h4: {
      fontSize: 13.33
    },
    h5: {
      fontSize: 11.11
    },
    h6: {
      fontSize: 9.26
    },
    allVariants: {
      color: 'red'
    }
  },
  breakpoints: {
    values: {
      xs: 290,
      sm: 450,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
})

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette {
    neutral_light: Palette['primary']
    neutral_dark: Palette['primary']
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteOptions {
    neutral_light?: PaletteOptions['primary']
    neutral_dark: PaletteOptions['primary']
  }
}
