import React from 'react'
import { Slider, Stack, Box } from '@mui/material'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { updateCurrentConfidenceScoreThresholdAction } from 'datastore/slices/v2-program-controller'

function valuetext(value: number) {
  return `${value}`
}

const UpdateConfidenceScoreThresholdSilderComponent: React.FC = () => {
  const dispatch = useStoreDispatch()

  const tempCurrentBotVersion = useStoreSelector(
    (storeselector) =>
      storeselector.v2ProgramController.tempConfigOptions.tempBotVersion
  )

  const handleChange = (event: Event, newValue: number | number[]) => {
    dispatch(updateCurrentConfidenceScoreThresholdAction(newValue))
  }

  const confidenceScoreTreshold = useStoreSelector(
    (storeState) => storeState.v2ProgramController.confidenceScoreTreshold
  )

  if (tempCurrentBotVersion !== '2.1') {
    return <Box />
  }

  return (
    <Box sx={{ paddingY: 1, fontSize: '13px', color: 'black' }}>
      Confidence Score Threshold
      <Stack
        spacing={2}
        direction="row"
        sx={{ mb: 1, gap: 1 }}
        alignItems="center">
        0.0
        <Slider
          aria-label="confidence score threshold"
          id="confidence-score-slider"
          valueLabelDisplay="auto"
          value={confidenceScoreTreshold}
          getAriaValueText={valuetext}
          min={0}
          max={1}
          step={0.01}
          onChange={handleChange}
        />
        1.0
      </Stack>
    </Box>
  )
}

export default UpdateConfidenceScoreThresholdSilderComponent
