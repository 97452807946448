import {
  Stack,
  Typography,
  ImageList,
  ImageListItem,
  Container,
  useMediaQuery
} from '@mui/material'
import React, { useState, useEffect } from 'react'

import {
  changePageService,
  showPopupService
} from 'datastore/slices/app-controller'
import { setCurrentProgramAction } from 'datastore/slices/program-controller'
import { setCurrentBlockService } from 'datastore/slices/block-controller'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { theme } from 'components/atoms/styles/theme'
import { PopupTypeEnum, ProgramItemInterface } from 'types'

import programAPI from 'datastore/apis/program-api'
import {
  PAGE_USER_BLOCK_INTERACT,
  NOTIFICATION_TITLE_MIC_DISABLED,
  NOTIFICATION_MSG_MIC_DISABLED
} from 'datastore/utils/constants'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'
import imgPlaceholder from 'assets/images/img-placeholder.png'

import log from 'loglevel'
import styles from './user-program-select.styles'

const UserProgramSelectComponent = () => {
  const dispatch = useStoreDispatch()
  const isMicOn = useStoreSelector(
    (storeState) => storeState.appController.micOn
  )

  const [programs, setPrograms] = useState<ProgramItemInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const results = await programAPI.fetchProgramList({
        status: 'active',
        owner: false
      })
      setPrograms(results.data)
      setIsLoading(false)
    })()
  }, [])

  const onUserProgramSelect = async (program: ProgramItemInterface) => {
    if (isMicOn) {
      // change page to lesson-interact
      setIsLoading(true)
      dispatch(setCurrentProgramAction(program))

      let { blockID } = program.lessonBlocks[0]
      const userProgressData = localStorage.getItem('userProgressData')
      log.log('UserProgramSelect::userProgressData:: ', userProgressData)
      if (userProgressData) {
        const userProgressJSON = JSON.parse(userProgressData)
        if (Object.keys(userProgressJSON).includes(program.id)) {
          blockID = userProgressJSON[program.id]
          log.log(
            'UserProgramSelect::userProgressJSON[program.id]:: ',
            userProgressJSON[program.id]
          )
        }
        log.log(
          'UserProgramSelect::logical_exp:: ',
          program.id in Object.keys(userProgressJSON)
        )
        log.log(
          'UserProgramSelect::Object.keys(userProgressJSON):: ',
          Object.keys(userProgressJSON)
        )
      }
      log.log('UserProgramSelect::program.id:: ', program.id)
      log.log('UserProgramSelect::blockID:: ', blockID)

      await dispatch(setCurrentBlockService(blockID))
      await dispatch(changePageService(`${PAGE_USER_BLOCK_INTERACT}`))
      setIsLoading(false)
    } else {
      dispatch(
        showPopupService({
          type: PopupTypeEnum.notification,
          data: {
            title: NOTIFICATION_TITLE_MIC_DISABLED,
            message: NOTIFICATION_MSG_MIC_DISABLED
          }
        })
      )
    }
  }

  const isSmallScreen = useMediaQuery('(max-width: 400px)')
  const isMediumScreen = useMediaQuery('(max-width: 800px)')
  const isLargeScreen = useMediaQuery('(max-width: 1026px)')

  const cols = () => {
    if (isSmallScreen) {
      return 1
    }
    if (isMediumScreen) {
      return 2
    }
    if (isLargeScreen) {
      return 3
    }
    return 4
  }

  return (
    <Container>
      <Stack direction="column">
        {programs.length && !isLoading ? (
          <Typography
            variant="h3"
            color={theme.palette.neutral_dark.dark}
            sx={(styles.fullWidth, styles.marginTop10px)}
            align="center">
            Select a language to start learning!
          </Typography>
        ) : undefined}

        <ImageList gap={10} cols={cols()}>
          {programs.map((program) => {
            return (
              <ImageListItem
                sx={styles.imgListItem}
                key={program.id}
                onClick={() => onUserProgramSelect(program)}>
                <div
                  style={{
                    width: '100%',
                    height: '350px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    background: 'white',
                    overflow: 'hidden'
                  }}>
                  <img
                    src={program.imageUrl || imgPlaceholder}
                    srcSet={program.imageUrl}
                    alt="pl"
                    loading="lazy"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      objectFit: 'cover',
                      height: '100%'
                    }}
                  />
                  <div
                    style={{
                      marginTop: '10px',
                      color: '#2D3691',
                      fontWeight: 'bold'
                    }}>
                    {program.name}
                  </div>
                </div>
              </ImageListItem>
            )
          })}

          {!programs.length ? (
            <ImageListItem sx={styles.listItem}>
              <Typography
                variant="h4"
                color={theme.palette.neutral_dark.dark}
                sx={styles.fullWidth}
                align="center">
                No programs available!
              </Typography>
            </ImageListItem>
          ) : undefined}
        </ImageList>
      </Stack>
      <LoaderComponent isLoading={isLoading} />
    </Container>
  )
}

export default UserProgramSelectComponent
