import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { theme } from 'components/atoms/styles/theme'
import TextInputComponent from 'components/atoms/inputs/text-input'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'

import { loginWithEmailService } from 'datastore/slices/auth-controller'
import { useStoreDispatch } from 'datastore/config/hooks'
import log from 'loglevel'
import { Alert } from 'alert'

const formTitle = 'Enter email to sign into admin app'

const SignInFormComponent = () => {
  const dispatch = useStoreDispatch()
  const [email, setEmail] = React.useState<string>('')

  const onEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    if (!email) {
      Alert.show('&&&& Email address is required &&&&')
    } else {
      log.info(' sign-in into app with email ')

      dispatch(loginWithEmailService({ email }))
    }
  }

  return (
    <Stack
      sx={{
        width: '100%',
        borderRadius: '4px',
        bgcolor: theme.palette.neutral_light.light
      }}>
      <Box
        sx={{
          p: 2,
          borderRadius: '4px 4px 0px 0px',
          bgcolor: theme.palette.primary.light
        }}>
        <Typography
          id="form-title"
          variant="h4"
          color={theme.palette.neutral_dark.dark}>
          {formTitle}
        </Typography>
      </Box>
      <Stack
        spacing={2}
        sx={{
          p: 3
        }}>
        <Stack
          spacing={1}
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <TextInputComponent
            width="100%"
            value={email}
            onValueChange={onEmailChange}
            label="Email address"
          />
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>
          <DefaultButtonComponent
            label="Continue"
            onButtonClick={handleSubmit}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default SignInFormComponent
