import React, { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import PopupComponent from 'components/molecules/popups/popup'
import AdminTopPanelComponent from 'components/organisms/panels/admin-top-panel/admin-top-panel'
import AdminSidePanelComponent from 'components/organisms/panels/admin-side-panel/admin-side-panel'
import AdminArenaHeaderComponent from 'components/organisms/panels/admin-arena-header/admin-arena-header'
import AdminArenaPanelComponent from 'components/organisms/panels/admin-arena-panel/admin-arena-panel'
import AdminBlockPreviewComponent from 'components/organisms/arenas/admin-block-preview/admin-block-preview'
import AdminBlockDesignComponent from 'components/organisms/arenas/admin-block-design/admin-block-design'
import AdminAuthComponent from 'components/organisms/arenas/admin-auth/admin-auth'

import {
  PAGE_ADMIN_BLOCK_DESIGN,
  PAGE_ADMIN_BLOCK_PREVIEW,
  PAGE_ADMIN_BLOCK_SETTINGS,
  PAGE_ADMIN_PROGRAM_SELECT,
  PAGE_ADMIN_AUTH
} from 'datastore/utils/constants'
import AdminProgramSelectComponent from 'components/organisms/arenas/admin-program-select/admin-program-select'
import AdminBlockSettingsComponent from 'components/organisms/arenas/admin-block-settings/admin-block-settings'

import { useStoreDispatch } from 'datastore/config/hooks'
import { showPopupService } from 'datastore/slices/app-controller'
import { PopupTypeEnum } from 'types'

interface AdminAppComponentPropsInterface {
  currentPageLocal: string
  popupType: PopupTypeEnum
  arenaPanelView: string
  shouldShowPopup: boolean
  shouldShowLoading: boolean
  shouldShowAddNode: boolean
  shouldShowDelNode: boolean
  currentBlock: any // check this
  dpCurrBotTreeDS: any // check this
  currentPopup: any // check this
  setShouldShowLoading: React.Dispatch<React.SetStateAction<boolean>>
  setPopupType: React.Dispatch<React.SetStateAction<PopupTypeEnum>>
  isGlobalPageAuth: () => boolean
  handleShowCreateProgramForm: () => void
  handleShowCreateBlockForm: () => void
  handleShowDeleteBlockForm: () => void
  handleOnAddNode: () => Promise<void>
  handleOnDelNode: () => Promise<void>
  handleOnChangeAdminArenaHeader: (event: any) => Promise<void>
  handleOnSaveBlock: () => Promise<void>
  handleOnPublishBlock: () => Promise<void>
  handleOnSaveSettings: () => Promise<void>
  handleOnNodeSelect: (nodeData: any) => Promise<void>
  handleOnNodeUnselect: () => void
  handleOnNewStateChange: (event: any) => Promise<void>
  handleOnStateTypeChange: (event: any) => Promise<void>
  handleOnStateNameChange: (event: any) => Promise<void>
  handleOnStateDescChange: (event: any) => Promise<void>
  handleOnStateAudioChange: (
    audioBlobURL: string,
    filename: string
  ) => Promise<void>
  handleOnStateRefChange: (event: any) => Promise<void>
  handleOnNewOptionChange: (event: any) => Promise<void>
  handleOnOptionNameChange: (event: any) => Promise<void>
  handleOnOptionDescChange: (event: any) => Promise<void>
  handleOnOptionAudioChange: (
    audioBlobURL: string,
    filename: string,
    index: number
  ) => Promise<void>
  handleOnOptionAudioIncrease: () => Promise<void>
  handleOnOptionAudioDecrease: () => Promise<void>
  handleOnOptionRefChange: (event: any) => Promise<void>
  handleOnPreviewTypeChange: (event: any) => Promise<void>
}

const AdminAppComponent: FunctionComponent<AdminAppComponentPropsInterface> = ({
  currentPageLocal,
  shouldShowAddNode,
  shouldShowDelNode,
  shouldShowLoading,
  shouldShowPopup,
  popupType,
  arenaPanelView,
  currentBlock,
  dpCurrBotTreeDS,
  currentPopup,
  setShouldShowLoading,
  setPopupType,
  isGlobalPageAuth,
  handleShowCreateProgramForm,
  handleShowCreateBlockForm,
  handleShowDeleteBlockForm,
  handleOnAddNode,
  handleOnDelNode,
  handleOnChangeAdminArenaHeader,
  handleOnSaveBlock,
  handleOnPublishBlock,
  handleOnSaveSettings,
  handleOnNodeSelect,
  handleOnNodeUnselect,
  handleOnNewStateChange,
  handleOnStateDescChange,
  handleOnStateNameChange,
  handleOnStateTypeChange,
  handleOnStateAudioChange,
  handleOnStateRefChange,
  handleOnNewOptionChange,
  handleOnOptionAudioChange,
  handleOnOptionDescChange,
  handleOnOptionNameChange,
  handleOnOptionAudioIncrease,
  handleOnOptionAudioDecrease,
  handleOnOptionRefChange,
  handleOnPreviewTypeChange
}) => {
  const dispatch = useStoreDispatch()

  return (
    <Stack
      className="Admin-App"
      sx={{
        display: 'flex',
        height: '100vh',
        overflow: 'clip'
      }}>
      <Box
        className="AppHeader-Container"
        sx={{
          display: isGlobalPageAuth() ? 'none' : 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'fit-content'
        }}>
        <AdminTopPanelComponent
          ddHide={currentPageLocal === PAGE_ADMIN_PROGRAM_SELECT}
          onCreateProgramSelect={handleShowCreateProgramForm}
        />
        <Divider className="AppHeader-Div" />
      </Box>
      <Stack
        className="AppBody-Container"
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1
        }}>
        <Box
          className="AdminSidePanel-Container"
          sx={{
            display:
              isGlobalPageAuth() ||
              currentPageLocal === PAGE_ADMIN_PROGRAM_SELECT
                ? 'none'
                : 'flex',
            flexDirection: 'row',
            width: 'fit-content',
            height: '100%'
          }}>
          <AdminSidePanelComponent
            onAddBlockSelect={handleShowCreateBlockForm}
            onDelBlockSelect={handleShowDeleteBlockForm}
          />
          <Divider className="AdminSidePanel-Div" orientation="vertical" />
        </Box>
        <Stack
          className="Arena-Container"
          sx={{
            display: 'flex',
            width:
              currentPageLocal === PAGE_ADMIN_PROGRAM_SELECT ? '50%' : '100%',
            height: '100%'
          }}>
          <Box
            className="AdminArenaHeader-Container"
            sx={{
              display:
                isGlobalPageAuth() ||
                (currentPageLocal === PAGE_ADMIN_BLOCK_DESIGN &&
                  !currentBlock.id)
                  ? 'none'
                  : 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 'fit-content'
            }}>
            <AdminArenaHeaderComponent
              type={currentPageLocal}
              onCreateProgramSelect={handleShowCreateProgramForm}
              onAddNode={handleOnAddNode}
              onDelNode={handleOnDelNode}
              isAddNodeDisabled={!shouldShowAddNode}
              isDelNodeDisabled={!shouldShowDelNode}
              onChangeData={handleOnChangeAdminArenaHeader}
              onSaveBlock={handleOnSaveBlock}
              onPublishBlock={handleOnPublishBlock}
              onSaveSettings={handleOnSaveSettings}
            />
          </Box>
          <Stack
            className="ArenaBody-Container"
            direction="row"
            sx={{
              display:
                currentPageLocal === PAGE_ADMIN_BLOCK_DESIGN && !currentBlock.id
                  ? 'none'
                  : 'flex',
              width: '100%',
              height: '100%'
            }}>
            <Box
              className="ArenaContent-Container"
              sx={{
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                width: '0px' // ToReview: Hack to enable parent container to have appropriate width especially to support OrgChartContainer in AdminBlockDesign
              }}>
              <Routes>
                <Route
                  path={`/${PAGE_ADMIN_AUTH}`}
                  element={<AdminAuthComponent firstTime={false} use1PAuth />}
                />
                <Route
                  path={`/${PAGE_ADMIN_PROGRAM_SELECT}`}
                  element={<AdminProgramSelectComponent />}
                />
                <Route
                  path={`/${PAGE_ADMIN_BLOCK_DESIGN}`}
                  element={
                    <AdminBlockDesignComponent
                      // datasource={{ ...currBotTreeDS! }}
                      datasource={dpCurrBotTreeDS}
                      onNodeSelect={handleOnNodeSelect}
                      onNodeUnselect={handleOnNodeUnselect}
                    />
                  }
                />
                <Route
                  path={`/${PAGE_ADMIN_BLOCK_PREVIEW}`}
                  element={<AdminBlockPreviewComponent />}
                />
                <Route
                  path={`/${PAGE_ADMIN_BLOCK_SETTINGS}`}
                  element={<AdminBlockSettingsComponent />}
                />
              </Routes>
            </Box>
            <Box
              className="AdminArenaPanel-Container"
              sx={{
                display:
                  isGlobalPageAuth() ||
                  currentPageLocal === PAGE_ADMIN_PROGRAM_SELECT ||
                  currentPageLocal === PAGE_ADMIN_BLOCK_SETTINGS
                    ? 'none'
                    : 'flex',
                width: '304px',
                height: '100%'
              }}>
              <Divider className="AdminArenaPanel-Div" orientation="vertical" />
              <AdminArenaPanelComponent
                display={arenaPanelView}
                onNewStateChange={handleOnNewStateChange}
                onStateTypeChange={handleOnStateTypeChange}
                onStateNameChange={handleOnStateNameChange}
                onStateDescChange={handleOnStateDescChange}
                onStateAudioChange={handleOnStateAudioChange}
                onStateRefChange={handleOnStateRefChange}
                onNewOptionChange={handleOnNewOptionChange}
                onOptionNameChange={handleOnOptionNameChange}
                onOptionDescChange={handleOnOptionDescChange}
                onOptionAudioChange={handleOnOptionAudioChange}
                onOptionAudioIncrease={handleOnOptionAudioIncrease}
                onOptionAudioDecrease={handleOnOptionAudioDecrease}
                onOptionRefChange={handleOnOptionRefChange}
                onPreviewTypeChange={handleOnPreviewTypeChange}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={shouldShowPopup}>
        <PopupComponent
          onClose={() => {
            setPopupType(PopupTypeEnum.none)
            dispatch(showPopupService({ type: PopupTypeEnum.none }))
          }}
          type={popupType}
          data={currentPopup.data}
        />
      </Dialog>
      <Dialog
        open={shouldShowLoading}
        onClose={() => {
          setShouldShowLoading(false)
        }}>
        <DialogContent>
          <DialogContentText>Loading ...</DialogContentText>
        </DialogContent>
      </Dialog>
    </Stack>
  )
}

export default AdminAppComponent
