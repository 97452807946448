import cookie from 'react-cookies'
import axios from 'axios'

import { LOGIN_GOOGLE_URI, URI_AUTHLIST } from 'datastore/apis/constants'
import { TOKEN_COOKIE, PATH_COOKIE } from 'datastore/utils/constants'
import { SignInResponseInterface } from 'types'
import log from 'loglevel'

function setDefaultAPIHeaders() {
  log.info('%%%%%% Axios Headers Setup %%%%%%')
  const token = cookie.load(TOKEN_COOKIE)
  axios.defaults.headers.get.authorization = `Bearer ${token}`
  axios.defaults.headers.post.authorization = `Bearer ${token}`
  axios.defaults.headers.put.authorization = `Bearer ${token}`
  axios.defaults.headers.delete.authorization = `Bearer ${token}`
  axios.defaults.headers.delete[
    'Content-Type'
  ] = `application/json;charset=UTF-8`
}

function getToken(): string | undefined {
  const token = cookie.load(TOKEN_COOKIE)
  log.info('%%%%%% getToken %%%%%%')
  return token
}

function setToken(data: any) {
  if (data !== undefined) {
    log.info('%%%%%% setToken %%%%%%')
    cookie.save(TOKEN_COOKIE, data, { path: PATH_COOKIE })

    setDefaultAPIHeaders()
  }
}

function deleteToken() {
  log.info('%%%%%% deleteToken %%%%%%')
  cookie.remove(TOKEN_COOKIE)
}

async function signInWithGoogle(data: { token: string }) {
  return axios.post<SignInResponseInterface>(LOGIN_GOOGLE_URI, data)
}

async function fetchAuthList() {
  return axios.get(URI_AUTHLIST)
}

export default {
  setDefaultAPIHeaders,
  getToken,
  setToken,
  deleteToken,
  signInWithGoogle,
  fetchAuthList
}
