import React from 'react'
import { Divider, Stack, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import VersionDropdownComponent from 'components/molecules/dropdowns/version-dropdown'
import RedirectToProdComponent from 'components/molecules/dropdowns/prod-redirection-dropdown'
import { theme } from 'components/atoms/styles/theme'

const ProgramSelectBottomPanelComponent: React.FC = () => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1
      }}
      marginTop="0">
      <Divider />
      <Stack
        direction="row"
        justifyContent={isLargeScreen ? 'center' : 'space-evenly'}
        gap="16px"
        width="100%">
        <VersionDropdownComponent />
        <RedirectToProdComponent />
      </Stack>
    </Box>
  )
}

export default ProgramSelectBottomPanelComponent
