import React from 'react'
import cx from 'classnames'
import styles from './loader-component.module.css'

interface PropsInterface {
  isLoading: boolean
}

const LoaderComponent: React.FunctionComponent<PropsInterface> = ({
  isLoading
}) => {
  return (
    <div className={cx([styles.loader, isLoading ? styles.show : styles.hide])}>
      <div className={styles['loader-inner']}>
        <div className={styles['loader-line-wrap']}>
          <div className={styles['loader-line']} />
        </div>
        <div className={styles['loader-line-wrap']}>
          <div className={styles['loader-line']} />
        </div>
        <div className={styles['loader-line-wrap']}>
          <div className={styles['loader-line']} />
        </div>
        <div className={styles['loader-line-wrap']}>
          <div className={styles['loader-line']} />
        </div>
        <div className={styles['loader-line-wrap']}>
          <div className={styles['loader-line']} />
        </div>
      </div>
    </div>
  )
}

export default LoaderComponent
