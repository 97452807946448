import React, { useState } from 'react'
import RefreshRounded from '@mui/icons-material/RefreshRounded'
import { IconButton, Box, Dialog } from '@mui/material'
import useReloadBotConfigApi from 'hooks/api/use-reload-bot-config-api'
import showNotification from 'components/molecules/notifications/notifications'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'

const ReloadBotConfigButtonComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const reloadConfig = useReloadBotConfigApi()

  const handleChange = () => {
    setIsLoading(true)
    reloadConfig(
      () => {
        showNotification({ type: 'info', message: 'config reloaded' })
        setIsLoading(false)
      },
      () => {
        showNotification({ type: 'error', message: 'config not reloaded' })
        setIsLoading(false)
      }
    )
  }

  return (
    <>
      <Box sx={{ paddingY: 1 }}>
        <IconButton
          onClick={handleChange}
          aria-label="Reload bot config"
          style={{ borderRadius: 0 }}
          sx={{ fontSize: '13px', color: 'black', border: '1px solid grey' }}>
          <RefreshRounded />
          Reload bot config
        </IconButton>
      </Box>
      {isLoading && (
        <Dialog open={isLoading} maxWidth="sm" fullWidth>
          <Box position="fixed" top={0} left={0} right={0} bottom={0}>
            <LoaderComponent isLoading={isLoading} />
          </Box>
        </Dialog>
      )}
    </>
  )
}

export default ReloadBotConfigButtonComponent
