import { useAnalytics } from 'reactfire'
import { logEvent } from 'firebase/analytics'
import { useStoreDispatch } from 'datastore/config/hooks'
import { showPopupService } from 'datastore/slices/app-controller'
import {
  NOTIFICATION_FEEDBACK_REQUEST_ACTION_NO,
  NOTIFICATION_FEEDBACK_REQUEST_ACTION_YES,
  NOTIFICATION_MSG_FEEDBACK_REQUEST,
  NOTIFICATION_TITLE_FEEDBACK_REQUEST
} from 'datastore/utils/constants'
import log from 'loglevel'
import { PopupTypeEnum } from 'types'
import { useCallback, useEffect, useRef } from 'react'
import { FeedbackItemType } from 'components/molecules/popups/popup-feedback'
import { usePopupChange } from './use-popup-change'

/**
 * Hooks that request user for feedback.
 */
export function useRequestFeedback() {
  const analytics = useAnalytics()

  const dispatch = useStoreDispatch()
  const previousUserUtteranceCount = useRef<number>()
  const requestFeeback = useCallback((userUtteranceCount: number) => {
    if (userUtteranceCount === previousUserUtteranceCount.current) {
      return // Prevent the method to call the dispatch method twice.
    }
    previousUserUtteranceCount.current = userUtteranceCount
    if (userUtteranceCount === 0 || userUtteranceCount % 5) {
      return
    }
    dispatch(
      showPopupService({
        type: PopupTypeEnum.notification,
        data: {
          title: NOTIFICATION_TITLE_FEEDBACK_REQUEST,
          message: NOTIFICATION_MSG_FEEDBACK_REQUEST,
          actions: [
            NOTIFICATION_FEEDBACK_REQUEST_ACTION_YES,
            NOTIFICATION_FEEDBACK_REQUEST_ACTION_NO
          ]
        }
      })
    )
  }, [])

  const [currentPopup, previousPopup] = usePopupChange()
  useEffect(() => {
    if (!previousPopup || currentPopup.type !== PopupTypeEnum.none) {
      return
    }
    const result = currentPopup.data?.result
    const { type: previousType, data: previousData } = previousPopup

    if (previousType === PopupTypeEnum.notification) {
      if (
        previousData?.title === NOTIFICATION_TITLE_FEEDBACK_REQUEST &&
        result !== NOTIFICATION_FEEDBACK_REQUEST_ACTION_YES
      ) {
        return
      }
      if (previousData?.title === NOTIFICATION_TITLE_FEEDBACK_REQUEST) {
        try {
          dispatch(showPopupService({ type: PopupTypeEnum.feedback }))
        } catch (e) {
          log.error(e)
        }
      }
    } else if (previousType === PopupTypeEnum.feedback && result) {
      const eventData: Record<string, unknown> = {}
      const feedbackItems: FeedbackItemType[] = result
      feedbackItems.forEach((item) => {
        eventData[item.id] = item.value
      })

      logEvent(analytics, 'user_feedback', eventData)
    }
  }, [currentPopup, previousPopup])

  return requestFeeback
}
