import React, { FunctionComponent } from 'react'
import { theme } from 'components/atoms/styles/theme'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

interface PropsInterface {
  children: React.ReactNode
  label?: string
  width?: string | number
  height?: string | number
  padding?: string | number
  flexGrow?: boolean
  hoverEnabled?: boolean
  isHidden?: boolean
}

const defaultProps = {
  label: '',
  width: 'auto',
  height: 'auto',
  padding: '16px' /* top, right, bottom, left */,
  flexGrow: false,
  hoverEnabled: true,
  isHidden: false
}

const InputComponent = React.forwardRef((props: any): React.ReactElement => {
  return <div {...props} />
})

const OutlinedDivComponent: FunctionComponent<PropsInterface> = ({
  children,
  label = defaultProps.label,
  width = defaultProps.width,
  height = defaultProps.height,
  padding = defaultProps.padding,
  flexGrow = defaultProps.flexGrow,
  hoverEnabled = defaultProps.hoverEnabled,
  isHidden = defaultProps.isHidden
}) => {
  return (
    <FormControl
      className="OutlinedContainer"
      sx={{
        flexGrow: flexGrow ? 1 : 0,
        display: isHidden ? 'none' : undefined
      }}>
      <Stack sx={{ width, flexGrow: flexGrow ? 1 : 0 }}>
        <Typography variant="h5" color={theme.palette.neutral_dark.main}>
          {label}
        </Typography>
        {(() => {
          if (!hoverEnabled)
            return (
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.neutral_dark.light,
                  borderRadius: 1,
                  width,
                  flexGrow: flexGrow ? 1 : 0,
                  height: flexGrow ? 0 : height,
                  padding
                }}>
                {children}
              </Box>
            )

          return (
            <TextField
              size="small"
              variant="outlined"
              label=""
              multiline
              InputLabelProps={{
                shrink: true,
                style: { fontSize: theme.typography.h4.fontSize }
              }}
              InputProps={{
                inputComponent: InputComponent,
                /* this changes element.style of the class instance "MuiOutlinedInput-root ..." */
                style: {
                  width,
                  height: flexGrow ? 0 : height,
                  padding
                }
              }}
              inputProps={{ children }}
              sx={{
                /* this changes the style for the entire class "MuiOutlinedInput-root ..." */
                '& .MuiOutlinedInput-root': {
                  flexGrow: flexGrow ? 1 : 0,
                  '& > fieldset': {
                    borderColor: theme.palette.neutral_dark.light
                  },
                  '&:hover': {
                    '& > fieldset': {
                      borderColor: theme.palette.neutral_dark.main
                    }
                  },
                  '&.Mui-focused': {
                    '& > fieldset': {
                      borderColor: theme.palette.primary.main
                    }
                  }
                },
                '& .MuiOutlinedInput-input': {
                  height: flexGrow ? '100%' : 'auto'
                },
                flexGrow: flexGrow ? 1 : 0
              }}
            />
          )
        })()}
      </Stack>
    </FormControl>
  )
}

OutlinedDivComponent.defaultProps = defaultProps

export default OutlinedDivComponent
