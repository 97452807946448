import { useState, useEffect } from 'react'

export function useAppFocus() {
  const [isWindowFocused, setIsWindowFocused] = useState(true)
  const [isDocumentVisible, setIsDocumentVisible] = useState(!document.hidden)

  const handleFocus = () => setIsWindowFocused(true)
  const handleBlur = () => setIsWindowFocused(false)
  const handleVisibilityChange = () => setIsDocumentVisible(!document.hidden)

  useEffect(() => {
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isWindowFocused && isDocumentVisible
}
