import React, { FunctionComponent } from 'react'
import { ListItemInterface, ProgramItemInterface } from 'types'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { theme as customTheme } from 'components/atoms/styles/theme'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { setCurrentBlockService } from 'datastore/slices/block-controller'
import { resetConvoUILogAction } from 'datastore/slices/convo-ui-controller'
import log from 'loglevel'

type F1<PType, ReturnType> = (param: PType) => ReturnType

interface PropsInterface {
  expandedWidth?: string | number
  collapsedWidth?: string | number
  bottomDivHeight?: string | number
  liData?: ListItemInterface[]
  liSelectID?: string
  onListItemSelect?: (liID: any) => F1<any, void>
  onAddBlockSelect?: () => void
  onDelBlockSelect?: () => void
}

const defaultProps = {
  expandedWidth: '240px',
  collapsedWidth: '60px',
  bottomDivHeight: '40px',
  liData: [
    { liID: '1', liLabel: 'One' },
    { liID: '2', liLabel: 'Two' },
    { liID: '3', liLabel: 'Three' }
  ],
  liSelectID: '',
  onListItemSelect: (liID: string) => (event: any) => {
    log.info(`*** SidePanel::onListItemSelect: ${liID} ***`, event)
  },
  onAddBlockSelect: () => {
    log.info(`*** SidePanel::onAddBlockSelect ***`)
  },
  onDelBlockSelect: () => {
    log.info(`*** SidePanel::onDelBlockSelect ***`)
  }
}

const SidePanelComponent: FunctionComponent<PropsInterface> = ({
  expandedWidth = defaultProps.expandedWidth,
  collapsedWidth = defaultProps.collapsedWidth,
  bottomDivHeight = defaultProps.bottomDivHeight,
  liData = defaultProps.liData,
  liSelectID = defaultProps.liSelectID,
  onListItemSelect = defaultProps.onListItemSelect,
  onAddBlockSelect = defaultProps.onAddBlockSelect,
  onDelBlockSelect = defaultProps.onDelBlockSelect
}) => {
  const [toggleOption, setToggleOption] = React.useState('')

  const dispatch = useStoreDispatch()
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const lessoBlockListData = currentProgram.id
    ? [
        ...(currentProgram as ProgramItemInterface).lessonBlocks.map(
          (obj: any) => {
            return { liID: obj.blockID, liLabel: obj.blockName }
          }
        )
      ]
    : []
  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )

  const listData = lessoBlockListData
  const listSelectID = currentBlock ? currentBlock.id : liSelectID

  log.info('**** currentProgram.id: ', currentProgram.id)
  log.info(
    '**** currentProgram.lessonBlocks: ',
    (currentProgram as ProgramItemInterface).lessonBlocks
  )
  log.info('**** liData: ', listData)
  log.info('**** liSelectID: ', listSelectID)
  log.info('**** unused Params ***', {
    liData,
    collapsedWidth,
    bottomDivHeight,
    onListItemSelect
  })

  React.useEffect(() => {
    if (!listSelectID) {
      setToggleOption('')
    } else {
      const selObj = listData.filter((obj) => {
        return obj.liID === listSelectID
      })

      if (selObj) {
        setToggleOption(listSelectID)
      }
    }
  })

  return (
    <Stack
      className="SidePanel"
      sx={{
        width: expandedWidth
      }}>
      <Box
        className="BlockList-Container"
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto'
        }}>
        <List>
          {listData.map(({ liID, liLabel }) => (
            <ListItemButton
              selected={toggleOption !== '' && toggleOption === liID}
              divider
              disableRipple
              // onClick={onListItemSelect(liID)}
              onClick={(event: any) => {
                log.info(
                  `*** Inner::SidePanel::onListItemSelect: ${liID} ***`,
                  event
                )
                log.info(
                  `*** SidePanel::handleOnLoadBlock::dispatchUpdateBotTree ***`
                )
                dispatch(setCurrentBlockService(liID))
                dispatch(resetConvoUILogAction())
              }}
              key={liID}
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 2.5
              }}>
              <ListItemText
                primary={
                  <Typography
                    variant="h4"
                    fontWeight="regular"
                    color={customTheme.palette.neutral_dark.dark}>
                    {liLabel}
                  </Typography>
                }
                sx={{ display: 'block' }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
      <Box
        className="VerticalSpacing"
        sx={{
          flexGrow: 1,
          height: 2
        }}
      />
      <Divider />
      <Stack direction="row" spacing={2} className="Block-ModButtons" sx={{}}>
        <DefaultButtonComponent
          label="Del Block"
          width="50%"
          height="40px"
          bgColor={customTheme.palette.error.main}
          onButtonClick={onDelBlockSelect}
        />
        <DefaultButtonComponent
          label="Add Block"
          width="50%"
          height="40px"
          onButtonClick={onAddBlockSelect}
        />
      </Stack>
    </Stack>
  )
}

SidePanelComponent.defaultProps = defaultProps
export default SidePanelComponent
