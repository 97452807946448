import toWav from 'audiobuffer-to-wav'
import { Buffer } from 'buffer'
import utilFuncs from 'datastore/utils/functions'
import { AudioRecorder } from '.'
import BufferPlayer from '../audio-players/buffer-player'

const inAudioType = 'audio/wav'

window.Buffer = Buffer

export class DefaultAudioRecorder implements AudioRecorder {
  private audioChunks: Blob[] = []

  private audioBlob: Blob | undefined

  private audioPlayer: BufferPlayer | undefined

  private mediaRecorder: MediaRecorder | undefined

  public mediaStream: MediaStream | undefined

  public audioUrl = ''

  public didInitialized = false

  async initMediaRecorder(): Promise<{ error: boolean }> {
    return new Promise((resolve) => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          this.mediaStream = stream
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.addEventListener('dataavailable', (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data)
            }
          })
          this.didInitialized = true
          resolve({ error: false })
        })
      } else {
        resolve({ error: true })
      }
    })
  }

  async startRecording(): Promise<boolean> {
    if (this.didInitialized === false) {
      await this.initMediaRecorder()
    }
    if (!this.mediaRecorder) {
      return false
    }
    this.clearState()
    this.mediaRecorder.start()
    return true
  }

  stopRecording(): Promise<{ error: boolean }> {
    return new Promise((resolve) => {
      if (!this.mediaRecorder) {
        resolve({ error: true })
        return
      }
      this.mediaRecorder.onstop = () => {
        this.audioBlob = new Blob(this.audioChunks, { type: inAudioType })
        if (this.audioBlob.size > 0) {
          DefaultAudioRecorder.toWavBlob(this.audioBlob).then((wavBlob) => {
            this.audioBlob = wavBlob
            this.audioUrl = URL.createObjectURL(this.audioBlob)
            resolve({ error: false })
          })
        }
      }
      this.mediaRecorder.stop()
    })
  }

  playAudio(onComplete: () => void) {
    this.audioPlayer = utilFuncs.updateGlobalAudio(this.audioUrl, inAudioType)
    if (this.audioPlayer) {
      this.audioPlayer.onended = () => {
        if (onComplete) {
          onComplete()
        }
      }
      this.audioPlayer.play()
    }
  }

  stopAudio() {
    if (this.audioPlayer) {
      this.audioPlayer.pause()
    }
  }

  clearState() {
    this.audioUrl = ''
    this.audioBlob = undefined
    this.audioChunks = []
    this.audioPlayer = undefined
  }

  private static toWavBlob(blob: Blob) {
    return new Promise<Blob>((resolve) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64 = `${reader.result}`.split(',')[1]
        const ab = new ArrayBuffer(base64.length)
        const buff = Buffer.from(base64, 'base64')
        const view = new Uint8Array(ab)
        for (let i = 0; i < buff.length; i += 1) {
          view[i] = buff[i]
        }
        const context = new (window.AudioContext ||
          (window as any).webkitAudioContext)()
        context.decodeAudioData(ab, (buffer) => {
          const wavFile = toWav(buffer)
          const wavBlob = new Blob([new DataView(wavFile)], {
            type: inAudioType
          })
          resolve(wavBlob)
        })
      }
    })
  }
}
