import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'
import AmazethuLogo from 'assets/images/amazethu-logo.png'
import styles from 'components/molecules/popups/popup.styles'
import { theme } from 'components/atoms/styles/theme'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import {
  AppUserInterface,
  AuthContext,
  useGetUser,
  useSigninUser
} from 'hooks/use-authentication'
import GoogleIcon from '@mui/icons-material/Google'
import { onAuthStateChanged, ProviderId } from 'firebase/auth'
import { FeatureFlagsEnum } from 'datastore/utils/constants'
import { useRemoteConfigFlag } from 'hooks/use-remote-config'
import moment from 'moment'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'
import { requestSignIn } from 'datastore/slices/auth-controller'

const USER_PROFILE_STORAGE_KEY = 'userProfileData'

function getStoredUserData() {
  return JSON.parse(localStorage.getItem(USER_PROFILE_STORAGE_KEY) || 'null')
}

const useUserAuthentication = () => {
  const { auth, signIn } = useSigninUser()

  const [user, setUser] = useState<AppUserInterface | null>(getStoredUserData())

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const newUser = {
          uid: currentUser.uid,
          providerId: currentUser.providerData[0]?.providerId,
          isAnonymous: currentUser.isAnonymous,
          displayName: currentUser.providerData[0]?.displayName || null,
          email: currentUser.providerData[0]?.email || null,
          photoURL: currentUser.providerData[0]?.photoURL || null,
          phoneNumber: currentUser.providerData[0]?.phoneNumber || null,
          firstSessionAt: !user
            ? new Date().toISOString()
            : user.firstSessionAt,
          previousId: user?.uid
        }
        localStorage.setItem(USER_PROFILE_STORAGE_KEY, JSON.stringify(newUser))
        setUser(newUser as AppUserInterface)
      } else {
        // Sign In anonymously if no user is found or logged out.
        signIn()
      }
    })

    return unsubscribe
  }, [])

  return { user }
}

const SignInFormComponent = ({
  info,
  dismissible,
  onClose
}: {
  info: string
  dismissible: boolean
  onClose: () => void
}) => {
  const { signIn } = useSigninUser()
  const [shouldOpenDialog, setOpendDialog] = useState(true)

  const closeDialog = () => {
    if (dismissible) {
      setOpendDialog(false)
      onClose()
    }
  }

  return (
    <Dialog
      open={shouldOpenDialog}
      maxWidth="sm"
      fullWidth
      onClose={closeDialog}>
      <Stack sx={styles.stackContainer}>
        <Box sx={styles.titleBox}>
          <Typography
            id="form-title"
            variant="h4"
            color={theme.palette.neutral_dark.dark}>
            SignIn/SignUp
          </Typography>
        </Box>

        <Box
          component="img"
          src={AmazethuLogo}
          width={24}
          height={24}
          sx={{
            imageRendering: '-webkit-optimize-contrast',
            marginX: 'auto',
            marginY: '6px'
          }}
        />
        <Box sx={{ marginBottom: '16px', textAlign: 'center' }}> {info} </Box>
        <Button
          startIcon={<GoogleIcon />}
          onClick={() => {
            signIn({ signInMethod: ProviderId.GOOGLE })
          }}
          sx={{
            backgroundColor: theme.palette.primary.main,
            my: '16px',
            mx: '16px',
            color: theme.palette.neutral_light.light,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark
            }
          }}>
          Sign in with Google
        </Button>
      </Stack>
    </Dialog>
  )
}

const AuthenticationComponent = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { user } = useUserAuthentication()

  const featureMustEnforceAuthAfterXDaysFree = useRemoteConfigFlag(
    FeatureFlagsEnum.enforceUserAuthenticationAfterXDaysFree
  )
  const featureDisableUserAuthentication = useRemoteConfigFlag(
    FeatureFlagsEnum.disableUserAuthentication
  )

  const { mustUserHaveLoggedIn, reason } = useStoreSelector(
    (state) => state.authController.authRequirement
  )

  const dispatch = useStoreDispatch()

  const closeDialog = () =>
    dispatch(requestSignIn({ mustUserHaveLoggedIn: false }))

  const auth = useMemo(() => ({ user: user as AppUserInterface }), [user])

  const isAuthenticationRequired = useMemo(() => {
    if (featureDisableUserAuthentication || !user) {
      return false
    }

    if (featureMustEnforceAuthAfterXDaysFree >= 0) {
      const date = moment(user.firstSessionAt)
      const daysSinceLastLogin = date.diff(moment(), 'days')
      return (
        user.isAnonymous &&
        daysSinceLastLogin >= featureMustEnforceAuthAfterXDaysFree
      )
    }

    return false
  }, [
    user,
    featureDisableUserAuthentication,
    featureMustEnforceAuthAfterXDaysFree
  ])

  if (!user) {
    return <LoaderComponent isLoading />
  }

  return (
    <AuthContext.Provider value={auth}>
      {(mustUserHaveLoggedIn || isAuthenticationRequired) && (
        <SignInFormComponent
          dismissible={!isAuthenticationRequired}
          onClose={closeDialog}
          info={reason || 'Please sign in to continue.'}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}

export default AuthenticationComponent
