import React from 'react'

import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import MicIcon from '@mui/icons-material/MicRounded'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import RestartIcon from '@mui/icons-material/RestartAltRounded'
import StopIcon from '@mui/icons-material/Stop'
import { useAudioRecorder } from '@sarafhbk/react-audio-recorder'

import { theme } from 'components/atoms/styles/theme'
import ConvoUIOptionsComponent from 'components/molecules/convo-ui/convo-ui-options'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { interactBlockService } from 'datastore/slices/block-controller'
import { setConvoUILogAction } from 'datastore/slices/convo-ui-controller'
import { Console } from 'logger'
import log from 'loglevel'

interface PropsInterface {
  width?: string | number
  height?: string | number
  containerWidth?: string | number
}

const defaultProps = {
  width: 'auto',
  height: '40px',
  containerWidth: 'auto'
}

const ConvoUIControlsComponent: React.FunctionComponent<PropsInterface> = ({
  width = defaultProps.width,
  height = defaultProps.height,
  containerWidth = defaultProps.containerWidth
}) => {
  const containerHeight = height === 'default' ? defaultProps.height : height
  const mainIconColor = theme.palette.neutral_dark.dark

  const [shouldExpand] = React.useState(false)

  const [isRecording, setIsRecording] = React.useState<boolean>(false)
  const { audioResult, startRecording, stopRecording } = useAudioRecorder()

  const dispatch = useStoreDispatch()
  const convoUILog = useStoreSelector(
    (storeState) => storeState.convoUIController.convoUILog
  )
  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )
  const previewTypeIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.previewTypeIndex
  ) as number

  // send user-action to update interaction with block end point
  const updateBlockInteraction = async (tmpLogOne: any, userAction: string) => {
    const blockStatus = ['draft', 'live'][previewTypeIndex]
    const audioBlobString = userAction === 'speech' ? audioResult : ''
    const response = await dispatch(
      interactBlockService(
        currentBlock.id,
        'user-admin',
        blockStatus,
        audioBlobString,
        userAction
      )
    )
    log.info('*** ConvoUIControls::response: ', response)

    // parse data to get the list of bot-speech from speak actions
    const speakActionObjs: any = response.data.actionsList.filter(
      (action: any) => {
        return action.name === 'speak'
      }
    )
    log.info('*** ConvoUIControls::speakActionObjs: ', speakActionObjs)

    // parse data to get the recent history of state transitions
    // ... i.e. state transitions from the most recent input-state
    const fullTransObjs = [
      ...response.data.debugInfo.convoLog.transitions
    ].reverse()
    const recentTransObjs: any = []
    fullTransObjs.every((transObj: any) => {
      if (transObj.optionID !== 'system-option-002') {
        recentTransObjs.unshift(transObj)
        return false
      }

      recentTransObjs.unshift(transObj)
      return true
    })
    log.info('*** ConvoUIControls::fullTransObjs: ', fullTransObjs)
    log.info('*** ConvoUIControls::recentTransObjs: ', recentTransObjs)

    Console.assert(
      recentTransObjs.length === speakActionObjs.length,
      `AssertError: at the each update of the convo, the number of recent transitions in the convoLog is expected to be the same as the number of speak actions in the actionsList`
    )

    const tmpLogTwo = [...tmpLogOne]
    // add the history of the most recent option first
    tmpLogTwo.push({
      type: 'text',
      speaker: 'user',
      value: recentTransObjs[0].optionName
    })
    // add the history of the recent speak actions
    // following the selection of the most recent option
    speakActionObjs.forEach((speakAction: any, index: number) => {
      tmpLogTwo.push({
        type: 'text',
        speaker: 'bot',
        value: recentTransObjs[index].stateName
      })
      tmpLogTwo.push({
        type: 'audio',
        speaker: 'bot',
        value: speakAction.data.audioURLs[0]
      })
    })
    dispatch(setConvoUILogAction(tmpLogTwo))
  }

  React.useEffect(() => {
    log.info('*** ConvoUIControls::re-render due to change to audioResult ****')
    log.info('*** ConvoUIControls::audioResult: ', audioResult)

    const tmpLogOne = [...convoUILog]

    // when the user is done recording audio,
    // ... update the chat-window then
    // ... send the recording to the backend server
    if (audioResult) {
      tmpLogOne.push({
        type: 'audio',
        speaker: 'user',
        value: audioResult
      })
      dispatch(setConvoUILogAction(tmpLogOne))

      updateBlockInteraction(tmpLogOne, 'speech').catch((error) => {
        log.error(
          '**** ConvoUIControls::audioResult::updateBlockInteraction::error: ',
          error
        )
      })
    }
  }, [audioResult])

  return (
    <Box className="ConvoUIControls-Container" sx={{}}>
      <Accordion
        disableGutters
        elevation={0}
        square
        className="ConvoUIControls"
        expanded={shouldExpand}
        sx={{
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&:before': {
            display: 'none'
          },
          width: containerWidth,
          display: 'flex',
          flexDirection: 'column-reverse'
          // width: '100%',
          // flexGrow: 1
        }}>
        <AccordionSummary
          // aria-controls="panel1d-content"
          className="ConvoUIControls-Header"
          // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          sx={{
            backgroundColor: theme.palette.neutral_light.light,
            // flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              // transform: 'rotate(90deg)'
            },
            '& .MuiAccordionSummary-content': {
              // marginLeft: theme.spacing(1)
            },
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            height: containerHeight,
            minHeight: 0
          }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mb: 0,
              px: 0,
              width
            }}
            // justifyContent="space-evenly"
            alignItems="center">
            <IconButton
              // disabled={hasAudio}
              aria-label={isRecording ? 'stopRec' : 'startRec'}
              onClick={() => {
                log.info('*** ConvoUIControls::recording: ', isRecording)

                if (!isRecording) {
                  log.info('*** ConvoUIControls::startRecording ')
                  startRecording()
                } else {
                  log.info('*** ConvoUIControls::stopRecording ')
                  stopRecording()
                }

                setIsRecording(!isRecording)
              }}>
              {isRecording ? (
                <StopIcon htmlColor={mainIconColor} />
              ) : (
                <MicIcon htmlColor={mainIconColor} />
              )}
            </IconButton>
            {/*
            <IconButton aria-label="help" onClick={toggleAcordion}>
              <HelpIcon htmlColor={mainIconColor} />
            </IconButton>
            */}
            <IconButton
              aria-label="back"
              onClick={async () => {
                log.info('*** ConvoUICnotrols::back ***')

                updateBlockInteraction([...convoUILog], 'back').catch(
                  (error) => {
                    log.error(
                      '**** ConvoUIControls::back::updateBlockInteraction::error: ',
                      error
                    )
                  }
                )
              }}>
              <ArrowBackIcon htmlColor={mainIconColor} />
            </IconButton>
            <IconButton
              aria-label="reset"
              onClick={async () => {
                log.info('*** ConvoUIControls::reset ***')

                updateBlockInteraction([], 'reset').catch((error) => {
                  log.error(
                    '**** ConvoUIControls::reset::updateBlockInteraction::error: ',
                    error
                  )
                })
              }}>
              <RestartIcon htmlColor={mainIconColor} />
            </IconButton>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            // borderBottom: '2px solid cyan',
            p: 0
          }}>
          <ConvoUIOptionsComponent />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

ConvoUIControlsComponent.defaultProps = defaultProps
export default ConvoUIControlsComponent
