import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import React, { useEffect } from 'react'
import { BoxProps, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { useWindowSize } from 'react-use'
import log from 'loglevel'
import { ProgramItemInterface } from '../../../../types'
import {
  useStoreDispatch,
  useStoreSelector
} from '../../../../datastore/config/hooks'
import ImageLoaderComponent from '../../../atoms/utils/image'
import { theme } from '../../../atoms/styles/theme'
import { changePageService } from '../../../../datastore/slices/app-controller'
import { PAGE_USER_PROGRAM_SELECT } from '../../../../datastore/utils/constants'
import { ViewType } from './user-block-interact-record-feedback'
import InteractionSetupComponent, {
  INTERACTION_OPTIONS
} from './user-block-interact-setup'

const BackButtonComponent = ({
  height,
  'aria-label': ariaLabel,
  width,
  minWidth,
  maxWidth,
  borderRadius
}: BoxProps) => {
  const dispatch = useStoreDispatch()

  return (
    <IconButton
      onClick={() => {
        log.info('*** UserBlockInteract::back ***')

        dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
        INTERACTION_OPTIONS.setAudioUrls([])
      }}
      sx={{
        bgcolor: '#D8D8D8',
        color: '#323232',
        fontSize: '22px',
        flexDirection: 'column',
        borderRadius: borderRadius || '8px',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        minWidth,
        maxWidth,
        height,
        ':hover': {
          'background-color': '#bababa'
        }
      }}
      aria-label={ariaLabel}>
      <ArrowBackIcon sx={{ color: 'neutral_dark' }} />
      {!ariaLabel ? null : (
        <Stack
          sx={{
            color: '#323232',
            fontSize: '27px',
            height: '84px',
            justifyContent: 'center'
          }}>
          {ariaLabel}
        </Stack>
      )}
    </IconButton>
  )
}

interface ProgramDetailsComponentProps {
  height: number
}

const ProgramDetailsComponent = ({ height }: ProgramDetailsComponentProps) => {
  const currentProgram: ProgramItemInterface = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  ) as ProgramItemInterface

  return (
    <Stack
      direction="row"
      height={height}
      padding="4px"
      alignItems="center"
      gap="16px">
      <ImageLoaderComponent
        height={height - 8}
        width={height - 8}
        style={{ objectFit: 'cover', borderRadius: 8 }}
        src={currentProgram.imageUrl}
        alt={currentProgram.name}
      />
      <Typography variant="h2" color={theme.palette.neutral_dark.dark}>
        {currentProgram.name}
      </Typography>
    </Stack>
  )
}

const BottomBarComponent = () => {
  const { width: pageWidth, height: pageHeight } = useWindowSize()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isLandScapeMode = pageWidth > pageHeight

  let barHeight: number
  let itemHeight: number
  let paddingX: number
  if (isDesktop) {
    barHeight = 119
    itemHeight = 89
    paddingX = 50
  } else if (isTablet) {
    barHeight = isLandScapeMode ? 109 : 119
    itemHeight = isLandScapeMode ? 72 : 89
    paddingX = isLandScapeMode ? 50 : 32
  } else {
    barHeight = isLandScapeMode ? 56 : 85
    itemHeight = isLandScapeMode ? 42 : 52
    paddingX = isLandScapeMode ? 24 : 16
  }

  return (
    <Box width="100%">
      <Divider />
      <Stack
        direction="row"
        justifyContent={isDesktop ? 'center' : 'space-between'}
        height={barHeight}
        paddingX={`${paddingX}px`}
        paddingY={`${Math.round(itemHeight / 5)}px`}
        alignItems="center">
        <ProgramDetailsComponent height={itemHeight} />
        {isDesktop ? null : (
          <BackButtonComponent
            height={itemHeight}
            width={(itemHeight * 3) / 2}
          />
        )}
      </Stack>
    </Box>
  )
}

const UserBlockInteractChildComponent = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Stack id="ds" flex="1" height="100%" direction="row">
      <Stack direction="column" alignItems="center" flex="1">
        <InteractionSetupComponent />
        <BottomBarComponent />
      </Stack>
      {!isDesktop ? null : (
        <BackButtonComponent
          borderRadius="0"
          width="13%"
          maxWidth="225px"
          minWidth="150px"
          aria-label="GO BACK"
        />
      )}
    </Stack>
  )
}

const UserBlockInteractComponent = () => {
  const [viewType, setViewType] = React.useState<ViewType>(
    INTERACTION_OPTIONS.value
  )
  INTERACTION_OPTIONS.value = viewType

  return (
    <Box position="relative" width="100%" height="100%">
      <UserBlockInteractChildComponent />
      <Box
        zIndex="1"
        position="absolute"
        top="0"
        sx={{ opacity: 0.1, ':hover': { opacity: 1 } }}>
        <RadioGroup
          value={viewType}
          style={{ display: 'flex', flexDirection: 'row' }}
          onChange={(_, value) => setViewType(value as ViewType)}>
          <FormControlLabel
            control={<Radio />}
            value="default"
            label="Default"
          />
          <FormControlLabel
            control={<Radio />}
            value="auto-record"
            label="Auto Record"
          />
          <FormControlLabel
            control={<Radio />}
            value="auto-record-with-vad"
            label="Auto Record With VAD"
          />
        </RadioGroup>
      </Box>
    </Box>
  )
}

export default UserBlockInteractComponent
