import React, { FunctionComponent } from 'react'
import { theme } from 'components/atoms/styles/theme'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'

interface PropsInterface {
  text: string
  width?: string | number
  height?: string | number
  bgColor?: string
  textColor?: string
}

const defaultProps = {
  width: 'auto',
  height: '40px',
  bgColor: theme.palette.primary.light,
  textColor: theme.palette.neutral_dark.dark
}

const ChatDisplayComponent: FunctionComponent<PropsInterface> = ({
  text,
  width,
  height,
  bgColor,
  textColor
}) => {
  return (
    <FormControl>
      <Box
        sx={{
          /* border: '2px solid', */
          /* borderColor: 'green', */
          borderRadius: 1,
          width,
          height,
          backgroundColor: bgColor,
          color: textColor,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
        <Typography
          variant="h4"
          sx={{ p: 2 }}
          color={theme.palette.neutral_dark.dark}>
          {text}
        </Typography>
      </Box>
    </FormControl>
  )
}

ChatDisplayComponent.defaultProps = defaultProps

export default ChatDisplayComponent
