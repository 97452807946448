import {
  Box,
  Container,
  Stack,
  Typography,
  List,
  ListItemButton,
  IconButton,
  ListItem
} from '@mui/material'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useStoreSelector, useStoreDispatch } from 'datastore/config/hooks'
import { changePageService } from 'datastore/slices/app-controller'
import { theme } from 'components/atoms/styles/theme'
import { ProgramItemInterface } from 'types'
import {
  PAGE_USER_BLOCK_INTERACT,
  PAGE_USER_PROGRAM_SELECT
} from 'datastore/utils/constants'

import { setCurrentBlockService } from 'datastore/slices/block-controller'

import styles from 'components/organisms/arenas/user-block-select/user-block-select.styles'
import imgPlaceholder from 'assets/images/img-placeholder.png'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'
import log from 'loglevel'

const UserBlockSelectComponent = () => {
  const [shouldShowLoading, setShouldShowLoading] = React.useState(false)
  const dispatch = useStoreDispatch()
  const currentProgram: ProgramItemInterface = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  ) as ProgramItemInterface
  // const dummyDesc =
  //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

  const onBackClick = () => {
    dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
  }

  return (
    <Container sx={styles.noLineHeight}>
      <Stack direction="row" spacing={2} sx={{ marginTop: '15px' }}>
        <Box sx={styles.programImage}>
          <img src={currentProgram.imageUrl || imgPlaceholder} alt="pl" />
        </Box>
        <Box sx={styles.programInfoBox}>
          <Box sx={styles.programInfoContent}>
            <Typography
              variant="h4"
              sx={styles.programName}
              color={theme.palette.neutral_dark.dark}>
              {currentProgram?.name}
            </Typography>
            <Typography
              paragraph
              sx={styles.programPublisher}
              color={theme.palette.neutral_dark.dark}
              variant="body2">
              by {currentProgram?.publisher}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.backButtonBox}>
          <IconButton
            onClick={onBackClick}
            sx={styles.backButton}
            aria-label="delete"
            size="large">
            <ArrowBackIcon color="secondary" />
          </IconButton>
        </Box>
      </Stack>

      <Box>
        <Box sx={styles.programInfoSmBox}>
          <Typography
            variant="h4"
            sx={styles.programName}
            color={theme.palette.neutral_dark.dark}>
            {currentProgram?.name}
          </Typography>
          <Typography
            paragraph
            sx={styles.programPublisher}
            color={theme.palette.neutral_dark.dark}
            variant="body2">
            by {currentProgram?.publisher}
          </Typography>
        </Box>
        <Typography variant="h4" color={theme.palette.neutral_dark.dark}>
          {currentProgram?.desc}
        </Typography>
      </Box>

      <Typography
        variant="h5"
        sx={styles.allLessons}
        color={theme.palette.neutral_dark.dark}>
        All Lessons
      </Typography>

      <List disablePadding>
        {currentProgram?.lessonBlocks?.map((lesson) => {
          return (
            <ListItemButton
              // onClick={onLessonClicked}
              key={lesson.blockID}
              onClick={async () => {
                log.info(
                  `*** Inner::UserBlockSelectComponent::onListItemSelect: ${lesson} ***`
                )
                setShouldShowLoading(true)
                await dispatch(setCurrentBlockService(lesson.blockID))
                await dispatch(changePageService(`${PAGE_USER_BLOCK_INTERACT}`))
                setShouldShowLoading(false)
              }}
              disableGutters
              divider
              sx={styles.listItem}>
              <Stack direction="row" spacing={1}>
                <Box>
                  <Typography
                    variant="h4"
                    color={theme.palette.neutral_dark.dark}
                    sx={{ marginBottom: 2 }}>
                    {lesson.blockName}
                  </Typography>
                  <Typography
                    paragraph
                    color={theme.palette.neutral_dark.dark}
                    variant="h5">
                    {lesson.desc}
                  </Typography>
                </Box>
              </Stack>
            </ListItemButton>
          )
        })}

        {!currentProgram?.lessonBlocks?.length ? (
          <ListItem disableGutters divider sx={styles.listItem}>
            <Typography
              variant="h4"
              color={theme.palette.neutral_dark.dark}
              sx={styles.fullWidth}
              align="center">
              No lessons created for {currentProgram?.name} yet
            </Typography>
          </ListItem>
        ) : undefined}
      </List>
      <LoaderComponent isLoading={shouldShowLoading} />
    </Container>
  )
}

export default UserBlockSelectComponent
