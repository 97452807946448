import * as React from 'react'
import Box from '@mui/material/Box'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import PersonIcon from '@mui/icons-material/Person'
import { useStoreDispatch } from 'datastore/config/hooks'
import { requestSignIn } from 'datastore/slices/auth-controller'
import { useGetUser, useSigninUser } from 'hooks/use-authentication'
import { theme } from 'components/atoms/styles/theme'

const ProfileDetailsComponent = ({ onClose }: { onClose: () => unknown }) => {
  const user = useGetUser()
  const { auth } = useSigninUser()

  const [deleteAccountAnchorEl, setDeleteAccountAnchorEl] =
    React.useState<HTMLButtonElement | null>(null)

  const handleConfirmDeletion = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDeleteAccountAnchorEl(event.currentTarget)
  }

  const handleCloseDeleteAccountConfirmationPopup = () => {
    setDeleteAccountAnchorEl(null)
  }

  const isDeleteAccountConfirmationPopupConfirmationOpen = Boolean(
    deleteAccountAnchorEl
  )

  const deleteAccountButtonId = isDeleteAccountConfirmationPopupConfirmationOpen
    ? 'simple-popover'
    : undefined

  const handleLogout = async () => {
    await auth.signOut()
    onClose()
  }

  const handleDeleteAccount = async () => {
    const { currentUser } = auth
    if (currentUser) {
      await currentUser.delete()
      onClose()
    }
  }

  return (
    <>
      <Dialog open>
        <DialogTitle
          sx={{
            backgroundColor: theme.palette.primary.light
          }}>
          <Typography variant="h4" sx={{ color: 'black' }}>
            User Profile
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              pt: 2
            }}>
            <Avatar
              sx={{
                width: 80,
                height: 80,
                color: 'white',
                backgroundImage: user?.photoURL
                  ? `url(${user.photoURL})`
                  : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: !user.isAnonymous
                  ? theme.palette.primary.main
                  : theme.palette.neutral_dark.light
              }}>
              {!user.photoURL && (
                <Typography
                  id="form-title"
                  variant="h2"
                  color={theme.palette.neutral_light.main}>
                  {(user.displayName || user.email)?.[0]}
                </Typography>
              )}
            </Avatar>
            <Typography color="black" variant="h4">
              {user.displayName}
            </Typography>
            <Stack
              justifyContent="start"
              alignItems="start"
              flexDirection="column"
              mb={3}
              gap={3}>
              <Typography variant="h4" color="black">
                Email: {user.email}
              </Typography>
              {user.phoneNumber && (
                <Typography variant="h4" color="black">
                  Phone: {user.phoneNumber}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: theme.palette.neutral_dark.main,
                  borderRadius: '16px',
                  p: 1
                }}>
                <GoogleIcon sx={{ color: theme.palette.neutral_dark.main }} />
                <Typography
                  variant="h5"
                  color={theme.palette.neutral_dark.main}>
                  Linked with Google Account
                </Typography>
              </Box>
            </Stack>
            <Divider
              sx={{
                alignSelf: 'stretch'
              }}>
              <Typography variant="h6" color={theme.palette.error.dark}>
                Danger Zone
              </Typography>
            </Divider>
            <Stack width="100%">
              <Button
                aria-describedby={deleteAccountButtonId}
                onClick={handleConfirmDeletion}
                color="error"
                variant="outlined"
                fullWidth>
                Delete Account
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleLogout}
            color="error"
            variant="contained"
            disableElevation>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        id={deleteAccountButtonId}
        open={isDeleteAccountConfirmationPopupConfirmationOpen}
        anchorEl={deleteAccountAnchorEl}
        onClose={handleCloseDeleteAccountConfirmationPopup}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Stack flexDirection="column" px={2} pt={2}>
          <Typography variant="h4" color={theme.palette.neutral_dark.dark}>
            Deleting your account is irreversible.
          </Typography>
          <Button
            sx={{ alignSelf: 'end' }}
            color="error"
            onClick={handleDeleteAccount}>
            CONFIRM
          </Button>
        </Stack>
      </Popover>
    </>
  )
}

const SpringPopper = () => {
  const dispatch = useStoreDispatch()
  const user = useGetUser()
  const [showProfileDetails, setShowProfileDetails] = React.useState(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!user.isAnonymous) {
      setShowProfileDetails(true)
    } else {
      dispatch(requestSignIn({ mustUserHaveLoggedIn: true }))
    }
  }

  const closeProfileDetails = () => {
    setShowProfileDetails(false)
  }

  return (
    <>
      {showProfileDetails && (
        <ProfileDetailsComponent onClose={closeProfileDetails} />
      )}
      <Box>
        <Avatar
          onClick={handleClick}
          component="button"
          sx={{
            border: 'none',
            cursor: 'pointer',
            backgroundImage: user?.photoURL
              ? `url(${user.photoURL})`
              : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: !user.isAnonymous
              ? theme.palette.primary.main
              : theme.palette.neutral_dark.light
          }}>
          {user.isAnonymous ? (
            <PersonIcon />
          ) : (
            <Typography
              id="form-title"
              variant="h2"
              color={theme.palette.neutral_light.main}>
              {(user.displayName || user.email)?.[0]}
            </Typography>
          )}
        </Avatar>
      </Box>
    </>
  )
}

const ProfileViewComponent = () => {
  return <SpringPopper />
}

export default ProfileViewComponent
