import React from 'react'

import Box from '@mui/material/Box'
import { Stack, SxProps, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'

import AmazethuLogo from 'assets/images/amazethu-logo.png'
import { theme } from 'components/atoms/styles/theme'

interface PropsInterface {
  width?: string | number
  height?: string | number
  sx?: SxProps
}

const defaultProps = {
  width: 'auto',
  height: '40px',
  sx: {}
}

const LogoPlusTextComponent: React.FunctionComponent<PropsInterface> = ({
  width = defaultProps.width,
  height = defaultProps.height,
  sx = defaultProps.sx
}) => {
  return (
    <FormControl
      className="LogoPlusText"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx
      }}>
      <Stack
        direction="row"
        sx={{
          width,
          height,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}>
        <Box
          sx={{
            marginRight: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <Box
            component="img"
            src={AmazethuLogo}
            width={24}
            height={24}
            sx={{
              imageRendering: '-webkit-optimize-contrast'
            }}
          />
        </Box>
        <Typography
          variant="caption"
          color={theme.palette.neutral_dark.dark}
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          amazéthu
        </Typography>
      </Stack>
    </FormControl>
  )
}

LogoPlusTextComponent.defaultProps = defaultProps

export default LogoPlusTextComponent
