/* eslint-disable consistent-return */

import { createSlice } from '@reduxjs/toolkit'
import showNotification from 'components/molecules/notifications/notifications'
import {
  ConvoActionsInterface,
  MessageInterface,
  V2ProgramItemInterface
} from 'types'

interface TempConfigOptionsInterface {
  tempLLM: string
  tempCCM: string
  tempASR: string
  tempTTS: string
  tempBotVersion: string
  tempAsrEnforceTargetLang: string
}

interface ProgramControllerStateInterface {
  currentProgram: V2ProgramItemInterface | Record<string, never>
  currentMessages: MessageInterface[]
  audioInTextOutConvoActions: ConvoActionsInterface[]
  audioInAudioOutConvoActions: ConvoActionsInterface[]
  currentConvoThreadID: string
  currentActiveLLM: string
  currentActiveCCM: string
  currentBotVersion: string
  currentActiveASR: string
  currentActiveTTS: string
  tempConfigOptions: TempConfigOptionsInterface
  userRecordedAudioUrl: string
  confidenceScoreTreshold: number
  asrEnforceTargetLang: string
}

// Define the initial state using that type
const INITIAL_STATE: ProgramControllerStateInterface = {
  currentProgram: {},
  currentMessages: [],
  audioInTextOutConvoActions: [],
  audioInAudioOutConvoActions: [],
  currentBotVersion: '2.0',
  currentConvoThreadID: '',
  currentActiveLLM: 'fake',
  currentActiveCCM: 'fake',
  currentActiveASR: 'fake',
  currentActiveTTS: 'fake',
  asrEnforceTargetLang: 'true',
  tempConfigOptions: {
    tempLLM: 'fake',
    tempCCM: 'fake',
    tempASR: 'fake',
    tempTTS: 'fake',
    tempBotVersion: '2.0',
    tempAsrEnforceTargetLang: 'true'
  },
  userRecordedAudioUrl: '',
  confidenceScoreTreshold: 0.7
}

const v2ProgramController = createSlice({
  name: 'v2-program-controller',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentProgramAction(state, action) {
      return {
        ...state,
        currentProgram: action.payload
      }
    },
    updateCurrentMessagesAction: (state, action) => {
      return {
        ...state,
        currentMessages: [...action.payload]
      }
    },
    asrEnforceTargetLangAction: (state, action) => {
      return {
        ...state,
        asrEnforceTargetLang: action.payload
      }
    },
    updateAudioInTextOutConvoActionsAction: (state, action) => {
      return {
        ...state,
        audioInTextOutConvoActions: [...action.payload]
      }
    },
    updateAudioInAudioOutConvoActionsAction: (state, action) => {
      return {
        ...state,
        audioInAudioOutConvoActions: [...action.payload]
      }
    },
    updateCurrentConvoThreadIDAction: (state, action) => {
      return {
        ...state,
        currentConvoThreadID: action.payload
      }
    },
    updateCurrentActiveLLMAction: (state, action) => {
      return {
        ...state,
        currentActiveLLM: action.payload
      }
    },
    updateCurrentBotVersionAction: (state, action) => {
      return {
        ...state,
        currentBotVersion: action.payload
      }
    },
    updateCurrentActiveCCMAction: (state, action) => {
      return {
        ...state,
        currentActiveCCM: action.payload
      }
    },
    updateCurrentConfidenceScoreThresholdAction: (state, action) => {
      return {
        ...state,
        confidenceScoreTreshold: action.payload
      }
    },
    updateCurrentActiveASRAction: (state, action) => {
      return {
        ...state,
        currentActiveASR: action.payload
      }
    },
    updateCurrentActiveTTSAction: (state, action) => {
      return {
        ...state,
        currentActiveTTS: action.payload
      }
    },
    updateTempConfigOptionsAction: (state, action) => {
      return {
        ...state,
        tempConfigOptions: {
          ...state.tempConfigOptions,
          ...action.payload
        }
      }
    },
    updateConfigOptionsAction: (state) => {
      const changes: string[] = []

      if (state.currentActiveASR !== state.tempConfigOptions.tempASR) {
        changes.push(`ASR Model updated to ${state.tempConfigOptions.tempASR}`)
      }
      if (state.currentActiveCCM !== state.tempConfigOptions.tempCCM) {
        changes.push(`CCM Model updated to ${state.tempConfigOptions.tempCCM}`)
      }
      if (state.currentActiveLLM !== state.tempConfigOptions.tempLLM) {
        changes.push(`LLM Model updated to ${state.tempConfigOptions.tempLLM}`)
      }
      if (state.currentActiveTTS !== state.tempConfigOptions.tempTTS) {
        changes.push(`TTS Model updated to ${state.tempConfigOptions.tempTTS}`)
      }
      if (state.currentBotVersion !== state.tempConfigOptions.tempBotVersion) {
        changes.push(
          `Bot Version updated to ${state.tempConfigOptions.tempBotVersion}`
        )
      }
      if (
        state.asrEnforceTargetLang !==
        state.tempConfigOptions.tempAsrEnforceTargetLang
      ) {
        changes.push(
          `ASR Enforce Target Lang updated to ${state.tempConfigOptions.tempAsrEnforceTargetLang}`
        )
      }
      showNotification({
        type: 'info',
        message: changes.map((change) => `${change}.`).join('\n')
      })
      return {
        ...state,
        currentActiveASR: state.tempConfigOptions.tempASR,
        currentActiveCCM: state.tempConfigOptions.tempCCM,
        currentActiveLLM: state.tempConfigOptions.tempLLM,
        currentActiveTTS: state.tempConfigOptions.tempTTS,
        currentBotVersion: state.tempConfigOptions.tempBotVersion,
        asrEnforceTargetLang: state.tempConfigOptions.tempAsrEnforceTargetLang,
        audioInAudioOutConvoActions: [],
        audioInTextOutConvoActions: []
      }
    },
    updateUserRecordedAudioUrl: (state, action) => {
      return {
        ...state,
        userRecordedAudioUrl: action.payload
      }
    },
    resetV2ProgramStates: (state) => {
      return {
        ...state,
        ...INITIAL_STATE
      }
    }
  }
})

export const {
  setCurrentProgramAction,
  updateCurrentMessagesAction,
  updateAudioInTextOutConvoActionsAction,
  updateAudioInAudioOutConvoActionsAction,
  updateCurrentConfidenceScoreThresholdAction,
  updateCurrentConvoThreadIDAction,
  updateCurrentActiveLLMAction,
  updateCurrentActiveCCMAction,
  updateCurrentBotVersionAction,
  updateCurrentActiveASRAction,
  updateCurrentActiveTTSAction,
  updateTempConfigOptionsAction,
  updateConfigOptionsAction,
  updateUserRecordedAudioUrl,
  asrEnforceTargetLangAction,
  resetV2ProgramStates
} = v2ProgramController.actions
export default v2ProgramController.reducer
