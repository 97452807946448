import React, { useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/system'
import BufferPlayer from 'components/molecules/media/audio-players/buffer-player'

const SpeechFeedBacksComponent = ({
  sessionStatus,
  programComplete,
  playPauseButtonDisabled,
  audioElement,
  onClick
}: {
  sessionStatus:
    | 'initializing'
    | 'ready_to_record'
    | 'playing'
    | 'pausing'
    | 'recording'
    | 'processing'
  audioElement: BufferPlayer
  programComplete: boolean
  playPauseButtonDisabled: boolean
  onClick: () => void
}) => {
  const { height: heightt } = useWindowSize()
  const size = (heightt * 5.4) / 100
  const bgcolor = '#D9D9D9'
  const width = `${size}px`
  const height = `${size}px`
  const borderRadius = `${size}px`

  const bar1 = useRef<HTMLDivElement>()
  const bar2 = useRef<HTMLDivElement>()
  const bar3 = useRef<HTMLDivElement>()
  const bar4 = useRef<HTMLDivElement>()
  const bar5 = useRef<HTMLDivElement>()

  const tDuration = `5ms`

  useEffect(() => {
    const arr = [bar3, bar2, bar4, bar1, bar5]

    if (sessionStatus === 'playing') {
      const bands = arr.length
      const minSize = size
      const maxSize = size * 5

      const transformMap = (value: number) => {
        return Math.floor(
          (Math.max(0, value - BufferPlayer.minAudioPitchFreq) *
            (maxSize - minSize)) /
            (BufferPlayer.maxAudioFreq - BufferPlayer.minAudioPitchFreq) +
            minSize
        )
      }

      let frameId: number
      const frameAnimation = () => {
        const freqs = audioElement.getSpeechLevel(bands * 2)

        for (let i = 0; i < bands; i += 1) {
          const ref = arr[i].current
          if (ref) ref.style.height = `${transformMap(freqs[i][1])}px`
        }

        frameId = requestAnimationFrame(frameAnimation)
      }

      frameId = requestAnimationFrame(frameAnimation)

      return () => cancelAnimationFrame(frameId)
    }

    for (let i = 0; i < arr.length; i += 1) {
      const ref = arr[i].current
      if (ref) ref.style.height = `${size}px`
    }
    return () => null
  }, [sessionStatus, size])

  return (
    <IconButton
      onClick={onClick}
      aria-label={
        sessionStatus === 'initializing' || sessionStatus === 'pausing'
          ? 'play'
          : 'pause'
      }
      style={{ width: '100%', flex: '1', borderRadius: 0 }}
      sx={{ gap: 1 }}
      disabled={
        sessionStatus === 'processing' ||
        programComplete ||
        playPauseButtonDisabled
      }>
      <Box
        color="#323232"
        position="absolute"
        top="10%"
        left="50%"
        sx={{ transform: 'translateX(-50%)' }}>
        {sessionStatus === 'playing' ? 'Click to Pause' : 'Click to Continue'}
      </Box>
      <Box
        ref={bar5}
        style={{ transitionDuration: tDuration }}
        bgcolor={bgcolor}
        width={width}
        height={height}
        borderRadius={borderRadius}
      />
      <Box
        ref={bar4}
        style={{ transitionDuration: tDuration }}
        bgcolor={bgcolor}
        width={width}
        height={height}
        borderRadius={borderRadius}
      />
      <Box
        ref={bar3}
        style={{ transitionDuration: tDuration }}
        bgcolor={bgcolor}
        width={width}
        height={height}
        borderRadius={borderRadius}
      />
      <Box
        ref={bar2}
        style={{ transitionDuration: tDuration }}
        bgcolor={bgcolor}
        width={width}
        height={height}
        borderRadius={borderRadius}
      />
      <Box
        ref={bar1}
        style={{ transitionDuration: tDuration }}
        bgcolor={bgcolor}
        width={width}
        height={height}
        borderRadius={borderRadius}
      />
    </IconButton>
  )
}

export default SpeechFeedBacksComponent
