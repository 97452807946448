import { useState, useEffect } from 'react'
import programApi from 'datastore/apis/program-api'
import { useStoreSelector } from 'datastore/config/hooks'
import { useMountedState } from 'react-use'
import { IS_STAG_ENV } from 'datastore/utils/constants'

/**
 * @returns {Object} The array of model values.
 */
export function useFetchModelValues() {
  const [models, setModels] = useState({
    llmModel: [],
    asrModel: [],
    ttsModel: [],
    ccmModel: []
  })

  const currentBotVersion = useStoreSelector(
    (storeselector) =>
      storeselector.v2ProgramController.tempConfigOptions.tempBotVersion
  )

  const mounted = useMountedState()

  useEffect(() => {
    const getModelValues = async () => {
      try {
        const results = await programApi.getRouteConfigModels({
          'FULL-API-VERSION': currentBotVersion
        })
        if (mounted()) {
          setModels(results.data)
        }
      } catch (error) {
        console.error('Failed to fetch model values:', error)
      }
    }

    if (IS_STAG_ENV) {
      getModelValues()
    }
  }, [])

  return models
}
