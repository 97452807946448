import {
  AudioRecorder,
  AudioRecorderType
} from 'components/molecules/media/audio-recorder'
import React from 'react'

import audioFXReady from 'assets/sounds/beep-ready.mp3'
import audioFXPass from 'assets/sounds/beep-pass.mp3'
import audioFXFail from 'assets/sounds/beep-fail.mp3'
import audioFXStart from 'assets/sounds/beep-start.mp3'
import audioFXStop from 'assets/sounds/beep-stop.mp3'
import audioFXPlay from 'assets/sounds/beep-play.mp3'
import audioFXPause from 'assets/sounds/beep-pause.mp3'
import audioFXCheer from 'assets/sounds/cheer-celebrate.mp3'
import log from 'loglevel'
import { Alert } from 'alert'
import { DefaultAudioRecorder } from 'components/molecules/media/audio-recorder/default-audio-recorder'
import { AudioActivityRecorder } from 'components/molecules/media/audio-recorder/audio-activity-recorder'
import BufferPlayer from 'components/molecules/media/audio-players/buffer-player'

interface ErrorObjInterface {
  errors: {
    field: string
    message: string
  }[]
}

function getErrorMessage(errorData: ErrorObjInterface): string {
  let msg = ''
  try {
    errorData.errors.forEach((err) => {
      msg += `${err.field}: ${err.message}. `
    })
  } catch (e) {
    log.error(e)
  }
  return msg || 'An unknown error occurred'
}

let globalAudio: BufferPlayer | undefined
function updateGlobalAudio(src: any, type = 'audio/wav') {
  if (!globalAudio) {
    const audioContext = new (AudioContext ||
      (window as any).webkitAudioContext)()

    log.log('*** functions::updateGlobalAudio CREATING global audio ***')

    globalAudio = new BufferPlayer(audioContext)
    if (src) {
      globalAudio.loadAudio(src)
    }

    const isProdEnv = process.env.NODE_ENV?.startsWith('prod')
    if (!isProdEnv) {
      ;(global as any).globalAudio = globalAudio
    }
  } else {
    log.info('*** functions::updateGlobalAudio RE-USING global audio ***')

    if (src) {
      globalAudio.loadAudio(src)
    }
  }

  log.info(`*** functions::updateGlobalAudio::globalAudio: ${globalAudio} ***`)
  log.info(globalAudio)

  return globalAudio
}

let globalRecorder: AudioRecorder | null = null
async function getGlobalRecorder(
  audioRecorderType: AudioRecorderType | null
): Promise<AudioRecorder> {
  if (audioRecorderType === null && globalRecorder !== null) {
    return globalRecorder
  }

  const expectedRecorderClass =
    audioRecorderType === 'vad' ? AudioActivityRecorder : DefaultAudioRecorder

  if (!globalRecorder || !(globalRecorder instanceof expectedRecorderClass)) {
    log.info(
      `*** functions::getGlobalRecorder creating globalRecorder ***`,
      expectedRecorderClass
    )

    if (globalRecorder && globalRecorder.mediaStream) {
      // Unsubscribe to the previous mic stream.
      globalRecorder.mediaStream.getTracks().forEach((track) => track.stop())
      globalRecorder.didInitialized = false
    }

    globalRecorder = new (
      audioRecorderType === 'vad' ? AudioActivityRecorder : DefaultAudioRecorder
    )()
    const response: any = await globalRecorder.initMediaRecorder()
    if (response.error) {
      Alert.show('Your microphone could not be enabled')
    }
  }

  log.info(
    `*** functions::getGlobalRecorder::globalRecorder: ***`,
    globalRecorder
  )

  return globalRecorder
}

export function disableMouseEventOnMobile(
  ref: React.RefObject<HTMLButtonElement>
) {
  const refCopy = ref.current
  const processTouch = (event: any) => {
    event.preventDefault() // prevent mouse event from firing afterwards on mobile
  }
  refCopy?.addEventListener('touchstart', processTouch, false)
  refCopy?.addEventListener('touchend', processTouch, false)
  return () => {
    refCopy?.removeEventListener('touchstart', processTouch, false)
    refCopy?.removeEventListener('touchend', processTouch, false)
  }
}

function playSoundEffect(fxName: string, onComplete?: () => void) {
  let audioFX = null
  if (fxName === 'ready') {
    audioFX = audioFXReady
  } else if (fxName === 'pass') {
    audioFX = audioFXPass
  } else if (fxName === 'fail') {
    audioFX = audioFXFail
  } else if (fxName === 'start') {
    audioFX = audioFXStart
  } else if (fxName === 'stop') {
    audioFX = audioFXStop
  } else if (fxName === 'play') {
    audioFX = audioFXPlay
  } else if (fxName === 'pause') {
    audioFX = audioFXPause
  } else if (fxName === 'cheer') {
    audioFX = audioFXCheer
  }

  let callbackCalled = false
  const callback = async () => {
    if (!callbackCalled) {
      callbackCalled = true
      onComplete?.()
    }
  }

  const audioElement = new Audio(audioFX)
  audioElement.onended = () => {
    callback()
  }
  try {
    audioElement.play()
  } catch (e) {
    callback()
  }
}

function awaitTimeout(delay: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export default {
  getErrorMessage,
  updateGlobalAudio,
  getGlobalRecorder,
  disableMouseEventOnMobile,
  awaitTimeout,
  playSoundEffect
}
