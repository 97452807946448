import React, { FunctionComponent, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'

import PopupComponent from 'components/molecules/popups/popup'
import UserTopPanelComponent from 'components/organisms/panels/user-top-panel/user-top-panel'

import {
  PAGE_USER_BLOCK_SELECT,
  PAGE_USER_BLOCK_INTERACT,
  PAGE_USER_PROGRAM_SELECT,
  AMAZETHU_V1,
  IS_PROD_ENV,
  NOTIFICATION_MSG_SYSTEM_ERROR,
  NOTIFICATION_MSG_NETWORK_ERROR,
  NOTIFICATION_TITLE_SYSTEM_ERROR,
  NOTIFICATION_TITLE_NETWORK_ERROR
} from 'datastore/utils/constants'
import UserProgramSelectComponent from 'components/organisms/arenas/user-program-select/user-program-select'
import V2UserProgramSelectComponent from 'components/organisms/arenas/v2-user-program-select/v2-user-program-select'
import UserBlockSelectComponent from 'components/organisms/arenas/user-block-select/user-block-select'
import UserBlockInteractComponent from 'components/organisms/arenas/user-block-interact/user-block-interact'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import {
  changePageService,
  didBackendAPIRaiseException,
  showPopupService,
  updateTestbedConfigModels
} from 'datastore/slices/app-controller'
import V2UserBlockInteractComponent from 'components/organisms/arenas/user-block-interact/v2-user-block-interact'
import { useFetchModelValues } from 'hooks/api/use-fetch-models'
import ProgramSelectBottomPanel from 'components/organisms/panels/program-select-bottom-panel/program-select-bottom-panel'
import { usePageViewTracking } from 'hooks/tracking/use-pageview-tracking'
import { PopupTypeEnum } from 'types'
import programApi from 'datastore/apis/program-api'
import ErrorDialogComponent from 'components/molecules/popups/error-dialog'

interface UserAppComponentPropsInterface {
  shouldShowPopup: boolean
  popupType: string
  currentPopup: any // check this
  setPopupType: React.Dispatch<React.SetStateAction<PopupTypeEnum>>
  isGlobalPageAuth: () => boolean
}

const UserAppComponent: FunctionComponent<UserAppComponentPropsInterface> = ({
  popupType,
  shouldShowPopup,
  currentPopup,
  isGlobalPageAuth,
  setPopupType
}) => {
  // Track the user page view.
  usePageViewTracking()

  const dispatch = useStoreDispatch()

  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )

  const currPage = useStoreSelector(
    (storeState) => storeState.appController.currPage
  )

  const models = useFetchModelValues()

  useEffect(() => {
    dispatch(updateTestbedConfigModels(models))
  }, [models])

  useEffect(() => {
    dispatch(didBackendAPIRaiseException(null))
  }, [])

  const backendAPIRaisedException = useStoreSelector(
    (storeState) => storeState.appController.backendAPIRaisedException
  )

  const isNetworkError =
    backendAPIRaisedException?.error &&
    backendAPIRaisedException?.error.includes('"message":"Network Error"')

  const handleCloseErrorDialog = () => {
    dispatch(didBackendAPIRaiseException(null))
    if (isNetworkError || !backendAPIRaisedException) {
      return
    }
    programApi.sendAlertNotificationToWebhook({
      message: backendAPIRaisedException.message,
      error: JSON.stringify(backendAPIRaisedException.error)
    })
    dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
  }

  return (
    <Stack
      className="User-App"
      sx={{
        display: 'flex',
        height: '100%',
        overflow: 'clip',
        position: 'relative'
      }}>
      {backendAPIRaisedException && (
        <ErrorDialogComponent
          open={!!backendAPIRaisedException}
          errorTitle={
            isNetworkError
              ? NOTIFICATION_TITLE_NETWORK_ERROR
              : NOTIFICATION_TITLE_SYSTEM_ERROR
          }
          errorMessage={
            isNetworkError
              ? NOTIFICATION_MSG_NETWORK_ERROR
              : NOTIFICATION_MSG_SYSTEM_ERROR
          }
          onClose={handleCloseErrorDialog}
        />
      )}
      <Box
        className="AppHeader-Container"
        sx={{
          display: isGlobalPageAuth() ? 'none' : 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'fit-content',
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 12
        }}>
        <UserTopPanelComponent
          ddHide
          // onCreateProgramSelect={()=>{}}
        />
        <Divider className="AppHeader-Div" />
      </Box>
      <Stack
        className="AppBody-Container"
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          'overflow-y': 'auto',
          flex: 1
        }}>
        <Routes>
          <Route
            path={`/${PAGE_USER_PROGRAM_SELECT}`}
            element={
              runtimeAppEnv === AMAZETHU_V1 ? (
                <UserProgramSelectComponent />
              ) : (
                <V2UserProgramSelectComponent />
              )
            }
          />
          <Route
            path={`/${PAGE_USER_BLOCK_SELECT}`}
            element={<UserBlockSelectComponent />}
          />
          <Route
            path={`/${PAGE_USER_BLOCK_INTERACT}`}
            element={
              runtimeAppEnv === AMAZETHU_V1 ? (
                <UserBlockInteractComponent />
              ) : (
                <V2UserBlockInteractComponent />
              )
            }
          />
        </Routes>
      </Stack>
      {currPage === PAGE_USER_PROGRAM_SELECT && (
        <Box
          className=""
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: 'fit-content',
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 12
          }}>
          <Divider className="AppHeader-Div" />
          {!IS_PROD_ENV && <ProgramSelectBottomPanel />}
        </Box>
      )}
      <Dialog open={shouldShowPopup}>
        <PopupComponent
          onClose={(result) => {
            setPopupType(PopupTypeEnum.none)
            dispatch(
              showPopupService({ type: PopupTypeEnum.none, data: { result } })
            )
          }}
          type={popupType}
          data={currentPopup.data}
        />
      </Dialog>
    </Stack>
  )
}

export default UserAppComponent
