import React from 'react'

import TextField from '@mui/material/TextField'

import { theme } from 'components/atoms/styles/theme'
import OutlinedDivComponent from 'components/atoms/utils/outlined-div'
import log from 'loglevel'

type ValueChangeFunc = (e: any) => void

interface PropsInterface {
  label?: string
  placeholder?: string
  width?: string | number
  height?: string | number
  value?: string
  onValueChange?: ValueChangeFunc
  isHidden?: boolean
}

const defaultProps = {
  label: '',
  width: 'auto',
  placeholder: '',
  height: '40px',
  value: '',
  onValueChange: (e: any) => {
    log.info(`*** TextInput::onValueChange: ${e.target.value} ***`)
  },
  isHidden: false
}

const TextInputComponent: React.FunctionComponent<PropsInterface> = ({
  label = defaultProps.label,
  placeholder = defaultProps.placeholder,
  width = defaultProps.width,
  height = defaultProps.height,
  value = defaultProps.value,
  onValueChange = defaultProps.onValueChange,
  isHidden = defaultProps.isHidden
}) => {
  return (
    <OutlinedDivComponent
      label={label}
      width={width}
      height={height}
      padding="0"
      isHidden={isHidden}>
      <TextField
        size="small"
        variant="outlined"
        label=""
        placeholder={placeholder}
        autoComplete="off"
        value={value}
        onChange={onValueChange}
        InputProps={{
          style: {
            width,
            height,
            fontSize: theme.typography.h4.fontSize,
            color: theme.palette.neutral_dark.dark
          }
        }}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: theme.typography.h4.fontSize }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: theme.palette.neutral_light.light
            }
          },
          width,
          display: 'flex'
        }}
      />
    </OutlinedDivComponent>
  )
}

TextInputComponent.defaultProps = defaultProps
export default TextInputComponent
