import React, { FunctionComponent } from 'react'
import { theme } from 'components/atoms/styles/theme'
import Radio from '@mui/material/Radio'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import FormControlLabel, {
  FormControlLabelProps
} from '@mui/material/FormControlLabel'
import OutlinedDivComponent from 'components/atoms/utils/outlined-div'
import log from 'loglevel'

type OptionChangeFunc = (newValue: any) => void

interface PropsInterface {
  label?: string
  options?: string[]
  width?: string | number
  height?: string | number
  defaultIndex?: number
  onOptionChange?: OptionChangeFunc
  isHidden?: boolean
}

const defaultProps = {
  label: 'Input - Radio Button Group',
  options: ['Option 1', 'Option 2'],
  width: 'auto',
  height: '40px',
  defaultIndex: -1,
  onOptionChange: (newValue: any) => {
    log.info(`*** onOptionChange: ${newValue} ***`)
  },
  isHidden: false
}

/* eslint-disable react/destructuring-assignment */
const CustomControlLabelComponent = (props: FormControlLabelProps) => {
  const radioGroup = useRadioGroup()

  return (
    <FormControlLabel
      {...props}
      sx={{
        '.MuiFormControlLabel-label': {
          color:
            radioGroup?.value === props.value
              ? theme.palette.primary.main
              : theme.palette.neutral_dark.dark,
          fontSize: theme.typography.h4.fontSize
        }
      }}
    />
  )
}
/* eslint-enable react/destructuring-assignment */

const RadioBtnGrpComponent: FunctionComponent<PropsInterface> = ({
  label = defaultProps.label,
  options = defaultProps.options,
  width = defaultProps.width,
  height = defaultProps.height,
  defaultIndex = defaultProps.defaultIndex,
  onOptionChange = defaultProps.onOptionChange,
  isHidden = defaultProps.isHidden
}) => {
  return (
    <OutlinedDivComponent
      label={label}
      width={width}
      height={height}
      padding="2"
      isHidden={isHidden}>
      <RadioGroup
        row
        name="radio-buttons-group"
        value={defaultIndex >= 0 ? options[defaultIndex] : ''}
        onChange={onOptionChange}>
        {options.map((text) => (
          <CustomControlLabelComponent
            key={text}
            value={text}
            control={<Radio disableRipple size="small" />}
            label={text}
          />
        ))}
      </RadioGroup>
    </OutlinedDivComponent>
  )
}

RadioBtnGrpComponent.defaultProps = defaultProps

export default RadioBtnGrpComponent
