// Base URL
export const URL_BASE = process.env.REACT_APP_API_SERVER

// Authentication Endpoints
export const LOGIN_GOOGLE_URI = `${URL_BASE}/api/v1/signin_google`
export const URI_AUTHLIST = `${URL_BASE}/api/v1/authlist`

// User Endpoints
export const URI_CREATE_ADMIN = `${URL_BASE}/api/v1/admin/create`
export const URI_ADMIN = `${URL_BASE}/api/v1/admin`

// Program Endpoints
export const URI_CREATE_PROGRAM = `${URL_BASE}/api/v1/program/create`
export const URI_LIST_PROGRAMS = `${URL_BASE}/api/v1/program/list`
export const URI_LIST_V2_PROGRAMS = `${URL_BASE}/api/v2/program/list`

export const URI_PROGRAM = `${URL_BASE}/api/v1/program`

// Block Endpoints
export const URI_CREATE_BLOCK = `${URL_BASE}/api/v1/block/create`
export const URI_BLOCK = `${URL_BASE}/api/v1/block`

// Upload Endpoints
export const URI_AUDIO_PHRASE_UPLOAD = `${URL_BASE}/api/v1/upload/audio_phrase`
export const URI_BOT_SPEECH_UPLOAD = `${URL_BASE}/api/v1/upload/bot_speech`
export const URI_BOT_IMAGE_UPLOAD = `${URL_BASE}/api/v1/upload/program_image`

// Convo Thread Endpoints
export const URI_FETCH_CONVO_THREAD = `${URL_BASE}/api/v2/thread/get`
export const URI_CONVO_THREAD_RESUME = `${URL_BASE}/api/v2/thread/resume`
export const URI_CONVO_THREAD_INTERACT = `${URL_BASE}/api/v2/thread/interact`
export const URI_CONVO_THREAD_RESET = `${URL_BASE}/api/v2/thread/reset`
export const URI_BOT_CONFIG_RELOAD = `${URL_BASE}/api/v2/bot/reload-config`

export const URI_THREAD_ROUTE_CONFIG_MODELS = `${URL_BASE}/api/v2/thread/route-config/models`

// Server Webhooks
export const URI_ALERT_WEBHOOK = `${URL_BASE}/api/v2/alert/push-notification`
