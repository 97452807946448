import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Variant } from '@mui/material/styles/createTypography'
import { styled } from '@mui/system'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'
import TextButtonComponent from 'components/atoms/buttons/text-button'
import { theme } from 'components/atoms/styles/theme'
import React, { FunctionComponent, useRef, useState } from 'react'

export enum FeedbackItemTypeEnum {
  inputField = 'inputField',
  ratingOver5 = 'ratingOver5'
}

export type FeedbackItemType = {
  title: string
  id: string
  required?: boolean
  placeholder?: string
  value?: string
  error?: boolean
} & (
  | {
      inputType: FeedbackItemTypeEnum.inputField
    }
  | {
      inputType: FeedbackItemTypeEnum.ratingOver5
      maxDesc: string
      minDesc: string
    }
)

const DEFAULT_PLACEHOLDER = 'Your answer'

const FEEDBACKS_DATA: FeedbackItemType[] = [
  {
    title: 'Overall, how was your experience using the product?',
    id: 'product_experience_review',
    inputType: FeedbackItemTypeEnum.ratingOver5,
    maxDesc: 'Great!',
    required: true,
    minDesc: 'Okay'
  },
  {
    title: 'What did you like about the product?',
    id: 'what_the_user_likes',
    required: true,
    inputType: FeedbackItemTypeEnum.inputField
  },
  {
    title: 'How likely are you to recommend the product to a friend?',
    id: 'product_recommendation_review',
    inputType: FeedbackItemTypeEnum.ratingOver5,
    required: true,
    maxDesc: 'Not Likely',
    minDesc: 'Right after the survey!'
  },
  {
    title:
      'What features would you like us to add as part of the next product update?',
    id: 'features_suggested',
    required: true,
    inputType: FeedbackItemTypeEnum.inputField
  },
  {
    title: 'What is your full name?',
    id: 'user_full_name',
    inputType: FeedbackItemTypeEnum.inputField
  },
  {
    title: 'What is your Email address?',
    id: 'user_email_address',
    inputType: FeedbackItemTypeEnum.inputField
  }
]

const FEEDBACK_ITEM_STYLE = {
  position: 'relative',
  backgroundColor: 'white',
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  padding: 3,
  paddingBottom: '25px',
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}

const FEEDBACK_RATING_COMPONENT_DEFAULT_PROPS = {
  fontSize: 0,
  minDesc: '',
  maxDesc: '',
  onChange: (event: React.ChangeEvent<HTMLInputElement>): unknown => event
}

const FeedbackRatingComponent: FunctionComponent<
  typeof FEEDBACK_RATING_COMPONENT_DEFAULT_PROPS
> = ({ onChange, fontSize, minDesc, maxDesc }) => {
  const isNotSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [radioGroupValue, setRadioGroupValue] = useState<string>()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event)
    setRadioGroupValue(event.target.value)
  }

  const notes = ['1', '2', '3', '4', '5']

  return (
    <Box>
      <RadioGroup
        onChange={handleChange}
        row
        sx={{ justifyContent: 'space-around' }}
        aria-labelledby="demo-form-control-label-placement"
        name="position">
        {notes.map((note) => (
          <FormControlLabel
            value={note}
            control={<Radio />}
            sx={{
              margin: 0,
              '.MuiFormControlLabel-label': {
                color:
                  radioGroupValue === note
                    ? theme.palette.primary.main
                    : theme.palette.neutral_dark.dark,
                fontSize
              },
              '.MuiRadio-root': {
                padding: isNotSmallScreen ? 2 : 0.5
              }
            }}
            label={note}
            labelPlacement="top"
          />
        ))}
      </RadioGroup>
      <Stack
        px={isNotSmallScreen ? 2 : 0}
        flexDirection="row"
        justifyContent="space-between">
        <Typography
          id="form-title"
          variant={isNotSmallScreen ? 'h4' : 'h5'}
          color={theme.palette.neutral_dark.dark}>
          {minDesc}
        </Typography>
        <Typography
          id="form-title"
          variant={isNotSmallScreen ? 'h4' : 'h5'}
          color={theme.palette.neutral_dark.dark}>
          {maxDesc}
        </Typography>
      </Stack>
    </Box>
  )
}

const ValidationTextFieldComponent = styled(TextField)({
  '& input:invalid + fieldset': {
    borderColor: theme.palette.error.light
  }
})

const FEEDBACK_TEXT_INPUT_FIELD_COMPONENT_DEFAULT_PROPS = {
  required: false,
  fontSize: 0,
  hasError: false,
  placeholder: DEFAULT_PLACEHOLDER,
  onChange: (event: React.ChangeEvent<HTMLInputElement>): unknown => event
}

const FeedbackTextInputFieldComponent: React.FC<
  typeof FEEDBACK_TEXT_INPUT_FIELD_COMPONENT_DEFAULT_PROPS
> = ({ required, hasError, fontSize, placeholder, onChange }) => (
  <ValidationTextFieldComponent
    fullWidth
    id="standard-basic"
    variant="standard"
    size="medium"
    sx={{
      '& .MuiInputBase-input': {
        fontSize
      }
    }}
    error={hasError}
    onChange={onChange}
    required={required}
    placeholder={placeholder}
  />
)

const FEEDBACK_COMPONENT_DEFAULT_PROP = {
  onClose: (value: null | typeof FEEDBACKS_DATA): unknown => value
}

const FeedbackComponent: React.FC<typeof FEEDBACK_COMPONENT_DEFAULT_PROP> = ({
  onClose
}) => {
  const feedbacksDataRef = useRef(FEEDBACKS_DATA.map((item) => ({ ...item })))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const variant = isMediumScreen ? 3 : 4
  const fontSize: number = (
    isMediumScreen ? theme.typography.h3.fontSize : theme.typography.h4.fontSize
  ) as number

  const [isValidFeedback, setIsValidFeedBack] = useState<boolean>(true)

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    feedbacksDataRef.current[idx].value = event.target.value
    feedbacksDataRef.current[idx].error = false
    setIsValidFeedBack(true)
  }
  const handleSubmit = () => {
    let idx = 0
    while (idx < feedbacksDataRef.current.length) {
      const item = feedbacksDataRef.current[idx]
      if (item.required && !item.value) {
        item.error = true
        setIsValidFeedBack(false)
        // Early break and return on invalid feeedback item found
        return
      }
      item.error = false
      idx += 1
    }

    onClose(feedbacksDataRef.current)
  }

  return (
    <Stack
      flex={1}
      overflow="hidden"
      borderRadius="4px"
      bgcolor={theme.palette.neutral_light.light}>
      <Box
        sx={{
          p: 2,
          borderRadius: '4px 4px 0px 0px',
          bgcolor: theme.palette.primary.light
        }}>
        <Typography
          id="form-title"
          variant={`h${variant - 1}` as Variant}
          color={theme.palette.neutral_dark.dark}>
          Feedback on Amazethu
        </Typography>
      </Box>
      <Stack
        gap={2}
        flex={1}
        p={isMediumScreen ? 5 : 1}
        py={2}
        overflow="hidden scroll">
        <Box sx={FEEDBACK_ITEM_STYLE}>
          <Typography
            id="form-title"
            variant={`h${variant}` as Variant}
            color={theme.palette.neutral_dark.dark}>
            Thank you for being an early user of Amazéthu! We have a few
            questions for you to help us know how we can improve with future
            iterations of the product.
          </Typography>
        </Box>
        {feedbacksDataRef.current.map((feedbackData, idx) => (
          <Box
            sx={{
              ...FEEDBACK_ITEM_STYLE,
              borderColor: feedbackData.error
                ? theme.palette.error.dark
                : 'transparent'
            }}>
            <Typography
              variant={`h${variant}` as Variant}
              fontWeight="bold"
              color={theme.palette.neutral_dark.dark}>
              {feedbackData.title}
              {feedbackData.required && (
                <span
                  style={{
                    fontWeight: 'bold',
                    color: theme.palette.error.dark
                  }}>
                  *
                </span>
              )}
            </Typography>
            {feedbackData.inputType !== FeedbackItemTypeEnum.ratingOver5 ? (
              <FeedbackTextInputFieldComponent
                required={!!feedbackData.required}
                placeholder={feedbackData.placeholder || DEFAULT_PLACEHOLDER}
                fontSize={fontSize}
                hasError={false}
                onChange={(event) => handleChange(event, idx)}
              />
            ) : (
              <FeedbackRatingComponent
                fontSize={fontSize}
                minDesc={feedbackData.minDesc}
                maxDesc={feedbackData.maxDesc}
                onChange={(event) => handleChange(event, idx)}
              />
            )}
            {feedbackData.error && (
              <Typography
                id="form-title"
                sx={{ position: 'absolute', bottom: 0, gap: '3px' }}
                variant={`h${variant + 1}` as Variant}
                color={theme.palette.error.dark}>
                <IconButton
                  sx={{
                    padding: 0,
                    paddingBottom: '2px',
                    color: theme.palette.error.dark,
                    svg: { fontSize: '15px' }
                  }}>
                  <ErrorOutlineOutlinedIcon />
                </IconButton>
                This is a required question
              </Typography>
            )}
          </Box>
        ))}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          padding: '12px 18px',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}>
        <TextButtonComponent
          label="CANCEL"
          onButtonClick={() => onClose(null)}
        />

        <DefaultButtonComponent
          label="SUBMIT"
          disabled={!isValidFeedback}
          onButtonClick={handleSubmit}
        />
      </Box>
    </Stack>
  )
}

export default FeedbackComponent
