import React from 'react'
import { MapInteractionCSS } from 'react-map-interaction'
import OrganizationChart from '@dabeng/react-orgchart'

import Box from '@mui/system/Box'

import { theme } from 'components/atoms/styles/theme'
import TreeNodeComponent from 'components/organisms/arenas/admin-block-design/tree-node'
import log from 'loglevel'

interface PropsInterface {
  datasource?: Record<string, unknown>
  onNodeSelect?: (nodeData: any) => void
  onNodeUnselect?: () => void
}

const defaultProps = {
  datasource: {
    id: 'uuid-abc',
    name: 'Convo State 01',
    desc: 'Bot welcoming the user to the experience',
    nodeType: 'state_new',
    objectID: '<STATE_ID_01>',
    stateType: 'input',
    audioURLs: [
      'https://amazethu-public-us-east-2.s3.us-east-2.amazonaws.com/convai-audio-collection/botbuilder-audio/6308c19c72ee570030bb34e2/bot_speech/%3CSTATE_ID_01%3E-618bd739a998443fc20b4397-0-1661518325.wav'
    ],
    children: [
      {
        id: 'uuid-def',
        name: 'NoMatchIntentOption',
        desc: 'Default option when no other option is matched.',
        nodeType: 'option_sys',
        objectID: 'system-option-003',
        children: [
          {
            id: 'uuid-ghi',
            name: 'Try Again',
            desc: 'Bot informing the user to try again.',
            nodeType: 'state_new',
            objectID: '7jVqj',
            stateType: 'transit',
            audioURLs: [
              'https://amazethu-public-us-east-2.s3.us-east-2.amazonaws.com/convai-audio-collection/botbuilder-audio/6308c19c72ee570030bb34e2/bot_speech/7jVqj-618bd739a998443fc20b4397-0-1661518450.wav'
            ],
            children: [
              {
                id: 'uuid-jkl',
                name: 'Convo State 01',
                nodeType: 'state_ref',
                objectID: '<STATE_ID_01>',
                refID: '#001'
              }
            ]
          }
        ]
      },
      {
        id: 'uuid-mno',
        name: 'Convo Option 01',
        desc: 'Option matched when user says something',
        nodeType: 'option_new',
        objectID: 'EarsE8DS',
        audioURLs: [
          'https://amazethu-public-us-east-2.s3.us-east-2.amazonaws.com/convai-audio-collection/botbuilder-audio/6308c19c72ee570030bb34e2/intent_options/EarsE8DS-618bd739a998443fc20b4397-0-1661518363.wav'
        ],
        children: [
          {
            id: 'uuid-pqr',
            name: 'Convo State 02',
            desc: 'Bot saying goodbye to the user',
            nodeType: 'state_new',
            objectID: 'v2KUA',
            stateType: 'end',
            audioURLs: [
              'https://amazethu-public-us-east-2.s3.us-east-2.amazonaws.com/convai-audio-collection/botbuilder-audio/6308c19c72ee570030bb34e2/bot_speech/v2KUA-618bd739a998443fc20b4397-0-1663423784.wav'
            ]
          }
        ]
      }
    ]
  },
  onNodeSelect: (nodeData: any) => {
    log.info(`*** onNodeSelect ***`, nodeData)
  },
  onNodeUnselect: () => {
    log.info(`*** onNodeUnselect ***`)
  }
}

const AdminBlockDesignComponent: React.FunctionComponent<PropsInterface> = ({
  datasource = defaultProps.datasource,
  onNodeSelect = defaultProps.onNodeSelect,
  onNodeUnselect = defaultProps.onNodeUnselect
}: PropsInterface) => {
  log.info('AdminBlockDesign::datasource: ', datasource)
  const dpDataSource = JSON.parse(JSON.stringify(datasource))

  return (
    <Box
      className="AdminBlockDesign"
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.neutral_light.dark
      }}>
      <MapInteractionCSS>
        <OrganizationChart
          datasource={dpDataSource}
          collapsible={false}
          multipleSelect={false}
          NodeTemplate={TreeNodeComponent}
          onClickNode={onNodeSelect}
          onClickChart={onNodeUnselect}
        />
      </MapInteractionCSS>
    </Box>
  )
}

AdminBlockDesignComponent.defaultProps = defaultProps
export default AdminBlockDesignComponent
