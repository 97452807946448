import React, { FunctionComponent } from 'react'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'

import LogoPlusTextComponent from 'components/molecules/logos/logo-plus-text'
import DropDownComponent from 'components/atoms/inputs/drop-down'

import { styled } from '@mui/material/styles'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import {
  changePageService,
  setMicOnAction,
  showPopupService
} from 'datastore/slices/app-controller'
import { setCurrentProgramService } from 'datastore/slices/program-controller'
import {
  LABEL_VIEW_PROGRAMS,
  LABEL_CREATE_PROGRAM,
  PAGE_ADMIN_PROGRAM_SELECT,
  PAGE_ADMIN_BLOCK_DESIGN,
  PAGE_USER_PROGRAM_SELECT,
  NOTIFICATION_TITLE_MIC_DENIED,
  NOTIFICATION_MSG_MIC_DENIED,
  FeatureFlagsEnum
} from 'datastore/utils/constants'
import { resetConvoUILogAction } from 'datastore/slices/convo-ui-controller'
import log from 'loglevel'
import utilFuncs from 'datastore/utils/functions'
import { useMediaQuery } from '@mui/material'
import { theme } from 'components/atoms/styles/theme'
import { useWindowSize } from 'react-use'
import { PopupTypeEnum } from 'types'
import ProfileViewComponent from 'components/molecules/popups/profile-view'
import { useAutoStopAllMicStreamOnFocusLose } from 'hooks/use-auto-disable-microphone-stream'
import { useRemoteConfigFlag } from 'hooks/use-remote-config'
import styles from './user-top-panel.styles'

interface PropsInterface {
  width?: string | number
  height?: string | number
  ddWidth?: string | number
  ddHide?: boolean
  onCreateProgramSelect?: () => void
}

const defaultProps = {
  width: '100%',
  height: '60px',
  ddWidth: '216px',
  ddHide: false,
  onCreateProgramSelect: () => {
    log.info(`*** onCreateProgramSelect ***`)
  }
}

const initialDropDownData = [
  { ddID: 'view_programs', ddLabel: LABEL_VIEW_PROGRAMS },
  { ddID: 'create_program', ddLabel: LABEL_CREATE_PROGRAM }
]

// TODO: Olawunmi to help place styles code in appropriate file
const MicToggleSwitchComponent = styled(Switch)(() => styles.switchStyles)

const UserTopPanelComponent: FunctionComponent<PropsInterface> = ({
  width = defaultProps.width,
  height = defaultProps.height,
  ddWidth = defaultProps.ddWidth,
  ddHide = defaultProps.ddHide,
  onCreateProgramSelect = defaultProps.onCreateProgramSelect
}) => {
  const dispatch = useStoreDispatch()
  const programList = useStoreSelector(
    (storeState) => storeState.programController.programList
  )
  const ddProgramListData = [
    ...initialDropDownData,
    ...programList.map((program: any) => {
      return { ddID: program.id, ddLabel: program.name }
    })
  ]
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )
  const isMicOn = useStoreSelector(
    (storeState) => storeState.appController.micOn
  )
  const currentPageGlobal = useStoreSelector(
    (storeState) => storeState.appController.currPage
  )

  const { disableMicAccess } = useAutoStopAllMicStreamOnFocusLose()

  const updateMicStatus = async () => {
    try {
      const rec = await utilFuncs.getGlobalRecorder(null)
      await Promise.resolve(rec.didInitialized || rec.initMediaRecorder())
      dispatch(setMicOnAction(true))
    } catch (error) {
      disableMicAccess()
      log.error('Microphone access denied:', error)

      dispatch(
        showPopupService({
          type: PopupTypeEnum.notification,
          data: {
            title: NOTIFICATION_TITLE_MIC_DENIED,
            message: NOTIFICATION_MSG_MIC_DENIED
          }
        })
      )
      dispatch(changePageService(PAGE_USER_PROGRAM_SELECT))
    }
  }

  const handleToggleMicOnOff = () => {
    log.info('**** UserTopPanel: Toggle Mic [En/Dis]able ****')

    if (!isMicOn) {
      updateMicStatus()
    } else {
      disableMicAccess()
    }
  }

  const { width: pageWidth, height: pageHeight } = useWindowSize()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const isLandScapeMode = pageWidth > pageHeight

  let paddingX: number
  if (isDesktop) {
    paddingX = 50
  } else if (isTablet) {
    paddingX = isLandScapeMode ? 50 : 32
  } else {
    paddingX = isLandScapeMode ? 24 : 16
  }

  const disableUserAuthentication = useRemoteConfigFlag(
    FeatureFlagsEnum.disableUserAuthentication
  )

  return (
    <Stack
      className="UserTopPanel"
      direction="row"
      sx={styles.rootContainer(width, height, `${paddingX}px`)}>
      <LogoPlusTextComponent sx={styles.logoStyle} />
      <Box className="UserTopPanel-Spacing" sx={{ flexGrow: 1 }} />
      <Stack
        className="UserTopPanel-Menu"
        direction="row"
        spacing={2}
        sx={{ alignItems: 'center' }}>
        <Box
          className="UserTopPanel-Menu-DropDown"
          sx={styles.menuDropDown(ddHide)}>
          <DropDownComponent
            type="regular"
            width={ddWidth}
            label=""
            ddData={ddProgramListData}
            ddSelectID={currentProgram.id}
            onDDChange={(event: any) => {
              log.info('UserTopPanel::onDDChange => ', event.target.value)

              if (event.target.value === 'view_programs') {
                dispatch(changePageService(PAGE_ADMIN_PROGRAM_SELECT))
              } else if (event.target.value === 'create_program') {
                onCreateProgramSelect()
              } else {
                dispatch(
                  setCurrentProgramService(event.target.value, acctProfile?.id)
                )
                dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
              }
              dispatch(resetConvoUILogAction())
            }}
          />
        </Box>
        <MicToggleSwitchComponent
          sx={{}}
          checked={isMicOn}
          onChange={handleToggleMicOnOff}
          disabled={currentPageGlobal !== PAGE_USER_PROGRAM_SELECT}
        />
        {!disableUserAuthentication && <ProfileViewComponent />}
      </Stack>
    </Stack>
  )
}

UserTopPanelComponent.defaultProps = defaultProps
export default UserTopPanelComponent
