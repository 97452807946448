import convoThreadApi from 'datastore/apis/convo-thread-api'
import { useCallback, useState } from 'react'

export default function useResetConvoThread() {
  const [isLoading, setIsLoading] = useState(false)

  const resetConvoThread = useCallback(
    async (onSucceed: () => unknown, onFailure: () => unknown) => {
      setIsLoading(true)
      try {
        await convoThreadApi.resetConvoThread()
        if (onSucceed) {
          onSucceed()
        }
      } catch (e) {
        if (onFailure) {
          onFailure()
        }
      }
      setIsLoading(false)
    },
    []
  )

  return { resetConvoThread, isLoading }
}
