import React from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import LogoPlusTextComponent from 'components/molecules/logos/logo-plus-text'
import SignInButtonComponent from 'components/atoms/buttons/sign-in-button'
import RegisterFormComponent from 'components/molecules/popups/register-form'
import SignInFormComponent from 'components/molecules/popups/sign-in-form'

interface PropsInterface {
  firstTime: boolean
  use1PAuth: boolean
}

const AdminAuthComponent: React.FunctionComponent<PropsInterface> = ({
  firstTime,
  use1PAuth
}) => {
  return (
    <Box
      className="AdminAuth-Container"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Stack
        className="AdminAuth"
        spacing={2}
        sx={{
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <LogoPlusTextComponent />
        {(() => {
          if (firstTime) {
            return <RegisterFormComponent />
          }
          if (use1PAuth) {
            return <SignInFormComponent />
          }

          // assume firstTime === false by default
          return <SignInButtonComponent />
        })()}
      </Stack>
    </Box>
  )
}

export default AdminAuthComponent
