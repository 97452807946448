import React from 'react'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from 'react-google-login'

import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import GoogleLogo from 'assets/images/google-logo.png'
import { theme } from 'components/atoms/styles/theme'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'

import { useStoreDispatch } from 'datastore/config/hooks'
import {
  loginWithGoogleService,
  loginWithGoogleHackService
} from 'datastore/slices/auth-controller'
import log from 'loglevel'

interface PropsInterface {
  width?: string | number
  height?: string | number
  textColor?: string
  hoverColor?: string
  borderColor?: string
}

const defaultProps = {
  width: '210px',
  height: '36px',
  textColor: theme.palette.neutral_dark.dark,
  hoverColor: theme.palette.primary.light,
  borderColor: theme.palette.neutral_dark.light
}

const SignInButtonComponent: React.FunctionComponent<PropsInterface> = ({
  width = defaultProps.width,
  height = defaultProps.height,
  textColor = defaultProps.textColor,
  hoverColor = defaultProps.hoverColor,
  borderColor = defaultProps.borderColor
  // onButtonClick = defaultProps.onButtonClick
}) => {
  const dispatch = useStoreDispatch()

  const shouldUseLoginHack = false // ensure this is FALSE in code pushed to repo
  if (shouldUseLoginHack)
    return (
      <DefaultButtonComponent
        label="Google Login Hack"
        onButtonClick={async () => {
          await dispatch(loginWithGoogleHackService())
        }}
        width={width}
      />
    )

  return (
    <GoogleLogin
      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      // buttonText="Login"
      onSuccess={async (
        response: GoogleLoginResponse | GoogleLoginResponseOffline
      ) => {
        const error = (await dispatch(
          loginWithGoogleService(response as GoogleLoginResponse)
        )) as any
        if (error && error.response?.status === 400) {
          log.info(error.response?.status)
        }
      }}
      onFailure={(params: any) => {
        log.info(params)
        log.info(params?.error)
      }}
      cookiePolicy="single_host_origin"
      //  isSignedIn
      disabled={false}
      render={(renderProps) => (
        <Button
          variant="outlined"
          disableElevation
          disableRipple
          onClick={renderProps.onClick}
          sx={{
            textTransform: 'none',
            width,
            height,
            borderColor,
            '&:hover': {
              bgcolor: hoverColor,
              borderColor: hoverColor
            }
          }}>
          <Box
            component="img"
            src={GoogleLogo}
            width={24}
            sx={{
              marginRight: 1,
              imageRendering: '-webkit-optimize-contrast'
            }}
          />
          <Typography variant="h4" color={textColor}>
            Continue with Google
          </Typography>
        </Button>
      )}
    />
  )
}

SignInButtonComponent.defaultProps = defaultProps
export default SignInButtonComponent
