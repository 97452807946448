import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import {
  REACT_APP_PROD_LANDING_PAGE_URL,
  REACT_APP_PROD_WEBAPP_URL
} from 'datastore/utils/constants'
import { theme } from 'components/atoms/styles/theme'
import TextButtonComponent from 'components/atoms/buttons/text-button'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'
import { Stack } from '@mui/system'

const PROD_LINKS = {
  'LANDING PAGE': `${REACT_APP_PROD_LANDING_PAGE_URL}`,
  'WEB APPLICATION': `${REACT_APP_PROD_WEBAPP_URL}`
}

const RedirectToProdComponent: React.FC = () => {
  const [canShowModal, setCanShowModal] = useState(false)
  const [currItem, setCurrentItem] = useState(Object.keys(PROD_LINKS)[0])

  const handleRedirectButtonClick = () => {
    setCanShowModal(true)
  }

  const handleCloseModal = () => {
    setCanShowModal(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentItem((event.target as HTMLInputElement).value)
  }
  const handleOKClick = () => {
    handleCloseModal()
    if (currItem) {
      window.open(`${currItem}?internal=true`)
    }
  }

  return (
    <Box
      sx={{
        width: 150,
        textAlign: 'center'
      }}>
      <Button
        onClick={handleRedirectButtonClick}
        className="chat-type-dropdown"
        sx={{
          padding: '16px 20px',
          border: 'solid #bbb 1px',
          textAlign: 'center',
          color: '#333'
        }}>
        GO TO PROD
      </Button>
      <Dialog
        open={canShowModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth>
        <Box
          sx={{
            p: 2,
            borderRadius: '4px 4px 0px 0px',
            bgcolor: theme.palette.primary.light
          }}>
          <Typography
            id="form-title"
            variant="h3"
            color={theme.palette.neutral_dark.dark}>
            Select Your Destination
          </Typography>
        </Box>
        <Stack spacing={2} p={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <FormControl>
              <RadioGroup onChange={handleChange}>
                {Object.entries(PROD_LINKS).map(([key, value]) => (
                  <FormControlLabel
                    sx={{
                      '.MuiFormControlLabel-label': {
                        color: '#333',
                        fontSize: '13.5px'
                      }
                    }}
                    value={value}
                    control={<Radio />}
                    label={key}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: '5px'
            }}>
            <TextButtonComponent
              label="CANCEL"
              onButtonClick={handleCloseModal}
            />
            <DefaultButtonComponent
              label="REDIRECT"
              onButtonClick={handleOKClick}
            />
          </Box>
        </Stack>
      </Dialog>
    </Box>
  )
}

export default RedirectToProdComponent
