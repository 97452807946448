import React, { FunctionComponent } from 'react'
import Stack from '@mui/material/Stack'
import ListItem from '@mui/material/ListItem'

import DefaultButtonComponent from 'components/atoms/buttons/default-button'
import AudioUploaderComponent from 'components/molecules/media/audio-uploader'
import OutlinedDivComponent from 'components/atoms/utils/outlined-div'
import { theme } from 'components/atoms/styles/theme'
import log from 'loglevel'

interface PropsInterface {
  label?: string
  width?: string | number
  height?: string | number
  actionBtnHeight?: string | number
  flexGrow?: boolean
  hoverEnabler?: boolean
  isHidden?: boolean
  uploadURLs?: string[]
  onValueChange?: (
    audioBlobURL: string,
    filename: string,
    index: number
  ) => void
  onIncreaseListSize?: () => void
  onDecreaseListSize?: () => void
}

const defaultProps = {
  label: 'Custom - Audio Upload Group',
  width: 'auto',
  height: 'auto',
  actionBtnHeight: 'auto',
  flexGrow: false,
  hoverEnabler: true,
  isHidden: false,
  uploadURLs: [''],
  onValueChange: (audioBlobURL: string, filename: string, index: number) => {
    log.info(`*** AudioUploaderGrp::onValueChange ***`)
    log.info(
      `*** AudioUploaderGrp::onValueChange::audioBlobURL: ${audioBlobURL}`
    )
    log.info(`*** AudioUploaderGrp::onValueChange::index: ${index}`)
  },
  onIncreaseListSize: () => {
    log.info(`*** AudioUploaderGrp::onIncreaseListSize ***`)
  },
  onDecreaseListSize: () => {
    log.info(`*** AudioUploaderGrp::onIncreaseListSize ***`)
  }
}

const AudioUploaderGrpComponent: FunctionComponent<PropsInterface> = ({
  label = defaultProps.label,
  width = defaultProps.width,
  height = defaultProps.height,
  actionBtnHeight = defaultProps.actionBtnHeight,
  flexGrow = defaultProps.flexGrow,
  hoverEnabler = defaultProps.hoverEnabler,
  isHidden = defaultProps.isHidden,
  uploadURLs = defaultProps.uploadURLs,
  onValueChange = defaultProps.onValueChange,
  onIncreaseListSize = defaultProps.onIncreaseListSize,
  onDecreaseListSize = defaultProps.onDecreaseListSize
}) => {
  log.info(`**** AudioUploaderGrp::uploadURLs: ${JSON.stringify(uploadURLs)}`)

  return (
    <OutlinedDivComponent
      label={label}
      flexGrow={flexGrow}
      hoverEnabled={hoverEnabler}
      isHidden={isHidden}>
      <Stack spacing={1} width={width} height={flexGrow ? '100%' : height}>
        <Stack
          className="AudioUploadList-Container"
          spacing={1}
          sx={{ overflowY: 'scroll' }}>
          {uploadURLs.map((audioURL, index) => (
            <ListItem
              value={`url-${index}`}
              disableGutters
              sx={{
                display: 'contents'
              }}>
              <AudioUploaderComponent
                uploadURL={audioURL}
                // onValueChange={onStateAudioChange}
                onValueChange={(audioBlobURL: string, filename: string) => {
                  log.info(`*** AudioUploaderGrp::Inner::onValueChange ***`)
                  log.info(
                    `*** AudioUploaderGrp::Inner::onValueChange::audioBlobURL: ${audioBlobURL} ***`
                  )
                  log.info(
                    `*** AudioUploaderGrp::Inner::onValueChange::index: ${index} ***`
                  )

                  onValueChange(audioBlobURL, filename, index)
                }}
              />
            </ListItem>
          ))}
        </Stack>
        <Stack
          className="AudioUploadList-ModButtons"
          direction="row"
          spacing={2}
          sx={{ display: 'flex' }}>
          <DefaultButtonComponent
            label="Del Phrase"
            width="100%"
            height={actionBtnHeight}
            bgColor={theme.palette.error.main}
            disabled={uploadURLs.length <= 1}
            onButtonClick={onDecreaseListSize}
          />
          <DefaultButtonComponent
            label="Add Phrase"
            width="100%"
            height={actionBtnHeight}
            onButtonClick={onIncreaseListSize}
          />
        </Stack>
      </Stack>
    </OutlinedDivComponent>
  )
}

AudioUploaderGrpComponent.defaultProps = defaultProps
export default AudioUploaderGrpComponent
