import React, { FunctionComponent } from 'react'
import { DropDownInterface } from 'types'

import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { theme } from 'components/atoms/styles/theme'
import OutlinedDivComponent from 'components/atoms/utils/outlined-div'
import log from 'loglevel'

type DDChangeFunc = (event: any) => void

interface PropsInterface {
  type: 'regular' | 'auto_complete'
  label?: string
  width?: string | number
  height?: string | number
  ddData?: DropDownInterface[]
  ddSelectID?: string
  onDDChange?: DDChangeFunc
  isHidden?: boolean
}

const defaultProps = {
  label: 'Input - DropDown',
  width: 'auto',
  height: '40px',
  ddData: [
    { ddID: '1', ddLabel: 'Oliver Hansen' },
    { ddID: '2', ddLabel: 'Van Henry' },
    { ddID: '3', ddLabel: 'April Tucker' },
    { ddID: '4', ddLabel: 'Ralph Hubbard' },
    { ddID: '5', ddLabel: 'Omar Alexander' },
    { ddID: '6', ddLabel: 'Carlos Abbott' },
    { ddID: '7', ddLabel: 'Miriam Wagner' },
    { ddID: '8', ddLabel: 'Bradley Wilkerson' },
    { ddID: '9', ddLabel: 'Virginia Andrews' },
    { ddID: '10', ddLabel: 'Kelly Snyder' }
  ],
  ddSelectID: '',
  onDDChange: (event: any) => {
    log.info(`*** onDDChange: ${event} ***`)
  },
  isHidden: false
}

const DropDownComponent: FunctionComponent<PropsInterface> = ({
  type,
  label = defaultProps.label,
  width = defaultProps.width,
  height = defaultProps.height,
  ddData = defaultProps.ddData,
  ddSelectID = defaultProps.ddSelectID,
  onDDChange = defaultProps.onDDChange,
  isHidden = defaultProps.isHidden
}) => {
  const [ddValue, setDropDownValue] = React.useState('')

  React.useEffect(() => {
    if (!ddSelectID) {
      setDropDownValue('')
    } else {
      const selObj = ddData.filter((obj) => {
        return obj.ddID === ddSelectID
      })

      if (selObj) {
        setDropDownValue(ddSelectID)
      }
    }
  })

  if (type === 'regular')
    return (
      <OutlinedDivComponent
        label={label}
        width={width}
        height={height}
        padding={0}
        isHidden={isHidden}>
        <Select
          // variant="outlined"
          // fullWidth
          // select
          // defaultValue=''
          value={ddValue}
          onChange={onDDChange}
          label=""
          size="small"
          /* from tests SelectDisplayProps overrides inputProps */
          inputProps={{
            sx: {
              /* border: '2px solid red' */
            }
          }}
          SelectDisplayProps={{
            style: {
              /* border: '2px solid blue' */
              // border: '2px solid blue',
              // boxSizing: 'border-box'
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }
          }}
          /* Menu API - https://mui.com/material-ui/api/menu/ */
          MenuProps={{
            /* sx: { bgcolor: 'blue' }, */
            autoFocus: false,
            elevation: 1,
            /* anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }, */
            marginThreshold: 0,
            PaperProps: { sx: { maxHeight: 248 } },
            /* MenuList API - https://mui.com/material-ui/api/menu-list/ */
            MenuListProps: {
              sx: {
                /* bgcolor: 'cyan', */
              }
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              /* '& > fieldset': { borderColor: 'red' } */
            },
            /* bgcolor: 'yellow', */
            display: 'flex'
            // flexDirection: 'column',
            // justifyContent: 'center'
          }}>
          {ddData.map(({ ddID, ddLabel }) => (
            <MenuItem
              key={ddID}
              value={ddID}
              disableRipple
              sx={
                {
                  /* bgcolor: 'red' */
                }
              }>
              <Typography variant="h4" color={theme.palette.neutral_dark.dark}>
                {ddLabel}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </OutlinedDivComponent>
    )

  if (type === 'auto_complete')
    return (
      <OutlinedDivComponent
        label={label}
        width={width}
        height={height}
        padding="0"
        isHidden={isHidden}>
        <Autocomplete
          size="small"
          options={ddData}
          getOptionLabel={(option) => option.ddLabel}
          noOptionsText={
            <Typography variant="h4" color={theme.palette.neutral_dark.light}>
              No options
            </Typography>
          }
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: theme.typography.h4.fontSize,
                  width,
                  height
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: theme.typography.h4.fontSize }
              }}
              sx={{
                fontSize: theme.typography.h4.fontSize,
                '& .MuiOutlinedInput-root': {
                  '& > fieldset': {
                    borderColor: theme.palette.neutral_light.light
                  }
                }
              }}
              label=""
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Typography
                  variant="h4"
                  color={theme.palette.neutral_dark.dark}>
                  {option}
                </Typography>
              </li>
            )
          }}
        />
      </OutlinedDivComponent>
    )

  return <Button variant="contained" />
}

DropDownComponent.defaultProps = defaultProps
export default DropDownComponent
