import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import JSONDigger from 'json-digger'
import { v4 as uuidv4 } from 'uuid'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import {
  changePageService,
  resetAppStates,
  showPopupService
} from 'datastore/slices/app-controller'
import {
  clearArenaPanelAction,
  setOptionArenaPanelAction,
  setPreviewArenaPanelAction,
  setStateArenaPanelAction
} from 'datastore/slices/arena-panel-controller'
import {
  ARENA_PANEL_NONE,
  ARENA_PANEL_OPTION,
  ARENA_PANEL_PREVIEW,
  ARENA_PANEL_STATE,
  PAGE_ADMIN_BLOCK_DESIGN,
  PAGE_ADMIN_BLOCK_PREVIEW,
  PAGE_ADMIN_BLOCK_SETTINGS,
  PAGE_ADMIN_PROGRAM_SELECT,
  PAGE_ADMIN_AUTH,
  PAGE_USER_AUTH,
  PAGE_USER_PROGRAM_SELECT,
  TITLE_P_BLOCK_NOTIFICATION,
  MESSAGE_P_BLOCK_NOTIFICATION,
  AMAZETHU_V2_LIVE,
  IS_STAG_ENV,
  IS_PROD_ENV
} from 'datastore/utils/constants'
import authAPI from 'datastore/apis/auth-api'
import {
  getProgramListService,
  updateProgramNameService,
  updateProgramDataService,
  setCurrentProgramService,
  updateProgramSettingsService
} from 'datastore/slices/program-controller'
import {
  clearSelectedNodeAction,
  setBotTreeDSAction,
  setSelectedNodeAction
} from 'datastore/slices/bot-tree-controller'
import {
  publishBlockService,
  resetBlockControllerAction,
  setCurrentBlockService,
  updateBlockDataService,
  updateBlockNameService
} from 'datastore/slices/block-controller'
import {
  uploadAudioPhraseService,
  uploadBotSpeechService,
  uploadProgramImageService
} from 'datastore/slices/upload-controller'
import { logoutService } from 'datastore/slices/auth-controller'
import { isDesktop } from 'react-device-detect'
import { Console } from 'logger'
import log from 'loglevel'
import { Alert } from 'alert'

import AdminAppComponent from 'app/admin-app'
import UserAppComponent from 'app/user-app'

import StagingAuthModalComponent from 'components/molecules/popups/staging-auth-modal'
import DesktopNotSupportedComponent from 'components/molecules/popups/desktop-not-supported'
import { useFirstMountState } from 'react-use'
import { useGetUser } from 'hooks/use-authentication'
import {
  AnalyticsProvider,
  useFirebaseApp,
  useInitPerformance
} from 'reactfire'
import { getAnalytics } from 'firebase/analytics'
import { PopupTypeEnum } from 'types'
import { resetV2ProgramStates } from 'datastore/slices/v2-program-controller'

const AppComponent: React.FC = () => {
  const firebaseApp = useFirebaseApp()

  const user = useGetUser()

  // Dispatches actions to reset redux states when the component mounts
  useEffect(() => {
    resetAppStates()
    resetV2ProgramStates()
  }, [])

  // Init firebase performance monitoring
  useInitPerformance(async (app) => {
    const { getPerformance } = await import('firebase/performance')
    return getPerformance(app)
  })

  const dispatch = useStoreDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const isFirsMount = useFirstMountState()
  const [shouldShowStagingAuthModal, setShouldShowStagingAuthModal] =
    useState<boolean>(false)

  useEffect(() => {
    if (IS_STAG_ENV) {
      setShouldShowStagingAuthModal(true)
    }
  }, [user])

  const [isModalClosed, setIsModalClosed] = useState<boolean>(false)

  const handleStagingCodeSubmit = (enteredCode: string) => {
    if (enteredCode === process.env.REACT_APP_STAGING_PASSCODE) {
      localStorage.setItem('canSeeStaging', btoa(`${enteredCode}`))
      setShouldShowStagingAuthModal(false)
      setIsModalClosed(true)
    } else {
      log.info("You don't have access to this page")
    }
  }

  const [isStagingAuthed, setIsStagingAuthed] = useState<boolean>(false)

  useEffect(() => {
    const storedValue = localStorage.getItem('canSeeStaging')
    setIsStagingAuthed(
      atob(`${storedValue}`) === process.env.REACT_APP_STAGING_PASSCODE
    )
  }, [isModalClosed])

  const currentPageLocal =
    location.pathname.substring(1) !== ''
      ? location.pathname.substring(1)
      : PAGE_USER_PROGRAM_SELECT

  const isLocalPageAdminRoute = (): boolean => {
    return currentPageLocal.startsWith(PAGE_ADMIN_AUTH)
  }
  const currentPageGlobal = useStoreSelector(
    (storeState) => storeState.appController.currPage
  )

  const currentPopup = useStoreSelector(
    (storeState) => storeState.appController.currPopup
  )

  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )

  const isLoggedIn = useStoreSelector(
    (storeState) => storeState.authController.loggedIn
  )
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )
  const tempState = useState<boolean>(false)
  const setAxiosInitialized = tempState[1]

  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )
  const currentBlockSettingsData = useStoreSelector(
    (storeState) => storeState.blockController.currentBlockSettingsData
  )
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const currentProgramSettingsData = useStoreSelector(
    (storeState) => storeState.programController.currentProgramSettingsData
  )

  const [shouldShowPopup, setShouldShowPopup] = React.useState(false)
  const [popupType, setPopupType] = React.useState(PopupTypeEnum.none)
  const [shouldShowLoading, setShouldShowLoading] = React.useState(false)
  const [shouldShowAddNode, setShouldShowAddNode] = React.useState(false)
  const [shouldShowDelNode, setShouldShowDelNode] = React.useState(false)

  const currBotTreeDS = useStoreSelector(
    (storeState) => storeState.botTreeController.botTree
  )
  const dpCurrBotTreeDS = JSON.parse(JSON.stringify(currBotTreeDS))
  const dsDigger = new JSONDigger(dpCurrBotTreeDS, 'id', 'children')

  const [arenaPanelView, setArenaPanelView] = React.useState(ARENA_PANEL_NONE)
  const stateTypeIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateTypeIndex
  )
  const stateRefID = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateRefID
  )
  const optionRefID = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionRefID
  )
  const currOptionName = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionName
  )
  const currOptionDesc = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionDesc
  )
  const currSelNode = useStoreSelector(
    (storeState) => storeState.botTreeController.selectedNode
  )
  const optionAudioList = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionAudioList
  )

  const setRefNodeHighlight = (refNodeID: string) => {
    log.info(' ############################## ')
    log.info(`*** App::setRefNodeHighlight ***`)

    const refNode = document.getElementById(refNodeID)

    log.info(`BEFORE refNode: `, refNode)
    refNode?.setAttribute('class', 'oc-node referenced')
    log.info(`AFTER refNode: `, refNode)
  }

  const clearRefNodesHighlight = () => {
    log.info(' ############################## ')
    log.info(`*** App::clearRefNodesHighlight ***`)

    if (optionRefID) {
      const refNode = document.getElementById(optionRefID)
      refNode?.setAttribute('class', 'oc-node')
    }

    if (stateRefID) {
      const refNode = document.getElementById(stateRefID)
      refNode?.setAttribute('class', 'oc-node')
    }
  }

  const clearSelectedNode = () => {
    log.info(' ############################## ')
    log.info(`*** App::clearSelectedNode ***`)
    log.info('currSelNode: ', currSelNode)
    log.info('stateRefID: ', stateRefID)
    log.info('optionRefID: ', optionRefID)

    clearRefNodesHighlight()
    dispatch(clearSelectedNodeAction())
  }

  React.useEffect(() => {
    log.info('&&&&&&&&&&& currentPageLocal: ', currentPageLocal)
    log.info('&&&&&&&&&&& currentPageGlobal: ', currentPageGlobal)

    if (currentPageLocal !== currentPageGlobal) {
      if (
        currentPageLocal.startsWith(PAGE_ADMIN_AUTH) &&
        !currentPageGlobal.startsWith(PAGE_ADMIN_AUTH)
      ) {
        dispatch(logoutService())
        dispatch(changePageService(PAGE_ADMIN_AUTH))
        navigate(`/${PAGE_ADMIN_AUTH}${location.search}`)
      } else if (
        !currentPageLocal.startsWith(PAGE_ADMIN_AUTH) &&
        currentPageGlobal.startsWith(PAGE_ADMIN_AUTH)
      ) {
        dispatch(logoutService())
        dispatch(changePageService(PAGE_USER_PROGRAM_SELECT))
        document.title = `${PAGE_USER_PROGRAM_SELECT} | AMAZETHU${
          IS_PROD_ENV ? '' : '-STAG'
        }`
        navigate(`/${PAGE_USER_PROGRAM_SELECT}${location.search}`)
      } else {
        document.title = `${currentPageGlobal} | AMAZETHU${
          IS_PROD_ENV ? '' : '-STAG'
        }`
        navigate(`/${currentPageGlobal}${location.search}`)
      }
    } else {
      document.title = `${currentPageGlobal} | AMAZETHU${
        IS_PROD_ENV ? '' : '-STAG'
      }`
      navigate(`/${currentPageGlobal}${location.search}`)
    }
  }, [currentPageLocal, currentPageGlobal])

  React.useEffect(() => {
    log.info('&&&&&&&&&&& currentPopup: ', currentPopup)
    log.info('&&&&&&&&&&& shouldShowPopup: ', shouldShowPopup)

    if (currentPopup.type === undefined) {
      dispatch(showPopupService({ type: PopupTypeEnum.none }))
      return
    }
    setShouldShowPopup(currentPopup.type !== PopupTypeEnum.none)
    setPopupType(currentPopup.type)
  }, [currentPopup])

  React.useEffect(() => {
    log.info('*** App::re-render due to change to isLoggedIn')
    if (isLoggedIn) {
      authAPI.setDefaultAPIHeaders()
      setAxiosInitialized(true)
      dispatch(getProgramListService({ owner: true, adminID: acctProfile?.id }))
    }

    log.info('###### currentPageGlobal: ', currentPageGlobal)
    log.info('###### currentPageLocal: ', currentPageLocal)
    log.info('###### isLoggedIn: ', isLoggedIn)

    if (isLocalPageAdminRoute()) {
      if (isLoggedIn && currentPageGlobal !== PAGE_ADMIN_AUTH) {
        dispatch(logoutService())
        dispatch(changePageService(PAGE_ADMIN_AUTH))
      } else if (isLoggedIn && currentPageGlobal === PAGE_ADMIN_AUTH) {
        dispatch(changePageService(PAGE_ADMIN_PROGRAM_SELECT))
      } else {
        dispatch(changePageService(PAGE_ADMIN_AUTH))
      }
    }
  }, [isLoggedIn])

  React.useEffect(() => {
    // setArenaPanelView(ARENA_PANEL_NONE)
    log.info('*** App::re-render due to change to currentProgram')
    log.info('*** App::currentProgram.id: ', currentProgram.id)
    dispatch(resetBlockControllerAction())
  }, [currentProgram])

  const handleOnNodeUnselect = () => {
    log.info(`*** App::handleOnNodeUnselect ***`)
    log.info(
      'App::handleOnNodeUnselect ds-digger: ',
      JSON.stringify(dsDigger.ds)
    )

    clearSelectedNode()
    setArenaPanelView(ARENA_PANEL_NONE)
    dispatch(clearArenaPanelAction())
    setShouldShowAddNode(false)
    setShouldShowDelNode(false)
  }

  React.useEffect(() => {
    log.info('*** App::re-render due to change to currentBlock')
    log.info('*** App::currentBlock.id: ', currentBlock.id)

    if (isLocalPageAdminRoute()) {
      if (currentPageLocal === PAGE_ADMIN_BLOCK_DESIGN) {
        handleOnNodeUnselect()

        if (currentBlock === undefined || currentBlock.id === undefined) {
          setArenaPanelView(ARENA_PANEL_NONE)
        }
      } else if (currentPageLocal === PAGE_ADMIN_BLOCK_PREVIEW) {
        if (!currentBlock.draftBotJSON) {
          // show notification explaining user needs to save block
          // ... before they can go to the preview page
          dispatch(
            showPopupService({
              type: PopupTypeEnum.notification,
              data: {
                title: TITLE_P_BLOCK_NOTIFICATION,
                message: MESSAGE_P_BLOCK_NOTIFICATION
              }
            })
          )

          setArenaPanelView(ARENA_PANEL_NONE)
          dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
        } else {
          // reset preview-arena-panel
          dispatch(setPreviewArenaPanelAction({ previewTypeIndex: 0 }))
        }
      } else if (currentPageLocal === PAGE_ADMIN_BLOCK_SETTINGS) {
        // do nothing
      }
    }
  }, [currentBlock])

  /*
   * ***********************************
   * Programs Page Events Handlers
   * ***********************************
   */

  const handleShowCreateProgramForm = () => {
    dispatch(showPopupService({ type: PopupTypeEnum.createProgram }))
  }

  const handleShowCreateBlockForm = () => {
    dispatch(showPopupService({ type: PopupTypeEnum.createBlock }))
  }

  const handleShowDeleteBlockForm = () => {
    if (currentBlock.id) {
      dispatch(showPopupService({ type: PopupTypeEnum.deleteBlock }))
    }
  }

  /*
   * ***********************************
   * Block Design Page Events Handlers
   * ***********************************
   */

  const getNodesList = async (treeDS: any, cond: any) => {
    log.info('xxxxxxxxxxx treeDS: ', treeDS)
    log.info('xxxxxxxxxxx cond: ', cond)

    try {
      const list = await treeDS.findNodes(cond)
      log.info('xxxxxxxxxxx list: ', list)

      return list.map((node: any) => ({
        ddID: node.id,
        ddLabel: node.name
      }))
    } catch (error) {
      return undefined
    }
  }

  const handleOnNodeSelect = async (nodeData: any) => {
    log.info(`*** App::handleOnNodeSelect ***`)
    log.info(
      `*** App::handleOnNodeSelect::nodeData: `,
      JSON.stringify(nodeData)
    )
    log.info('App::handleOnNodeSelect ds-digger: ', JSON.stringify(dsDigger.ds))

    // ToDo: Investigate bug with reference node not highlighted when
    // ... it was the node previously immediately selected,
    // ... all other cases seem to be working fine
    clearRefNodesHighlight()
    if ('refID' in nodeData && nodeData.refID)
      setRefNodeHighlight(nodeData.refID)

    dispatch(
      setSelectedNodeAction({
        selectedNode: JSON.parse(JSON.stringify(nodeData))
      })
    )

    setShouldShowAddNode(false)
    setShouldShowDelNode(false)
    if ('stateType' in nodeData && nodeData.stateType === 'input') {
      setShouldShowAddNode(true)
    }
    if (
      'nodeType' in nodeData &&
      nodeData.nodeType.includes('option') &&
      nodeData.deletable
    ) {
      setShouldShowDelNode(true)
    }

    // update state-arena-panel
    if (nodeData.nodeType.includes(ARENA_PANEL_STATE)) {
      setArenaPanelView(ARENA_PANEL_STATE)

      // update new-state radio-btn in arena-panel
      if (
        nodeData.nodeType.includes('new') ||
        nodeData.nodeType.includes('ref')
      ) {
        if (nodeData.nodeType.includes('new'))
          dispatch(setStateArenaPanelAction({ newStateIndex: 0 }))
        if (nodeData.nodeType.includes('ref'))
          dispatch(setStateArenaPanelAction({ newStateIndex: 1 }))
      } else {
        dispatch(setStateArenaPanelAction({ newStateIndex: -1 }))
      }

      // update state-type radio-btn in arena panel
      if ('stateType' in nodeData) {
        if (nodeData.stateType.includes('input'))
          dispatch(setStateArenaPanelAction({ stateTypeIndex: 0 }))
        if (nodeData.stateType.includes('transit'))
          dispatch(setStateArenaPanelAction({ stateTypeIndex: 1 }))
        if (nodeData.stateType.includes('end'))
          dispatch(setStateArenaPanelAction({ stateTypeIndex: 2 }))
      } else {
        dispatch(setStateArenaPanelAction({ stateTypeIndex: -1 }))
      }

      // update state-name text-input in arena panel
      if ('name' in nodeData) {
        dispatch(setStateArenaPanelAction({ stateName: nodeData.name }))
      } else {
        dispatch(setStateArenaPanelAction({ stateName: '' }))
      }

      // update state-desc text-input in arena panel
      if ('desc' in nodeData) {
        dispatch(setStateArenaPanelAction({ stateDesc: nodeData.desc }))
      } else {
        dispatch(setStateArenaPanelAction({ stateDesc: '' }))
      }

      // update state-audio-list audio-uploader in arena panel
      if ('audioURLs' in nodeData) {
        dispatch(
          setStateArenaPanelAction({ stateAudioList: nodeData.audioURLs })
        )
      } else {
        dispatch(setStateArenaPanelAction({ stateAudioList: [''] }))
      }

      // update state-ref drop-down in arena panel
      if ('refID' in nodeData) {
        dispatch(setStateArenaPanelAction({ stateRefID: nodeData.refID }))
      } else {
        dispatch(setStateArenaPanelAction({ stateRefID: '' }))
      }
    }
    // update option-arena-panel
    else if (nodeData.nodeType.includes(ARENA_PANEL_OPTION)) {
      if (nodeData.nodeType === 'option_sys') {
        setArenaPanelView(ARENA_PANEL_NONE)
      } else {
        setArenaPanelView(ARENA_PANEL_OPTION)

        // update new-option radio-btn in arena-panel
        if (
          nodeData.nodeType.includes('new') ||
          nodeData.nodeType.includes('ref')
        ) {
          if (nodeData.nodeType.includes('new'))
            dispatch(setOptionArenaPanelAction({ newOptionIndex: 0 }))
          if (nodeData.nodeType.includes('ref'))
            dispatch(setOptionArenaPanelAction({ newOptionIndex: 1 }))
        } else {
          dispatch(setOptionArenaPanelAction({ newOptionIndex: -1 }))
        }

        // update option-name text-input in arena panel
        if ('name' in nodeData) {
          dispatch(setOptionArenaPanelAction({ optionName: nodeData.name }))
        } else {
          dispatch(setOptionArenaPanelAction({ optionName: '' }))
        }

        // update option-desc text-input in arena panel
        if ('desc' in nodeData) {
          dispatch(setOptionArenaPanelAction({ optionDesc: nodeData.desc }))
        } else {
          dispatch(setOptionArenaPanelAction({ optionDesc: '' }))
        }

        // update option-audio-list audio-uploader-group in arena panel
        if ('audioURLs' in nodeData) {
          dispatch(
            setOptionArenaPanelAction({ optionAudioList: nodeData.audioURLs })
          )
        } else {
          dispatch(setOptionArenaPanelAction({ optionAudioList: [''] }))
        }

        // update option-ref drop-down in arena panel
        if ('refID' in nodeData) {
          dispatch(setOptionArenaPanelAction({ optionRefID: nodeData.refID }))
        } else {
          dispatch(setOptionArenaPanelAction({ optionRefID: '' }))
        }
      }
    } else {
      setArenaPanelView(ARENA_PANEL_NONE)
    }
  }

  const handleOnAddNode = async () => {
    log.info(`*** App::handleOnAddNode ***`)
    if (currSelNode) {
      log.info(`currSelNode: `, currSelNode)

      const foundNode = await dsDigger.findNodeById(currSelNode.id)
      log.info('@@@@@@@@@@@ foundNode: ', foundNode)
      let params = JSON.parse(JSON.stringify(foundNode))

      if ('nodeType' in foundNode && 'stateType' in foundNode) {
        if (
          foundNode.nodeType === 'state_new' &&
          foundNode.stateType === 'input'
        ) {
          const newNode = [
            {
              id: uuidv4(),
              objectID: uuidv4(),
              nodeType: 'option_new',
              name: 'AddedOption',
              audioURLs: [''],
              deletable: true,
              isComplete: false,
              children: [
                {
                  id: uuidv4(),
                  objectID: uuidv4(),
                  nodeType: 'state_new',
                  name: 'New State',
                  audioURLs: [''],
                  isComplete: false
                }
              ]
            }
          ]

          await dsDigger.addChildren(
            currSelNode.id,
            JSON.parse(JSON.stringify(newNode))
          )

          params = JSON.parse(JSON.stringify(dsDigger.ds))
          const statesList = await getNodesList(dsDigger, {
            nodeType: 'state_new'
          })
          const optionsList = await getNodesList(dsDigger, {
            nodeType: 'option_new'
          })

          dispatch(
            setBotTreeDSAction({
              botTree: params,
              statesList,
              optionsList
            })
          )
        }
      }
    }
  }

  const handleOnDelNode = async () => {
    log.info(`*** App::handleOnDelNode ***`)

    if (currSelNode) {
      log.info(`currSelNode: `, currSelNode)

      const foundNode = await dsDigger.findNodeById(currSelNode.id)
      log.info('@@@@@@@@@@@ foundNode: ', foundNode)
      let params = JSON.parse(JSON.stringify(foundNode))

      if ('nodeType' in foundNode) {
        if (foundNode.nodeType.includes('option')) {
          if ('deletable' in foundNode && !foundNode.deletable) {
            return
          }

          await dsDigger.removeNode(currSelNode.id)

          params = JSON.parse(JSON.stringify(dsDigger.ds))
          const statesList = await getNodesList(dsDigger, {
            nodeType: 'state_new'
          })
          const optionsList = await getNodesList(dsDigger, {
            nodeType: 'option_new'
          })

          dispatch(
            setBotTreeDSAction({
              botTree: params,
              statesList,
              optionsList
            })
          )
          clearSelectedNode()
        }
      }

      handleOnNodeUnselect()
    }

    setShouldShowDelNode(false)
  }

  /*
   * ***********************************
   * ***********************************
   */

  const updateSelectedNode = async (nodeID: string, updateObj: any) => {
    log.info('**** App::updateSelectedNode ****')
    log.info('nodeID: ', nodeID)
    log.info('updateObj: ', updateObj)

    log.info('dsDigger: ', dsDigger)
    log.info('dsDigger.ds: ', dsDigger.ds)

    // let params = { ...dsDigger.ds }
    const foundNode = await dsDigger.findNodeById(nodeID)
    log.info('@@@@@@@@@@@ foundNode: ', foundNode)
    let params = JSON.parse(JSON.stringify(foundNode))

    if ('name' in updateObj) params.name = updateObj.name
    if ('desc' in updateObj) params.desc = updateObj.desc
    if ('nodeType' in updateObj) params.nodeType = updateObj.nodeType
    if ('objectID' in updateObj) params.objectID = updateObj.objectID
    if ('stateType' in updateObj) params.stateType = updateObj.stateType
    if ('audioURLs' in updateObj) params.audioURLs = updateObj.audioURLs
    if ('refID' in updateObj) params.refID = updateObj.refID

    log.info('BEFORE dsDigger.ds: ', dsDigger.ds)

    // node validation to check if required info has been provided for node
    params.isComplete = false
    if (params.nodeType === 'state_new') {
      if (
        params.name &&
        params.stateType &&
        !params.audioURLs.includes('') &&
        !params.audioURLs.includes(null) &&
        !params.audioURLs.includes(undefined) &&
        params.audioURLs.length === 1
      )
        params.isComplete = true
    }
    if (params.nodeType === 'state_ref') {
      if (params.name && params.refID) params.isComplete = true
    }
    if (params.nodeType === 'option_new') {
      if (
        params.name &&
        !params.audioURLs.includes('') &&
        !params.audioURLs.includes(null) &&
        !params.audioURLs.includes(undefined) &&
        params.audioURLs.length >= 1
      )
        params.isComplete = true
    }
    if (params.nodeType === 'option_ref') {
      if (params.name && params.refID) params.isComplete = true
    }

    if ('nodeType' in updateObj || 'stateType' in updateObj) {
      params.children = undefined
    }

    await dsDigger.updateNode(params)
    log.info('AFTER dsDigger.ds: ', dsDigger.ds)

    // add more nodes based on the node type and state type
    if ('stateType' in updateObj) {
      if (params.nodeType === 'state_new') {
        if (params.stateType === 'input' || params.stateType === 'transit') {
          let newNode: any[] = []
          if (params.stateType === 'input') {
            newNode = [
              {
                id: uuidv4(),
                objectID: 'system-option-003',
                nodeType: 'option_sys',
                name: 'NoOption',
                desc: '',
                deletable: false,
                isComplete: true,
                children: [
                  {
                    id: uuidv4(),
                    objectID: uuidv4(),
                    nodeType: 'state_new',
                    name: 'New State',
                    desc: '',
                    audioURLs: ['']
                  }
                ]
              },
              {
                id: uuidv4(),
                objectID: uuidv4(),
                nodeType: 'option_new',
                name: 'DefaultOption',
                desc: '',
                audioURLs: [''],
                deletable: false,
                isComplete: false,
                children: [
                  {
                    id: uuidv4(),
                    objectID: uuidv4(),
                    nodeType: 'state_new',
                    name: 'New State',
                    desc: '',
                    audioURLs: ['']
                  }
                ]
              }
            ]
          } else {
            newNode = [
              {
                id: uuidv4(),
                objectID: uuidv4(),
                nodeType: 'state_new',
                name: 'New State',
                desc: '',
                audioURLs: [''],
                isComplete: false
              }
            ]
          }

          await dsDigger.addChildren(
            nodeID,
            JSON.parse(JSON.stringify(newNode))
          )
        }
      }
    }

    // add more nodes based on the node type and state type
    if ('nodeType' in updateObj) {
      if (
        params.nodeType === 'option_new' ||
        params.nodeType === 'option_ref'
      ) {
        const newNode: any[] = [
          {
            id: uuidv4(),
            objectID: uuidv4(),
            nodeType: 'state_new',
            name: 'New State',
            desc: '',
            audioURLs: [''],
            isComplete: false
          }
        ]

        await dsDigger.addChildren(nodeID, JSON.parse(JSON.stringify(newNode)))
      }
    }

    log.info('qqqqqqqqqqqqqqq')
    log.info('updateObj: ', updateObj)
    log.info('params: ', params)

    params = { ...dsDigger.ds }
    const statesList = await getNodesList(dsDigger, { nodeType: 'state_new' })
    const optionsList = await getNodesList(dsDigger, { nodeType: 'option_new' })

    dispatch(
      setBotTreeDSAction({
        botTree: params,
        statesList,
        optionsList
      })
    )
  }

  const handleOnNewStateChange = async (event: any) => {
    log.info(`*** App::handleOnNewStateChange: ${event.target.value} ***`)
    const newValue = event.target.value
    log.info('!!!!!!!!!!!!!!!!!!! stateTypeIndex: ', stateTypeIndex)

    dispatch(
      setStateArenaPanelAction({
        newStateIndex: ['Yes', 'No'].indexOf(newValue)
      })
    )
    if (newValue === 'Yes') {
      await updateSelectedNode(currSelNode.id, {
        nodeType: 'state_new',
        stateType:
          stateTypeIndex === undefined
            ? undefined
            : ['input', 'transit', 'end'][stateTypeIndex],
        objectID: uuidv4()
      })
    }
    if (newValue === 'No') {
      await updateSelectedNode(currSelNode.id, {
        nodeType: 'state_ref',
        stateType: undefined,
        objectID: uuidv4()
      })
    }

    clearRefNodesHighlight()
    dispatch(setStateArenaPanelAction({ stateRefID: '' }))
  }

  const handleOnStateTypeChange = async (event: any) => {
    log.info(`*** App::handleOnStateTypeChange: ${event.target.value} ***`)

    const newValue = event.target.value
    log.info(`*** AdminArenaPanel::onStateTypeChange: ${newValue} ***`)
    // ToDo: update bot-tree [frontend version of bot-logic]

    dispatch(
      setStateArenaPanelAction({
        stateTypeIndex: ['Input', 'Transit', 'End'].indexOf(newValue)
      })
    )

    setShouldShowAddNode(false)
    if (newValue === 'Input') {
      await updateSelectedNode(currSelNode.id, {
        // nodeType: ['state_new', 'state_ref'][newStateIndex],
        stateType: 'input'
      })

      setShouldShowAddNode(true)
    }
    if (newValue === 'Transit')
      await updateSelectedNode(currSelNode.id, {
        // nodeType: ['state_new', 'state_ref'][newStateIndex],
        stateType: 'transit'
      })
    if (newValue === 'End')
      await updateSelectedNode(currSelNode.id, {
        // nodeType: ['state_new', 'state_ref'][newStateIndex],
        stateType: 'end'
      })
  }

  const handleOnStateNameChange = async (event: any) => {
    log.info(`*** App::handleOnStateNameChange: ${event.target.value} ***`)

    dispatch(
      setStateArenaPanelAction({
        stateName: event.target.value
      })
    )
    await updateSelectedNode(currSelNode.id, {
      name: event.target.value
    })
  }

  const handleOnStateDescChange = async (event: any) => {
    log.info(`*** App::handleOnStateDescChange: ${event.target.value} ***`)

    dispatch(
      setStateArenaPanelAction({
        stateDesc: event.target.value
      })
    )
    await updateSelectedNode(currSelNode.id, {
      desc: event.target.value
    })
  }

  const handleOnStateAudioChange = async (
    audioBlobURL: string,
    filename: string
  ) => {
    log.info(`*** App::handleOnStateAudioChange ***`)
    log.info(
      `*** App::handleOnStateAudioChange::audioBlobURL: ${audioBlobURL} filename: ${filename}`
    )

    const filenameWithoutExt = filename.replace(/\.[^/.]+$/, '')

    if (audioBlobURL) {
      log.info(' DISPATCHING UPLOAD BOT_SPEECH ')

      const response = await dispatch(
        uploadBotSpeechService(
          currentBlock.id,
          currSelNode.objectID,
          audioBlobURL
        )
      )
      log.info(
        `*** App::handleOnStateAudioChange::uploadPath: ${response.data}`
      )
      dispatch(
        setStateArenaPanelAction({
          stateAudioList: [response.data],
          stateName: filenameWithoutExt
        })
      )
      await updateSelectedNode(currSelNode.id, {
        audioURLs: [response.data],
        name: filenameWithoutExt
      })
    } else {
      log.info(' DISPATCHING RESET STATE UPLOADED_AUDIO_PATH ')

      dispatch(
        setStateArenaPanelAction({
          stateAudioList: [''],
          stateName: ''
        })
      )
      await updateSelectedNode(currSelNode.id, {
        audioURLs: [''],
        name: ''
      })
    }
  }

  const handleOnStateRefChange = async (event: any) => {
    log.info(`*** App::handleOnStateRefChange: ${event.target.value} ***`)
    const newRefID = event.target.value

    clearRefNodesHighlight()
    setRefNodeHighlight(newRefID)

    const foundNode = await dsDigger.findNodeById(newRefID)
    await updateSelectedNode(currSelNode.id, {
      refID: newRefID,
      objectID: foundNode.objectID,
      name: foundNode.name
    })
    dispatch(
      setStateArenaPanelAction({
        stateRefID: newRefID,
        stateName: foundNode.name
      })
    )
  }

  const handleOnNewOptionChange = async (event: any) => {
    log.info(`*** App::handleOnNewOptionChange: ${event.target.value} ***`)
    const newValue = event.target.value

    dispatch(
      setOptionArenaPanelAction({
        newOptionIndex: ['Yes', 'No'].indexOf(newValue),
        optionName: currOptionName,
        optionDesc: currOptionDesc
      })
    )
    if (newValue === 'Yes') {
      await updateSelectedNode(currSelNode.id, {
        nodeType: 'option_new',
        refID: undefined,
        objectID: uuidv4()
      })
    }
    if (newValue === 'No') {
      await updateSelectedNode(currSelNode.id, {
        nodeType: 'option_ref',
        objectID: uuidv4()
      })
    }

    clearRefNodesHighlight()
    dispatch(setOptionArenaPanelAction({ optionRefID: '' }))
  }

  const handleOnOptionNameChange = async (event: any) => {
    log.info(`*** App::handleOnOptionNameChange: ${event.target.value} ***`)

    dispatch(
      setOptionArenaPanelAction({
        optionName: event.target.value
      })
    )
    await updateSelectedNode(currSelNode.id, {
      name: event.target.value
    })
  }

  const handleOnOptionDescChange = async (event: any) => {
    log.info(`*** App::handleOnOptionDescChange: ${event.target.value} ***`)

    dispatch(
      setOptionArenaPanelAction({
        optionDesc: event.target.value
      })
    )

    await updateSelectedNode(currSelNode.id, {
      desc: event.target.value
    })
  }

  const handleOnOptionAudioChange = async (
    audioBlobURL: string,
    filename: string,
    index: number
  ) => {
    log.info(`*** App::handleOnOptionAudioChange  ***`)
    log.info(
      `*** App::handleOnOptionAudioChange::audioBlobURL: ${audioBlobURL}`
    )
    log.info(`*** App::handleOnOptionAudioChange::filename: ${filename}`)
    log.info(`*** App::handleOnOptionAudioChange::index: ${index}`)

    const filenameWithoutExt = filename.replace(/\.[^/.]+$/, '')

    if (audioBlobURL) {
      log.info(' DISPATCHING UPLOAD AUDIO_PHRASE ')

      const response = await dispatch(
        uploadAudioPhraseService(
          currentBlock.id,
          currSelNode.objectID,
          `${index}`,
          audioBlobURL
        )
      )
      log.info(
        `*** App::handleOnOptionAudioChange::uploadPath: ${response.data}`
      )
      const tmpList = [...(optionAudioList ?? [])]
      tmpList[index] = response.data
      dispatch(
        setOptionArenaPanelAction({
          optionAudioList: tmpList,
          optionName: filenameWithoutExt
        })
      )
      await updateSelectedNode(currSelNode.id, {
        audioURLs: tmpList,
        name: filenameWithoutExt
      })
    } else {
      log.info(' DISPATCHING RESET OPTION UPLOADED_AUDIO_PATH ')

      const tmpList = [...(optionAudioList ?? [])]
      tmpList[index] = ''
      dispatch(
        setOptionArenaPanelAction({
          optionAudioList: tmpList,
          optionName: ''
        })
      )
      await updateSelectedNode(currSelNode.id, {
        audioURLs: tmpList,
        optionName: ''
      })
    }
  }

  const handleOnOptionAudioIncrease = async () => {
    log.info(`*** App::handleOnOptionAudioIncrease ***`)

    log.info(
      `*** App::handleOnOptionAudioIncrease::PREV optionAudioList: ${JSON.stringify(
        optionAudioList
      )}`
    )
    log.info(
      `*** App::handleOnOptionAudioIncrease::NEW optionAudioList: ${JSON.stringify(
        [...(optionAudioList ?? []), '']
      )}`
    )
    dispatch(
      setOptionArenaPanelAction({
        optionAudioList: [...(optionAudioList ?? []), '']
      })
    )
    await updateSelectedNode(currSelNode.id, {
      audioURLs: [...(optionAudioList ?? []), '']
    })
    /*
    onButtonClick={() => {
      log.info('AudioUploaderGrp::add')
      setURLList([...urlList, ''])
    }}
    */
  }

  const handleOnOptionAudioDecrease = async () => {
    log.info(`*** App::handleOnOptionAudioDecrease  ***`)
    log.info(
      `*** App::handleOnOptionAudioDecrease::PREV optionAudioList: ${JSON.stringify(
        optionAudioList
      )}  ***`
    )

    const tmpList = [...(optionAudioList ?? [])]
    if (tmpList) {
      tmpList.pop()
      log.info(
        `*** App::handleOnOptionAudioDecrease::NEW optionAudioList: ${JSON.stringify(
          tmpList
        )}  ***`
      )
      dispatch(setOptionArenaPanelAction({ optionAudioList: [...tmpList] }))
      await updateSelectedNode(currSelNode.id, {
        audioURLs: [...tmpList]
      })
    }
    /*
    onButtonClick={() => {
      log.info('AudioUploaderGrp::delete')
      log.info('BEFORE AudioUploaderGrp::urlList: ', urlList)
      urlList.pop()
      log.info('AFTER AudioUploaderGrp::urlList: ', urlList)
      setURLList([...urlList])
    }}
    */
  }

  const handleOnOptionRefChange = async (event: any) => {
    log.info(`*** App::handleOnOptionRefChange: ${event.target.value} ***`)
    const newRefID = event.target.value

    clearRefNodesHighlight()
    setRefNodeHighlight(newRefID)

    const foundNode = await dsDigger.findNodeById(newRefID)
    await updateSelectedNode(currSelNode.id, {
      refID: newRefID,
      objectID: foundNode.objectID,
      name: foundNode.name
    })
    dispatch(
      setOptionArenaPanelAction({
        optionRefID: newRefID,
        optionName: foundNode.name
      })
    )
  }

  const handleOnPreviewTypeChange = async (event: any) => {
    log.info(`*** App::handleOnPreviewTypeChange: ${event.target.value} ***`)

    if (['Draft', 'Live'].indexOf(event.target.value) === 1) {
      log.info(
        `*** App::handleOnPreviewTypeChange::currentBlock.liveBotJSON: ${currentBlock.liveBotJSON} ***`
      )

      if (!currentBlock.liveBotJSON) {
        // show notification explaining user needs to publish block
        // ... before they can preview the live block
        dispatch(
          showPopupService({
            type: PopupTypeEnum.notification,
            data: {
              title: TITLE_P_BLOCK_NOTIFICATION,
              message: MESSAGE_P_BLOCK_NOTIFICATION
            }
          })
        )
      } else {
        dispatch(
          setPreviewArenaPanelAction({
            previewTypeIndex: 1
          })
        )
      }
    } else {
      dispatch(
        setPreviewArenaPanelAction({
          previewTypeIndex: 0
        })
      )
    }
  }

  const handleOnChangeAdminArenaHeader = async (event: any) => {
    log.info(
      `*** App::handleOnChangeAdminArenaHeader: ${event.target.value} ***`
    )

    if (event.target.value === 'block_design') {
      setArenaPanelView(ARENA_PANEL_NONE)
      dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
    }

    if (event.target.value === 'block_preview') {
      // if currentBlock does not have a valid draft-bot, display a warning
      log.info(
        `*** App::handleOnChangeAdminArenaHeader::currentBlock.draftBotJSON: ${currentBlock.draftBotJSON} ***`
      )

      if (!currentBlock.draftBotJSON) {
        // show notification explaining user needs to save block
        // ... before they can go to the preview page
        dispatch(
          showPopupService({
            type: PopupTypeEnum.notification,
            data: {
              title: TITLE_P_BLOCK_NOTIFICATION,
              message: MESSAGE_P_BLOCK_NOTIFICATION
            }
          })
        )
      } else {
        setArenaPanelView(ARENA_PANEL_PREVIEW)
        dispatch(changePageService(PAGE_ADMIN_BLOCK_PREVIEW))

        // reset preview-arena-panel
        dispatch(setPreviewArenaPanelAction({ previewTypeIndex: 0 }))
      }
    }

    if (event.target.value === 'block_settings') {
      setArenaPanelView(ARENA_PANEL_NONE)
      dispatch(changePageService(PAGE_ADMIN_BLOCK_SETTINGS))
    }
  }

  const handleOnSaveBlock = async () => {
    log.info(`*** App::handleOnSaveBlock ***`)
    log.info('**** App::handleOnSaveBlock::dsDigger.ds: ', dsDigger.ds)

    let isAllNodesCompleted = false
    let isDistToEndStatesCalculated = false
    let isLogicTreeConverted = false

    // ensure that all nodes are completely populated before
    // attempting to call save-endpoint on the backend
    try {
      const foundNodes = await dsDigger.findNodes({ isComplete: false })
      log.info('**** App::handleOnSaveBlock::foundNodes: ', foundNodes)
      if (foundNodes) {
        Alert.show(
          'Cannot save block until basic info for all nodes have been provided'
        )

        // return
      } else {
        isAllNodesCompleted = true
      }
    } catch (error) {
      // ToDo: look into why the error below is being triggered as the search above ideally
      // ... should result in a silent fail
      if (error.message !== "The nodes don't exist.") {
        Alert.show('Error while attempting to find incomplete nodes')

        // return
      } else {
        isAllNodesCompleted = true
      }
    }

    // ToDo: algorithm below does not account for scenarios where
    // ... end-state-node is referenced via another state
    // ... update algorithm to take this into account
    // ... by ensuring reference end-state-nodes are included in end-nodes-list
    // calculate number of prompts to end-state-nodes from each state-node
    try {
      log.info(' &&&&&&&&&&&&&& ')
      log.info('FE-logicTree-JSON: ', JSON.stringify(dsDigger.ds))
      const endNodesList = await dsDigger.findNodes({ stateType: 'end' })
      log.info('end-nodes-list: ', JSON.stringify(endNodesList))

      while (endNodesList.length > 0) {
        const endNode = endNodesList.shift()
        log.info('curr-node: ', JSON.stringify(endNode))

        let parentNode = endNode
        let currentLevel = 0
        let isEndLoop = false

        while (!isEndLoop) {
          // note: parent of end-node can ONLY be either state_new, option_new, option_ref
          // find closest ancestor-node that is a state
          parentNode = await dsDigger.findParent(parentNode.id)
          log.info('parent-node: ', JSON.stringify(parentNode))
          if (parentNode.nodeType !== 'state_new') {
            parentNode = await dsDigger.findParent(parentNode.id)
            log.info('updated-parent-node: ', JSON.stringify(parentNode))
          }

          // note: at this stage it is expected that state-type of parent node is either input or transit
          if (parentNode.stateType === 'input') {
            currentLevel += 1
          }

          log.info('parent-node-name: ', parentNode.name)
          log.info('end-node-name: ', endNode.name)
          log.info('currentLevel: ', currentLevel)
          log.info(' ---------------- ')

          if (!parentNode.distToEndStates) {
            parentNode.distToEndStates = {}
          }
          // parentNode.distToEndStates.push({ [endNode.objectID]: currentLevel })
          parentNode.distToEndStates[endNode.objectID] = currentLevel

          // update node
          await dsDigger.updateNode(parentNode)
          log.info('parent-node-after: ', JSON.stringify(parentNode))
          log.info('ds-digger-after: ', JSON.stringify(dsDigger.ds))

          // ToDo: currently, this algorithm works by starting from the end-state-node
          // ... and working its way up to the start-state-node
          // ... there could be opportunities to stop earlier for example,
          // ... if the parentNode's distToEndStates object already contains a key
          // ... for the current endNode being processed
          if (parentNode.id === 'uuid-root') {
            isEndLoop = true
            log.info('******** ENDING LOOP')
          }
        }
      }

      // update logic-tree to reflect changes to nodes
      const logicTreeParams = JSON.parse(JSON.stringify(dsDigger.ds))
      const statesList = await getNodesList(dsDigger, {
        nodeType: 'state_new'
      })
      const optionsList = await getNodesList(dsDigger, {
        nodeType: 'option_new'
      })
      dispatch(
        setBotTreeDSAction({
          botTree: logicTreeParams,
          statesList,
          optionsList
        })
      )

      isDistToEndStatesCalculated = true
      log.info(' &&&&&&&&&&&&&& ')
    } catch (error) {
      log.info('**** App::handleOnSaveBlock::error.message: ', error.message)
      Alert.show(
        'Error while attempting to calculate distance to end-state-nodes'
      )

      return
    }

    log.info('******** START fe to be logic-tree conversion')
    const logicData: any = {
      states: [],
      intentOptions: []
    }

    // convert fe-logic-tree data into the expected be-logic-tree format
    try {
      const nodesToProcessQueue = ['uuid-root']
      while (nodesToProcessQueue.length > 0) {
        const nodeID = nodesToProcessQueue.shift()
        let foundNode = await dsDigger.findNodeById(nodeID)
        foundNode = JSON.parse(JSON.stringify(foundNode))

        Console.assert(
          foundNode.nodeType === 'state_new',
          'AssertError: nodeType of foundNode expected to be state_new'
        )

        const newState: any = {
          id: foundNode.objectID,
          name: foundNode.name,
          desc: foundNode.desc,
          isStart: nodeID === 'uuid-root',
          type: foundNode.stateType,
          distToEndStates: foundNode.distToEndStates,
          actions: [
            {
              name: 'speak',
              data: {
                audioURLs: foundNode.audioURLs
              }
            }
          ]
        }

        if ('children' in foundNode && foundNode.children.length > 0) {
          if (foundNode.children.length > 1) {
            Console.assert(
              foundNode.children[0].nodeType.includes('option'),
              "AssertError: nodeType of foundNode's direct children expected to be options"
            )
          }

          if (foundNode.children[0].nodeType.includes('option')) {
            const transitionsData: any = []

            foundNode.children.forEach((child: any) => {
              transitionsData.push({
                optionID: child.objectID,
                optionName: child.name,
                stateID: child.children[0].objectID,
                stateName: child.children[0].name
              })

              // add option info to intent-options
              if (child.nodeType === 'option_new') {
                const newOption: any = {
                  id: child.objectID,
                  name: child.name,
                  desc: child.desc,
                  phraseList: {
                    audioURLs: child.audioURLs
                  }
                }
                logicData.intentOptions.push(newOption)
              }

              // add state after option to state-to-process-queue
              if (child.children[0].nodeType === 'state_new') {
                nodesToProcessQueue.push(child.children[0].id)
              }
            })

            const newAction: any = {
              name: 'listen',
              data: {
                transitions: transitionsData
              }
            }
            newState.actions.push(newAction)
          }

          if (foundNode.children[0].nodeType.includes('state')) {
            const newAction: any = {
              name: 'transition',
              data: {
                stateID: foundNode.children[0].objectID,
                stateName: foundNode.children[0].name
              }
            }
            newState.actions.push(newAction)

            // add state after transit to state-to-process-queue
            if (foundNode.children[0].nodeType === 'state_new') {
              nodesToProcessQueue.push(foundNode.children[0].id)
            }
          }
        } else {
          const newAction: any = {
            name: 'shutdown'
          }
          newState.actions.push(newAction)
        }

        logicData.states.push(newState)
      }
      isLogicTreeConverted = true

      log.info('****************************')
      log.info('currentBlock.id: ', currentBlock.id)
      log.info('logicData: ', logicData)
      log.info('BE-logicTree-JSON: ', JSON.stringify(logicData))
      log.info('****************************')
    } catch (error) {
      log.info('**** App::handleOnSaveBlock::error.message: ', error.message)
      Alert.show(
        'Error while attempting to convert fe-logic-tree to be-logic-tree'
      )

      // return
    }

    if (
      isAllNodesCompleted &&
      isDistToEndStatesCalculated &&
      isLogicTreeConverted
    ) {
      // // ToDo: uncomment temporay comment to disable saving
      // call save-endpoint to update the draft-bot logic on the server
      setShouldShowLoading(true)
      const response = await dispatch(
        updateBlockDataService(
          currentBlock.id,
          { draftBot: logicData },
          acctProfile?.id
        )
      )
      setShouldShowLoading(false)

      // currently, uploading an audio-phrase or a bot-speech overwrites
      // the previous url for the audio-phrase or bot-speech.
      // in order to ensure the proper functioning of the block,
      // it is a hard requirement for the admin to save the updated bot-logic
      // as if this fails, users would not be able to interact with the block.
      // hence, it is important for the admin to be notified if updating the block-logic fails
      if (!response.success) {
        dispatch(
          showPopupService({
            type: PopupTypeEnum.notification,
            data: {
              title: 'ERROR',
              message: 'Failed to update block data'
            }
          })
        )
      } else {
        dispatch(setCurrentBlockService(currentBlock.id))
      }
    } else {
      dispatch(
        showPopupService({
          type: PopupTypeEnum.notification,
          data: {
            title: 'ERROR',
            message:
              'Failed to create valid block-update-data payload for server'
          }
        })
      )
    }
  }

  const handleOnPublishBlock = async () => {
    log.info(`*** App::handleOnPublishBlock ***`)

    log.info('****************************')
    log.info('currentBlock.id: ', currentBlock.id)
    log.info('****************************')

    setShouldShowLoading(true)
    await dispatch(publishBlockService(currentBlock.id, acctProfile?.id))
    await dispatch(setCurrentBlockService(currentBlock.id))
    setShouldShowLoading(false)
  }

  const handleOnSaveSettings = async () => {
    log.info(`*** App::handleOnSaveSettings ***`)
    log.info(
      `*** App::handleOnSaveSettings::currProgramSettingsData: ${JSON.stringify(
        currentProgramSettingsData
      )} ***`
    )
    log.info(
      `*** App::handleOnSaveSettings::currentBlockSettingsData: ${JSON.stringify(
        currentBlockSettingsData
      )} ***`
    )

    setShouldShowLoading(true)

    let updatedProgramSettingsData = currentProgramSettingsData
    if (currentProgramSettingsData.imageUrl) {
      // get File
      const programImage = await fetch(currentProgramSettingsData.imageUrl)
        .then((r) => r.blob())
        .then((blob) => new File([blob], 'programImage'))
      // upload file to get a url from backend
      const uploadProgramImageResponse = await dispatch(
        uploadProgramImageService(currentProgram.id, programImage)
      )
      // update program settings with new image url from backend
      if (uploadProgramImageResponse.success) {
        updatedProgramSettingsData = {
          ...currentProgramSettingsData,
          imageUrl: uploadProgramImageResponse.data
        }
        await dispatch(updateProgramSettingsService(updatedProgramSettingsData))
      }
    }

    const updateSettingsResponse = await Promise.all([
      dispatch(
        updateBlockDataService(
          currentBlock.id,
          currentBlockSettingsData,
          acctProfile?.id
        )
      ),
      dispatch(
        updateBlockNameService(
          currentBlock.id,
          currentBlockSettingsData.name ?? '',
          acctProfile?.id
        )
      ),
      dispatch(
        updateProgramNameService(
          currentProgram.id,
          currentProgramSettingsData?.name ?? '',
          acctProfile?.id
        )
      ),
      dispatch(
        updateProgramDataService(
          currentProgram.id,
          updatedProgramSettingsData,
          acctProfile?.id
        )
      )
    ])
    log.info(
      `*** App::handleOnSaveSettings: ${JSON.stringify(
        updateSettingsResponse
      )} ***`
    )

    // ToDo: investigate if there is a better way to do this
    // ... the side-panel uses the block-list data contained in the programs
    // ... since the design allows for both updates to the current
    // ... block and program from the block-settings arena, then
    // ... we have to refresh both the current program and block
    // ... but unfortunately, in the main-app page everytime the current
    // ... program refreshes, it resets the current block.
    // ... Current solution is to cache the current block-id,
    // ... refresh the current program (which would reset the current block),
    // ... then afterwards reset the current block to the cached block-id
    const cachedBlockID = currentBlock.id
    await dispatch(setCurrentProgramService(currentProgram.id, acctProfile?.id))
    await dispatch(setCurrentBlockService(cachedBlockID))

    setShouldShowLoading(false)
  }
  const isGlobalPageAuth = (): boolean => {
    return (
      currentPageGlobal === PAGE_USER_AUTH ||
      currentPageGlobal === PAGE_ADMIN_AUTH
    )
  }

  if (!user) {
    return <div />
  }

  let component = (
    <UserAppComponent
      popupType={popupType}
      shouldShowPopup={shouldShowPopup}
      currentPopup={currentPopup}
      isGlobalPageAuth={isGlobalPageAuth}
      setPopupType={setPopupType}
    />
  )

  if (isLocalPageAdminRoute()) {
    component = (
      <AdminAppComponent
        currentPageLocal={currentPageLocal}
        popupType={popupType}
        arenaPanelView={arenaPanelView}
        shouldShowPopup={shouldShowPopup}
        shouldShowLoading={shouldShowLoading}
        shouldShowAddNode={shouldShowAddNode}
        shouldShowDelNode={shouldShowDelNode}
        currentBlock={currentBlock}
        dpCurrBotTreeDS={dpCurrBotTreeDS}
        currentPopup={currentPopup}
        setShouldShowLoading={setShouldShowLoading}
        setPopupType={setPopupType}
        isGlobalPageAuth={isGlobalPageAuth}
        handleShowCreateProgramForm={handleShowCreateProgramForm}
        handleShowCreateBlockForm={handleShowCreateBlockForm}
        handleShowDeleteBlockForm={handleShowDeleteBlockForm}
        handleOnAddNode={handleOnAddNode}
        handleOnDelNode={handleOnDelNode}
        handleOnChangeAdminArenaHeader={handleOnChangeAdminArenaHeader}
        handleOnSaveBlock={handleOnSaveBlock}
        handleOnPublishBlock={handleOnPublishBlock}
        handleOnSaveSettings={handleOnSaveSettings}
        handleOnNodeSelect={handleOnNodeSelect}
        handleOnNodeUnselect={handleOnNodeUnselect}
        handleOnNewStateChange={handleOnNewStateChange}
        handleOnStateTypeChange={handleOnStateTypeChange}
        handleOnStateNameChange={handleOnStateNameChange}
        handleOnStateDescChange={handleOnStateDescChange}
        handleOnStateAudioChange={handleOnStateAudioChange}
        handleOnStateRefChange={handleOnStateRefChange}
        handleOnNewOptionChange={handleOnNewOptionChange}
        handleOnOptionNameChange={handleOnOptionNameChange}
        handleOnOptionDescChange={handleOnOptionDescChange}
        handleOnOptionAudioChange={handleOnOptionAudioChange}
        handleOnOptionAudioIncrease={handleOnOptionAudioIncrease}
        handleOnOptionAudioDecrease={handleOnOptionAudioDecrease}
        handleOnOptionRefChange={handleOnOptionRefChange}
        handleOnPreviewTypeChange={handleOnPreviewTypeChange}
      />
    )
  } else if (IS_STAG_ENV && !isStagingAuthed) {
    component = (
      <StagingAuthModalComponent
        open={shouldShowStagingAuthModal}
        onSubmit={handleStagingCodeSubmit}
      />
    )
  }

  const isLive = runtimeAppEnv === AMAZETHU_V2_LIVE

  component =
    isDesktop && isLive ? (
      <DesktopNotSupportedComponent open={isDesktop} />
    ) : (
      component
    )

  return (
    <AnalyticsProvider sdk={getAnalytics(firebaseApp)}>
      {component}
    </AnalyticsProvider>
  )
}

export default AppComponent
