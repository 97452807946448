import { SxProps } from '@mui/material'
import { theme } from 'components/atoms/styles/theme'

const container: SxProps = {
  p: 2,
  paddingLeft: '0',
  paddingRight: '0',
  marginTop: '15px',
  marginBottom: '20px',
  backgroundColor: theme.palette.neutral_light.light
}

const firstColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const secondColumn: SxProps = { flexGrow: 1 }

const thirdColumn: SxProps = {
  display: 'flex',
  alignItems: 'center'
}

const programArenaHeaderBtn = {
  width: '131px'
}

export default {
  container,
  firstColumn,
  secondColumn,
  thirdColumn,
  programArenaHeaderBtn
}
