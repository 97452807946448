import React from 'react'
import { IconButton, Box } from '@mui/material'
import showNotification from 'components/molecules/notifications/notifications'
import useResetConvoThread from 'hooks/api/use-reset-convo-thread-api'

const ResetConvoThreadComponent: React.FC = () => {
  const { resetConvoThread, isLoading } = useResetConvoThread()

  const handleChange = () => {
    resetConvoThread(
      () => {
        showNotification({ type: 'info', message: 'convo thread reset' })
      },
      () => {
        showNotification({
          type: 'error',
          message: 'Failed to reset the convo thread'
        })
      }
    )
  }

  return (
    <Box sx={{ paddingY: 1 }}>
      <IconButton
        disabled={isLoading}
        onClick={handleChange}
        aria-label="Reset ConvoThread"
        style={{ borderRadius: 0 }}
        sx={{ fontSize: '13px', color: 'black', border: '1px solid grey' }}>
        Reset ConvoThread
      </IconButton>
    </Box>
  )
}

export default ResetConvoThreadComponent
