import React from 'react'
import 'components/organisms/arenas/admin-block-design/tree-node.module.css'

const TreeNodeComponent = (nodeObj: any): React.ReactElement => {
  const { nodeData } = nodeObj
  const styleClass =
    nodeData.isComplete === undefined || !nodeData.isComplete
      ? 'incomplete'
      : ''

  return (
    <div className={`${styleClass}`}>
      <div className={`${nodeData.nodeType}`}> </div>
      <div className="body">{nodeData.name}</div>
    </div>
  )
}

export default TreeNodeComponent
