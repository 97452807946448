import React, { FunctionComponent } from 'react'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { theme } from 'components/atoms/styles/theme'
import LogoPlusTextComponent from 'components/molecules/logos/logo-plus-text'
import DropDownComponent from 'components/atoms/inputs/drop-down'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { logoutService } from 'datastore/slices/auth-controller'
import { changePageService } from 'datastore/slices/app-controller'
import { setCurrentProgramService } from 'datastore/slices/program-controller'
import {
  LABEL_VIEW_PROGRAMS,
  LABEL_CREATE_PROGRAM,
  PAGE_ADMIN_PROGRAM_SELECT,
  PAGE_ADMIN_BLOCK_DESIGN
} from 'datastore/utils/constants'
import { resetConvoUILogAction } from 'datastore/slices/convo-ui-controller'
import log from 'loglevel'

interface PropsInterface {
  width?: string | number
  height?: string | number
  ddWidth?: string | number
  ddHide?: boolean
  onCreateProgramSelect?: () => void
}

const defaultProps = {
  width: '100%',
  height: '60px',
  ddWidth: '216px',
  ddHide: false,
  onCreateProgramSelect: () => {
    log.info(`*** onCreateProgramSelect ***`)
  }
}

const initialDropDownData = [
  { ddID: 'view_programs', ddLabel: LABEL_VIEW_PROGRAMS },
  { ddID: 'create_program', ddLabel: LABEL_CREATE_PROGRAM }
]

const AdminTopPanelComponent: FunctionComponent<PropsInterface> = ({
  width = defaultProps.width,
  height = defaultProps.height,
  ddWidth = defaultProps.ddWidth,
  ddHide = defaultProps.ddHide,
  onCreateProgramSelect = defaultProps.onCreateProgramSelect
}) => {
  const dispatch = useStoreDispatch()
  const programList = useStoreSelector(
    (storeState) => storeState.programController.programList
  )
  const ddProgramListData = [
    ...initialDropDownData,
    ...programList.map((program: any) => {
      return { ddID: program.id, ddLabel: program.name }
    })
  ]
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )

  return (
    <Stack
      className="AdminTopPanel"
      direction="row"
      sx={{
        p: 2,
        width,
        height,
        backgroundColor: theme.palette.neutral_light.light
      }}>
      <LogoPlusTextComponent />
      <Box className="AdminTopPanel-Spacing" sx={{ flexGrow: 1 }} />
      <Stack
        className="AdminTopPanel-Menu"
        direction="row"
        spacing={2}
        sx={{ alignItems: 'center' }}>
        <Box
          className="AdminTopPanel-Menu-DropDown"
          sx={{
            display: ddHide ? 'none' : 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <DropDownComponent
            type="regular"
            width={ddWidth}
            label=""
            ddData={ddProgramListData}
            ddSelectID={currentProgram.id}
            onDDChange={(event: any) => {
              log.info('AdminTopPanel::onDDChange => ', event.target.value)

              if (event.target.value === 'view_programs') {
                dispatch(changePageService(PAGE_ADMIN_PROGRAM_SELECT))
              } else if (event.target.value === 'create_program') {
                onCreateProgramSelect()
              } else {
                dispatch(
                  setCurrentProgramService(event.target.value, acctProfile?.id)
                )
                dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
              }
              dispatch(resetConvoUILogAction())
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            log.info('**** AdminTopPanel: Logging Out ****')
            dispatch(logoutService())
          }}>
          Logout
        </Button>
      </Stack>
    </Stack>
  )
}

AdminTopPanelComponent.defaultProps = defaultProps
export default AdminTopPanelComponent
