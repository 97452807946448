import { SxProps } from '@mui/material'

const fullTableStack: SxProps = {
  width: '100%',
  height: '100%',
  display: 'table'
}

const headerRow: SxProps = {
  marginBottom: '15px'
}

const lastModifiedDate: SxProps = {
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '34px'
}

const list: SxProps = {
  height: '100%',
  overflowY: 'auto'
}

const listItem: SxProps = {
  paddingLeft: '0',
  paddingRight: '0'
}

const updatedAt: SxProps = {
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}

const iconButton: SxProps = { marginLeft: '10px', padding: '0' }

const inputContainer: SxProps = {
  position: 'relative',
  width: 'inherit',
  padding: '0'
}

const saveButton: SxProps = {
  position: 'absolute',
  right: '-68px',
  width: '64px',
  height: '28px',
  top: '50%',
  transform: 'translateY(-50%)'
}

export default {
  fullTableStack,
  headerRow,
  lastModifiedDate,
  list,
  updatedAt,
  listItem,
  iconButton,
  inputContainer,
  saveButton
}
