import axios from 'axios'

import { URI_CREATE_ADMIN, URI_ADMIN } from 'datastore/apis/constants'

async function createUser(data: { email: string }) {
  return axios.post(`${URI_CREATE_ADMIN}`, data)
}

async function fetchUser(data: { email: string }) {
  return axios.get(`${URI_ADMIN}`, {
    params: {
      email: data.email
    }
  })
}

export default { createUser, fetchUser }
