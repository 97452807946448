import React, { FunctionComponent, useEffect, useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import PauseIcon from '@mui/icons-material/PauseRounded'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import MicIcon from '@mui/icons-material/MicRounded'
import UploadIcon from '@mui/icons-material/UploadRounded'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import StopIcon from '@mui/icons-material/Stop'
import { useAudioRecorder } from '@sarafhbk/react-audio-recorder'
import { v4 as uuidv4 } from 'uuid'

import { theme } from 'components/atoms/styles/theme'
import OutlinedDivComponent from 'components/atoms/utils/outlined-div'
import { Tooltip } from '@mui/material'
import log from 'loglevel'

interface PropsInterface {
  label?: string
  width?: string | number
  height?: string | number
  isHidden?: boolean
  uploadURL?: string
  onValueChange?: (audioBlobURL: string, filename: string) => void
}

const defaultProps = {
  label: '',
  width: 'auto',
  height: 40,
  isHidden: false,
  uploadURL: '',
  onValueChange: (audioBlobURL: string) => {
    log.info(`*** AudioUploader::onValueChange: ${audioBlobURL} ***`)
  }
}

const AudioUploaderComponent: FunctionComponent<PropsInterface> = ({
  label = defaultProps.label,
  width = defaultProps.width,
  height = defaultProps.height,
  isHidden = defaultProps.isHidden,
  uploadURL = defaultProps.uploadURL,
  onValueChange = defaultProps.onValueChange
}) => {
  const [isRecording, setIsRecording] = React.useState<boolean>(false)
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false)
  const [hasAudio, setHasAudio] = React.useState<boolean>(false)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [audioBlobURL, setAudioBlobURL] = React.useState<string>('')
  const [fileName, setFileName] = React.useState<string>('')

  const mainIconColor = theme.palette.neutral_dark.dark
  const { audioResult, startRecording, stopRecording } = useAudioRecorder()
  const inputId = uuidv4()

  const clearAudio = () => {
    log.info('*** AudioUploader::delete')
    if (audioRef.current) {
      audioRef.current.src = ''
      setHasAudio(false)
    }

    // clear audio-blob-url
    setAudioBlobURL('clearUploadURL')
    setFileName('')
  }

  const setAudio = (audioUrl: string) => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl
      setHasAudio(true)
    }
  }

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause()
    }
  }

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play()
    }
  }

  const togglePlay = () => {
    if (audioRef.current) {
      setIsPlaying(!isPlaying)
      if (isPlaying) {
        pauseAudio()
      } else {
        playAudio()
      }
    }
  }

  // set audio source based on upload-url value
  useEffect(() => {
    log.info('*** AudioUploader::re-render due to change to uploadURL ****')
    log.info('*** AudioUploader::uploadURL: ', uploadURL)

    if (uploadURL) {
      setAudio(uploadURL)
    }
  }, [uploadURL])

  // trigger upload or reset based on audio-blob-url value
  React.useEffect(() => {
    log.info('*** AudioUploader::re-render due to change to audioBlobURL ****')
    log.info('*** AudioUploader::audioBlobURL: ', audioBlobURL)

    if (audioBlobURL) {
      if (audioBlobURL === 'clearUploadURL') {
        onValueChange('', '')
      } else {
        onValueChange(audioBlobURL, fileName)
      }
    }
  }, [audioBlobURL])

  // set audio-blob-url in state when audio recorder component has recorded audio
  useEffect(() => {
    log.info('*** AudioUploader::re-render due to change to audioResult ****')
    log.info('*** AudioUploader::audioResult: ', audioResult)

    if (audioResult) {
      setFileName('recorded.wav')
      setAudioBlobURL(audioResult)
    }
  }, [audioResult])

  // set audio-blob-url after local audio file has been selected
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFileName(file.name)
      const tmpURL = URL.createObjectURL(file)
      setAudioBlobURL(tmpURL)
    } else {
      setFileName('')
    }
  }

  return (
    <OutlinedDivComponent
      label={label}
      width={width}
      height={height}
      padding="0"
      isHidden={isHidden}>
      <Stack
        spacing={0}
        direction="row"
        sx={{
          mb: 0,
          px: 0,
          backgroundColor: !hasAudio
            ? theme.palette.neutral_light.light
            : theme.palette.primary.light,
          width,
          height
        }}
        justifyContent="space-evenly"
        alignItems="center">
        <IconButton
          disabled={hasAudio}
          aria-label={isRecording ? 'stopRec' : 'startRec'}
          onClick={() => {
            log.info('*** AudioUploader::recording: ', isRecording)

            if (!hasAudio)
              if (!isRecording) {
                log.info('*** AudioUploader::startRecording ')
                startRecording()
              } else {
                log.info('*** AudioUploader::stopRecording ')
                stopRecording()
              }

            setIsRecording(!isRecording)
          }}>
          {!hasAudio ? (
            <>
              {isRecording ? (
                <StopIcon htmlColor={mainIconColor} />
              ) : (
                <MicIcon htmlColor={mainIconColor} />
              )}
            </>
          ) : (
            <MicIcon />
          )}
        </IconButton>
        <input
          type="file"
          id={inputId}
          accept="audio/*"
          style={{ display: 'none' }}
          onChange={onChangeFile}
        />
        <label htmlFor={inputId}>
          <IconButton disabled={hasAudio} aria-label="upload" component="span">
            {!hasAudio ? (
              <UploadIcon htmlColor={mainIconColor} />
            ) : (
              <UploadIcon />
            )}
          </IconButton>
        </label>
        <Tooltip title={fileName}>
          <span>
            <IconButton
              disabled={!hasAudio}
              aria-label={!isPlaying ? 'play' : 'pause'}
              onClick={togglePlay}>
              {hasAudio ? (
                <>
                  {!isPlaying ? (
                    <PlayIcon htmlColor={mainIconColor} />
                  ) : (
                    <PauseIcon htmlColor={mainIconColor} />
                  )}
                </>
              ) : (
                <PlayIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
        <audio ref={audioRef} onEnded={() => setIsPlaying(false)} />
        <IconButton
          disabled={!hasAudio}
          aria-label="delete"
          onClick={clearAudio}>
          {hasAudio ? <DeleteIcon htmlColor={mainIconColor} /> : <DeleteIcon />}
        </IconButton>
      </Stack>
    </OutlinedDivComponent>
  )
}

AudioUploaderComponent.defaultProps = defaultProps
export default AudioUploaderComponent
