import { useStoreSelector } from 'datastore/config/hooks'
import { useEffect, useState } from 'react'
import { useFirstMountState } from 'react-use'
import { PopupInterface } from 'types'

export function usePopupChange(): [
  currentPopup: PopupInterface,
  previousPopup: PopupInterface | undefined
] {
  const activePopup = useStoreSelector(
    (storeState) => storeState.appController.currPopup
  )
  const isFirstMount = useFirstMountState()

  const [[currentPopup, previousPopup], setPopups] = useState<
    [PopupInterface, PopupInterface | undefined]
  >([activePopup, undefined])

  useEffect(() => {
    if (!isFirstMount) {
      setPopups([activePopup, currentPopup])
    }
  }, [activePopup])

  return [currentPopup, previousPopup]
}
