import { useCallback, useEffect } from 'react'
import utilFuncs from 'datastore/utils/functions'
import { DefaultAudioRecorder } from 'components/molecules/media/audio-recorder/default-audio-recorder'
import { useStoreDispatch } from 'datastore/config/hooks'
import { setMicOnAction } from 'datastore/slices/app-controller'
import { useAppFocus } from './use-app-focus'

export function useAutoStopAllMicStreamOnFocusLose() {
  const dispatch = useStoreDispatch()

  const isAppFocussed = useAppFocus()

  const disableMicAccess = useCallback(async () => {
    const mediaRec = await utilFuncs.getGlobalRecorder(null)
    if (mediaRec instanceof DefaultAudioRecorder) {
      if (mediaRec.mediaStream) {
        mediaRec.mediaStream.getTracks().forEach((track) => track.stop())
        mediaRec.didInitialized = false
        dispatch(setMicOnAction(false))
      }
    }
  }, [])

  useEffect(() => {
    if (isAppFocussed) {
      return
    }
    disableMicAccess()
  }, [isAppFocussed])

  return { isAppFocussed, disableMicAccess }
}
