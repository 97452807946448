import React, { FunctionComponent } from 'react'
import Stack from '@mui/material/Stack'
import RadioBtnGrpComponent from 'components/atoms/inputs/radio-btn-grp'
import TextInputComponent from 'components/atoms/inputs/text-input'
import AudioUploaderComponent from 'components/molecules/media/audio-uploader'
import AudioUploaderGrpComponent from 'components/molecules/media/audio-uploader-grp'
import {
  ARENA_PANEL_STATE,
  ARENA_PANEL_OPTION,
  ARENA_PANEL_PREVIEW,
  ARENA_PANEL_NONE
} from 'datastore/utils/constants'
import { useStoreSelector } from 'datastore/config/hooks'
import DropDownComponent from 'components/atoms/inputs/drop-down'
import log from 'loglevel'

type EventFunc = (event: any) => void
type AudioStageChangedFunc = (audioBlobURL: string, filename: string) => void
type AudioOptionChangeFunc = (
  audioBlobURL: string,
  filename: string,
  index: number
) => void

interface PropsInterface {
  display?: string
  onNewStateChange?: EventFunc
  onStateTypeChange?: EventFunc
  onStateNameChange?: EventFunc
  onStateDescChange?: EventFunc
  onStateAudioChange?: AudioStageChangedFunc
  onStateRefChange?: EventFunc
  onNewOptionChange?: EventFunc
  onOptionNameChange?: EventFunc
  onOptionDescChange?: EventFunc
  onOptionAudioChange?: AudioOptionChangeFunc
  onOptionAudioIncrease?: () => void
  onOptionAudioDecrease?: () => void
  onOptionRefChange?: EventFunc
  onPreviewTypeChange?: EventFunc
}

const defaultProps = {
  display: ARENA_PANEL_NONE,
  onNewStateChange: (event: any) => {
    log.info(`*** ArenaPanel::onNewStateChange: ${event.target.value} ***`)
  },
  onStateTypeChange: (event: any) => {
    log.info(`*** ArenaPanel::onStateTypeChange: ${event.target.value} ***`)
  },
  onStateNameChange: (event: any) => {
    log.info(`*** ArenaPanel::onStateNameChange: ${event.target.value} ***`)
  },
  onStateDescChange: (event: any) => {
    log.info(`*** ArenaPanel::onStateDescChange: ${event.target.value} ***`)
  },
  onStateAudioChange: (audioBlobURL: string, filename: string) => {
    log.info(
      `*** ArenaPanel::onStateAudioChange: ${audioBlobURL} filename: ${filename} ***`
    )
  },
  onStateRefChange: (event: any) => {
    log.info(`*** ArenaPanel::onStateRefChange: ${event.target.value} ***`)
  },
  onNewOptionChange: (event: any) => {
    log.info(`*** ArenaPanel::onNewOptionChange: ${event.target.value} ***`)
  },
  onOptionNameChange: (event: any) => {
    log.info(`*** ArenaPanel::onOptionNameChange: ${event.target.value} ***`)
  },
  onOptionDescChange: (event: any) => {
    log.info(`*** ArenaPanel::onOptionDescChange: ${event.target.value} ***`)
  },
  onOptionAudioChange: (
    audioBlobURL: string,
    filename: string,
    index: number
  ) => {
    log.info(`*** ArenaPanel::onOptionAudioChange ***`)
    log.info(
      `*** ArenaPanel::onOptionAudioChange::audioBlobURL: ${audioBlobURL}`
    )
    log.info(`*** ArenaPanel::onOptionAudioChange::index: ${index}`)
  },
  onOptionAudioIncrease: () => {
    log.info(`*** ArenaPanel::onOptionAudioIncrease ***`)
  },
  onOptionAudioDecrease: () => {
    log.info(`*** ArenaPanel::onOptionAudioDecrease ***`)
  },
  onOptionRefChange: (event: any) => {
    log.info(`*** ArenaPanel::onOptionRefChange: ${event.target.value} ***`)
  },
  onPreviewTypeChange: (event: any) => {
    log.info(`*** ArenaPanel::onPreviewTypeChange: ${event.target.value} ***`)
  }
}

const StateArenaPanelComponent = (customProps: any) => {
  const {
    width,
    onNewStateChange,
    onStateTypeChange,
    onStateNameChange,
    onStateDescChange,
    onStateAudioChange,
    onStateRefChange
  } = customProps

  const newStateIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.newStateIndex
  )
  const stateTypeIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateTypeIndex
  )
  const stateName = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateName
  )
  const stateDesc = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateDesc
  )
  const stateAudioList = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateAudioList
  )
  const stateRefID = useStoreSelector(
    (storeState) => storeState.arenaPanelController.stateRefID
  )
  const stateRefList = useStoreSelector(
    (storeState) => storeState.botTreeController.statesList
  )

  return (
    <>
      <RadioBtnGrpComponent
        label="New State"
        width={width}
        options={['Yes', 'No']}
        defaultIndex={newStateIndex}
        onOptionChange={onNewStateChange}
      />
      <RadioBtnGrpComponent
        label="State Type"
        width={width}
        options={['Input', 'Transit', 'End']}
        defaultIndex={stateTypeIndex}
        onOptionChange={onStateTypeChange}
        isHidden={newStateIndex === 1}
      />
      <TextInputComponent
        label="State Name"
        width={width}
        value={stateName}
        isHidden={newStateIndex === 1}
        onValueChange={onStateNameChange}
      />
      <TextInputComponent
        label="State Description"
        width={width}
        value={stateDesc}
        isHidden={newStateIndex === 1}
        onValueChange={onStateDescChange}
      />
      <AudioUploaderComponent
        label="Bot Speech"
        uploadURL={stateAudioList ? stateAudioList[0] : ''}
        width={width}
        isHidden={newStateIndex === 1}
        onValueChange={onStateAudioChange}
      />
      <DropDownComponent
        type="regular"
        // width={ddWidth}
        label="Select Existing State"
        ddData={stateRefList}
        ddSelectID={stateRefID}
        onDDChange={onStateRefChange}
        isHidden={newStateIndex === 0}
      />
    </>
  )
}

const OptionsArenaPanelComponent = (customProps: any) => {
  const {
    width,
    onNewOptionChange,
    onOptionNameChange,
    onOptionDescChange,
    onOptionAudioChange,
    onOptionAudioIncrease,
    onOptionAudioDecrease,
    onOptionRefChange
  } = customProps

  const newOptionIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.newOptionIndex
  )
  const optionName = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionName
  )
  const optionDesc = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionDesc
  )
  const optionAudioList = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionAudioList
  )
  const optionRefID = useStoreSelector(
    (storeState) => storeState.arenaPanelController.optionRefID
  )
  const optionRefList = useStoreSelector(
    (storeState) => storeState.botTreeController.optionsList
  )
  log.info('**** ArenaPanel::optionAudioList: ', optionAudioList)

  return (
    <>
      <RadioBtnGrpComponent
        label="New Option"
        width={width}
        options={['Yes', 'No']}
        defaultIndex={newOptionIndex}
        onOptionChange={onNewOptionChange}
      />
      <TextInputComponent
        label="Option Name"
        width={width}
        value={optionName}
        onValueChange={onOptionNameChange}
        isHidden={newOptionIndex === 1}
      />
      <TextInputComponent
        label="Option Description"
        width={width}
        value={optionDesc}
        onValueChange={onOptionDescChange}
        isHidden={newOptionIndex === 1}
      />
      <AudioUploaderGrpComponent
        actionBtnHeight="40px"
        label="Audio Phrases"
        flexGrow
        hoverEnabler={false}
        isHidden={newOptionIndex === 1}
        uploadURLs={optionAudioList}
        onValueChange={onOptionAudioChange}
        onIncreaseListSize={onOptionAudioIncrease}
        onDecreaseListSize={onOptionAudioDecrease}
      />
      <DropDownComponent
        type="regular"
        // width={ddWidth}
        label="Select Existing Option"
        ddData={optionRefList}
        ddSelectID={optionRefID}
        onDDChange={onOptionRefChange}
        isHidden={newOptionIndex === 0}
      />
    </>
  )
}

const PreviewArenaPanelComponent = (customProps: any) => {
  const { width, onPreviewTypeChange } = customProps

  const previewTypeIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.previewTypeIndex
  )

  return (
    <>
      <RadioBtnGrpComponent
        label="Preview Type"
        width={width}
        options={['Draft', 'Live']}
        defaultIndex={previewTypeIndex}
        onOptionChange={onPreviewTypeChange}
      />
    </>
  )
}

const ArenaPanelComponent: FunctionComponent<PropsInterface> = ({
  display = defaultProps.display,
  onNewStateChange = defaultProps.onNewStateChange,
  onStateTypeChange = defaultProps.onStateTypeChange,
  onStateNameChange = defaultProps.onStateNameChange,
  onStateDescChange = defaultProps.onStateDescChange,
  onStateAudioChange = defaultProps.onStateAudioChange,
  onStateRefChange = defaultProps.onStateRefChange,
  onNewOptionChange = defaultProps.onNewOptionChange,
  onOptionNameChange = defaultProps.onOptionNameChange,
  onOptionDescChange = defaultProps.onOptionDescChange,
  onOptionAudioChange = defaultProps.onOptionAudioChange,
  onOptionAudioIncrease = defaultProps.onOptionAudioIncrease,
  onOptionAudioDecrease = defaultProps.onOptionAudioDecrease,
  onOptionRefChange = defaultProps.onOptionRefChange,
  onPreviewTypeChange = defaultProps.onPreviewTypeChange
}) => {
  return (
    <Stack
      spacing={2}
      sx={{
        pt: 2,
        pb: 2,
        px: 2,
        flexGrow: 1
      }}>
      {(() => {
        if (display === ARENA_PANEL_STATE)
          return (
            <StateArenaPanelComponent
              onNewStateChange={onNewStateChange}
              onStateTypeChange={onStateTypeChange}
              onStateNameChange={onStateNameChange}
              onStateDescChange={onStateDescChange}
              onStateAudioChange={onStateAudioChange}
              onStateRefChange={onStateRefChange}
            />
          )
        if (display === ARENA_PANEL_OPTION)
          return (
            <OptionsArenaPanelComponent
              onNewOptionChange={onNewOptionChange}
              onOptionNameChange={onOptionNameChange}
              onOptionDescChange={onOptionDescChange}
              onOptionAudioChange={onOptionAudioChange}
              onOptionAudioIncrease={onOptionAudioIncrease}
              onOptionAudioDecrease={onOptionAudioDecrease}
              onOptionRefChange={onOptionRefChange}
            />
          )
        if (display === ARENA_PANEL_PREVIEW)
          return (
            <PreviewArenaPanelComponent
              onPreviewTypeChange={onPreviewTypeChange}
            />
          )
        return <div />
      })()}
    </Stack>
  )
}

ArenaPanelComponent.defaultProps = defaultProps
export default ArenaPanelComponent
