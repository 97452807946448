import React, { FunctionComponent } from 'react'
import { theme } from 'components/atoms/styles/theme'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import PauseRounded from '@mui/icons-material/PauseRounded'
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded'
import Avatar from '@mui/material/Avatar'
import log from 'loglevel'

interface PropsInterface {
  pos: 'none' | 'left' | 'right'
  bgColor?: string
  width?: string | number
  height?: string | number
  audioURL?: string
}

const defaultProps = {
  bgColor: theme.palette.primary.light,
  width: 'auto',
  height: '40px',
  audioURL: ''
}

const CustomAvatarComponent = (customProps: any) => {
  const { pos } = customProps
  if (pos === 'left' || pos === 'right')
    return (
      <Avatar
        alt="Travis Howard"
        src="/static/images/avatar/2.jpg"
        sx={{
          width: 32,
          height: 32,
          fontSize: theme.typography.h3.fontSize,
          ml: 2
        }}
      />
    )

  return <></>
}

const AudioPlayerComponent: FunctionComponent<PropsInterface> = ({
  pos,
  bgColor = defaultProps.bgColor,
  width = defaultProps.width,
  height = defaultProps.height,
  audioURL = defaultProps.audioURL
}) => {
  const duration = 200 // seconds
  const [position, setPosition] = React.useState(32)
  const [isPaused, setIsPaused] = React.useState(true)
  // const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000'

  let hasAudio = false
  if (audioURL) hasAudio = true
  log.info('*** AudioPlayer::audioURL: ', audioURL)
  log.info('*** AudioPlayer::hasAudio: ', hasAudio)

  return (
    <Box
      sx={{
        borderRadius: 1.5,
        backgroundColor: bgColor,
        width,
        height,
        pr: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
      <Stack
        spacing={0}
        direction="row"
        sx={{ mr: 0, px: 0 }}
        alignItems="center">
        {pos === 'left' ? <CustomAvatarComponent pos={pos} /> : <></>}
        <IconButton
          disabled={!hasAudio}
          aria-label={isPaused ? 'play' : 'pause'}
          onClick={() => {
            log.info('**** AudioPlayer::isPaused: ', isPaused)
            // log.info('**** AudioPlayer::audioResult: ', audioResult)
            // log.info('**** AudioPlayer::audioBlobURL: ', audioBlobURL)
            log.info('**** AudioPlayer::audioURL: ', audioURL)

            if (hasAudio) {
              const tmp = new Audio(audioURL)
              tmp.play()
              tmp.onended = () => {
                log.info('**** AudioPlayer::onended')
                setIsPaused(true)
              }

              setIsPaused(!isPaused)
            }
          }}
          sx={{ color: theme.palette.neutral_dark.dark }}>
          {isPaused ? <PlayArrowRounded /> : <PauseRounded />}
        </IconButton>
        <Slider
          aria-label="time-indicator"
          size="small"
          value={position}
          min={0}
          step={1}
          max={duration}
          onChange={(_, value) => setPosition(value as number)}
          sx={{
            color: theme.palette.neutral_dark.dark,
            height: 4,
            '& .MuiSlider-thumb': {
              width: 8,
              height: 8,
              transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
              '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)'
              },
              '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`
              },
              '&.Mui-active': {
                width: 20,
                height: 20
              }
            },
            '& .MuiSlider-rail': {
              opacity: 0.28
            }
          }}
        />
        {pos === 'right' ? <CustomAvatarComponent pos={pos} /> : <></>}
      </Stack>
    </Box>
  )
}

AudioPlayerComponent.defaultProps = defaultProps
export default AudioPlayerComponent
