import React, { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import MicIcon from '@mui/icons-material/Mic'
import StopIcon from '@mui/icons-material/Stop'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { updateUserRecordedAudioUrl } from 'datastore/slices/v2-program-controller'
import { keyframes } from '@emotion/react'
import Box from '@mui/system/Box'
import utilFuncs from 'datastore/utils/functions'
import {
  AudioRecorder,
  AudioRecorderType
} from 'components/molecules/media/audio-recorder'
import { useMountedState } from 'react-use'
import log from 'loglevel'
import { useAppFocus } from 'hooks/use-app-focus'
import { setMicOnAction } from 'datastore/slices/app-controller'

const GROW_KEYFRAMES = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`

const WAVE_STYLE = {
  position: 'absolute',
  borderRadius: '50%',
  background: 'radial-gradient(orange, transparent)',
  opacity: '0.7'
}

const V2RecordButtonComponent: React.FC = () => {
  const isAppFocussed = useAppFocus()
  const [isRecording, setIsRecording] = useState(false)
  const audioRecorder = useRef<AudioRecorder | null>(null)
  const dispatch = useStoreDispatch()
  const mounted = useMountedState()

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  useEffect(() => {
    if (isAppFocussed) {
      return
    }
    setIsRecording(false)
    audioRecorder.current?.stopRecording()
  }, [isAppFocussed])

  const handleRecord = async () => {
    if (isRecording) {
      await audioRecorder.current?.stopRecording()

      const audioUrl = audioRecorder.current?.audioUrl
      if (audioUrl) {
        log.warn('dispashing url', audioUrl)
        dispatch(updateUserRecordedAudioUrl(audioUrl))
      }

      if (mounted()) {
        setIsRecording(false)
      }
    } else {
      const audioRec = await utilFuncs.getGlobalRecorder(
        AudioRecorderType.default
      )
      dispatch(setMicOnAction(true))
      audioRecorder.current = audioRec

      const didRecordingStarted = await audioRec.startRecording()
      if (didRecordingStarted) {
        setIsRecording(true)
      }
    }
  }

  const animation = isRecording
    ? `${GROW_KEYFRAMES} 2s ease-in-out infinite`
    : 'none'

  return (
    <Button
      variant="contained"
      onClick={handleRecord}
      disabled={!!isMobileMenuOpen}
      className="record-button"
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none'
        },
        '&:focus': {
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent'
        },
        '&:active': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none'
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none'
        }
      }}>
      {isRecording ? (
        <StopIcon
          sx={{
            color: 'rgba(0,0,0,0.8)',
            position: 'absolute',
            zIndex: 2
          }}
        />
      ) : (
        <MicIcon
          sx={{
            color: isMobileMenuOpen ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.8)',
            position: 'absolute',
            zIndex: 2
          }}
        />
      )}
      {isRecording && (
        <Box
          sx={{
            ...WAVE_STYLE,
            animation,
            width: '20px',
            height: '20px',
            animationDelay: '0s'
          }}
        />
      )}
      {isRecording && (
        <Box
          sx={{
            ...WAVE_STYLE,
            animation,
            width: '35px',
            height: '35px',
            animationDelay: '0.2s'
          }}
        />
      )}
      <Box
        sx={{
          ...WAVE_STYLE,
          animation,
          width: '45px',
          height: '45px',
          animationDelay: '0.4s'
        }}
      />
    </Button>
  )
}

export default V2RecordButtonComponent
