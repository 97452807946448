/* eslint-disable consistent-return */

import { createSlice } from '@reduxjs/toolkit'
import programAPI from 'datastore/apis/program-api/v1'
import {
  PAGE_ADMIN_BLOCK_DESIGN,
  PAGE_ADMIN_PROGRAM_SELECT
} from 'datastore/utils/constants'
import utils from 'datastore/utils/functions'
import { changePageService } from 'datastore/slices/app-controller'
import {
  ApiResponseInterface,
  ProgramItemInterface,
  ProgramSettingsInterface
} from 'types'
import log from 'loglevel'

interface ProgramControllerStateInterface {
  programList: ProgramItemInterface[]
  currentProgram: ProgramItemInterface | Record<string, never>
  currentProgramSettingsData: ProgramSettingsInterface
}

// Define the initial state using that type
const initialState: ProgramControllerStateInterface = {
  programList: [],
  currentProgram: {},
  currentProgramSettingsData: {}
}

const programController = createSlice({
  name: 'program-controller',
  initialState,
  reducers: {
    setProgramListAction(state, action) {
      return {
        ...state,
        programList: action.payload
      }
    },
    setCurrentProgramAction(state, action) {
      return {
        ...state,
        currentProgram: action.payload
      }
    },
    resetProgramControllerAction() {
      return { ...initialState }
    },
    updateProgramSettingsAction(state, action) {
      return {
        ...state,
        currentProgramSettingsData: action.payload
      }
    }
  }
})

export const {
  setProgramListAction,
  setCurrentProgramAction,
  resetProgramControllerAction,
  updateProgramSettingsAction
} = programController.actions
export default programController.reducer

// ============
// Action Functions / Asynchronous Actions
// ============
export function getProgramListService(data: any) {
  return async function (dispatch: any) {
    try {
      const queryParams: any = {}
      if (data.owner) queryParams.owner = data.owner
      if (data.adminID) queryParams.adminID = data.adminID

      log.info(
        `**** [request] programController::getProgramListService: ${JSON.stringify(
          queryParams
        )}`
      )

      const res = await programAPI.fetchProgramList(queryParams)
      log.info(res.data)
      if (res.status === 200) {
        log.info(
          `**** [response] programController::getProgramListService: ${JSON.stringify(
            res.data
          )}`
        )
        dispatch(setProgramListAction(res.data))
      }
    } catch (error) {
      log.error(error)
      return error
    }
  }
}

export function setCurrentProgramService(programID: string, adminID?: string) {
  return async function (dispatch: any) {
    try {
      const res = await programAPI.fetchProgram(programID, { adminID })
      log.info(res.data)
      if (res.status === 200) {
        log.info(
          `**** programController::setCurrentProgramService: ${JSON.stringify(
            res.data
          )}`
        )
        dispatch(setCurrentProgramAction(res.data))
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export function createProgramService(programName: string, adminID?: string) {
  return async function (dispatch: any) {
    try {
      const res = await programAPI.createProgram(
        { name: programName },
        { adminID }
      )
      log.info(res.data)
      if (res.status === 200) {
        log.info(`**** programController::createProgramService: ${res.data}`)

        dispatch(getProgramListService({ owner: true, adminID }))
        dispatch(setCurrentProgramService(res.data.id, adminID))
        dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export function updateProgramStatusService(
  id: string,
  status: boolean,
  adminID?: string
) {
  return async function (dispatch: any): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: ''
    }

    try {
      const res = await programAPI.updateProgramData(
        id,
        {
          status: status ? 'active' : 'inactive'
        },
        { adminID }
      )
      if (res.status === 200) {
        dispatch(getProgramListService({ owner: true, adminID }))
        apiResponse.success = true
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function updateProgramNameService(
  id: string,
  name: string,
  adminID?: string
) {
  return async function (dispatch: any): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: ''
    }
    try {
      const res = await programAPI.updateProgramName(
        id,
        { name },
        { adminID: adminID as string }
      )
      if (res.status === 200) {
        dispatch(getProgramListService({ owner: true, adminID }))
        apiResponse.success = true
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function updateProgramDataService(
  id: string,
  data: any,
  adminID?: string
) {
  return async function (dispatch: any): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: ''
    }
    try {
      const res = await programAPI.updateProgramData(id, data, { adminID })
      if (res.status === 200) {
        dispatch(getProgramListService({ owner: true, adminID }))
        apiResponse.success = true
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function deleteProgramService(programID: string, adminID: string) {
  return async function (dispatch: any) {
    try {
      const res = await programAPI.deleteProgram(programID, { adminID })
      log.info(res)
      if (res.status === 200) {
        log.info(`**** programController::deleteProgramService: ${res.data}`)
        dispatch(resetProgramControllerAction())
        dispatch(changePageService(PAGE_ADMIN_PROGRAM_SELECT))
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export function updateProgramSettingsService(psData: ProgramSettingsInterface) {
  return async function (dispatch: any) {
    log.info('*** blockController::updateProgramSettingsService ***')
    try {
      log.info(`******** ${JSON.stringify(psData)} ********`)

      dispatch(updateProgramSettingsAction(psData))
    } catch (error) {
      log.error(error)
    }
  }
}
