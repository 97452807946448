import React, { useState, useEffect, useRef } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { V2ProgramItemInterface } from 'types'
import { useStoreSelector, useStoreDispatch } from 'datastore/config/hooks'
import {
  updateCurrentConvoThreadIDAction,
  updateUserRecordedAudioUrl
} from 'datastore/slices/v2-program-controller'

import {
  AMAZETHU_V2_TESTBED,
  FeatureFlagsEnum,
  IS_PROD_ENV,
  IS_STAG_ENV
} from 'datastore/utils/constants'
import programAPI from 'datastore/apis/program-api'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'
import {
  didBackendAPIRaiseException,
  updateHasUpdatedTestbedModels
} from 'datastore/slices/app-controller'
import Box from '@mui/system/Box'
import utilFuncs from 'datastore/utils/functions'
import { useGetUser } from 'hooks/use-authentication'
import InfoDialogComponent from 'components/molecules/popups/v2-info-dialog'
import { useRequestFeedback } from 'hooks/use-request-feedback'
import { useRemoteConfigFlag } from 'hooks/use-remote-config'
import V2UserAndBotAudioPlayerComponent from 'components/molecules/media/audio-players/v2-user-and-bot-audio-player'

const urlToBlob = async (url: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  return blob
}

const convertHashToBlobUrl = (hash: string) => {
  const audioBlob = new Blob(
    [Uint8Array.from(atob(hash), (c) => c.charCodeAt(0))],
    { type: 'audio/wav' }
  )
  const audioUrl = URL.createObjectURL(audioBlob)
  return audioUrl
}

function usePlayAudio() {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const bufferPlayerRef = useRef(utilFuncs.updateGlobalAudio(null))

  bufferPlayerRef.current.onplay = () => setIsPlaying(true)
  bufferPlayerRef.current.onpause = () => setIsPlaying(false)
  bufferPlayerRef.current.onended = () => setIsPlaying(false)

  return { isPlaying }
}

function formatLatency(msg: object) {
  let result = ''
  if (msg === null || msg === undefined) return result
  Object.entries(msg).forEach((val) => {
    result += `${val[0]}: ${val[1]}, `
  })
  result = result.slice(0, -2)
  return result
}

interface ActionsInterface {
  dataType: 'text' | 'audio'
  sender: 'user' | 'bot' | 'backend-log' | 'testbed-log' | 'latency-log'
  text: string
  audioUrl: string
}

interface MessageInterface {
  userUtteranceEval?: object
  botLogs?: object[]
  parserErrors?: any
  actions: ActionsInterface[]
}

const useTextInteraction = () => {
  const [messages, setMessages] = useState<MessageInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstMount, setIsFirstMount] = useState<boolean>(true)
  const dispatch = useStoreDispatch()

  const currentProgram = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentProgram
  )

  const currentConvoThreadID = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentConvoThreadID
  )

  const currentActiveLLM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveLLM
  )

  const currentActiveASR = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveASR
  )

  const currentActiveTTS = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveTTS
  )

  const confidenceScoreThreshold = useStoreSelector(
    (storeState) => storeState.v2ProgramController.confidenceScoreTreshold
  )

  const userRecordedAudioUrl = useStoreSelector(
    (storeState) => storeState.v2ProgramController.userRecordedAudioUrl
  )

  const currentActiveCCM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveCCM
  )

  const currentBotVersion = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentBotVersion
  )

  const hasUpdatedTestbedModels = useStoreSelector(
    (storeState) => storeState.appController.hasUpdatedTestbedModels
  )

  const user = useGetUser()

  const formattedActions = (data: ActionsInterface[]) => {
    return data.map((item: any) => {
      return {
        text: item.contentTranscript,
        sender: 'bot',
        dataType: 'text',
        audioUrl: convertHashToBlobUrl(item.content)
      }
    })
  }

  const llmOrCcmKey = currentBotVersion === '2.1' ? 'llmModel' : 'ccmModel'
  const llmOrCcmValue =
    currentBotVersion === '2.1' ? currentActiveLLM : currentActiveCCM
  const testbedConfigText = `${llmOrCcmKey}: ${llmOrCcmValue}, ttsModel: ${currentActiveTTS}, asrModel: ${currentActiveASR}, confidenceScoreThreshold: ${confidenceScoreThreshold}`

  useEffect(() => {
    const getActions = async () => {
      try {
        setIsLoading(true)
        setMessages([])
        const results = await programAPI.getThreadResumeActions(
          user.uid,
          (currentProgram as V2ProgramItemInterface).botID,
          'audio',
          'audio',
          currentActiveLLM,
          currentActiveASR,
          currentActiveTTS,
          confidenceScoreThreshold,
          currentActiveCCM,
          currentBotVersion
        )
        dispatch(updateCurrentConvoThreadIDAction(results.data.threadID))

        const testbedConfigsMessage: MessageInterface = {
          actions: [
            {
              text: testbedConfigText,
              sender: 'testbed-log',
              dataType: 'text',
              audioUrl: ''
            }
          ]
        }
        setMessages((prevMessages) => [...prevMessages, testbedConfigsMessage])

        const configsMessage: MessageInterface = {
          actions: [
            {
              text: `${results.data.configs}`,
              sender: 'backend-log',
              dataType: 'text',
              audioUrl: ''
            }
          ]
        }
        setMessages((prevMessages) => [...prevMessages, configsMessage])

        const botMessage: MessageInterface = {
          botLogs: results.data.bot_logs,
          parserErrors: results.data.parser_errors,
          actions: formattedActions(results.data.actions) as ActionsInterface[]
        }
        setMessages((prevMessages) => [...prevMessages, botMessage])

        setIsLoading(false)
      } catch (error) {
        dispatch(
          didBackendAPIRaiseException({
            message: 'Encountered error in ResumeActions',
            error: JSON.stringify(error)
          })
        )
        setIsLoading(false)
      }
    }

    if (isFirstMount || hasUpdatedTestbedModels) {
      getActions()
      setIsFirstMount(false)
      dispatch(updateHasUpdatedTestbedModels(false))
    }

    return () => {
      setMessages([])
    }
  }, [isFirstMount, hasUpdatedTestbedModels])

  const messagesEndRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const sendAudioToBackend = async () => {
      if (userRecordedAudioUrl) {
        const formData = new FormData()
        const audioBlob = await urlToBlob(userRecordedAudioUrl)
        formData.append('userUtterance', audioBlob, 'userUtterance.mp3')

        try {
          setIsLoading(true)
          const response =
            await programAPI.getThreadInteractActionsWithFormData(
              currentConvoThreadID,
              'audio',
              'audio',
              formData,
              currentActiveLLM,
              currentActiveASR,
              currentActiveTTS,
              confidenceScoreThreshold,
              currentActiveCCM,
              currentBotVersion
            )

          const configsMessage: MessageInterface = {
            actions: [
              {
                text: `${response.data.configs}`,
                sender: 'backend-log',
                dataType: 'text',
                audioUrl: ''
              }
            ]
          }
          setMessages((prevMessages) => [...prevMessages, configsMessage])

          const latencyMessage: MessageInterface = {
            actions: [
              {
                text: `${formatLatency(response.data.latency)}`,
                sender: 'latency-log',
                dataType: 'text',
                audioUrl: ''
              }
            ]
          }
          setMessages((prevMessages) => [...prevMessages, latencyMessage])

          const userMessage: MessageInterface = {
            actions: [
              {
                text: response.data.asr_logs,
                sender: 'user',
                dataType: 'text',
                audioUrl: userRecordedAudioUrl
              }
            ]
          }
          setMessages((prevMessages) => [...prevMessages, userMessage])

          const botMessage: MessageInterface = {
            botLogs: response.data.bot_logs,
            userUtteranceEval: response.data.user_utterance_evaluation,
            parserErrors: response.data.parser_errors,
            actions: formattedActions(
              response.data.actions
            ) as ActionsInterface[]
          }
          setMessages((prevMessages) => [...prevMessages, botMessage])

          setIsLoading(false)
        } catch (error) {
          dispatch(
            didBackendAPIRaiseException({
              message: 'Encountered error in InteractActions',
              error: JSON.stringify(error)
            })
          )
          setIsLoading(false)
        }
      }
    }

    sendAudioToBackend()
    dispatch(updateUserRecordedAudioUrl(''))
  }, [userRecordedAudioUrl])

  const requestFeedback = useRequestFeedback()
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    let userInteractionCount = 0
    messages.forEach((msg) => {
      if (msg.actions[0].sender === 'user') {
        userInteractionCount += 1
      }
    })

    requestFeedback(userInteractionCount)
  }, [messages])

  const shouldEnablePlaybackUtteranceFeature = useRemoteConfigFlag(
    FeatureFlagsEnum.playBotUtterance
  )

  const { isPlaying } = usePlayAudio()

  return {
    messages,
    isLoading,
    messagesEndRef,
    isPlaying,
    shouldEnablePlaybackUtteranceFeature
  }
}

export default useTextInteraction
