import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { updateTempConfigOptionsAction } from 'datastore/slices/v2-program-controller'
import { ModelInterface } from 'types'

const CCMDropdownComponent: React.FC<ModelInterface> = ({ data }) => {
  const [tempSelectedVersion, setTempSelectedVersion] = useState('')

  const currentActiveCCM = useStoreSelector(
    (storeselector) => storeselector.v2ProgramController.currentActiveCCM
  )

  const tempCurrentBotVersion = useStoreSelector(
    (storeselector) =>
      storeselector.v2ProgramController.tempConfigOptions.tempBotVersion
  )

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  const dispatch = useStoreDispatch()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTempSelectedVersion(event.target.value)
    dispatch(updateTempConfigOptionsAction({ tempCCM: event.target.value }))
  }

  useEffect(() => {
    setTempSelectedVersion(currentActiveCCM)
  }, [isMobileMenuOpen])

  if (tempCurrentBotVersion === '2.1') {
    return <Box />
  }

  return (
    <Box sx={{ marginBottom: 1.2 }}>
      <Typography
        variant="caption"
        component="legend"
        sx={{ color: 'black', marginBottom: -1.5, fontSize: '1.2rem' }}>
        CCM
      </Typography>
      <Select
        value={tempSelectedVersion}
        onChange={handleChange}
        sx={{
          width: '100%',
          height: '30px',
          fontSize: '0.7rem'
        }}>
        {data?.map((model) => (
          <MenuItem
            key={model}
            value={model}
            sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
            {model}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default CCMDropdownComponent
