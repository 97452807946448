import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { updateTempCurrInteractionTypeAction } from 'datastore/slices/app-controller'
import {
  AUDIO_IN_AUDIO_OUT,
  AUDIO_IN_TEXT_OUT
} from 'datastore/utils/constants'

const ChatTypeDropdownComponent: React.FC = () => {
  const chatOptions = [AUDIO_IN_AUDIO_OUT, AUDIO_IN_TEXT_OUT]
  const [tempSelectedVersion, setTempSelectedVersion] = useState('')

  const currInteractionType = useStoreSelector(
    (storeSelector) => storeSelector.appController.currInteractionType
  )

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  const dispatch = useStoreDispatch()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTempSelectedVersion(event.target.value)
    dispatch(updateTempCurrInteractionTypeAction(event.target.value))
  }

  useEffect(() => {
    setTempSelectedVersion(currInteractionType)
  }, [isMobileMenuOpen])

  return (
    <Select
      value={tempSelectedVersion}
      onChange={handleChange}
      sx={{
        width: '100%',
        height: '30px',
        fontSize: '0.7rem'
      }}
      className="chat-type-dropdown">
      {chatOptions.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ChatTypeDropdownComponent
