import loglevel from 'loglevel'

const logger = loglevel.getLogger('BufferPlayer')

/**
 * The lowest pitch frequency audible by human
 * wiki: https://en.wikipedia.org/wiki/Hearing_range
 */
const LOWEST_PITCH_FREQUENCY_HZ = 20

class BufferPlayer {
  public playbackState: 'playing' | 'paused' | 'ended' | undefined

  private loadingState:
    | 'fetchingData'
    | 'completed'
    | 'loadingFailed'
    | undefined

  private audioContext: AudioContext

  private audioBuffer: AudioBuffer | null

  private sourceNode: AudioBufferSourceNode | undefined

  private audioAnalyzer: AnalyserNode

  private gainNode: GainNode

  private startTime: number

  private playTime: number

  public url: string | undefined

  public onended: undefined | ((...args: unknown[]) => void)

  public onpause: undefined | ((...args: unknown[]) => void)

  public onplay: undefined | ((...args: unknown[]) => void)

  public onloaded: undefined | ((...args: unknown[]) => void)

  static get minAudioPitchFreq() {
    return LOWEST_PITCH_FREQUENCY_HZ
  }

  static get maxAudioFreq() {
    return 256
  }

  constructor(audioContext: AudioContext) {
    this.audioContext = audioContext
    this.startTime = 0
    this.playTime = 0
    this.audioBuffer = null
    this.audioAnalyzer = this.audioContext.createAnalyser()
    this.audioAnalyzer.fftSize = 256
    this.gainNode = this.audioContext.createGain()
    this.gainNode.gain.value = 2.0
  }

  private disconnectNodes() {
    this.sourceNode?.disconnect()
  }

  private updateSourceNode() {
    this.disconnectNodes()

    if (!this.audioBuffer) {
      logger.warn('Unexpected undefined audio buffer')

      return
    }

    this.sourceNode = this.audioContext.createBufferSource()
    this.sourceNode.buffer = this.audioBuffer

    this.gainNode.connect(this.audioAnalyzer)
    this.sourceNode.connect(this.gainNode)
    this.audioAnalyzer.connect(this.audioContext.destination)

    this.sourceNode.onended = () => {
      if (this.playbackState === 'paused') {
        logger.warn('paused')
        return // pass
      }

      this.playbackState = 'ended'
      this.startTime = 0
      this.playTime = 0

      if (this.onended) {
        this.onended()
      }
    }
  }

  /**
   * Gets the current pitch level.
   * @param {number} bands - the number of bands to map in the audio.
   */
  getSpeechLevel(bands: number) {
    const analyser = this.audioAnalyzer

    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    analyser.getByteFrequencyData(dataArray)

    const freqs: [min: number, max: number, avg: number][] = new Array(bands)
    const sigsPerBand = Math.floor(bufferLength / bands)

    for (let i = 0; i < bufferLength; i += 1) {
      const bandId = Math.floor(i / sigsPerBand)

      if (bandId === bands) {
        freqs[bandId - 1][2] = Math.floor(freqs[bandId - 1][2] / sigsPerBand)
        break
      }

      if (freqs[bandId] === undefined) {
        freqs[bandId] = [BufferPlayer.maxAudioFreq, 0, 0]
      }

      const currentFreq = freqs[bandId]
      const sig = dataArray[i]

      if (sig < currentFreq[0]) {
        currentFreq[0] = sig
      }
      if (sig > currentFreq[1]) {
        currentFreq[1] = sig
      }

      currentFreq[2] += sig

      if (i >= sigsPerBand && i % sigsPerBand === 0) {
        freqs[bandId - 1][2] = Math.floor(freqs[bandId - 1][2] / sigsPerBand)
      }
    }

    return freqs
  }

  /**
   * Loads an audio from the given url.
   * @param{string} url - The audio url to load.
   */
  async loadAudio(url: string, type = 'audio/wav') {
    this.url = url
    this.loadingState = 'fetchingData'
    this.audioBuffer = null

    try {
      const response = await fetch(url, { cache: 'force-cache' })
      const audioData = await response.arrayBuffer()
      logger.log('response loaded')
      this.pause()
      this.audioBuffer = await this.audioContext.decodeAudioData(audioData)
    } catch (error) {
      this.loadingState = 'loadingFailed'
      logger.error('Error loading audio:', error)
      throw error
    }

    this.loadingState = 'completed'
    this.playTime = 0
    this.startTime = 0

    if (this.onloaded) {
      this.onloaded()
      this.onloaded = undefined
    }
  }

  /**
   * Starts playing the loaded audio.
   */
  play() {
    if (this.loadingState === undefined) {
      logger.error('Failed to start the audio, you should load the audio first')
      return
    }

    if (this.loadingState === 'loadingFailed') {
      logger.log(
        'The audio failed to load. We may retry by calling the load method with the saved url'
      )
      return
    }

    if (this.loadingState === 'fetchingData') {
      this.onloaded = this.play
      return
    }

    if (this.playbackState === 'playing') {
      logger.log('Audio is already playing')
      return
    }

    if (this.loadingState === 'completed' || this.playbackState === 'paused') {
      // Update the sourceNode before starting the audio.
      this.updateSourceNode()

      this.sourceNode?.start(0, this.playTime)

      this.startTime = this.audioContext.currentTime
      this.playbackState = 'playing'

      if (this.onplay) {
        this.onplay()
      }

      logger.info('updateThePlaybackState')
    }
  }

  /**
   * Stop the current audio.
   */
  pause() {
    if (this.playbackState === 'playing') {
      this.playbackState = 'paused'

      this.sourceNode?.stop()
      this.playTime += this.audioContext.currentTime - this.startTime

      if (this.onpause) {
        this.onpause()
      }
    }
  }
}

export default BufferPlayer
