import React, { ImgHTMLAttributes, useState } from 'react'
import imgPlaceholder from 'assets/images/img-placeholder.png'

const ImageLoaderComponent = (props: ImgHTMLAttributes<any>) => {
  const [didImageLoad, setDidImageLoad] = useState<boolean>(false)

  const { alt } = props

  const finalImage = (
    <img {...props} alt={alt || 'pl'} onLoad={() => setDidImageLoad(true)} />
  )

  const placeholder = <img {...props} alt={alt || 'pl'} src={imgPlaceholder} />

  return <>{didImageLoad ? finalImage : placeholder}</>
}
export default ImageLoaderComponent
