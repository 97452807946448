import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import {
  updateCurrentBotVersionAction,
  updateTempConfigOptionsAction
} from 'datastore/slices/v2-program-controller'

const BotVersionDropdownComponent: React.FC = () => {
  const [tempSelectedVersion, setTempSelectedVersion] = useState('')

  const currentBotVersion = useStoreSelector(
    (storeSelector) => storeSelector.v2ProgramController.currentBotVersion
  )

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  const dispatch = useStoreDispatch()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTempSelectedVersion(event.target.value)
    dispatch(
      updateTempConfigOptionsAction({ tempBotVersion: event.target.value })
    )
  }

  useEffect(() => {
    setTempSelectedVersion(currentBotVersion)
  }, [isMobileMenuOpen])

  return (
    <Box sx={{ marginBottom: 1.2 }}>
      <Typography
        variant="caption"
        component="legend"
        sx={{ color: 'black', marginBottom: -1.5, fontSize: '1.2rem' }}>
        Bot Version
      </Typography>
      <Select
        value={tempSelectedVersion}
        onChange={handleChange}
        className="bot-version-dropdown"
        sx={{
          width: '100%',
          height: '30px',
          fontSize: '0.7rem'
        }}>
        {['2.0', '2.1'].map((version) => (
          <MenuItem
            key={version}
            value={version}
            sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
            {version}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default BotVersionDropdownComponent
