import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import React, {
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import logger from 'loglevel'
import { useFirstMountState, useMountedState } from 'react-use'
import { ConvoActionsInterface, V2ProgramItemInterface } from 'types'
import programApi from 'datastore/apis/program-api'
import { updateCurrentConvoThreadIDAction } from 'datastore/slices/v2-program-controller'
import utilFuncs from 'datastore/utils/functions'
import { AudioRecorderType } from 'components/molecules/media/audio-recorder'
import BufferPlayer from 'components/molecules/media/audio-players/buffer-player'
import { didBackendAPIRaiseException } from 'datastore/slices/app-controller'
import { useGetUser } from './use-authentication'

export type SessionInteractionStatus =
  | 'playing'
  | 'pausing'
  | 'processing'
  | 'initializing'
  | 'recording'
  | 'ready_to_record'

export interface SessionInteractionHookResult {
  sessionStatus: SessionInteractionStatus
  audioPlayer: BufferPlayer
  isProgramComplete: boolean
  onRecordClick: () => void
}

function useResumeInteraction() {
  const dispatch = useStoreDispatch()

  const isFirstMount = useFirstMountState()

  const currentProgram = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentProgram
  )

  const currentActiveLLM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveLLM
  )

  const currentActiveASR = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveASR
  )

  const currentActiveTTS = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveTTS
  )

  const confidenceScoreTreshold = useStoreSelector(
    (storeState) => storeState.v2ProgramController.confidenceScoreTreshold
  )

  const currentBotVersion = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentBotVersion
  )

  const currentActiveCCM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveCCM
  )

  const user = useGetUser()

  const [isLoading, setIsLoading] = useState(false)
  const [resumeActions, setActions] = useState<ConvoActionsInterface[]>()

  useEffect(() => {
    if (isFirstMount) {
      ;(async () => {
        try {
          setIsLoading(true)
          const results = await programApi.getThreadResumeActions(
            user.uid,
            (currentProgram as V2ProgramItemInterface).botID,
            'audio',
            'audio',
            currentActiveLLM,
            currentActiveASR,
            currentActiveTTS,
            confidenceScoreTreshold,
            currentActiveCCM,
            currentBotVersion
          )

          const { actions } = results.data
          if (actions[actions.length - 1].name !== 'listen') {
            actions.push({
              name: 'listen',
              contentType: 'audioBase64',
              content: ''
            })
          }

          dispatch(updateCurrentConvoThreadIDAction(results.data.threadID))
          setActions(results.data.actions)
          setIsLoading(false)
        } catch (error) {
          dispatch(
            didBackendAPIRaiseException({
              message: 'Encountered error in InteractActions',
              error: JSON.stringify(error)
            })
          )
          setIsLoading(false)
        }
      })()
    }
  }, [isFirstMount])

  return { isLoading, resumeActions }
}

function useInteract() {
  const currentActiveLLM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveLLM
  )

  const currentActiveASR = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveASR
  )

  const confidenceScoreTreshold = useStoreSelector(
    (storeState) => storeState.v2ProgramController.confidenceScoreTreshold
  )

  const currentActiveTTS = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveTTS
  )

  const currentConvoThreadID = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentConvoThreadID
  )

  const currentActiveCCM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveCCM
  )

  const currentBotVersion = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentBotVersion
  )

  const [isLoading, setIsLoading] = useState(false)
  const [interactActions, setInteractionActions] =
    useState<ConvoActionsInterface[]>()
  const dispatch = useStoreDispatch()

  const postUtterance = useCallback(
    async (audioUrl) => {
      const userUtteranceBlob = await fetch(audioUrl).then((res) => res.blob())

      const formData = new FormData()
      formData.append('userUtterance', userUtteranceBlob, 'userUtterance.mp3')

      try {
        setIsLoading(true)
        const results = await programApi.getThreadInteractActionsWithFormData(
          currentConvoThreadID,
          'audio',
          'audio',
          formData,
          currentActiveLLM,
          currentActiveASR,
          currentActiveTTS,
          confidenceScoreTreshold,
          currentActiveCCM,
          currentBotVersion
        )
        setIsLoading(false)

        const { actions } = results.data
        if (actions[actions.length - 1].name !== 'listen') {
          actions.push({
            name: 'listen',
            contentType: 'audioBase64',
            content: ''
          })
        }

        setInteractionActions(actions)
      } catch (error) {
        dispatch(
          didBackendAPIRaiseException({
            message: 'Encountered error in InteractActions',
            error: JSON.stringify(error)
          })
        )
        setIsLoading(false)
      }
    },
    [
      currentActiveLLM,
      currentActiveASR,
      currentActiveTTS,
      confidenceScoreTreshold
    ]
  )

  return { isLoading, postUtterance, interactActions }
}

function useGetListOfActions() {
  const { isLoading: isResumeInteractionLoading, resumeActions } =
    useResumeInteraction()
  const {
    isLoading: isInteractionLoading,
    postUtterance,
    interactActions
  } = useInteract()

  return {
    isResumeInteractionLoading,
    isInteractionLoading,
    postUtterance,
    actions: interactActions || resumeActions || []
  }
}

const audioPlayer = utilFuncs.updateGlobalAudio(null)
const recordUser = async (
  setSessionStatus: Dispatch<SetStateAction<SessionInteractionStatus>>
) => {
  utilFuncs.playSoundEffect('start')
  setSessionStatus('recording')
  const audioRecorder = await utilFuncs.getGlobalRecorder(
    AudioRecorderType.default
  )
  audioRecorder.startRecording()
}

const submitUserRecord = async (postUtterance: (str: string) => unknown) => {
  const audioRecorder = await utilFuncs.getGlobalRecorder(
    AudioRecorderType.default
  )
  utilFuncs.playSoundEffect('stop')
  await audioRecorder.stopRecording()
  postUtterance(audioRecorder.audioUrl)
}

const goToNextAction = async (
  action: ConvoActionsInterface,
  setActionID: Dispatch<SetStateAction<number>>,
  setSessionStatus: Dispatch<SetStateAction<SessionInteractionStatus>>
) => {
  if (action?.name === 'listen') {
    setSessionStatus('ready_to_record')
  } else if (action?.name === 'speak') {
    if (action?.userIsCorrect === true) {
      utilFuncs.playSoundEffect('pass')
    } else if (action?.userIsCorrect === false) {
      utilFuncs.playSoundEffect('fail')
    }
    const audioBlob = new Blob(
      [Uint8Array.from(atob(action.content), (c) => c.charCodeAt(0))],
      { type: 'audio/wav' }
    )
    const audioUrl = URL.createObjectURL(audioBlob)
    await audioPlayer.loadAudio(audioUrl)
    audioPlayer.play()
    setSessionStatus('playing')
    setActionID((prev) => prev + 1)
  } else {
    logger.error('Invalid state found')
  }
}

/**
 * Interacts with the current session.
 * @param {React.RefObject<HTMLButtonElement>} recorder.
 * @return {SessionInteractionHookResult}
 */
export function useSessionInteraction(): SessionInteractionHookResult {
  const mounted = useMountedState()

  const {
    isResumeInteractionLoading,
    isInteractionLoading,
    postUtterance,
    actions
  } = useGetListOfActions()

  const [sessionStatus, setSessionStatus] =
    useState<SessionInteractionStatus>('initializing')

  const [actionID, setActionID] = useState(-1)

  useEffect(() => {
    if (actions.length > 0) {
      setActionID(0)
      goToNextAction(actions[0], setActionID, setSessionStatus)
    } else {
      setActionID(-1)
    }
    logger.warn('action list changed', actions)
  }, [actions])

  const onRecordClick = useCallback(() => {
    console.warn('Record button click')
    if (sessionStatus === 'ready_to_record') {
      recordUser(setSessionStatus)
    } else if (sessionStatus === 'recording') {
      submitUserRecord(postUtterance)
    }
  }, [setSessionStatus, postUtterance, sessionStatus])

  // Audio player refs
  audioPlayer.onpause = useCallback(() => {
    if (sessionStatus === 'playing') {
      setSessionStatus('pausing')
    }
  }, [sessionStatus])
  audioPlayer.onplay = useCallback(() => {
    if (sessionStatus === 'pausing') {
      setSessionStatus('playing')
    }
  }, [sessionStatus])
  audioPlayer.onended = useCallback(() => {
    if (mounted()) {
      goToNextAction(actions[actionID], setActionID, setSessionStatus)
    }
  }, [sessionStatus, mounted, actions, actionID])

  useEffect(() => {
    if (isResumeInteractionLoading) {
      setSessionStatus('initializing')
    } else if (isInteractionLoading) {
      setSessionStatus('processing')
    }
  }, [isResumeInteractionLoading, isInteractionLoading])

  useEffect(() => {
    audioPlayer.onplay = () => null
    return () => {
      audioPlayer.pause()
      audioPlayer.onplay = audioPlayer.pause
    }
  }, [])

  return {
    sessionStatus,
    audioPlayer,
    isProgramComplete: false,
    onRecordClick
  }
}
