import { createSlice } from '@reduxjs/toolkit'
import {
  AMAZETHU_V2_LIVE,
  AMAZETHU_V2_TESTBED,
  AUDIO_IN_TEXT_OUT,
  IS_PROD_ENV,
  PAGE_USER_PROGRAM_SELECT
} from 'datastore/utils/constants'
import { PopupInterface, PopupTypeEnum } from 'types'
import { Console } from 'logger'
import log from 'loglevel'

interface TestbedConfigModelsInterface {
  llmModel: []
  ttsModel: []
  ccmModel: []
  asrModel: []
}

interface AppControllerStateInterface {
  currPage: string
  currPopup: PopupInterface
  micOn: boolean
  isInternalDev: boolean
  runtimeAppEnv: string
  currInteractionType: string
  tempCurrInteractionType: string
  safeInteractionRoute: boolean
  isMobileMenuOpen: boolean
  isUserOnDesktop: boolean
  backendAPIRaisedException: {
    message: string
    error: string
  } | null
  hasUpdatedTestbedModels: boolean
  testbedConfigModels: TestbedConfigModelsInterface
}

// Define the initial state using that type
const initialState: AppControllerStateInterface = {
  currPage: PAGE_USER_PROGRAM_SELECT,
  currPopup: { type: PopupTypeEnum.none },
  micOn: false,
  isInternalDev: false,
  runtimeAppEnv: AMAZETHU_V2_LIVE,
  currInteractionType: AUDIO_IN_TEXT_OUT,
  tempCurrInteractionType: AUDIO_IN_TEXT_OUT,
  safeInteractionRoute: false,
  isMobileMenuOpen: false,
  isUserOnDesktop: true,
  backendAPIRaisedException: null,
  hasUpdatedTestbedModels: false,
  testbedConfigModels: {
    llmModel: [],
    ttsModel: [],
    asrModel: [],
    ccmModel: []
  }
}

export const appController = createSlice({
  name: 'app-controller',
  initialState,
  reducers: {
    setCurrentPageAction: (state, action) => {
      if (state.currPage !== action.payload) {
        log.info('CHANGE PAGE!')
        log.info(state.currPage)
        log.info(action.payload)
        return {
          ...state,
          currPage: action.payload
        }
      }

      return {
        ...state
      }
    },
    setCurrentPopupAction: (state, action) => {
      if (state.currPopup !== action.payload) {
        log.info('CHANGE POPUP!')
        log.info(state.currPopup)
        log.info(action.payload)
        return {
          ...state,
          currPopup: action.payload
        }
      }

      return {
        ...state
      }
    },
    setMicOnAction: (state, action) => {
      log.info('SET MIC STATE!')
      log.info(action.payload)
      return {
        ...state,
        micOn: action.payload
      }
    },
    setIsInternalDev: (state, action) => {
      return {
        ...state,
        isInternalDev: action.payload
      }
    },
    setCurrentVersionAction: (state, action) => {
      log.info('CHANGE CURRENT VERSION!')
      log.info(action.payload)
      return {
        ...state,
        runtimeAppEnv: action.payload
      }
    },
    setCurrentInteractionTypeAction: (state, action) => {
      log.info('CHANGE CURRENT INTERACTION TYPE!')
      log.info(action.payload)
      return {
        ...state,
        currInteractionType: action.payload
      }
    },
    updateSafeInteractionRoute: (state, action) => {
      return {
        ...state,
        safeInteractionRoute: action.payload
      }
    },
    updateIsMobileMenuOpen: (state, action) => {
      return {
        ...state,
        isMobileMenuOpen: action.payload
      }
    },
    didBackendAPIRaiseException: (state, action) => {
      return {
        ...state,
        backendAPIRaisedException: action.payload
      }
    },
    updateInteractionConfigOptionsAction: (state) => {
      return {
        ...state,
        currInteractionType: state.tempCurrInteractionType
      }
    },
    updateTempCurrInteractionTypeAction: (state, action) => {
      return {
        ...state,
        tempCurrInteractionType: action.payload
      }
    },
    updateHasUpdatedTestbedModels: (state, action) => {
      return {
        ...state,
        hasUpdatedTestbedModels: action.payload
      }
    },
    updateTestbedConfigModels: (state, action) => {
      return {
        ...state,
        testbedConfigModels: action.payload
      }
    },
    resetAppStates: (state) => {
      return {
        ...state,
        ...initialState
      }
    }
  }
})

export const {
  setCurrentPageAction,
  setCurrentPopupAction,
  setMicOnAction,
  setCurrentVersionAction,
  setIsInternalDev,
  setCurrentInteractionTypeAction,
  updateSafeInteractionRoute,
  updateIsMobileMenuOpen,
  didBackendAPIRaiseException,
  updateInteractionConfigOptionsAction,
  updateTempCurrInteractionTypeAction,
  updateHasUpdatedTestbedModels,
  updateTestbedConfigModels,
  resetAppStates
} = appController.actions
export default appController.reducer

export function changePageService(newPage: any) {
  return async (dispatch: any) => {
    log.info('*** changePageService ***')
    try {
      log.info(`******** ${newPage} ********`)
      dispatch(setCurrentPageAction(newPage))
    } catch (error) {
      log.info(error)
    }
  }
}

export function showPopupService(popup: PopupInterface) {
  return async (dispatch: any) => {
    log.info('*** showPopupService ***')
    try {
      log.info(`******** ${JSON.stringify(popup)} ********`)

      Console.assert(
        popup.type != null,
        `AssertError: all popups are required to have a label key`
      )
      if (popup.type === PopupTypeEnum.notification) {
        Console.assert(
          popup.data !== null &&
            popup.data.title !== null &&
            popup.data.message !== null,
          `AssertError: notification popup data is required along with attributes for title and message`
        )
      }

      dispatch(setCurrentPopupAction(popup))
    } catch (error) {
      log.info(error)
    }
  }
}
