import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { theme } from 'components/atoms/styles/theme'
import TextInputComponent from 'components/atoms/inputs/text-input'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'
import TextButtonComponent from 'components/atoms/buttons/text-button'
import styles from 'components/molecules/popups/popup.styles'

import {
  createProgramService,
  setCurrentProgramService
} from 'datastore/slices/program-controller'
import {
  createBlockService,
  deleteBlockService
} from 'datastore/slices/block-controller'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import log from 'loglevel'
import { Alert } from 'alert'
import { PopupTypeEnum } from 'types'
import FeedbackComponent from './popup-feedback'

interface PropsInterface {
  type?: string
  data?: any
  onClose?: (action: unknown) => void
}

const defaultProps = {
  type: PopupTypeEnum.none,
  data: null,
  onClose: (result?: unknown) => {
    log.info(`*** Popup::onClose::${result} ***`)
  }
}

const CreateProgramFormComponent = (customProps: any) => {
  const { dispatch, onClose } = customProps
  const [programName, setProgramName] = React.useState<string>('')
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )

  const onProgramNameChange = (e: any) => {
    setProgramName(e.target.value)
  }

  const handleSubmit = async () => {
    if (!programName) {
      Alert.show('&&&& Program name info is required &&&&')
    } else {
      log.info(' DISPATCHING CREATE PROGRAM ')
      await dispatch(createProgramService(programName, acctProfile?.id))
      onClose()
    }
  }

  return (
    <>
      <Stack spacing={2} sx={styles.textInputContainer}>
        <Box sx={styles.textInput}>
          <TextInputComponent
            label=""
            width="100%"
            value={programName}
            placeholder="Program Name"
            onValueChange={onProgramNameChange}
          />
        </Box>
        <Box sx={styles.buttonsContainer}>
          <TextButtonComponent label="Cancel" onButtonClick={onClose} />
          <DefaultButtonComponent label="Create" onButtonClick={handleSubmit} />
        </Box>
      </Stack>
    </>
  )
}

const CreateBlockFormComponent = (customProps: any) => {
  const { dispatch, onClose } = customProps
  const [blockName, setBlockName] = React.useState<string>('')
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )

  const onBlockNameChange = (e: any) => {
    setBlockName(e.target.value)
  }

  const handleSubmit = async () => {
    if (!blockName) {
      Alert.show('&&&& Block name info is required &&&&')
    } else {
      log.info(' DISPATCHING CREATE BLOCK ')
      await dispatch(
        createBlockService(
          blockName,
          'lesson',
          currentProgram.id,
          acctProfile?.id
        )
      )
      onClose()
    }
  }

  return (
    <>
      <Stack spacing={2} sx={styles.textInputContainer}>
        <Box sx={styles.textInput}>
          <TextInputComponent
            label=""
            width="100%"
            value={blockName}
            placeholder="Block Name"
            onValueChange={onBlockNameChange}
          />
        </Box>
        <Box sx={styles.buttonsContainer}>
          <TextButtonComponent label="Cancel" onButtonClick={onClose} />
          <DefaultButtonComponent label="Create" onButtonClick={handleSubmit} />
        </Box>
      </Stack>
    </>
  )
}

const DeleteBlockFormComponent = (customProps: any) => {
  const { dispatch, onClose } = customProps
  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )
  const warnMessage = 'Are you sure you want to delete selected block?'

  const handleSubmit = async () => {
    log.info(' DISPATCHING DELETE BLOCK ')

    await dispatch(deleteBlockService(currentBlock.id, acctProfile?.id))
    await dispatch(setCurrentProgramService(currentProgram.id, acctProfile?.id))
    onClose()
  }

  return (
    <>
      <Stack spacing={2} sx={styles.textInputContainer}>
        <Box sx={styles.textInput}>
          <Typography variant="h4" color={theme.palette.neutral_dark.dark}>
            {warnMessage}
          </Typography>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <TextButtonComponent label="No" onButtonClick={onClose} />
          <DefaultButtonComponent label="Yes" onButtonClick={handleSubmit} />
        </Box>
      </Stack>
    </>
  )
}

const NotificationFormComponent = (customProps: any) => {
  const { data, onClose } = customProps
  /*
  const warnMessage =
    'You cannot preview the block without saving or publishing'
  */

  const actions = data?.actions || ['OK']
  return (
    <>
      <Stack spacing={2} sx={styles.textInputContainer}>
        <Box sx={styles.textInput}>
          <Typography variant="h4" color={theme.palette.neutral_dark.dark}>
            {data.message}
          </Typography>
        </Box>
        <Box sx={styles.buttonsContainer}>
          {actions.length === 2 && (
            <TextButtonComponent
              label={actions[1]}
              onButtonClick={() => onClose(actions[1])}
            />
          )}
          <DefaultButtonComponent
            label={actions[0]}
            onButtonClick={() => onClose(actions[0])}
          />
        </Box>
      </Stack>
    </>
  )
}

const PopupComponent: React.FunctionComponent<PropsInterface> = ({
  type = defaultProps.type,
  data = defaultProps.data,
  onClose = defaultProps.onClose
}) => {
  const dispatch = useStoreDispatch()

  let formTitle = ''
  if (type === PopupTypeEnum.createProgram) {
    formTitle = 'Create New Program'
  } else if (type === PopupTypeEnum.createBlock) {
    formTitle = 'Create New Block'
  } else if (type === PopupTypeEnum.deleteBlock) {
    formTitle = 'Delete Block'
  } else if (type === PopupTypeEnum.feedback) {
    formTitle = 'Feedback on Amazethu'
  } else if (type === PopupTypeEnum.notification) {
    formTitle = data.title
  } else {
    formTitle = 'Invalid Form'
  }

  if (type === PopupTypeEnum.none) {
    return <div />
  }

  if (type === PopupTypeEnum.feedback)
    return <FeedbackComponent onClose={onClose} />

  return (
    <Stack sx={styles.stackContainer}>
      <Box sx={styles.titleBox}>
        <Typography
          id="form-title"
          variant="h4"
          color={theme.palette.neutral_dark.dark}>
          {formTitle}
        </Typography>
      </Box>
      {(() => {
        if (type === PopupTypeEnum.createProgram)
          return (
            <CreateProgramFormComponent dispatch={dispatch} onClose={onClose} />
          )
        if (type === PopupTypeEnum.createBlock)
          return (
            <CreateBlockFormComponent dispatch={dispatch} onClose={onClose} />
          )
        if (type === PopupTypeEnum.deleteBlock)
          return (
            <DeleteBlockFormComponent dispatch={dispatch} onClose={onClose} />
          )
        if (type === PopupTypeEnum.notification)
          return (
            <NotificationFormComponent
              dispatch={dispatch}
              data={data}
              onClose={onClose}
            />
          )
        return <div />
      })()}
    </Stack>
  )
}

PopupComponent.defaultProps = defaultProps
export default PopupComponent
