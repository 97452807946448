import React, { createRef, useEffect, useState } from 'react'
import {
  SessionInteractionStatus,
  useSessionInteraction
} from 'hooks/use-session-interaction'
import { useRequestFeedback } from 'hooks/use-request-feedback'
import utilFuncs from '../../../../datastore/utils/functions'
import LoadingProcessingComponent from './user-block-interact-loading-processing'
import SpeechFeedBacksComponent from './user-block-interact-speech-feedback'
import RecordFeedbackComponent from './user-block-interact-record-feedback'

function useShowFeedback(sessionStatus: SessionInteractionStatus) {
  const requestFeedback = useRequestFeedback()
  const [utteranceItteration, setUtteranceIteration] = useState(0)

  useEffect(() => {
    if (sessionStatus === 'processing') {
      setUtteranceIteration((prev) => prev + 1)
    }
  }, [sessionStatus])

  useEffect(() => {
    requestFeedback(utteranceItteration)
  }, [utteranceItteration])

  return null
}

const AudioInAudioOutChatComponent = () => {
  const recButtonRef = createRef<HTMLButtonElement>()

  const { audioPlayer, sessionStatus, isProgramComplete, onRecordClick } =
    useSessionInteraction()

  const showFeedbackResponse = useShowFeedback(sessionStatus)

  return (
    <>
      {(sessionStatus === 'playing' || sessionStatus === 'pausing') && (
        <SpeechFeedBacksComponent
          audioElement={audioPlayer}
          sessionStatus={sessionStatus}
          programComplete={isProgramComplete}
          playPauseButtonDisabled={false}
          onClick={() => {
            if (sessionStatus === 'playing') {
              utilFuncs.playSoundEffect('pause', () => {
                audioPlayer.pause()
              })
            } else {
              utilFuncs.playSoundEffect('play', () => {
                audioPlayer.play()
              })
            }
          }}
        />
      )}
      {(sessionStatus === 'recording' ||
        sessionStatus === 'ready_to_record') && (
        <RecordFeedbackComponent
          sessionStatus={sessionStatus}
          onClick={onRecordClick}
          ref={recButtonRef}
        />
      )}

      {(sessionStatus === 'processing' || sessionStatus === 'initializing') && (
        <LoadingProcessingComponent
          isProcessing={sessionStatus === 'processing'}
        />
      )}
    </>
  )
}

export default AudioInAudioOutChatComponent
