import React, { useState } from 'react'

import Stack from '@mui/material/Stack'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import TextInputComponent from 'components/atoms/inputs/text-input'
import Button from '@mui/material/Button'
import { updateProgramSettingsService } from 'datastore/slices/program-controller'
import { updateBlockSettingsService } from 'datastore/slices/block-controller'
import { BlockSettingsInterface, ProgramSettingsInterface } from 'types'
import log from 'loglevel'

const AdminBlockSettingsComponent = () => {
  const dispatch = useStoreDispatch()
  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )
  const currentBlockSettingsData = useStoreSelector(
    (storeState) => storeState.blockController.currentBlockSettingsData
  )
  const currentProgram = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  )
  const currentProgramSettingsData = useStoreSelector(
    (storeState) => storeState.programController.currentProgramSettingsData
  )
  const [programImageFile, setProgramImageFile] = useState<File>()

  const onBlockNameChange = (event: any) => {
    log.info(
      `*** AdminBlockSettingsComponent::onBlockNameChange: ${event.target.value} ***`
    )

    const updatedBlockData: BlockSettingsInterface = {
      ...currentBlockSettingsData,
      name: event.target.value
    }
    dispatch(updateBlockSettingsService(updatedBlockData))
  }

  const onBlockDescChange = (event: any) => {
    log.info(
      `*** AdminBlockSettingsComponent::onBlockDescChange: ${event.target.value} ***`
    )

    const updatedBlockData: BlockSettingsInterface = {
      ...currentBlockSettingsData,
      desc: event.target.value
    }
    dispatch(updateBlockSettingsService(updatedBlockData))
  }

  const onProgramNameChange = (event: any) => {
    log.info(
      `*** AdminBlockSettingsComponent::onProgramNameChange: ${event.target.value} ***`
    )

    const updatedProgramData: ProgramSettingsInterface = {
      ...currentProgramSettingsData,
      name: event.target.value
    }
    dispatch(updateProgramSettingsService(updatedProgramData))
  }

  const onProgramDescChange = (event: any) => {
    log.info(
      `*** AdminBlockSettingsComponent::onProgramDescChange: ${event.target.value} ***`
    )

    const updatedProgramData: ProgramSettingsInterface = {
      ...currentProgramSettingsData,
      desc: event.target.value
    }
    dispatch(updateProgramSettingsService(updatedProgramData))
  }

  const onProgramPublisherChange = (event: any) => {
    log.info(
      `*** AdminBlockSettingsComponent::onProgramPublisherChange: ${event.target.value} ***`
    )

    const updatedProgramData: ProgramSettingsInterface = {
      ...currentProgramSettingsData,
      publisher: event.target.value
    }
    dispatch(updateProgramSettingsService(updatedProgramData))
  }

  const onProgramImageFileChange = (event: any) => {
    log.info(
      `*** AdminBlockSettingsComponent::onProgramImageFileChange: ${event.target.files} ***`
    )
    setProgramImageFile(event.target.files[0])
  }

  React.useEffect(() => {
    log.info(
      '*** AdminBlockSettingsComponent::re-render due to change to currentBlock'
    )
    log.info('*** AdminBlockSettingsComponent::currentBlock: ', currentBlock)

    if (currentBlock.id) {
      const updatedBlockData: BlockSettingsInterface = {
        name: currentBlock.name,
        desc: currentBlock.desc
      }
      dispatch(updateBlockSettingsService(updatedBlockData))
    }
  }, [currentBlock])

  React.useEffect(() => {
    log.info(
      '*** AdminBlockSettingsComponent::re-render due to change to currentProgram'
    )
    log.info(
      '*** AdminBlockSettingsComponent::currentProgram: ',
      currentProgram
    )

    const updatedProgramData: ProgramSettingsInterface = {
      name: currentProgram.name,
      desc: currentProgram.desc,
      imageUrl: currentProgram.imageUrl,
      publisher: currentProgram.publisher
    }
    dispatch(updateProgramSettingsService(updatedProgramData))
  }, [currentProgram])

  React.useEffect(() => {
    log.info(
      `*** AdminBlockSettingsComponent::re-render due to change to image upload`
    )
    if (programImageFile === File.prototype || programImageFile === undefined) {
      return () => undefined
    }
    const updatedProgramData: ProgramSettingsInterface = {
      ...currentProgramSettingsData,
      imageUrl: URL.createObjectURL(programImageFile)
    }
    dispatch(updateProgramSettingsService(updatedProgramData))

    return () => {
      if (updatedProgramData.imageUrl) {
        URL.revokeObjectURL(updatedProgramData.imageUrl)
      }
    }
  }, [programImageFile])

  return (
    <Stack
      spacing={2}
      p={2}
      sx={{
        width: '30%',
        height: '50%',
        display: 'flex'
      }}>
      <TextInputComponent
        label="Block Name"
        value={currentBlockSettingsData.name}
        onValueChange={onBlockNameChange}
      />
      <TextInputComponent
        label="Block Description"
        value={currentBlockSettingsData.desc}
        onValueChange={onBlockDescChange}
      />
      <TextInputComponent
        label="Program Name"
        value={currentProgramSettingsData.name}
        onValueChange={onProgramNameChange}
      />
      <TextInputComponent
        label="Program Description"
        value={currentProgramSettingsData.desc}
        onValueChange={onProgramDescChange}
      />
      <TextInputComponent
        label="Program Publisher"
        value={currentProgramSettingsData.publisher}
        onValueChange={onProgramPublisherChange}
      />
      <Stack direction="row">
        <img
          height={100}
          src={currentProgramSettingsData.imageUrl ?? '/placeholder.png'}
          alt={currentProgramSettingsData.name}
        />
        <Button
          sx={{ height: '30%', mt: 8, ml: 2 }}
          variant="contained"
          component="label">
          Upload
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={onProgramImageFileChange}
          />
        </Button>
      </Stack>
    </Stack>
  )
}

export default AdminBlockSettingsComponent
