import { Alert, Snackbar, Typography } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'

type MessageType = 'success' | 'error' | 'info' | 'warning'

interface NotifyProps {
  message: string
  type: MessageType
  verticalPosition?: 'top' | 'bottom'
  horizontalPosition?: 'left' | 'right' | 'center'
}

const NotificationSnackbar: React.FC<NotifyProps> = ({
  message,
  type,
  verticalPosition = 'bottom',
  horizontalPosition = 'center'
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition
      }}
      sx={{ position: 'fixed', zIndex: 1500 }}
      open>
      <Alert severity={type} sx={{ width: '100%' }}>
        <Typography
          component="div"
          variant="body2"
          sx={{ whiteSpace: 'pre-line' }}>
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

NotificationSnackbar.defaultProps = {
  verticalPosition: 'top',
  horizontalPosition: 'center'
}

const showNotification = ({
  message,
  type,
  verticalPosition,
  horizontalPosition
}: NotifyProps) => {
  const container = document.createElement('div')
  document.body.appendChild(container)

  ReactDOM.render(
    <NotificationSnackbar
      message={message}
      type={type}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
    />,
    container
  )

  setTimeout(() => {
    document.body.removeChild(container)
  }, 5000)
}

export default showNotification
