import axios from 'axios'

import { store as reduxStore } from 'datastore/config/store'
import { URI_CONVO_THREAD_RESET } from 'datastore/apis/constants'

async function resetConvoThread() {
  const storeState = reduxStore.getState()

  const { currentConvoThreadID, currentBotVersion } =
    storeState.v2ProgramController

  return axios.post<any>(
    `${URI_CONVO_THREAD_RESET}/${currentConvoThreadID}`,
    null,
    {
      headers: { 'FULL-API-VERSION': currentBotVersion }
    }
  )
}

export default { resetConvoThread }
