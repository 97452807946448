import React, { useCallback, useRef, useState } from 'react'
import VolumeUp from '@mui/icons-material/VolumeUp'
import PauseRounded from '@mui/icons-material/PauseRounded'
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded'
import { keyframes } from '@emotion/react'
import { Box } from '@mui/material'
import utilFuncs from 'datastore/utils/functions'
import log from 'loglevel'
import { IS_PROD_ENV, IS_STAG_ENV } from 'datastore/utils/constants'
import programApi from 'datastore/apis/program-api'

const PULSE_FRAMES = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

interface AudioComponentPropsInterface {
  audioUrl: string
  isPlaying: boolean
}

const V2UserAndBotAudioPlayerComponent: React.FC<
  AudioComponentPropsInterface
> = ({ audioUrl, isPlaying }) => {
  const bufferPlayerRef = useRef(utilFuncs.updateGlobalAudio(null))
  const isProcessingPlay = useRef(false)

  const isActive = bufferPlayerRef.current.url === audioUrl

  const togglePlay = useCallback(async () => {
    if (isProcessingPlay.current) {
      return
    }
    isProcessingPlay.current = true
    if (!isActive) {
      try {
        await bufferPlayerRef.current.loadAudio(audioUrl)
      } catch (error) {
        error.stack = error.stack || new Error().stack
        programApi.sendAlertNotificationToWebhook({
          message: 'Encountered error while loading the audio hash',
          error
        })
      }
      bufferPlayerRef.current.play()
    } else if (isPlaying) {
      bufferPlayerRef.current.pause()
    } else {
      bufferPlayerRef.current.play()
    }
    isProcessingPlay.current = false
  }, [isPlaying, audioUrl])

  const useIcon = () => {
    const styling = {
      color: isPlaying && isActive ? 'purple' : 'rgba(0, 0, 0, 0.6)',
      animation: isPlaying && isActive ? `${PULSE_FRAMES} 1s infinite` : 'none',
      fontSize: '25px'
    }

    if (isActive && bufferPlayerRef.current.playbackState === 'playing')
      return <PauseRounded sx={styling} />
    if (isActive && bufferPlayerRef.current.playbackState === 'paused')
      return <PlayArrowRounded sx={styling} />
    return <VolumeUp sx={styling} />
  }

  return (
    <div>
      <Box
        onClick={togglePlay}
        sx={{
          backgroundColor: '#eaeaea',
          borderRadius: '25px',
          height: '30px',
          width: '30px',
          padding: '3px',
          boxShadow: '1px 1px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
        {useIcon()}
      </Box>
    </div>
  )
}

export default V2UserAndBotAudioPlayerComponent
