export enum AudioRecorderType {
  default = 'default',
  vad = 'vad'
}

export abstract class AudioRecorder {
  abstract mediaStream: MediaStream | undefined

  /**
   * Whether the instance is ready to record or not.
   * @default false
   */
  abstract didInitialized: boolean

  /**
   * The url of the recorded audio.
   * @default ""
   */
  abstract audioUrl: string

  /**
   * Initializes the media recorder of this instance.
   */
  abstract initMediaRecorder(): Promise<{ error: boolean }>

  /**
   * Starts to record audio.
   */
  abstract startRecording: (onRecordEnded?: () => void) => Promise<boolean>

  /**
   * Stops recording the audio.
   */
  abstract stopRecording: () => Promise<{ error: boolean }>
}
