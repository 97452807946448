import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

import { theme } from 'components/atoms/styles/theme'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'
import DropDownComponent from 'components/atoms/inputs/drop-down'

import {
  PAGE_ADMIN_PROGRAM_SELECT,
  PAGE_ADMIN_BLOCK_DESIGN,
  PAGE_ADMIN_BLOCK_PREVIEW,
  PAGE_ADMIN_BLOCK_SETTINGS
} from 'datastore/utils/constants'
import log from 'loglevel'
import styles from './admin-arena-header.styles'

interface PropsInterface {
  type: string
  width?: string | number
  height?: string | number
  ddWidth?: string | number
  onCreateProgramSelect?: () => void
  onAddNode?: () => void
  onDelNode?: () => void
  isAddNodeDisabled?: boolean
  isDelNodeDisabled?: boolean
  onChangeData?: (newValue: any) => void
  onSaveBlock?: () => void
  onPublishBlock?: () => void
  onSaveSettings?: () => void
}

const defaultProps = {
  width: '100%',
  height: '60px',
  ddWidth: 216,
  onCreateProgramSelect: () => {
    log.info(`*** ArenaHeader::onCreateProgramSelect ***`)
  },
  onAddNode: () => {
    log.info(`*** ArenaHeader::onAddNode ***`)
  },
  onDelNode: () => {
    log.info(`*** ArenaHeader::onDelNode ***`)
  },
  isAddNodeDisabled: true,
  isDelNodeDisabled: true,
  onChangeData: () => {
    log.info(`*** ArenaHeader::onChangeData ***`)
  },
  onSaveBlock: () => {
    log.info(`*** ArenaHeader::onSaveBlock ***`)
  },
  onPublishBlock: () => {
    log.info(`*** ArenaHeader::onPublishBlock ***`)
  },
  onSaveSettings: () => {
    log.info(`*** ArenaHeader::onSaveSettings ***`)
  }
}

const ddHeaderData = [
  { ddID: 'block_design', ddLabel: 'Design Block' },
  { ddID: 'block_preview', ddLabel: 'Preview Block' },
  { ddID: 'block_settings', ddLabel: 'Settings' }
]

const AdminBlockDesignHeaderComponent = (customProps: any) => {
  const {
    width,
    height,
    ddWidth,
    OnChangeDataFunction,
    onAddNodeFunction,
    onDelNodeFunction,
    isAddNodeDisabled,
    isDelNodeDisabled,
    customOnButtonClick
  } = customProps

  return (
    <Stack
      className="AdminBlockDesignHeader"
      direction="row"
      sx={{
        p: 2,
        width,
        height,
        backgroundColor: theme.palette.neutral_light.light
      }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        <DropDownComponent
          type="regular"
          width={ddWidth}
          label=""
          ddData={ddHeaderData}
          ddSelectID="block_design"
          onDDChange={OnChangeDataFunction}
        />
        <DefaultButtonComponent
          label="Del Option"
          bgColor={theme.palette.error.main}
          onButtonClick={onDelNodeFunction}
          disabled={isDelNodeDisabled}
          width={110}
        />
        <DefaultButtonComponent
          label="Add Option"
          onButtonClick={onAddNodeFunction}
          disabled={isAddNodeDisabled}
          width={110}
        />
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <DefaultButtonComponent
          label="Save"
          onButtonClick={customOnButtonClick}
        />
      </Stack>
    </Stack>
  )
}

const SettingsArenaHeaderComponent = (customProps: any) => {
  const { width, height, ddWidth, OnChangeDataFunction, customOnButtonClick } =
    customProps

  return (
    <Stack
      className="SettingsArenaHeader"
      direction="row"
      sx={{
        p: 2,
        width,
        height,
        backgroundColor: theme.palette.neutral_light.light
      }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        <DropDownComponent
          type="regular"
          width={ddWidth}
          label=""
          ddData={ddHeaderData}
          ddSelectID="block_settings"
          onDDChange={OnChangeDataFunction}
        />
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <DefaultButtonComponent
          label="Save"
          onButtonClick={customOnButtonClick}
        />
      </Stack>
    </Stack>
  )
}

const PreviewArenaHeaderComponent = (customProps: any) => {
  const { width, height, ddWidth, OnChangeDataFunction, customOnButtonClick } =
    customProps

  return (
    <Stack
      className="PreviewArenaHeader"
      direction="row"
      sx={{
        p: 2,
        width,
        height,
        backgroundColor: theme.palette.neutral_light.light
      }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        <DropDownComponent
          type="regular"
          width={ddWidth}
          label=""
          ddData={ddHeaderData}
          ddSelectID="block_preview"
          onDDChange={OnChangeDataFunction}
        />
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <DefaultButtonComponent
          label="Publish"
          onButtonClick={customOnButtonClick}
        />
      </Stack>
    </Stack>
  )
}

const AdminProgramSelectHeaderComponent = (customProps: any) => {
  const { width, height, customOnButtonClick } = customProps

  return (
    <Stack
      className="AdminProgramSelectHeader"
      direction="row"
      sx={{
        width,
        height,
        ...styles.container
      }}>
      <Typography
        variant="h3"
        color={theme.palette.neutral_dark.dark}
        sx={styles.firstColumn}>
        Your Programs
      </Typography>
      <Box sx={styles.secondColumn} />
      <Stack direction="row" spacing={2} sx={styles.thirdColumn}>
        <DefaultButtonComponent
          label="New Program"
          width={styles.programArenaHeaderBtn.width}
          onButtonClick={customOnButtonClick}
        />
      </Stack>
    </Stack>
  )
}

const ArenaHeaderComponent: React.FunctionComponent<PropsInterface> = ({
  type,
  width = defaultProps.width,
  height = defaultProps.height,
  ddWidth = defaultProps.ddWidth,
  onCreateProgramSelect = defaultProps.onCreateProgramSelect,
  onAddNode = defaultProps.onAddNode,
  onDelNode = defaultProps.onDelNode,
  isAddNodeDisabled = defaultProps.isAddNodeDisabled,
  isDelNodeDisabled = defaultProps.isDelNodeDisabled,
  onChangeData = defaultProps.onChangeData,
  onSaveBlock = defaultProps.onSaveBlock,
  onPublishBlock = defaultProps.onPublishBlock,
  onSaveSettings = defaultProps.onSaveSettings
}) => {
  /*
  const dispatch = useStoreDispatch()

  const onChangeData = (newValue: any) => {
    log.info(`*** ArenaHeader-DropDown-onDDChange: ${newValue} ***`)
    const index = ddHeaderData.indexOf(newValue)
    log.info(`*** ArenaHeader-DropDown-currDDIndex: ${index} ***`)

    if (index === 0) dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
    if (index === 1) dispatch(changePageService(PAGE_ADMIN_BLOCK_PREVIEW))
  }
  */

  return (
    <>
      {(() => {
        if (type === PAGE_ADMIN_PROGRAM_SELECT)
          return (
            <AdminProgramSelectHeaderComponent
              width={width}
              height={height}
              customOnButtonClick={onCreateProgramSelect}
            />
          )
        if (type === PAGE_ADMIN_BLOCK_DESIGN)
          return (
            <AdminBlockDesignHeaderComponent
              width={width}
              height={height}
              ddWidth={ddWidth}
              OnChangeDataFunction={onChangeData}
              onAddNodeFunction={onAddNode}
              onDelNodeFunction={onDelNode}
              isAddNodeDisabled={isAddNodeDisabled}
              isDelNodeDisabled={isDelNodeDisabled}
              customOnButtonClick={onSaveBlock}
            />
          )
        if (type === PAGE_ADMIN_BLOCK_PREVIEW)
          return (
            <PreviewArenaHeaderComponent
              width={width}
              height={height}
              ddWidth={ddWidth}
              OnChangeDataFunction={onChangeData}
              customOnButtonClick={onPublishBlock}
            />
          )
        if (type === PAGE_ADMIN_BLOCK_SETTINGS)
          return (
            <SettingsArenaHeaderComponent
              width={width}
              height={height}
              ddWidth={ddWidth}
              OnChangeDataFunction={onChangeData}
              customOnButtonClick={onSaveSettings}
            />
          )

        return <Typography>INVALID ARENA PAGE</Typography>
      })()}
      <Divider
        sx={{ display: type === PAGE_ADMIN_PROGRAM_SELECT ? 'none' : 'flex' }}
      />
    </>
  )
}

ArenaHeaderComponent.defaultProps = defaultProps
export default ArenaHeaderComponent
