import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  updateIsMobileMenuOpen,
  updateTempCurrInteractionTypeAction
} from 'datastore/slices/app-controller'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { BoxProps } from '@mui/system'

import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import LLMDropdownComponent from 'components/molecules/dropdowns/llm-model-dropdown'
import UpdateConfidenceScoreThresholdSilderComponent from 'components/molecules/sliders/confidence-score-threshold'
import { Typography } from '@mui/material'
import ASRDropdownComponent from 'components/molecules/dropdowns/asr-model-dropdown'
import TTSDropdownComponent from 'components/molecules/dropdowns/tts-model-dropdown'
import ChatTypeDropdownComponent from 'components/molecules/dropdowns/chat-type-dropdown'
import CCMDropdownComponent from 'components/molecules/dropdowns/ccm-model-dropdown'
import BotVersionDropdownComponent from 'components/molecules/dropdowns/bot-version-dropdown'
import ASREnforceTargetLangDropdown from 'components/molecules/dropdowns/asr-enforce-target-lang-dropdown'
import { updateTempConfigOptionsAction } from 'datastore/slices/v2-program-controller'
import ReloadBotConfigButtonComponent from './reload-bot-config-button'
import ResetConvoThreadComponent from './reset-convo-thread'

export const MobileMenuComponent = () => {
  const { asrModel, ccmModel, ttsModel, llmModel } = useStoreSelector(
    (storeState) => storeState.appController.testbedConfigModels
  )

  return (
    <Stack height="100%" overflow="auto" padding="20px">
      <Stack sx={{ padding: 2 }}>
        <ResetConvoThreadComponent />
        <ReloadBotConfigButtonComponent />
        <BotVersionDropdownComponent />
        <UpdateConfidenceScoreThresholdSilderComponent />
        <LLMDropdownComponent data={llmModel} />
        <CCMDropdownComponent data={ccmModel} />
        <Box sx={{ marginBottom: 1.2 }}>
          <Typography
            variant="caption"
            component="legend"
            sx={{
              marginBottom: -1.5,
              fontSize: '1.2rem',
              color: 'black',
              fontWeight: '400'
            }}>
            ASR
          </Typography>
          <ASRDropdownComponent data={asrModel} />
        </Box>
        <ASREnforceTargetLangDropdown />
        <Box sx={{ marginBottom: 1.2 }}>
          <Typography
            variant="caption"
            component="legend"
            sx={{
              marginBottom: -1.5,
              fontSize: '1.2rem',
              color: 'black',
              fontWeight: '400'
            }}>
            TTS
          </Typography>
          <TTSDropdownComponent data={ttsModel} />
        </Box>
        <Box sx={{ marginBottom: 1.2 }}>
          <Typography
            variant="caption"
            component="legend"
            sx={{
              marginBottom: -1.5,
              fontSize: '1.2rem',
              color: 'black',
              fontWeight: '400'
            }}>
            CHAT TYPE
          </Typography>
          <ChatTypeDropdownComponent />
        </Box>
      </Stack>
    </Stack>
  )
}

interface CustomBoxPropsInterface extends BoxProps {
  height?: number
  width?: number | string
}

const UserInteractivityMenuComponent: React.FC<CustomBoxPropsInterface> = ({
  height,
  width,
  minWidth,
  maxWidth,
  borderRadius
}: BoxProps) => {
  const myTheme = useTheme()
  const isMobile = useMediaQuery(myTheme.breakpoints.down('md'))
  const dispatch = useStoreDispatch()

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  const currentActiveASR = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveASR
  )

  const currentActiveLLM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveLLM
  )

  const currentActiveTTS = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveTTS
  )

  const currentActiveCCM = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentActiveCCM
  )

  const currentBotVersion = useStoreSelector(
    (storeState) => storeState.v2ProgramController.currentBotVersion
  )

  const asrEnforceTargetLang = useStoreSelector(
    (storeState) => storeState.v2ProgramController.asrEnforceTargetLang
  )

  const currInteractionType = useStoreSelector(
    (storeSelector) => storeSelector.appController.currInteractionType
  )

  const { asrModel, ccmModel, ttsModel, llmModel } = useStoreSelector(
    (storeState) => storeState.appController.testbedConfigModels
  )

  const resetTempConfigOptions = () => {
    dispatch(
      updateTempConfigOptionsAction({
        tempASR: currentActiveASR,
        tempLLM: currentActiveLLM,
        tempTTS: currentActiveTTS,
        tempCCM: currentActiveCCM,
        tempBotVersion: currentBotVersion,
        tempAsrEnforceTargetLang: asrEnforceTargetLang,
        tempChatType: currInteractionType
      })
    )
    dispatch(updateTempCurrInteractionTypeAction(currInteractionType))
  }

  const handleDrawerOpenClose = () => {
    if (isMobileMenuOpen) {
      dispatch(updateIsMobileMenuOpen(false))
    } else {
      resetTempConfigOptions()
      dispatch(updateIsMobileMenuOpen(true))
    }
  }

  return (
    <Box
      sx={{
        bgcolor: '#D8D8D8',
        color: '#323232',
        fontSize: '22px',
        flexDirection: 'column',
        borderRadius: borderRadius || '8px',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        minWidth,
        maxWidth,
        position: 'relative',
        height,
        ':hover': {
          'background-color': '#bababa'
        }
      }}>
      {isMobile ? (
        <Stack
          className="interactivity-hamburger-icon"
          sx={{
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={handleDrawerOpenClose}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </Stack>
      ) : (
        <Stack
          sx={{
            height: '100%',
            justifyContent: 'center'
          }}>
          <ResetConvoThreadComponent />
          <ReloadBotConfigButtonComponent />
          <UpdateConfidenceScoreThresholdSilderComponent />
          <CCMDropdownComponent data={ccmModel} />
          <LLMDropdownComponent data={llmModel} />
          <Box sx={{ marginBottom: 1.2 }}>
            <Typography
              variant="caption"
              component="legend"
              sx={{ marginBottom: -1.5, fontSize: '1.2rem' }}>
              ASR
            </Typography>
            <ASRDropdownComponent data={asrModel} />
          </Box>
          <ASREnforceTargetLangDropdown />
          <Box sx={{ marginBottom: 1.2 }}>
            <Typography
              variant="caption"
              component="legend"
              sx={{ marginBottom: -1.5, fontSize: '1.2rem' }}>
              TTS
            </Typography>
            <TTSDropdownComponent data={ttsModel} />
          </Box>
          <Box sx={{ marginBottom: 1.2 }}>
            <Typography
              variant="caption"
              component="legend"
              sx={{ marginBottom: -1.5, fontSize: '1.2rem' }}>
              CHAT TYPE
            </Typography>
            <ChatTypeDropdownComponent />
          </Box>
        </Stack>
      )}
    </Box>
  )
}

export default UserInteractivityMenuComponent
