import React from 'react'

import Box from '@mui/system/Box'

import { theme } from 'components/atoms/styles/theme'
import AudioPlayerComponent from 'components/molecules/media/audio-players/default'
import ChatDisplayComponent from 'components/molecules/convo-ui/chat-display'

interface PropsInterface {
  // type: 'audio' | 'text'
  // speaker: 'user' | 'bot'
  type: string
  speaker: string
  value?: string
  width?: string | number
  height?: string | number
  containerWidth?: string | number
}

const defaultProps = {
  value: '',
  width: '200px',
  height: '40px',
  containerWidth: 'auto'
}

const ConvoUIBubbleComponent: React.FunctionComponent<PropsInterface> = ({
  type,
  speaker,
  value = defaultProps.value,
  width = defaultProps.width,
  height = defaultProps.height,
  containerWidth = defaultProps.containerWidth
}) => {
  const bubbleWidth = width === 'default' ? defaultProps.width : width

  return (
    <Box
      className="ConvoUIBubble"
      sx={{
        width: containerWidth,
        display: 'flex',
        justifyContent: speaker === 'user' ? 'flex-end' : 'flex-start'
      }}>
      {(() => {
        if (type === 'audio') {
          if (speaker === 'user')
            return (
              <AudioPlayerComponent
                pos="left"
                bgColor={theme.palette.primary.light}
                width={bubbleWidth}
                height={height}
                audioURL={value}
              />
            )

          // assume speaker === 'bot' by default
          return (
            <AudioPlayerComponent
              pos="right"
              bgColor={theme.palette.neutral_light.dark}
              width={bubbleWidth}
              height={height}
              audioURL={value}
            />
          )
        }

        // assume type === 'text' by default
        if (speaker === 'user')
          return (
            <ChatDisplayComponent
              text={value || ''}
              width={bubbleWidth}
              height={height}
            />
          )

        return (
          <ChatDisplayComponent
            text={value || ''}
            width={bubbleWidth}
            height={height}
            bgColor={theme.palette.neutral_light.dark}
          />
        )
      })()}
    </Box>
  )
}

ConvoUIBubbleComponent.defaultProps = defaultProps
export default ConvoUIBubbleComponent
