import { SxProps } from '@mui/material'
import { theme } from 'components/atoms/styles/theme'

function rootContainer(
  width: string | number,
  height: string | number,
  paddingX?: string | number,
  paddingY?: string | number
): SxProps {
  return {
    px: paddingX ?? 2,
    py: paddingY ?? 2,
    width,
    height,
    backgroundColor: theme.palette.neutral_light.light,
    position: 'relative',
    overflow: 'hidden'
  }
}

const logoStyle: SxProps = {
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  }
}

function menuDropDown(ddHide: boolean): SxProps {
  return {
    display: ddHide ? 'none' : 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}

const feedBackHeading: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const feedBackLink: SxProps = {
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.main
  },
  '&:visited': {
    color: theme.palette.neutral_dark.dark
  }
}

const disabledFeedbackLink: SxProps = {
  color: theme.palette.grey[500],
  pointerEvents: 'none',
  cursor: 'default',
  textDecoration: 'none'
}

const switchStyles: SxProps = {
  width: 62,
  height: 34,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 5,
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(28px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#8A2BE2'
        )}" d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#8A2BE2'
      }
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.25
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
    width: 24,
    height: 24,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: -1,
      top: 0,
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        'gray'
      )}" d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3 3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z"/></svg>')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'gray',
    borderRadius: 40 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        'white'
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        'white'
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .Mui-disabled .MuiSwitch-thumb': {
    backgroundColor: 'white'
  }
}

export default {
  logoStyle,
  rootContainer,
  menuDropDown,
  feedBackHeading,
  feedBackLink,
  disabledFeedbackLink,
  switchStyles
}
