import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface PropsInterface {
  data: object | object[]
  buttonName: string
  dialogTitle: string
}

const InfoDialogComponent: React.FC<PropsInterface> = ({
  data,
  buttonName,
  dialogTitle
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClickOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const renderObject = (obj: any, indent = 0) => {
    return Object.keys(obj).map((key) => {
      const value = obj[key]
      const paddingLeft = `${indent * 20}px`

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        return (
          <div key={key}>
            <Typography
              variant="body2"
              component="span"
              color="black"
              sx={{ fontWeight: 'bold', paddingLeft }}>
              {Number(key) || key === '0' ? Number(key) + 1 : key}:
            </Typography>
            {renderObject(value, indent + 1)}
          </div>
        )
      }
      if (Array.isArray(value)) {
        return (
          <div key={key}>
            <Typography
              variant="body2"
              component="span"
              sx={{ fontWeight: 'bold', paddingLeft }}>
              {key}:
            </Typography>
            <Box sx={{ paddingLeft }}>
              {value.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  {typeof item === 'object' ? (
                    renderObject(item, indent + 1)
                  ) : (
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ paddingLeft: `${(indent + 1) * 20}px` }}>
                      - {item}
                    </Typography>
                  )}
                </div>
              ))}
            </Box>
          </div>
        )
      }
      return (
        <div key={key}>
          <Typography
            variant="body2"
            component="div"
            color="black"
            sx={{ paddingLeft }}>
            <span style={{ fontWeight: 'bold', color: 'black' }}>{key}:</span>{' '}
            {value?.toString()}
          </Typography>
        </div>
      )
    })
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ color: '#3d3d3d', borderColor: 'gray' }}>
        {buttonName}
      </Button>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2, fontSize: 15, color: 'black' }}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          {Array.isArray(data)
            ? data.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  {renderObject(item)}
                  {index < data.length - 1 && <hr />}
                </div>
              ))
            : renderObject(data)}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', pr: 1 }}>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default InfoDialogComponent
