import {
  Stack,
  Typography,
  ImageList,
  ImageListItem,
  Container,
  useMediaQuery
} from '@mui/material'
import React, { useState, useEffect } from 'react'

import {
  changePageService,
  didBackendAPIRaiseException,
  setMicOnAction,
  showPopupService,
  updateIsMobileMenuOpen,
  updateSafeInteractionRoute
} from 'datastore/slices/app-controller'
import { setCurrentProgramAction } from 'datastore/slices/v2-program-controller'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { theme } from 'components/atoms/styles/theme'
import { PopupTypeEnum, V2ProgramItemInterface } from 'types'

import programAPI from 'datastore/apis/program-api'
import {
  PAGE_USER_BLOCK_INTERACT,
  NOTIFICATION_TITLE_MIC_DISABLED,
  NOTIFICATION_MSG_MIC_DISABLED,
  IS_PROD_ENV,
  IS_STAG_ENV,
  NOTIFICATION_FEEDBACK_REQUEST_ACTION_CANCEL,
  NOTIFICATION_FEEDBACK_REQUEST_ACTION_MIC_ON
} from 'datastore/utils/constants'
import LoaderComponent from 'components/atoms/loaders/loader-component/loader-component'
import imgPlaceholder from 'assets/images/img-placeholder.png'
import { usePopupChange } from 'hooks/use-popup-change'
import utilFuncs from 'datastore/utils/functions'
import styles from './v2-user-program-select.styles'

const V2UserProgramSelectComponent = () => {
  const dispatch = useStoreDispatch()
  const isMicOn = useStoreSelector(
    (storeState) => storeState.appController.micOn
  )

  const [programs, setPrograms] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeProgram, setActiveProgram] =
    useState<V2ProgramItemInterface | null>(null)
  const goToProgram = async (program?: V2ProgramItemInterface) => {
    setActiveProgram(null)
    if (program || activeProgram) {
      setIsLoading(true)
      dispatch(setCurrentProgramAction(program || activeProgram))
      dispatch(updateSafeInteractionRoute(true))
      await dispatch(changePageService(`${PAGE_USER_BLOCK_INTERACT}`))
      setIsLoading(false)
    }
  }

  const [currentPopup, previousPopup] = usePopupChange()
  useEffect(() => {
    if (
      previousPopup?.data?.title === NOTIFICATION_TITLE_MIC_DISABLED &&
      currentPopup.type === PopupTypeEnum.none
    ) {
      if (
        `${currentPopup.data?.result}` !==
        NOTIFICATION_FEEDBACK_REQUEST_ACTION_MIC_ON
      ) {
        return
      }
      utilFuncs
        .getGlobalRecorder(null)
        .then(async (rec) => rec.didInitialized || rec.initMediaRecorder())
        .then(() => dispatch(setMicOnAction(true)))
        .then(() => goToProgram())
        .catch(() => dispatch(setMicOnAction(false)))
    }
  }, [currentPopup, previousPopup])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const results = await programAPI.fetchV2ProgramList({
          status: 'active',
          owner: false
        })

        setPrograms(results.data)
        setIsLoading(false)
      } catch (error) {
        dispatch(
          didBackendAPIRaiseException({
            message: 'Error fetching programs for ProgramSelect page.',
            error: JSON.stringify(error)
          })
        )
        setIsLoading(false)
      }
    })()
    dispatch(updateIsMobileMenuOpen(false))
  }, [])

  const activePopup = useStoreSelector(
    (storeState) => storeState.appController.currPopup
  )

  const handleBrowserBackButton = (event: PopStateEvent) => {
    if (activePopup.type !== PopupTypeEnum.none) {
      dispatch(
        showPopupService({ type: PopupTypeEnum.none, data: { result: null } })
      )
    } else {
      event.preventDefault()
      window.history.pushState(null, '', window.location.href)
    }
  }

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)

    window.addEventListener('popstate', handleBrowserBackButton)

    return () => {
      window.removeEventListener('popstate', handleBrowserBackButton)
    }
  }, [activePopup])

  const onUserProgramSelect = async (program: V2ProgramItemInterface) => {
    if (isMicOn) {
      goToProgram(program)
    } else {
      setActiveProgram(program)
      dispatch(
        showPopupService({
          type: PopupTypeEnum.notification,
          data: {
            title: NOTIFICATION_TITLE_MIC_DISABLED,
            message: NOTIFICATION_MSG_MIC_DISABLED,
            actions: [
              NOTIFICATION_FEEDBACK_REQUEST_ACTION_MIC_ON,
              NOTIFICATION_FEEDBACK_REQUEST_ACTION_CANCEL
            ]
          }
        })
      )
    }
  }

  const isSmallScreen = useMediaQuery('(max-width: 600px)')
  const isMediumScreen = useMediaQuery('(max-width: 800px)')
  const isLargeScreen = useMediaQuery('(max-width: 1026px)')

  const cols = () => {
    if (isSmallScreen) {
      return 1
    }
    if (isMediumScreen) {
      return 2
    }
    if (isLargeScreen) {
      return 3
    }
    return 4
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        padding: 0,
        overflow: 'hidden'
      }}>
      <Stack direction="column" flex="1" marginBottom="0" overflow="auto">
        {programs.length && !isLoading ? (
          <Typography
            variant="h3"
            color={theme.palette.neutral_dark.dark}
            sx={(styles.fullWidth, styles.marginTop10px)}
            align="center">
            Select a language to start learning!
          </Typography>
        ) : undefined}

        <ImageList
          gap={10}
          cols={cols()}
          sx={{
            display: 'flex',
            justifyContent: isSmallScreen ? 'center' : 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            marginBottom: 1
          }}>
          {programs.map((program) => {
            return (
              <ImageListItem
                sx={styles.imgListItem}
                key={program.name}
                onClick={() => onUserProgramSelect(program)}>
                <div
                  style={{
                    width: '100%',
                    height: '350px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    background: 'white'
                  }}>
                  <img
                    src={program.imageUrl || imgPlaceholder}
                    srcSet={program.imageUrl}
                    alt="pl"
                    loading="lazy"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      objectFit: 'cover',
                      height: '100%'
                    }}
                  />
                </div>
              </ImageListItem>
            )
          })}

          {!programs.length ? (
            <ImageListItem sx={styles.listItem}>
              <Typography
                variant="h4"
                color={theme.palette.neutral_dark.dark}
                sx={styles.fullWidth}
                align="center">
                No programs available!
              </Typography>
            </ImageListItem>
          ) : undefined}
        </ImageList>
      </Stack>
      <LoaderComponent isLoading={isLoading} />
    </Container>
  )
}

export default V2UserProgramSelectComponent
