import { useState, useEffect } from 'react'
import { useFirebaseApp } from 'reactfire'
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue
} from 'firebase/remote-config'
import log from 'loglevel'
import { FeatureFlagsEnum, IS_PROD_ENV } from 'datastore/utils/constants'

const REMOTE_CONFIG_DEFAULT = {
  [FeatureFlagsEnum.playBotUtterance]: !IS_PROD_ENV,
  [FeatureFlagsEnum.disableUserAuthentication]: IS_PROD_ENV,
  [FeatureFlagsEnum.enforceUserAuthenticationAfterXDaysFree]: 1,
  [FeatureFlagsEnum.enforceUserSubscriptionAfterXDaysFree]: 7,
  [FeatureFlagsEnum.disableChromeIOS]: IS_PROD_ENV
}

export function useRemoteConfigFlag<
  T extends keyof typeof REMOTE_CONFIG_DEFAULT
>(key: T): (typeof REMOTE_CONFIG_DEFAULT)[T] {
  const firebaseApp = useFirebaseApp()

  const remoteConfig = getRemoteConfig(firebaseApp)
  remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULT
  remoteConfig.settings.minimumFetchIntervalMillis = 10000

  const defaultValue = getValue(remoteConfig, key)

  const staticValue = REMOTE_CONFIG_DEFAULT[key]
  let initialValue
  if (typeof staticValue === 'number') {
    initialValue = defaultValue.asNumber()
  } else if (typeof staticValue === 'boolean') {
    initialValue = defaultValue.asBoolean()
  } else {
    initialValue = defaultValue.asString()
  }
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await fetchAndActivate(remoteConfig)
        const configValue = getValue(remoteConfig, key)
        if (typeof staticValue === 'number') {
          const newValue = configValue.asNumber()
          setValue(newValue)
        } else if (typeof staticValue === 'string') {
          const newValue = configValue.asString()
          setValue(newValue)
        } else {
          const newValue = configValue.asBoolean()
          setValue(newValue)
        }
      } catch (error) {
        log.error('Failed to fetch remote config:', error)
      }
    }

    fetchConfig()
  }, [key])

  return value as (typeof REMOTE_CONFIG_DEFAULT)[typeof key]
}
