import axios from 'axios'

import {
  URI_BOT_SPEECH_UPLOAD,
  URI_AUDIO_PHRASE_UPLOAD,
  URI_BOT_IMAGE_UPLOAD
} from 'datastore/apis/constants'
import log from 'loglevel'

async function uploadBotSpeech(data: {
  blockID: string
  stateID: string
  botSpeechFile: File
}) {
  log.info('uploadAPIs::uploadBotSpeech::data.blockID: ', data.blockID)
  log.info('uploadAPIs::uploadBotSpeech::data.stateID: ', data.stateID)
  log.info(
    'uploadAPIs::uploadBotSpeech::data.botSpeechFile: ',
    data.botSpeechFile
  )

  const formData = new FormData()
  formData.append('blockID', data.blockID)
  formData.append('stateID', data.stateID)
  formData.append('botSpeech', data.botSpeechFile)
  return axios.post(`${URI_BOT_SPEECH_UPLOAD}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

async function uploadAudioPhrase(data: {
  blockID: string
  optionID: string
  phraseIndex: string
  audioPhraseFile: any
}) {
  log.info('uploadAPIs::uploadAudioPhrase::data.blockID: ', data.blockID)
  log.info('uploadAPIs::uploadAudioPhrase::data.optionID: ', data.optionID)
  log.info(
    'uploadAPIs::uploadAudioPhrase::data.phraseIndex: ',
    data.phraseIndex
  )
  log.info(
    'uploadAPIs::uploadAudioPhrase::data.audioPhraseFile: ',
    data.audioPhraseFile
  )

  const formData = new FormData()
  formData.append('blockID', data.blockID)
  formData.append('optionID', data.optionID)
  formData.append('phraseIndex', data.phraseIndex)
  formData.append('audioPhrase', data.audioPhraseFile)
  return axios.post(`${URI_AUDIO_PHRASE_UPLOAD}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

async function uploadProgramImage(data: {
  programImageFile: File
  programId: string
}) {
  const formData = new FormData()
  formData.append('programID', data.programId)
  formData.append('programImage', data.programImageFile)
  return axios.post(`${URI_BOT_IMAGE_UPLOAD}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export default { uploadBotSpeech, uploadAudioPhrase, uploadProgramImage }
