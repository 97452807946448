import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistCombineReducers
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appControllerReducer from 'datastore/slices/app-controller'
import authControllerReducer from 'datastore/slices/auth-controller'
import programControllerReducer from 'datastore/slices/program-controller'
import v2ProgramControllerReducer from 'datastore/slices/v2-program-controller'
import arenaPanelControllerReducer from 'datastore/slices/arena-panel-controller'
import botTreeControllerReducer from 'datastore/slices/bot-tree-controller'
import blockControllerReducer from 'datastore/slices/block-controller'
import convoUIControllerReducer from 'datastore/slices/convo-ui-controller'

const persistConfig = {
  key: 'amazethu',
  version: 1,
  storage
}

/*
const appReducer = combineReducers({
  appController: appControllerReducer,
  authController: authControllerReducer,
  programController: programControllerReducer,
  arenaPanelController: arenaPanelControllerReducer,
  botTreeController: botTreeControllerReducer
  // convoController: convoControllerReducer
})

// const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
const rootReducer = (state: any, action: any) => {
  if (action.type === 'botTreeController/clearSelectedNodeIDAction') {
    Console.log('**** store::rootReducer::botTreeController/clearSelectedNodeIDAction ****')

    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root')

    state = undefined
  }
  return appReducer(state, action)
}
*/

const persistedRootReducer = persistCombineReducers(persistConfig, {
  appController: appControllerReducer,
  authController: authControllerReducer,
  programController: programControllerReducer,
  v2ProgramController: v2ProgramControllerReducer,
  arenaPanelController: arenaPanelControllerReducer,
  botTreeController: botTreeControllerReducer,
  blockController: blockControllerReducer,
  convoUIController: convoUIControllerReducer
})

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)
export type StoreState = ReturnType<typeof store.getState>
export type StoreDispatch = typeof store.dispatch
