import React from 'react'

import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'

import { theme } from 'components/atoms/styles/theme'
import log from 'loglevel'

interface PropsInterface {
  label: string
  width?: string | number
  height?: string | number
  bgColor?: string
  textColor?: string
  hoverColor?: string
  onButtonClick?: () => void
}

const defaultProps = {
  width: '102px',
  height: '36px',
  bgColor: theme.palette.neutral_light.light,
  textColor: theme.palette.neutral_dark.dark,
  hoverColor: theme.palette.neutral_light.dark,
  onButtonClick: () => {
    log.info('*** onButtonClick ***')
  }
}

const TextButtonComponent: React.FunctionComponent<PropsInterface> = ({
  label,
  width = defaultProps.width,
  height = defaultProps.height,
  bgColor = defaultProps.bgColor,
  textColor = defaultProps.textColor,
  hoverColor = defaultProps.hoverColor,
  onButtonClick = defaultProps.onButtonClick
}) => {
  return (
    <Button
      variant="text"
      onClick={onButtonClick}
      disableElevation
      disableRipple
      sx={{
        textTransform: 'none',
        width,
        height,
        bgcolor: bgColor,
        '&:hover': {
          bgcolor: hoverColor
        }
      }}>
      <Typography variant="h4" color={textColor}>
        {label}
      </Typography>
    </Button>
  )
}

TextButtonComponent.defaultProps = defaultProps
export default TextButtonComponent
