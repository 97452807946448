import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { theme } from 'components/atoms/styles/theme'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListIcon from '@mui/icons-material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import { ProgramItemInterface } from 'types'
import { useStoreSelector, useStoreDispatch } from 'datastore/config/hooks'
import TextInputComponent from 'components/atoms/inputs/text-input'
import { changePageService } from 'datastore/slices/app-controller'
import {
  setCurrentProgramService,
  updateProgramNameService,
  updateProgramStatusService
} from 'datastore/slices/program-controller'
import { PAGE_ADMIN_BLOCK_DESIGN } from 'datastore/utils/constants'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'
import { Box } from '@mui/material'
import { Alert } from 'alert'
import styles from './admin-program-select.styles'

interface EditProgramItemInterface {
  rename?: string
  editMode?: boolean
  popUpOpen?: boolean
  popUpAnchor?: HTMLElement
  isActive?: boolean
}
interface EditProgramInterface {
  [key: string]: EditProgramItemInterface
}

const AdminProgramSelectComponent = () => {
  const dispatch = useStoreDispatch()
  const tableData: ProgramItemInterface[] = useStoreSelector(
    (storeState) => storeState.programController.programList
  )
  const acctProfile = useStoreSelector(
    (storeState) => storeState.authController.acctProfile
  )
  const [editProgram, setEditProgram] = useState<EditProgramInterface>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const selectedProgram: EditProgramInterface = {}
    tableData.forEach((programItem) => {
      selectedProgram[programItem.id] = {
        rename: programItem.name,
        popUpOpen: false,
        isActive: programItem.status === 'active'
      }
    })
    setEditProgram(selectedProgram)
  }, [tableData])

  const updateSelectedProgram = (
    selectedProgram: EditProgramItemInterface,
    id: string
  ) => {
    setEditProgram((oldValue) => {
      const clone = { ...oldValue }
      if (clone[id]) {
        const keys = Object.keys(selectedProgram)
        keys.forEach((key) => {
          ;(clone[id] as any)[key] = (selectedProgram as any)[key]
        })
      }
      return clone
    })
  }

  const onProgramNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    updateSelectedProgram({ rename: e.target.value }, id)
  }

  const onProgramSelected = (program: ProgramItemInterface) => {
    if (!editProgram[program.id]?.editMode) {
      dispatch(setCurrentProgramService(program.id, acctProfile?.id))
      dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
    }
  }

  const toggleProgramStatus = async (programId: string) => {
    const isCurrentProgramActive = editProgram[programId]?.isActive
    setIsLoading(true)
    const result = await dispatch(
      updateProgramStatusService(
        programId,
        !isCurrentProgramActive,
        acctProfile?.id
      )
    )
    if (!result.success) {
      Alert.show(`${result.reason}`)
    }
    setIsLoading(false)
  }

  const getContextMenu = (
    id: string,
    selectedProgram?: EditProgramItemInterface
  ) => {
    return (
      <Menu
        id={id}
        open={selectedProgram?.popUpOpen || false}
        anchorEl={selectedProgram?.popUpAnchor || null}
        onClose={() => {
          if (selectedProgram) {
            updateSelectedProgram(
              { popUpOpen: false, popUpAnchor: selectedProgram.popUpAnchor },
              id
            )
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          onClick={() => {
            updateSelectedProgram({ popUpOpen: false, editMode: true }, id)
          }}
          sx={{ color: theme.palette.neutral_dark.dark }}>
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateSelectedProgram({ popUpOpen: false }, id)
            toggleProgramStatus(id)
          }}
          sx={{ color: theme.palette.neutral_dark.dark }}>
          {selectedProgram?.isActive ? 'Deactivate' : 'Activate'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateSelectedProgram({ popUpOpen: false }, id)
          }}>
          Delete
        </MenuItem>
      </Menu>
    )
  }

  const updateProgramName = async (
    id: string,
    stateItem?: EditProgramItemInterface
  ) => {
    if (stateItem && stateItem.rename) {
      setIsLoading(true)
      const result = await dispatch(
        updateProgramNameService(id, stateItem.rename, acctProfile?.id)
      )
      if (result.success) {
        updateSelectedProgram({ editMode: false }, id)
      } else {
        Alert.show(`${result.reason}`)
      }
      setIsLoading(false)
    }
  }

  return (
    <Stack sx={styles.fullTableStack}>
      <>
        <Stack direction="row" sx={styles.headerRow}>
          <Typography
            variant="h5"
            fontWeight="bold"
            color={theme.palette.neutral_dark.dark}>
            Recent Programs
          </Typography>
          <Typography
            variant="h5"
            fontWeight="bold"
            color={theme.palette.neutral_dark.dark}
            sx={styles.lastModifiedDate}>
            Last Modified Date
          </Typography>
        </Stack>
        <Divider />
      </>
      <List disablePadding sx={styles.list}>
        {tableData.map((program) => (
          <React.Fragment key={program.name}>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <Brightness1Icon
                  color={
                    editProgram[program.id]?.isActive ? 'success' : 'disabled'
                  }
                />
              </ListItemIcon>
              <ListItemButton
                onClick={() => onProgramSelected(program)}
                disableRipple={editProgram[program.id]?.editMode}>
                <ListItemText
                  primary={
                    <Stack direction="row">
                      {editProgram[program.id]?.editMode ? (
                        <Box sx={styles.inputContainer}>
                          <TextInputComponent
                            label=""
                            width="100%"
                            value={editProgram[program.id]?.rename}
                            placeholder="Program Name"
                            onValueChange={(e) => {
                              onProgramNameChange(e, program.id)
                            }}
                          />
                          <DefaultButtonComponent
                            label="Save"
                            onButtonClick={() => {
                              updateProgramName(
                                program.id,
                                editProgram[program.id]
                              )
                            }}
                            sx={styles.saveButton}
                            disabled={
                              !editProgram[program.id]?.rename || isLoading
                            }
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant="h4"
                          color={theme.palette.neutral_dark.dark}>
                          {program.name}
                        </Typography>
                      )}
                      <Typography
                        variant="h4"
                        color={theme.palette.neutral_dark.dark}
                        sx={styles.updatedAt}>
                        <Moment format="MMM DD, YYYY  HH:mm">
                          {program.updatedAt}
                        </Moment>
                      </Typography>
                    </Stack>
                  }
                  sx={{}}
                />
              </ListItemButton>
              <IconButton
                aria-label="delete"
                onClick={(e) => {
                  updateSelectedProgram(
                    { popUpOpen: true, popUpAnchor: e.currentTarget },
                    program.id
                  )
                }}
                size="large"
                sx={styles.iconButton}>
                <ListIcon color="secondary" />
              </IconButton>
            </ListItem>
            <Divider />
            {getContextMenu(program.id, editProgram[program.id])}
          </React.Fragment>
        ))}
      </List>
    </Stack>
  )
}

export default AdminProgramSelectComponent
