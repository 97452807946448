import { createSlice } from '@reduxjs/toolkit'
import {
  ApiResponseInterface,
  BlockItemInterface,
  BlockSettingsInterface
} from 'types'

import blockAPI from 'datastore/apis/block-api'
import { PAGE_ADMIN_BLOCK_DESIGN } from 'datastore/utils/constants'
import utils from 'datastore/utils/functions'
import { changePageService } from 'datastore/slices/app-controller'
import {
  getProgramListService,
  setCurrentProgramService
} from 'datastore/slices/program-controller'
import {
  resetBotTreeDSAction,
  setBotTreeDSAction
} from 'datastore/slices/bot-tree-controller'

import { v4 as uuidv4 } from 'uuid'
import { Console } from 'logger'
import log from 'loglevel'

interface BlockControllerStateInterface {
  currentBlock: BlockItemInterface | Record<string, never>
  currentBlockSettingsData: BlockSettingsInterface
}

// Define the initial state using that type
const initialState: BlockControllerStateInterface = {
  currentBlock: {},
  currentBlockSettingsData: {}
}

const blockController = createSlice({
  name: 'block-controller',
  initialState,
  reducers: {
    setCurrentBlockAction(state, action) {
      return {
        ...state,
        currentBlock: action.payload
      }
    },
    resetBlockControllerAction() {
      return { ...initialState }
    },
    updateBlockSettingsAction(state, action) {
      return {
        ...state,
        currentBlockSettingsData: action.payload
      }
    }
  }
})

export const {
  setCurrentBlockAction,
  resetBlockControllerAction,
  updateBlockSettingsAction
} = blockController.actions
export default blockController.reducer

function getNodeTypeSuffix(processedNodes: any, objectID: string) {
  const refNode = processedNodes.find((ref: any) => {
    return ref.objectID === objectID
  })

  let nodeSuffix = '_new'
  if (refNode) nodeSuffix = '_ref'

  return nodeSuffix
}

function getRefID(processedNodes: any, objectID: string) {
  const refNode = processedNodes.find((ref: any) => {
    return ref.objectID === objectID
  })

  return refNode.nodeID
}

// ============
// Action Functions / Asynchronous Actions
// ============
export function setCurrentBlockService(blockID: string) {
  return async function (dispatch: any) {
    try {
      const res = await blockAPI.fetchBlock(blockID)
      log.info(res.data)
      if (res.status === 200) {
        if (!res.data.draftBotJSON) {
          // reset bot-tree
          dispatch(resetBotTreeDSAction())
        } else {
          // load/convert draft-bot logic data into bot-tree and send to the redux controller

          const treeData: any = {}
          const logicData: any = JSON.parse(
            JSON.stringify(res.data.draftBotJSON)
          )
          const rootState: any = logicData.states.find((state: any) => {
            return state.isStart === true
          })
          const objectsToProcessQueue: any = [
            {
              stateID: rootState.id,
              nodeObj: treeData
            }
          ]
          const processedStateNodes: any = []
          const processedOptionNodes: any = []
          log.info(
            '**** blockController::setCurrentBlockService::rootState.id: ',
            rootState.id
          )

          while (objectsToProcessQueue.length > 0) {
            const objectToProcess = objectsToProcessQueue.shift()
            const objectToProcessID = objectToProcess.stateID
            const stateToProcess: any = logicData.states.find((state: any) => {
              return state.id === objectToProcessID
            })
            log.info(
              '**** blockController::setCurrentBlockService::stateToProcess: ',
              stateToProcess
            )
            const nodeToProcess = objectToProcess.nodeObj

            nodeToProcess.name = stateToProcess.name
            nodeToProcess.objectID = stateToProcess.id
            nodeToProcess.nodeType = `state${getNodeTypeSuffix(
              processedStateNodes,
              nodeToProcess.objectID
            )}`
            nodeToProcess.id =
              stateToProcess.isStart && nodeToProcess.nodeType === 'state_new'
                ? 'uuid-root'
                : uuidv4()
            nodeToProcess.isComplete = true // bot-tree that has an incomplete node should not be able to saved in the first place

            if (nodeToProcess.nodeType === 'state_new') {
              nodeToProcess.desc = stateToProcess.desc
              nodeToProcess.stateType = stateToProcess.type
              nodeToProcess.distToEndStates = stateToProcess.distToEndStates

              Console.assert(
                stateToProcess.actions.length === 2,
                `AssertError: stateToProcess with id: ${stateToProcess.id} is expected to have 2 actions`
              )
              Console.assert(
                stateToProcess.actions[0].name === 'speak',
                `AssertError: stateToProcess with id: ${stateToProcess.id} is expected to have the first action as a speak-action`
              )
              nodeToProcess.audioURLs = stateToProcess.actions[0].data.audioURLs

              if (stateToProcess.actions[1].name === 'listen') {
                nodeToProcess.children = []
                stateToProcess.actions[1].data.transitions.forEach(
                  (transObj: any, index: number) => {
                    const optionToProcess: any = logicData.intentOptions.find(
                      (option: any) => {
                        return option.id === transObj.optionID
                      }
                    )

                    log.info(
                      '**** blockController::setCurrentBlockService::optionToProcess: ',
                      optionToProcess
                    )
                    // populate option-node
                    let optionData: any = {}
                    if (transObj.optionID === 'system-option-003') {
                      optionData = {
                        id: uuidv4(),
                        name: transObj.optionName,
                        objectID: transObj.optionID,
                        nodeType: `option_sys`
                      }
                    } else {
                      optionData = {
                        id: uuidv4(),
                        name: transObj.optionName,
                        objectID: transObj.optionID,
                        desc: optionToProcess.desc,
                        nodeType: `option${getNodeTypeSuffix(
                          processedOptionNodes,
                          optionToProcess.id
                        )}`
                      }
                    }
                    optionData.deletable = index > 1
                    optionData.isComplete = true // bot-tree that has an incomplete node should not be able to saved in the first place

                    if (optionData.nodeType === 'option_new') {
                      optionData.audioURLs =
                        optionToProcess.phraseList.audioURLs

                      // add to option-processed-node to processed-nodes-list
                      processedOptionNodes.push({
                        objectID: transObj.optionID,
                        nodeID: optionData.id,
                        nodeName: optionData.name
                      })
                    } else if (optionData.nodeType === 'option_ref') {
                      optionData.refID = getRefID(
                        processedOptionNodes,
                        optionToProcess.id
                      )
                    }

                    // create option-node's child and add to object-to-process-queue
                    optionData.children = [{}]
                    objectsToProcessQueue.push({
                      stateID: transObj.stateID,
                      nodeObj: optionData.children[0]
                    })

                    // add option-node to parent-state-node
                    nodeToProcess.children.push(optionData)
                  }
                )
              } else if (stateToProcess.actions[1].name === 'transition') {
                // create state-node's child and add to object-to-process-queue
                nodeToProcess.children = [{}]
                objectsToProcessQueue.push({
                  stateID: stateToProcess.actions[1].data.stateID,
                  nodeObj: nodeToProcess.children[0]
                })
              }

              // add to state-processed-node to processed-nodes-list
              processedStateNodes.push({
                objectID: nodeToProcess.objectID,
                nodeID: nodeToProcess.id,
                nodeName: nodeToProcess.name
              })
            } else if (nodeToProcess.nodeType === 'state_ref') {
              nodeToProcess.refID = getRefID(
                processedStateNodes,
                nodeToProcess.objectID
              )
            }
          }

          const statesList = processedStateNodes.map((stateNode: any) => {
            return { ddID: stateNode.nodeID, ddLabel: stateNode.nodeName }
          })
          const optionsList = processedOptionNodes.map((optionNode: any) => {
            return { ddID: optionNode.nodeID, ddLabel: optionNode.nodeName }
          })

          log.info('############################')
          // log.info('currentBlock.id: ', currentBlock.id)
          log.info('logicData: ', JSON.stringify(logicData))
          log.info('treeData: ', JSON.stringify(treeData))
          log.info('statesList: ', statesList)
          log.info('optionsList: ', optionsList)
          log.info('############################')

          dispatch(
            setBotTreeDSAction({
              botTree: treeData,
              statesList,
              optionsList
            })
          )
        }
        dispatch(setCurrentBlockAction(res.data))
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export function createBlockService(
  blockName: string,
  blockType: string,
  blockProgramID: string,
  adminID?: string
) {
  return async function (dispatch: any) {
    try {
      const res = await blockAPI.createBlock(
        {
          name: blockName,
          type: blockType,
          programID: blockProgramID
        },
        adminID ?? ''
      )
      log.info(res.data)
      if (res.status === 200) {
        log.info(
          `**** blockController::createBlockService: ${JSON.stringify(
            res.data
          )}`
        )
        dispatch(getProgramListService({ owner: true, adminID }))
        dispatch(setCurrentProgramService(res.data.id, adminID))
        dispatch(changePageService(PAGE_ADMIN_BLOCK_DESIGN))
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export function updateBlockNameService(
  id: string,
  name: string,
  adminID?: string
) {
  return async function (dispatch: any): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: ''
    }
    try {
      const res = await blockAPI.updateBlockName(id, { name })
      if (res.status === 200) {
        dispatch(getProgramListService({ owner: true, adminID }))
        apiResponse.success = true
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function updateBlockDataService(
  id: string,
  data: any,
  adminID?: string
) {
  return async function (dispatch: any): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: ''
    }
    try {
      const res = await blockAPI.updateBlockData(id, data)
      log.info('updateBlockDataService::res: ', res)

      if (res.status === 200) {
        dispatch(getProgramListService({ owner: true, adminID }))
        apiResponse.success = true
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function deleteBlockService(blockID: string, adminID?: string) {
  return async function (dispatch: any) {
    try {
      const res = await blockAPI.deleteBlock(blockID)
      log.info(res)
      if (res.status === 200) {
        log.info(`**** blockController::deleteBlockService: ${res.data}`)
        await dispatch(resetBlockControllerAction())
        // dispatch(changePageService(PAGE_ADMIN_PROGRAM_SELECT))
        await dispatch(getProgramListService({ owner: true, adminID }))
      }
    } catch (error) {
      log.error(error)
    }
  }
}

export function interactBlockService(
  blockID: string,
  userID: string,
  status: string,
  userUtteranceBlobURL: string,
  userAction: string
) {
  return async function (): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: '',
      data: undefined
    }
    try {
      let userUtteranceFile = null
      if (userUtteranceBlobURL) {
        const userUtteranceBlob = await fetch(userUtteranceBlobURL).then(
          (res) => res.blob()
        )
        userUtteranceFile = new File([userUtteranceBlob], 'user_speech.wav')
      }
      const res = await blockAPI.interactBlock(blockID, {
        userID,
        status,
        userUtteranceFile,
        userAction
      })

      log.info(
        '**** blockController::interactBlockService::res.statusText: ',
        res.status
      )
      log.info(
        '**** blockController::interactBlockService::res.data: ',
        JSON.stringify(res.data)
      )

      if (res.status === 200) {
        apiResponse.success = true
        apiResponse.data = res.data
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function publishBlockService(
  id: string,
  adminID?: string
): (dispatch: any) => Promise<ApiResponseInterface> {
  return async function (dispatch: any): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: ''
    }
    try {
      const res = await blockAPI.publishBlock(id)
      log.info('publishBlockService::res: ', res)

      if (res.status === 200) {
        dispatch(getProgramListService({ owner: true, adminID }))
        apiResponse.success = true
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function updateBlockSettingsService(bsData: BlockSettingsInterface) {
  return async function (dispatch: any) {
    log.info('*** blockController::updateBlockSettingsService ***')
    try {
      log.info(`******** ${JSON.stringify(bsData)} ********`)

      dispatch(updateBlockSettingsAction(bsData))
    } catch (error) {
      log.info(error)
    }
  }
}
