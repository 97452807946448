import { createSlice } from '@reduxjs/toolkit'
import {
  // PAGE_USER_AUTH,
  // ARENA_PANEL_STATE,
  // ARENA_PANEL_OPTION,
  // ARENA_PANEL_PREVIEW,
  ARENA_PANEL_NONE
} from 'datastore/utils/constants'
import log from 'loglevel'

interface ArenaPanelControllerStateInterface {
  display?: string
  newStateIndex?: number
  stateTypeIndex?: number
  stateName?: string
  stateDesc?: string
  stateAudioList?: string[]
  stateRefID?: string
  newOptionIndex?: number
  optionName?: string
  optionDesc?: string
  optionAudioList?: string[]
  optionRefID?: string
  previewTypeIndex?: number
}

// Define the initial state using that type
const initialState: ArenaPanelControllerStateInterface = {
  display: ARENA_PANEL_NONE,
  newStateIndex: -1,
  stateTypeIndex: -1,
  stateName: '',
  stateDesc: '',
  stateAudioList: [],
  stateRefID: '',
  newOptionIndex: -1,
  optionName: '',
  optionDesc: '',
  optionAudioList: [''],
  optionRefID: '',
  previewTypeIndex: -1
}

export const arenaPanelController = createSlice({
  name: 'arena-panel-controller',
  initialState,
  reducers: {
    setCurrentDisplayAction: (state, action) => {
      if (state.display !== action.payload) {
        return {
          ...state,
          display: action.payload
        }
      }

      return { ...state }
    },
    setStateArenaPanelAction: (state, action) => {
      log.info('**** arenaPanelController::setStateArenaPanelAction ****')
      log.info('action.payload: ', action.payload)

      const params = { ...state }
      if ('newStateIndex' in action.payload)
        params.newStateIndex = action.payload.newStateIndex
      if ('stateTypeIndex' in action.payload)
        params.stateTypeIndex = action.payload.stateTypeIndex
      if ('stateName' in action.payload)
        params.stateName = action.payload.stateName
      if ('stateDesc' in action.payload)
        params.stateDesc = action.payload.stateDesc
      if ('stateAudioList' in action.payload)
        params.stateAudioList = action.payload.stateAudioList
      if ('stateRefID' in action.payload)
        params.stateRefID = action.payload.stateRefID

      return params
    },
    setOptionArenaPanelAction: (state, action) => {
      log.info('**** arenaPanelController::setOptionArenaPanelAction ****')
      log.info(
        '**** arenaPanelController::setOptionArenaPanelAction::action.payload: ',
        action.payload
      )

      const params = { ...state }
      if ('newOptionIndex' in action.payload)
        params.newOptionIndex = action.payload.newOptionIndex
      if ('optionName' in action.payload)
        params.optionName = action.payload.optionName
      if ('optionDesc' in action.payload)
        params.optionDesc = action.payload.optionDesc
      if ('optionAudioList' in action.payload)
        params.optionAudioList = action.payload.optionAudioList
      if ('optionRefID' in action.payload)
        params.optionRefID = action.payload.optionRefID

      return params
    },
    setPreviewArenaPanelAction: (state, action) => {
      const params = { ...state }
      if ('previewTypeIndex' in action.payload)
        params.previewTypeIndex = action.payload.previewTypeIndex

      return params
    },
    clearArenaPanelAction: () => {
      return { ...initialState }
    }
  }
})

export const {
  setCurrentDisplayAction,
  setStateArenaPanelAction,
  setOptionArenaPanelAction,
  setPreviewArenaPanelAction,
  clearArenaPanelAction
} = arenaPanelController.actions
export default arenaPanelController.reducer
