import { ApiResponseInterface } from 'types'

import uploadAPI from 'datastore/apis/upload-api'
import utils from 'datastore/utils/functions'
import log from 'loglevel'

// ============
// Action Functions / Asynchronous Actions
// ============
export function uploadBotSpeechService(
  blockID: string,
  stateID: string,
  botSpeechBlobURL: string
) {
  return async function (): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: '',
      data: undefined
    }
    try {
      const botSpeechBlob = await fetch(botSpeechBlobURL).then((res) =>
        res.blob()
      )
      const botSpeechFile = new File([botSpeechBlob], 'bot_speech.wav')
      const res = await uploadAPI.uploadBotSpeech({
        blockID,
        stateID,
        botSpeechFile
      })

      log.info(
        '**** uploadController::uploadBotSpeechService::res.statusText: ',
        res.status
      )
      log.info(
        '**** uploadController::uploadBotSpeechService::res.data: ',
        res.data
      )

      if (res.status === 200) {
        apiResponse.success = true
        apiResponse.data = res.data.path
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function uploadAudioPhraseService(
  blockID: string,
  optionID: string,
  phraseIndex: string,
  audioPhraseBlobURL: string
) {
  return async function (): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: '',
      data: undefined
    }
    try {
      const audioPhraseBlob = await fetch(audioPhraseBlobURL).then((res) =>
        res.blob()
      )
      const audioPhraseFile = new File([audioPhraseBlob], 'audio_phrase.wav')
      const res = await uploadAPI.uploadAudioPhrase({
        blockID,
        optionID,
        phraseIndex,
        audioPhraseFile
      })

      log.info(
        '**** uploadController::uploadAudioPhraseService::res.statusText: ',
        res.status
      )
      log.info(
        '**** uploadController::uploadAudioPhraseService::res.data: ',
        res.data
      )

      if (res.status === 200) {
        apiResponse.success = true
        apiResponse.data = res.data.path
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}

export function uploadProgramImageService(
  programId: string,
  programImageFile: File
) {
  return async function (): Promise<ApiResponseInterface> {
    const apiResponse: ApiResponseInterface = {
      success: false,
      reason: '',
      data: undefined
    }
    try {
      const res = await uploadAPI.uploadProgramImage({
        programId,
        programImageFile
      })

      log.info(
        '**** uploadController::uploadProgramImageService::res.statusText: ',
        res.status
      )
      log.info(
        '**** uploadController::uploadProgramImageService::res.data: ',
        res.data
      )

      if (res.status === 200) {
        apiResponse.success = true
        apiResponse.data = res.data.path
      }
    } catch (error) {
      log.error(error)
      apiResponse.reason = utils.getErrorMessage((error as any).response.data)
    }
    return apiResponse
  }
}
