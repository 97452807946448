import botApi from 'datastore/apis/bot-api'
import { useCallback } from 'react'

export default function useReloadBotConfigApi() {
  const callback = useCallback(
    async (onSucceed: () => unknown, onFailure: () => unknown) => {
      try {
        await botApi.reloadBotConfig()
        if (onSucceed) {
          onSucceed()
        }
      } catch (e) {
        if (onFailure) {
          onFailure()
        }
      }
    },
    []
  )

  return callback
}
