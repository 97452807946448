import axios from 'axios'

import { store as reduxStore } from 'datastore/config/store'
import {
  URI_CONVO_THREAD_INTERACT,
  URI_CONVO_THREAD_RESUME,
  URI_ALERT_WEBHOOK,
  URI_FETCH_CONVO_THREAD,
  URI_LIST_V2_PROGRAMS,
  URI_THREAD_ROUTE_CONFIG_MODELS
} from 'datastore/apis/constants'
import { V2ProgramItemInterface, ProgramListAPIParamsInterface } from 'types'
import { AMAZETHU_V2_LIVE, IS_PROD_ENV } from 'datastore/utils/constants'

async function fetchV2ProgramList(queryParams: ProgramListAPIParamsInterface) {
  return axios.get<V2ProgramItemInterface[]>(`${URI_LIST_V2_PROGRAMS}`, {
    params: queryParams
  })
}

async function fetchConvoThread(botID: string, userID: string) {
  return axios.get<any>(`${URI_FETCH_CONVO_THREAD}/${userID}/${botID}`)
}

async function getThreadResumeActions(
  userID: string,
  botID: string,
  outputType: string,
  inputType: string,
  currLlmModel: string,
  currAsrModel: string,
  currTtsModel: string,
  confidenceScoreThreshold: number,
  currCcmModel: string,
  botVersion: string
) {
  const storeState = reduxStore.getState()

  const {
    asrEnforceTargetLang,
    currentActiveASR,
    currentActiveLLM,
    currentActiveCCM,
    currentActiveTTS
  } = storeState.v2ProgramController

  const { runtimeAppEnv } = storeState.appController

  const url =
    !IS_PROD_ENV && runtimeAppEnv !== AMAZETHU_V2_LIVE
      ? `${URI_CONVO_THREAD_RESUME}/${userID}/${botID}?asrEnforceTargetLang=${asrEnforceTargetLang}&outputType=${outputType}&inputType=${inputType}&ccmModel=${currCcmModel}&llmModel=${currLlmModel}&asrModel=${currAsrModel}&ttsModel=${currTtsModel}&confidenceScoreThreshold=${confidenceScoreThreshold}&GenInvalidFakeEvaluation=true`
      : `${URI_CONVO_THREAD_RESUME}/${userID}/${botID}`
  return axios.post<any>(url, null, {
    headers: { 'FULL-API-VERSION': botVersion }
  })
}

async function getThreadInteractActionsWithFormData(
  threadID: string,
  outputType: string,
  inputType: string,
  bodyParams: any,
  currLlmModel: string,
  currAsrModel: string,
  currTtsModel: string,
  confidenceScoreThreshold: number,
  currCcmModel: string,
  botVersion: string
) {
  const storeState = reduxStore.getState()

  const { asrEnforceTargetLang } = storeState.v2ProgramController
  const { runtimeAppEnv } = storeState.appController

  const url =
    !IS_PROD_ENV && runtimeAppEnv !== AMAZETHU_V2_LIVE
      ? `${URI_CONVO_THREAD_INTERACT}/${threadID}?asrEnforceTargetLang=${asrEnforceTargetLang}&outputType=${outputType}&inputType=${inputType}&ccmModel=${currCcmModel}&llmModel=${currLlmModel}&asrModel=${currAsrModel}&ttsModel=${currTtsModel}&confidenceScoreThreshold=${confidenceScoreThreshold}&GenInvalidFakeEvaluation=true`
      : `${URI_CONVO_THREAD_INTERACT}/${threadID}`
  return axios.post<any>(url, bodyParams, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'FULL-API-VERSION': botVersion
    }
  })
}

async function getRouteConfigModels(extraHeader?: Record<string, string>) {
  const storeState = reduxStore.getState()

  const { currentBotVersion } = storeState.v2ProgramController

  if (currentBotVersion === '2.1') {
    return axios.get<any>(`${URI_THREAD_ROUTE_CONFIG_MODELS}`)
  }
  return axios.get<any>(`${URI_THREAD_ROUTE_CONFIG_MODELS}`, {
    headers: extraHeader
  })
}

async function sendAlertNotificationToWebhook(bodyParams: {
  message: string
  error: any
}) {
  return axios.post(`${URI_ALERT_WEBHOOK}`, bodyParams)
}

export default {
  fetchV2ProgramList,
  fetchConvoThread,
  getThreadResumeActions,
  getThreadInteractActionsWithFormData,
  getRouteConfigModels,
  sendAlertNotificationToWebhook
}

// ToDo: restructure calls to endpoints to enable usage such as 'programAPIs.fetchProgramList'
/*
const listPrograms = async (
  params: ProgramListAPIParamsInterface
): Promise<ProgramItemInterface[]> => {
  try {
    const response = await axios.get<ProgramItemInterface[]>(
      `${URI_LIST_PROGRAMS}`,
      {
        params
      }
    )
    return response.data
  } catch (e) {
    Console.log(e)
    return []
  }
}

export default { listPrograms }
*/
