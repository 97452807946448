import { createSlice } from '@reduxjs/toolkit'
import log from 'loglevel'

interface ConvoUIControllerStateInterface {
  // convoUILog?: ConvoUILogData[]
  convoUILog?: any
}

// Define the initial state using that type
const initialState: ConvoUIControllerStateInterface = {
  convoUILog: []
}

export const convoUIController = createSlice({
  name: 'convo-ui-controller',
  initialState,
  reducers: {
    resetConvoUILogAction: (state) => {
      log.info('**** convoUIController::resetConvoUILogAction ****')
      log.info('state.convoUILog: ', state.convoUILog)

      return {
        ...initialState
      }
    },
    setConvoUILogAction: (state, action) => {
      log.info('**** convoUIController::setConvoUILogAction ****')
      log.info('**** action.payload: ', action.payload)

      return {
        ...state,
        convoUILog: action.payload
      }
    }
  }
})

export const { resetConvoUILogAction, setConvoUILogAction } =
  convoUIController.actions
export default convoUIController.reducer
