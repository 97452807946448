export class Console {
  private static readonly logger = global.console || window?.console

  public static log(...args: unknown[]) {
    return Console.logger.log(...args)
  }

  public static error(...args: unknown[]) {
    return Console.logger.error(...args)
  }

  public static warn(...args: unknown[]) {
    return Console.logger.warn(...args)
  }

  public static info(...args: unknown[]) {
    return Console.logger.info(...args)
  }

  public static assert(condition: boolean, ...args: unknown[]) {
    return Console.logger.assert(condition, ...args)
  }
}
