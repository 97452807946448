import React, { createRef, useEffect } from 'react'
import { useNavigate } from 'react-router'
import log from 'loglevel'
import BufferPlayer from 'components/molecules/media/audio-players/buffer-player'
import { useGetUser } from 'hooks/use-authentication'
import { ProgramItemInterface, SessionStatuses } from '../../../../types'
import {
  useStoreDispatch,
  useStoreSelector
} from '../../../../datastore/config/hooks'
import { Console } from '../../../../logger'
import { changePageService } from '../../../../datastore/slices/app-controller'
import {
  MINIMUM_RECORD_LENGTH_MILLIS,
  PAGE_USER_PROGRAM_SELECT
} from '../../../../datastore/utils/constants'
import { AudioRecorderType } from '../../../molecules/media/audio-recorder'
import utilFuncs from '../../../../datastore/utils/functions'
import {
  interactBlockService,
  setCurrentBlockService
} from '../../../../datastore/slices/block-controller'
import LoadingProcessingComponent from './user-block-interact-loading-processing'
import SpeechFeedBacksComponent from './user-block-interact-speech-feedback'
import RecordFeedbackComponent from './user-block-interact-record-feedback'

export type ViewType = 'default' | 'auto-record' | 'auto-record-with-vad'
export const INTERACTION_OPTIONS: {
  value: ViewType
  setAudioUrls: (value: ((prevState: string[]) => string[]) | string[]) => void
} = { value: 'auto-record', setAudioUrls: () => null }

let audioPlayer: BufferPlayer | undefined
let isStopRecordingAllowed = true
let hasStoppedRecordingBeforeMinimumLength = false

const userBlockDataObject: Record<string, (...args: unknown[]) => unknown> = {}
const InteractionSetupComponent = () => {
  const [sessionStatus, setSessionStatus] =
    React.useState<SessionStatuses>('initializing')
  const [audioURLs, setAudioURLs] = React.useState<string[]>([])
  INTERACTION_OPTIONS.setAudioUrls = setAudioURLs

  // needed to use Olawunmi's recorder
  const [isRecording, setIsRecording] = React.useState(false)
  const [audioResult, setAudioResult] = React.useState('')

  const [shouldShowLoading, setShouldShowLoading] = React.useState(false)

  const dispatch = useStoreDispatch()
  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )
  const currentProgram: ProgramItemInterface = useStoreSelector(
    (storeState) => storeState.programController.currentProgram
  ) as ProgramItemInterface
  const recButtonRef = createRef<HTMLButtonElement>()
  const [isPlayPauseButtonDisabled, setIsPlayPauseButtonDisabled] =
    React.useState<boolean>(false)
  const [promptsLeft, setPromptsLeft] = React.useState<number>(0)
  const [isProgramComplete, setIsProgramComplete] =
    React.useState<boolean>(false)
  const [promptRetrys, setPromptRetrys] = React.useState<number>(0)
  const MAX_RETRYS = 3

  const userProfileJSON = useGetUser()
  const navigate = useNavigate()
  const viewType = INTERACTION_OPTIONS.value
  const audioRecorderType =
    viewType === 'auto-record-with-vad'
      ? AudioRecorderType.vad
      : AudioRecorderType.default

  const stopRecording = async () => {
    try {
      const audioRecorder = await utilFuncs.getGlobalRecorder(audioRecorderType)
      log.info('*** UserBlockInteract:: audioRecorder', audioRecorder)
      if (audioRecorder.didInitialized) {
        if (isRecording) {
          setIsRecording(false)
          log.info('******* UBlockInteract:: stoping Recording')
          await audioRecorder.stopRecording()
          log.info('******* UBlockInteract:: stopedRecording')
          setAudioResult(audioRecorder.audioUrl)
        }
      } else {
        log.error(
          '**** UserBlockInteract::stopRecording::error: media recorder is null or undefined ***'
        )
      }
    } catch (error) {
      log.error('**** UserBlockInteract::stopRecording::error: ', error)
    }
  }
  userBlockDataObject.stopRecording = stopRecording

  // needed to use Olawunmi's recorder
  const startRecording = async () => {
    try {
      const audioRecorder = await utilFuncs.getGlobalRecorder(audioRecorderType)
      if (audioRecorder.didInitialized) {
        if (!isRecording) {
          setIsRecording(true)
          audioRecorder.startRecording(async () => {
            utilFuncs.playSoundEffect('stop')
            await userBlockDataObject.stopRecording()
            setSessionStatus('processing')
            hasStoppedRecordingBeforeMinimumLength = false
          })
        }
      } else {
        if (!window.MediaRecorder) {
          alert(
            'Your browser version is too old, the application require available on recent version of your browser'
          )
          navigate('/')
        }
        log.error(
          '**** UserBlockInteract::startRecording::error: media recorder is null or undefined ***'
        )
      }
    } catch (error) {
      log.error('**** UserBlockInteract::startRecording::error: ', error)
    }
  }

  // get inital block audio response to begin conversation with user
  const updateBlockInteraction = async (userAction: string) => {
    setShouldShowLoading(true)

    const audioBlobString = userAction === 'speech' ? audioResult : ''
    const updateBlockResponse = await Promise.all([
      new Promise((resolve) => {
        log.info('*** UserBlockInteract::audioResult: ', audioResult)
        if (audioResult) {
          utilFuncs.awaitTimeout(1000).then(() => {
            // playback user-utterance while waiting for response from server
            audioPlayer = utilFuncs.updateGlobalAudio(audioResult)
            audioPlayer?.play()
            audioPlayer.onended = resolve
          })
        } else {
          resolve(null)
        }
      }),
      dispatch(
        interactBlockService(
          currentBlock.id,
          userProfileJSON.uid,
          'live',
          audioBlobString,
          userAction
        )
      )
    ])
    log.info(
      `*** UserBlockInteract::updateBlockResponse: ${JSON.stringify(
        updateBlockResponse
      )} ***`
    )

    const response = updateBlockResponse[1]
    log.info('*** UserBlockInteract::response: ', response)

    let isPassedUser = true
    // parse data to get number of prompts left
    log.info(
      '*** UserBlockInteract::response.data.distToEndStates: ',
      response.data.distToEndStates
    )
    if (response.data.distToEndStates) {
      const dictionary: { [key: string]: number } =
        response.data.distToEndStates
      const largestValue = Math.max(...Object.values(dictionary))

      log.info('*** UserBlockInteract::largestValue: ', largestValue)
      log.info('*** UserBlockInteract::promptsLeft: ', promptsLeft)
      if (largestValue === promptsLeft) {
        isPassedUser = false
        setPromptRetrys(promptRetrys + 1)
      }
      setPromptsLeft(largestValue)
    } else {
      setPromptsLeft(-1)
    }

    // parse data to get the list of bot-speech from speak actions
    const speakActionObjs: any = response.data.actionsList.filter(
      (action: any) => {
        return action.name === 'speak'
      }
    )
    log.info('*** UserBlockInteract::speakActionObjs: ', speakActionObjs)

    // set audioURLs to play based on speakActionObjs values
    const urls: any[] = []
    speakActionObjs.forEach((speakAction: any) => {
      urls.push(speakAction.data.audioURLs[0])
    })
    log.info('*** UserBlockInteract::urls: ', urls)

    if (userAction === 'speech' || userAction === 'next') {
      /*
      await utilFuncs.awaitTimeout(1000).then(() => {
        // play beep-sound before playing response audios from server
        utilFuncs.playAfterRecordBeep(async () =>{
          await utilFuncs.awaitTimeout(1000).then(() => {
            setAudioURLs([...urls])
          })
        })
      })
      */

      const fxName = isPassedUser && userAction !== 'next' ? 'pass' : 'fail'
      // play beep-sound before playing response audios from server
      utilFuncs.playSoundEffect(fxName, () => {
        setAudioURLs([...urls])
      })
    } else {
      setAudioURLs([...urls])
    }

    setShouldShowLoading(false)
  }

  const handleOnPauseAudio = () => {
    log.info('*** UserBlockInteract::handleOnPauseAudio')
    log.info(
      '*** UserBlockInteract::handleOnPauseAudio::audioPlayer: ',
      audioPlayer
    )

    if (audioPlayer) {
      audioPlayer.pause()
    }
  }

  const handleOnPlayAudio = () => {
    log.info('*** UserBlockInteract::handleOnPlayAudio')
    log.info('*** UserBlockInteract::handleOnPlayAudio::audioURLs: ', audioURLs)
    log.info(
      '*** UserBlockInteract::handleOnPlayAudio::audioPlayer: ',
      audioPlayer
    )

    if (sessionStatus === 'pausing') {
      Console.assert(
        !!audioPlayer,
        'AssertError: audioPlayer is expected to have a valid value'
      )

      audioPlayer?.play()
    } else if (audioURLs.length > 0) {
      const audioURL = audioURLs.shift()

      audioPlayer = utilFuncs.updateGlobalAudio(audioURL)
      audioPlayer?.play()

      setIsPlayPauseButtonDisabled(false)

      if (audioPlayer)
        audioPlayer.onended = () => {
          // Disable play/pause button after audio ends (before recording starts)
          setIsPlayPauseButtonDisabled(true)
          handleOnPlayAudio()
        }
    } else if (promptsLeft > 0) {
      // play beep-sound before allowing user to record
      utilFuncs.playSoundEffect('ready', () => {
        audioPlayer = undefined
        setSessionStatus('ready_to_record')
      })
    } else if (promptsLeft <= 0) {
      setAudioResult('')

      // if this is not the last block in program's lesson-blocks list
      // trigger start of next-block in the list
      const blockIndex = currentProgram.lessonBlocks.findIndex(
        (item) => item.blockName === currentBlock.name
      )
      log.info('*** UserBlockInteract::currentBlock: ', currentBlock)
      log.info('*** UserBlockInteract::blockIndex: ', blockIndex)
      log.info(
        '*** UserBlockInteract::currentBlockByIndex: ',
        currentProgram.lessonBlocks[blockIndex]
      )
      log.info(
        '*** UserBlockInteract::nextBlockByIndex: ',
        currentProgram.lessonBlocks[blockIndex + 1]
      )

      // end current block interaction
      if (currentBlock.id) {
        dispatch(
          interactBlockService(
            currentBlock.id,
            userProfileJSON.uid,
            'live',
            '',
            'end'
          )
        )
      }

      if (blockIndex + 1 < currentProgram.lessonBlocks.length) {
        // set current block to next block in lesson-blocks list
        dispatch(
          setCurrentBlockService(
            currentProgram.lessonBlocks[blockIndex + 1].blockID
          )
        )
      } else {
        // remove program-id from user-progress-data in local-storage
        // this means user has to start program from beginning
        // once it has been completed
        const userProgressData = localStorage.getItem('userProgressData')
        if (userProgressData) {
          const userProgressJSON = JSON.parse(userProgressData)
          delete userProgressJSON[currentProgram.id]

          localStorage.setItem(
            'userProgressData',
            JSON.stringify(userProgressJSON)
          )
        }

        setIsProgramComplete(true)
      }
    }
  }

  const saveUserProgressLocally = () => {
    if (!isProgramComplete && currentProgram.id && currentBlock.id) {
      // load and modify user-progress-data to local-storage
      // in web-browser
      const userProgressData = localStorage.getItem('userProgressData')
      let userProgressJSON = null
      if (userProgressData) {
        userProgressJSON = JSON.parse(userProgressData)
        userProgressJSON[currentProgram.id] = currentBlock.id
      } else {
        userProgressJSON = { [currentProgram.id]: currentBlock.id }
      }
      if (userProgressJSON) {
        localStorage.setItem(
          'userProgressData',
          JSON.stringify(userProgressJSON)
        )
      }
    }
  }

  React.useEffect(() => {
    log.info('*** UserBlockInteract::re-render due to change to currentBlock')
    log.info('*** UserBlockInteract::currentBlock: ', currentBlock)

    setIsProgramComplete(false)
    if (currentBlock.id) {
      saveUserProgressLocally()

      updateBlockInteraction('resume').catch((error) => {
        log.error(
          '**** UserBlockInteract::updateBlockInteraction::resume-error: ',
          error
        )
        setShouldShowLoading(false)
      })
    } else {
      // change page back to program select if current-block-id is not valid
      dispatch(changePageService(`${PAGE_USER_PROGRAM_SELECT}`))
      setAudioURLs([])
    }
  }, [currentBlock])

  React.useEffect(() => {
    log.info(
      '*** UserBlockInteract::re-render due to change to audioResult ****'
    )
    log.info('*** UserBlockInteract::audioResult: ', audioResult)
    log.info('*** UserBlockInteract::sessionStatus: ', sessionStatus)
    log.info('*** UserBlockInteract::currentBlock.id: ', currentBlock.id)
    log.info('*** UserBlockInteract::promptRetrys: ', promptRetrys)
    log.info('*** UserBlockInteract::MAX_RETRYS: ', MAX_RETRYS)

    if (audioResult && currentBlock.id) {
      if (promptRetrys >= MAX_RETRYS) {
        setPromptRetrys(0)
        updateBlockInteraction('next').catch((error) => {
          log.error(
            '**** UserBlockInteract::updateBlockInteraction::next-error: ',
            error
          )
          setShouldShowLoading(false)
        })
      } else {
        updateBlockInteraction('speech').catch((error) => {
          log.error(
            '**** UserBlockInteract::updateBlockInteraction::speech-error: ',
            error
          )
          setShouldShowLoading(false)
        })
      }
    }
  }, [audioResult])

  React.useEffect(() => {
    log.info('*** UserBlockInteract::re-render due to change to audioURLs ****')
    log.info('*** UserBlockInteract::audioURLs: ', audioURLs)

    // enable auto-play if the session-status is either
    // currently playing audio or processing speech
    if (
      audioURLs.length > 0 &&
      (sessionStatus === 'initializing' ||
        sessionStatus === 'playing' ||
        sessionStatus === 'processing')
    ) {
      handleOnPlayAudio()
      setSessionStatus('playing')
    }
  }, [audioURLs])

  React.useEffect(() => {
    return () => {
      audioPlayer?.pause()
      audioPlayer = undefined
      isStopRecordingAllowed = true
      hasStoppedRecordingBeforeMinimumLength = false
      utilFuncs
        .getGlobalRecorder(audioRecorderType)
        .then((recorder) => recorder?.stopRecording())
    }
  }, [])

  const onMouseDown = async () => {
    if (
      sessionStatus === 'ready_to_record' &&
      !hasStoppedRecordingBeforeMinimumLength
    ) {
      log.info('*** UserBlockInteract::startRecording ')
      utilFuncs.playSoundEffect('start')
      startRecording()
      setSessionStatus('recording')
      isStopRecordingAllowed = false
    }
  }

  React.useEffect(() => {
    ;(async () => {
      if (viewType === 'auto-record-with-vad') {
        // pass.
      } else {
        await utilFuncs.awaitTimeout(MINIMUM_RECORD_LENGTH_MILLIS)
        isStopRecordingAllowed = true
        if (hasStoppedRecordingBeforeMinimumLength) {
          log.info('*** UserBlockInteract::stopRecording ')
          utilFuncs.playSoundEffect('stop')
          stopRecording()
          setSessionStatus('processing')
          hasStoppedRecordingBeforeMinimumLength = false
        }
      }
    })()
  }, [isRecording])

  const onMouseUp = () => {
    if (sessionStatus === 'recording') {
      if (isStopRecordingAllowed) {
        log.info('*** UserBlockInteract::stopRecording ')
        stopRecording()
        setSessionStatus('processing')
      } else {
        hasStoppedRecordingBeforeMinimumLength = true
      }
    }
  }

  useEffect(() => {
    if (viewType !== 'default') {
      // placeholder for testing.

      if (sessionStatus === 'ready_to_record') {
        onMouseDown()
      } else if (sessionStatus === 'recording') {
        onMouseUp()
      }
    }
  }, [viewType, sessionStatus])

  useEffect(() => {
    if (recButtonRef.current) {
      return utilFuncs.disableMouseEventOnMobile(recButtonRef)
    }
    return function () {
      return null
    }
  }, [recButtonRef])

  return (
    <>
      {audioPlayer &&
      (sessionStatus === 'playing' || sessionStatus === 'pausing') ? (
        <SpeechFeedBacksComponent
          audioElement={audioPlayer}
          sessionStatus={sessionStatus}
          programComplete={isProgramComplete}
          playPauseButtonDisabled={isPlayPauseButtonDisabled}
          onClick={() => {
            /* if (sessionStatus === 'initializing') {
              // pass-raison: auto initializing speech.
            } else */
            if (sessionStatus === 'playing') {
              utilFuncs.playSoundEffect('pause', () => {
                handleOnPauseAudio()
                setSessionStatus('pausing')
              })
            } else {
              utilFuncs.playSoundEffect('play', () => {
                handleOnPlayAudio()
                setSessionStatus('playing')
              })
            }
          }}
        />
      ) : null}
      {sessionStatus === 'recording' || sessionStatus === 'ready_to_record' ? (
        <RecordFeedbackComponent
          sessionStatus={sessionStatus}
          onClick={() => null}
          ref={recButtonRef}
        />
      ) : null}
      {sessionStatus === 'processing' || sessionStatus === 'initializing' ? (
        <LoadingProcessingComponent
          isProcessing={sessionStatus === 'processing'}
        />
      ) : null}
    </>
  )
}

export default InteractionSetupComponent
