import React, { FunctionComponent } from 'react'

import { Button, SxProps, Theme } from '@mui/material'
import Typography from '@mui/material/Typography'

import { theme } from 'components/atoms/styles/theme'
import log from 'loglevel'

interface PropsInterface {
  label: string
  width?: string | number
  height?: string | number
  disabled?: boolean
  bgColor?: string
  textColor?: string
  hoverColor?: string
  sx?: SxProps<Theme>
  onButtonClick?: () => void
}

const defaultProps = {
  width: '102px',
  height: '36px',
  disabled: false,
  bgColor: theme.palette.primary.main,
  textColor: theme.palette.neutral_light.light,
  hoverColor: theme.palette.primary.main,
  sx: {},
  onButtonClick: () => {
    log.info('*** onButtonClick ***')
  }
}

const DefaultButtonComponent: FunctionComponent<PropsInterface> = ({
  label,
  width = defaultProps.width,
  height = defaultProps.height,
  disabled = defaultProps.disabled,
  bgColor = defaultProps.bgColor,
  textColor = defaultProps.textColor,
  hoverColor = defaultProps.hoverColor,
  onButtonClick = defaultProps.onButtonClick,
  sx
}) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onButtonClick}
      disableElevation
      disableRipple
      sx={{
        textTransform: 'none',
        width,
        height,
        bgcolor: bgColor,
        '&:hover': {
          bgcolor: hoverColor
        },
        ...sx
      }}>
      <Typography variant="h4" fontWeight="550" color={textColor}>
        {label}
      </Typography>
    </Button>
  )
}

DefaultButtonComponent.defaultProps = defaultProps
export default DefaultButtonComponent
