import { SxProps } from '@mui/material'
import { theme } from 'components/atoms/styles/theme'

const noLineHeight: SxProps = { lineHeight: '0' }
const backButtonBox: SxProps = {}
const backButton: SxProps = {
  margin: '20px 0',
  cursor: 'pointer',
  fontSize: '1.9rem'
}
const programImage: SxProps = {
  img: {
    height: '88px',
    width: 'auto'
  }
}
const programInfoBox: SxProps = { position: 'relative', width: '100%' }
const programInfoSmBox: SxProps = {
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginTop: '10px'
  }
}
const programInfoContent: SxProps = {
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)'
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}
const programName: SxProps = { fontSize: '20px' }
const programPublisher: SxProps = { fontSize: '11px' }
const allLessons: SxProps = { marginTop: '20px' }
const listItem: SxProps = { padding: '15px 0' }
const fullWidth: SxProps = { width: '100%' }

export default {
  noLineHeight,
  backButton,
  programImage,
  programInfoBox,
  programInfoContent,
  programName,
  programPublisher,
  allLessons,
  listItem,
  fullWidth,
  backButtonBox,
  programInfoSmBox
}
