import { keyframes, SxProps } from '@mui/material'
import { SessionStatuses } from 'types'

const typographyBox = {
  position: 'relative',
  width: '100%',
  marginTop: '10px'
}

const playingAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`
function stateBg(sessionStatus: SessionStatuses, lessonEnded: boolean) {
  const baseGrad = 'linear-gradient(129deg, #8EC5FC 0%, #E0C3FC 84%)'
  let bg = baseGrad
  let animation = 'none'

  if (lessonEnded) {
    bg = 'linear-gradient(2deg, #e0e0e0 0%, #eeeeee 46%, #eeeeee 100%)'
  } else if (sessionStatus === 'playing') {
    bg = baseGrad
    animation = `${playingAnimation} 1.5s infinite`
  } else if (
    sessionStatus === 'ready_to_record' ||
    sessionStatus === 'recording'
  ) {
    bg = 'linear-gradient(62deg, #8EC5FC 0%, #ffeb3b 100%)'
    if (sessionStatus === 'recording') {
      animation = `${playingAnimation} 1.5s infinite`
    }
  }
  return {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: '-9999',
    backgroundImage: bg,
    backgroundSize: '200% 200%',
    animation
  }
}
const container = {
  zIndex: '1',
  position: 'relative',
  lineHeight: '0',
  flex: 1
}

const centerBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-56%)',
  width: '100%',
  padding: '0 16px',
  '@media (max-height: 520px)': {
    transform: 'none',
    position: 'static',
    padding: 'none'
  }
}

const stack = { margin: '18px 0' }
const programTitle = { margin: '10px 0', textAlign: 'center' }
const divider = { marginBottom: '20px' }

function iconBox(sessionStatus: SessionStatuses, context: 'play' | 'record') {
  let base: SxProps = {
    textAlign: 'center'
  }
  if (context === 'play') {
    base = {
      ...base,
      display:
        sessionStatus === 'initializing' ||
        sessionStatus === 'playing' ||
        sessionStatus === 'pausing' ||
        sessionStatus === 'processing'
          ? 'block'
          : 'none'
    }
  } else {
    base = {
      ...base,
      display:
        sessionStatus === 'ready_to_record' || sessionStatus === 'recording'
          ? 'block'
          : 'none'
    }
  }
  return base
}

const holdToRecLabel = {
  fontSize: '25px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '35px',
  color: '#ff5252',
  backgroundColor: '#fafafad4',
  transition: 'opacity 0.3s ease-in-out',
  opacity: '1'
}

const playPauseBox = {
  position: 'relative',
  marginBottom: '20px',
  width: '321px',
  height: '321px',
  fontSize: '100px',
  borderRadius: '0',
  backgroundImage: 'linear-gradient(135deg, #8bc6ecba 0%, #9599e25e 100%)',
  boxShadow: '1px 7px 11px -1px rgb(0 0 0 / 12%)',
  ':active': {
    transform: 'translateY(2px)',
    boxShadow: '0px 4px 11px -5px rgb(0 0 0 / 12%)'
  }
}

const recordingPulse = keyframes`
  0% {
    transform: translate(-50%,-50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: translate(-50%,-50%) scale(1);
    box-shadow: 0 0 0 30px rgba(255, 82, 82, 0);
  }

  100% {
    transform: translate(-50%,-50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`
const recordingPulse2 = keyframes`
  0% {
    transform: translateY(2px) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: translateY(2px) scale(1);
    box-shadow: 0 0 0 30px rgba(255, 82, 82, 0);
  }

  100% {
    transform: translateY(2px) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`
function recordBox(sessionStatus: SessionStatuses) {
  return {
    position: 'relative',
    marginBottom: '20px',
    width: '321px',
    height: '321px',
    fontSize: '100px',
    borderRadius: '0',
    backgroundImage: 'linear-gradient(180deg, #FF9A8B 0%, #FF6A88 99%)',
    boxShadow: '1px 7px 11px -1px rgb(0 0 0 / 12%)',
    svg: {
      zIndex: '1'
    },
    ':active': {
      transform: 'translateY(2px)',
      boxShadow: '0px 4px 11px -5px rgb(0 0 0 / 12%)'
    },
    animation:
      sessionStatus === 'recording'
        ? `${recordingPulse2} 1.2s infinite`
        : 'none'
  }
}

const pulseElement = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '150px',
  height: '150px',
  background: '#ffffff5c',
  borderRadius: '50%',
  zIndex: '0',
  animation: `${recordingPulse} 1.2s infinite`
}

export default {
  typographyBox,
  stateBg,
  container,
  centerBox,
  stack,
  programTitle,
  divider,
  iconBox,
  holdToRecLabel,
  playPauseBox,
  recordBox,
  pulseElement
}
