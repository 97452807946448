import React, { FunctionComponent } from 'react'

import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

import ConvoUIBubbleComponent from 'components/molecules/convo-ui/convo-ui-bubble'
import ConvoUIControlsComponent from 'components/molecules/convo-ui/convo-ui-controls'

import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { interactBlockService } from 'datastore/slices/block-controller'
import { setConvoUILogAction } from 'datastore/slices/convo-ui-controller'
import { Console } from 'logger'
import log from 'loglevel'

interface PropsInterface {
  bubbleWidth?: string | number
  bubbleHeight?: string | number
  controlsHeight?: string | number
}

const defaultProps = {
  bubbleWidth: 'default',
  bubbleHeight: 'default',
  controlsHeight: 'default'
}

const AdminBlockPreviewComponent: FunctionComponent<PropsInterface> = ({
  bubbleWidth = defaultProps.bubbleWidth,
  bubbleHeight = defaultProps.bubbleHeight,
  controlsHeight = defaultProps.controlsHeight
}) => {
  const dispatch = useStoreDispatch()
  const convoUILog = useStoreSelector(
    (storeState) => storeState.convoUIController.convoUILog
  )
  const currentBlock = useStoreSelector(
    (storeState) => storeState.blockController.currentBlock
  )
  const previewTypeIndex = useStoreSelector(
    (storeState) => storeState.arenaPanelController.previewTypeIndex
  )

  // get inital block audio response to begin conversation with user
  const startBlockInteraction = async (blockStatus: string) => {
    Console.assert(
      blockStatus === 'draft' || blockStatus === 'live',
      `AssertError: blockStatus has to be either draft or live instead of ${blockStatus}`
    )

    const response = await dispatch(
      interactBlockService(
        currentBlock.id,
        'user-admin',
        blockStatus,
        '',
        'reset'
      )
    )
    log.info('*** AdminBlockPreview::response: ', response)

    // parse data to get the list of bot-speech from speak actions
    const speakActionObjs: any = response.data.actionsList.filter(
      (action: any) => {
        return action.name === 'speak'
      }
    )
    log.info('*** AdminBlockPreview::speakActionObjs: ', speakActionObjs)

    // parse data to get the history of state transitions
    const transObjs = response.data.debugInfo.convoLog.transitions
    log.info('*** AdminBlockPreview::transObjs: ', transObjs)

    Console.assert(
      transObjs.length === speakActionObjs.length,
      `AssertError: at the start of the convo, the number of transitions in the convoLog is expected to be the same as the number of speak actions in the actionsList`
    )

    const tmpLog: any[] = []
    speakActionObjs.forEach((speakAction: any, index: number) => {
      tmpLog.push({
        type: 'text',
        speaker: 'bot',
        value: transObjs[index].stateName
      })
      tmpLog.push({
        type: 'audio',
        speaker: 'bot',
        value: speakAction.data.audioURLs[0]
      })
    })
    dispatch(setConvoUILogAction(tmpLog))
  }

  React.useEffect(() => {
    log.info(
      '*** AdminBlockPreview::re-render due to change to currentBlock or previewTypeIndex'
    )
    log.info('*** AdminBlockPreview::currentBlock: ', currentBlock)
    log.info('*** AdminBlockPreview::previewTypeIndex: ', previewTypeIndex)

    if (previewTypeIndex === undefined) {
      return
    }

    const blockStatus = ['draft', 'live'][previewTypeIndex]
    log.info('*** AdminBlockPreview::blockStatus: ', blockStatus)

    startBlockInteraction(blockStatus).catch((error) => {
      log.error('**** AdminBlockPreview::startBlockInteraction::error: ', error)
    })
  }, [currentBlock, previewTypeIndex])

  return (
    <Stack
      className="AdminBlockPreview"
      spacing={0}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex'
      }}>
      <Stack
        className="ChatWindow"
        sx={{
          width: '100%',
          height: '100%',
          display: 'table'
          // bgcolor: 'green' // ToReview: Hack to temporarily disable chat-friendly AdminBlockPreview
        }}>
        <Stack
          spacing={0.5}
          sx={{
            display: 'block',
            // display: 'none', // ToReview: Hack to temporarily disable chat-friendly AdminBlockPreview
            height: '100%',
            overflowY: 'scroll'
          }}>
          {convoUILog.map((data: any) => (
            <ConvoUIBubbleComponent
              type={data.type}
              speaker={data.speaker}
              value={data.value}
              width={bubbleWidth}
              height={bubbleHeight}
            />
          ))}
        </Stack>
      </Stack>
      <Divider />
      <ConvoUIControlsComponent height={controlsHeight} />
    </Stack>
  )
}

AdminBlockPreviewComponent.defaultProps = defaultProps
export default AdminBlockPreviewComponent
