import { MicVAD, utils } from '@ricky0123/vad-web'
import * as ort from 'onnxruntime-web'
import utilFuncs from 'datastore/utils/functions'
import { AudioRecorder } from '.'
import BufferPlayer from '../audio-players/buffer-player'

// change the config
ort.env.wasm.wasmPaths = {
  'ort-wasm-simd-threaded.wasm': '/ort-wasm-simd-threaded.wasm',
  'ort-wasm-simd.wasm': '/ort-wasm-simd.wasm',
  'ort-wasm.wasm': '/ort-wasm.wasm',
  'ort-wasm-threaded.wasm': '/ort-wasm-threaded.wasm'
}

const inAudioType = 'audio/wav'

export class AudioActivityRecorder implements AudioRecorder {
  private audioChunks = new Float32Array(0)

  private audioPlayer: BufferPlayer | undefined

  private vad: MicVAD | undefined

  private onSpeechEndCallback: (() => void) | undefined

  public mediaStream: MediaStream | undefined

  public audioUrl = ''

  public isSpeeching = false

  public get didInitialized() {
    return this.vad !== undefined
  }

  private onSpeechStart(): any {
    this.isSpeeching = true
  }

  private onSpeechEnd(audio: Float32Array): any {
    const mergedAudio = new Float32Array(this.audioChunks.length + audio.length)

    mergedAudio.set(this.audioChunks, 0)
    mergedAudio.set(audio, this.audioChunks.length)

    this.audioChunks = mergedAudio
    this.isSpeeching = false

    if (this.onSpeechEndCallback) {
      this.onSpeechEndCallback()
    }
  }

  async initMediaRecorder(): Promise<{ error: boolean }> {
    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) =>
            MicVAD.new({
              stream,
              onSpeechEnd: this.onSpeechEnd.bind(this),
              onSpeechStart: this.onSpeechStart.bind(this),
              modelURL: '/silero_vad.onnx',
              workletURL: '/vad.worklet.bundle.min.js'
            })
          )
          .then((vad) => {
            this.vad = vad
            resolve({ error: false })
          })
          .catch(reject)
      } else {
        resolve({ error: true })
      }
    })
  }

  async startRecording(onSpeechEnd?: () => void): Promise<boolean> {
    this.clearState()

    this.onSpeechEndCallback = onSpeechEnd
    if (!this.vad) {
      return false
    }
    this.vad?.start()
    return true
  }

  async stopRecording(): Promise<{ error: boolean }> {
    if (!this.didInitialized) {
      return { error: true }
    }
    this.vad?.pause()
    const audioBuffer = utils.encodeWAV(this.audioChunks)
    if (audioBuffer.byteLength > 0) {
      const base64Audio = utils.arrayBufferToBase64(audioBuffer)
      this.audioUrl = `data:audio/wav;base64,${base64Audio}`
    }
    return { error: false }
  }

  playAudio(onComplete: () => void) {
    this.audioPlayer = utilFuncs.updateGlobalAudio(this.audioUrl, inAudioType)
    if (this.audioPlayer) {
      this.audioPlayer.onended = () => {
        if (onComplete) {
          onComplete()
        }
      }
      this.audioPlayer.play()
    }
  }

  stopAudio() {
    if (this.audioPlayer) {
      this.audioPlayer.pause()
    }
  }

  clearState() {
    this.audioUrl = ''
    this.audioChunks = new Float32Array(0)
    this.audioPlayer = undefined
  }
}
