import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { updateTempConfigOptionsAction } from 'datastore/slices/v2-program-controller'
import React, { useEffect, useState } from 'react'
import { ModelInterface } from 'types'

const ASRDropdownComponent: React.FC<ModelInterface> = ({ data }) => {
  const [tempSelectedVersion, setTempSelectedVersion] = useState('')

  const currentActiveASR = useStoreSelector(
    (storeSelector) => storeSelector.v2ProgramController.currentActiveASR
  )

  const isMobileMenuOpen = useStoreSelector(
    (storeState) => storeState.appController.isMobileMenuOpen
  )

  const dispatch = useStoreDispatch()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setTempSelectedVersion(event.target.value)
    dispatch(updateTempConfigOptionsAction({ tempASR: event.target.value }))
  }

  useEffect(() => {
    setTempSelectedVersion(currentActiveASR)
  }, [isMobileMenuOpen])

  return (
    <Select
      value={tempSelectedVersion}
      onChange={handleChange}
      sx={{
        width: '100%',
        height: '30px',
        fontSize: '0.7rem'
      }}>
      {data?.map((model) => (
        <MenuItem
          key={model}
          value={model}
          sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
          {model}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ASRDropdownComponent
