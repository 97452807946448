import React from 'react'
import { useStoreDispatch, useStoreSelector } from 'datastore/config/hooks'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { setCurrentVersionAction } from 'datastore/slices/app-controller'
import {
  AMAZETHU_V1,
  AMAZETHU_V2_LIVE,
  AMAZETHU_V2_TESTBED
} from 'datastore/utils/constants'

const VersionDropdownComponent: React.FC = () => {
  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )
  const dispatch = useStoreDispatch()

  const shouldDisableDropdown = window.location.pathname === '/session'

  const handleChange = (event: SelectChangeEvent<string>) => {
    dispatch(setCurrentVersionAction(event.target.value as string))
  }

  const versions = [AMAZETHU_V1, AMAZETHU_V2_LIVE, AMAZETHU_V2_TESTBED]

  return (
    <Select
      value={runtimeAppEnv}
      onChange={handleChange}
      disabled={!!shouldDisableDropdown}
      id="version-dropdown">
      {versions.map((version) => (
        <MenuItem value={version} sx={{ color: 'rgba(0, 0, 0, 0.9)' }}>
          {version}
        </MenuItem>
      ))}
    </Select>
  )
}

export default VersionDropdownComponent
