import React from 'react'
import { useStoreSelector } from 'datastore/config/hooks'
import { Box, Button } from '@mui/material'
import { IS_PROD_ENV } from 'datastore/utils/constants'

const VersionChooserModalComponent: React.FC = () => {
  const runtimeAppEnv = useStoreSelector(
    (storeState) => storeState.appController.runtimeAppEnv
  )

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1300,
        width: 150,
        transformOrigin: 'top center',
        opacity: 0.8,
        transform: 'translate(50%) rotate(-45deg)',
        right: '32px',
        bottom: '8px',
        textAlign: 'center',
        background: 'purple',
        color: 'white'
      }}>
      <Button
        disabled
        className="versionChooserFlag"
        sx={{
          padding: '3px 20px',
          textAlign: 'center',
          background: 'purple',
          color: 'white',
          fontSize: '0.6rem',
          '&:disabled': { color: '#ddd' }
        }}>
        {runtimeAppEnv}
      </Button>
    </Box>
  )
}

const VersionFlagComponent: React.FC = ({ children }) => {
  return (
    <>
      {!IS_PROD_ENV && <VersionChooserModalComponent />}
      {children}
    </>
  )
}

export default VersionFlagComponent
