/* eslint-disable react/no-array-index-key */

import React, { FunctionComponent } from 'react'
import Carousel from 'react-material-ui-carousel'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { theme } from 'components/atoms/styles/theme'
import AudioPlayerComponent from 'components/molecules/media/audio-players/default'
import DefaultButtonComponent from 'components/atoms/buttons/default-button'

interface PropsInterface {
  width?: string | number
  height?: string | number
  buttonsWidth?: string | number
}

const defaultProps = {
  width: '100%',
  height: '300px',
  buttonsWidth: '200px'
}

const ConvoUIOptionsComponent: FunctionComponent<PropsInterface> = ({
  width = defaultProps.width,
  height = defaultProps.height,
  buttonsWidth = defaultProps.buttonsWidth
}) => {
  const perChunk = 2 // items per chunk
  const inputArray = ['a', 'b', 'c', 'd', 'e']
  const SplitArrayIntoChunks = (arr: string[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    )

  const fakeAudioUrlList =
    'https://cdn.pixabay.com/photo/2022/01/25/04/42/bird-6965228_1280.jpg '
      .repeat(6)
      .split(' ')
      .slice(0, 6)
  // log.info('*****************')
  // log.info(fakeAudioUrlList)
  const chunkedSubArrays = SplitArrayIntoChunks(inputArray, perChunk)
  // chunkedSubArrays.map((item, i) => log.info(item))
  // log.info('*****************')

  return (
    <Box
      className="ConvoUIOptions-Carousel-Container"
      // spacing={0}
      sx={{
        /* padding should be adjusted based on the presence of the audio for each option */
        pl: 0,
        pt: 0,
        pb: 0,
        pr: 0,
        backgroundColor: theme.palette.neutral_light.main,
        width,
        height,
        display: 'flex'
      }}>
      <Carousel
        className="ConvoUIOptions-Carousel"
        autoPlay={false}
        swipe={false}
        animation="slide"
        indicators={false}
        navButtonsAlwaysVisible
        cycleNavigation={false}
        sx={{
          width: '100%'
        }}>
        {chunkedSubArrays.map((subArray, mainIndex) => (
          <Box
            className={`ConvoUIOptions-Container-${mainIndex}`}
            key={mainIndex}
            sx={{
              width,
              height,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: '60px'
            }}>
            {subArray.map((optionName, subIndex) => (
              <Stack
                key={subIndex}
                className={`Option-${optionName}`}
                direction="column"
                sx={{
                  width: buttonsWidth,
                  height: '200px'
                }}>
                <DefaultButtonComponent
                  label={`Option-${optionName}`}
                  width="100%"
                />
                <Stack
                  className="OptionPhrases-Window"
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'table'
                  }}>
                  <Stack
                    className="OptionPhrases-Container"
                    spacing={1}
                    sx={{
                      display: 'block',
                      height: '100%',
                      overflowY: 'scroll'
                    }}>
                    {fakeAudioUrlList.map((url, i) => (
                      <AudioPlayerComponent key={i} pos="none" />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

ConvoUIOptionsComponent.defaultProps = defaultProps
export default ConvoUIOptionsComponent
